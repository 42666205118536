var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.canTakeTheCase
      ? _c("div", { staticClass: "top-action share" }, [
          _c("button", { staticClass: "button", on: { click: _vm.takeCase } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t.get("appointment.take-the-case")) +
                "\n    "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }