import Vue from "../vue.js"

import { forEach, chain } from "lodash"

import { Constants } from "../models/Constants"

export default {
  namespaced: true,
  state: {},
  mutations: {
    update(state, invitations) {
      forEach(invitations, (invitation) => Vue.set(state, invitation.invitation_id, invitation))
    },

    upsertInvitation(state, invitation) {
      Vue.set(state, invitation.invitation_id, invitation)
    },
  },
  getters: {
    getPendingInvitations: (state) => {
      return chain(state)
        .values()
        .filter((invitation) => invitation.status === Constants.Invitation.STATUS_CREATED)
        .orderBy(
          (invitation) => {
            return invitation.created_at
          },
          ["desc"]
        )
        .value()
    },
  },
  actions: {
    fetch({ commit }, { object_id }) {
      return Vue.http
        .get("/ajax/invitations/" + object_id)
        .catch((error) => {
          if (error.status !== 401) {
            return Promise.reject(error)
          }
        })
        .then((response) => {
          commit("update", response.data.data)
        })
    },
    inviteAppointmentParticipant({ commit }, params) {
      return Vue.http
        .post("/ajax/appointment/" + params.appointment_id + "/participants", params)
        .then((response) => {
          commit("upsertInvitation", response.data.data)
        })
    },
    acceptInvitation({ commit }, { invitation_id }) {
      let request = Vue.http.post("/ajax/invitation/" + invitation_id + "/accept")
      request.then((response) => {
        commit("upsertInvitation", response.data.data)
      })
      return request
    },
    declineInvitation({ commit }, { invitation_id }) {
      let request = Vue.http.post("/ajax/invitation/" + invitation_id + "/decline")
      request.then((response) => {
        commit("upsertInvitation", response.data.data)
      })
      return request
    },
    revokeInvitation({ commit }, { invitation_id }) {
      let request = Vue.http.post("/ajax/invitation/" + invitation_id + "/revoke")
      request.then((response) => {
        commit("upsertInvitation", response.data.data)
      })
      return request
    },
    resendInvitation({ commit }, { invitation_id }) {
      return Vue.http.post("/ajax/invitation/" + invitation_id + "/resend")
    },
    socket_appointment({ commit, dispatch }, appointment) {
      dispatch("fetch", { object_id: appointment.appointment_id })
    },
    socket_clinical_trial({ commit, dispatch }, trial_id) {
      dispatch("fetch", { object_id: trial_id })
    },
  },
}
