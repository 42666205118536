//
//
//
//
//
//

import Modal from "../../../../../plugins/modal/modal"
import ActionMixin from "./ActionMixin"

export default {
  mixins: [ActionMixin],
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeCustomer() {
      Modal.confirm(this)
        .title(this.$t.get("product.customer-delete"))
        .content(this.$t.get("product.customer-delete-prompt"))
        .positive(this.$t.get("common.yes"), function () {
          let params = {
            action: "removeCustomer",
            componentId: this.componentId,
            customerId: this.customer.group_customer_id || this.customer.tdCustomerId,
          }
          this.processing = true
          return this.$http
            .post(this.componentUrl, params)
            .then((response) => {
              this.processing = false
              this.$emit("reload-list")
              this.$bridge.emit("addToast", response.data.message, "success")
            })
            .catch((response) => {
              this.processing = false
              this.$bridge.emit("addToast", response.response.data.message, "error")
            })
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
  },
}
