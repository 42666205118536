var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "get-mobile-apps" }, [
    _c("p", [_vm._v(_vm._s(_vm.text))]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c(
        "a",
        {
          staticClass: "appstore",
          attrs: {
            href: "https://apps.apple.com/gb/app/trustedoctor/id1479695400?ls=1",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: {
              src: "https://trustedoctor.s3.eu-central-1.amazonaws.com/assets/static-v2/app_store.png",
              alt: _vm.text,
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "playstore",
          attrs: {
            href: "http://play.google.com/store/apps/details?id=app.android.trustedoctor&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
            target: "_blank",
          },
        },
        [
          _c("img", {
            attrs: {
              alt: _vm.text,
              src: "https://trustedoctor.s3.eu-central-1.amazonaws.com/assets/static-v2/google_play.png",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }