var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filters-box",
      class: { "filters-hidden": _vm.isVisible === false },
    },
    [
      _c("div", { staticClass: "flex flex-wrap" }, [
        _c("div", { staticClass: "w-3/12" }, [
          _c("span", { staticClass: "filter-title" }, [
            _vm._v(_vm._s(_vm.$t.get("common.filters")) + ":"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-9/12 text-right" }, [
          _c(
            "button",
            {
              staticClass: "button text gray",
              on: { click: _vm.clearFilters },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("common.clear-filters")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.isVisible === true
            ? _c(
                "button",
                {
                  staticClass: "button text gray",
                  on: {
                    click: function ($event) {
                      return _vm.hideFilters()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("common.hide-filters")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isVisible === false
            ? _c(
                "button",
                {
                  staticClass: "button text gray",
                  on: {
                    click: function ($event) {
                      return _vm.showFilters()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("common.show-filters")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap flex-spacing filters material-form" },
        [_vm.isVisible ? _vm._t("default") : _vm._e()],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }