//
//
//
//
//
//

import Modal from "../../../../../plugins/modal/modal"
import ActionMixin from "./ActionMixin"
import { Constants } from "../../../../../models/Constants"

export default {
  mixins: [ActionMixin],
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statuses: Constants.GroupCustomer,
    }
  },
  computed: {
    customerActive() {
      if (this.customer === null || this.customer.hasOwnProperty("status") === false) {
        return false
      }
      return (
        this.customer.status == this.statuses.STATUS_ACTIVE || this.customer.status === "Active"
      )
    },
    buttonDisabled() {
      if (this.customerActive === false) {
        return true
      }
      // support "classic" group customer model
      if (this.customer.hasOwnProperty("onboarded_at") === true) {
        return this.customer.onboarded_at !== null
      }
      // support SearchRecord
      if (this.customer.hasOwnProperty("tdOnboardedAt") === true) {
        return this.customer.tdOnboardedAt !== null
      }
      return true
    },
  },
  methods: {
    onboardCustomer() {
      Modal.confirm(this)
        .title(this.$t.get("product.onboarding"))
        .content(this.$t.get("product.onboard-send-confirm"))
        .positive(this.$t.get("common.yes"), function () {
          let params = {
            action: "onboardCustomer",
            componentId: this.componentId,
            customerId: this.customer.group_customer_id || this.customer.tdCustomerId,
          }
          this.processing = true
          return this.$http
            .post(this.componentUrl, params)
            .then((response) => {
              this.processing = false
              this.$emit("reload-list")
              this.$bridge.emit("addToast", response.data.message, "success")
            })
            .catch((response) => {
              this.processing = false
              this.$bridge.emit("addToast", response.response.data.message, "error")
            })
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
  },
}
