export default class Tick {
  constructor() {
    this.tickTimout = null
    this.tickInstances = []

    this.fps = 1000 / 24

    this.tickTimout = setTimeout(() => this.tick(), this.fps)
  }

  addTickInstance(c) {
    this.tickInstances.push(c)
  }

  tick() {
    this.tickInstances.forEach((instance) => instance.onTick())
    this.tickTimout = setTimeout(() => this.tick(), this.fps)
  }

  static get get() {
    if (typeof window._ticker === typeof undefined) {
      window._ticker = new Tick()
    }

    return window._ticker
  }
}
