var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bootstrap-view", on: { contextmenu: _vm.contextMenuShow } },
    [
      _vm.contextMenu.active
        ? _c(
            "div",
            {
              staticClass: "context-menu-dropdown",
              style: {
                top: _vm.contextMenu.position.top,
                left: _vm.contextMenu.position.left,
              },
            },
            [
              _c(
                "ul",
                {
                  on: {
                    click: function ($event) {
                      _vm.contextMenu.active = false
                    },
                  },
                },
                [
                  _vm._l(_vm.contextToolsList, function (tool, key) {
                    return [
                      tool.type == _vm.ToolType.SWITCH
                        ? _c(
                            "li",
                            {
                              key: key,
                              class: { active: _vm.current == key },
                              on: {
                                click: function ($event) {
                                  return _vm.toolSwitch(key, tool)
                                },
                              },
                            },
                            [
                              _c("i", { class: [tool.icon] }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(tool.name))]),
                              _vm._v(" "),
                              _vm.current == key &&
                              tool.actionsComponent !== null
                                ? _c(tool.actionsComponent, {
                                    tag: "component",
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      tool.type == _vm.ToolType.TOGGLE
                        ? _c(
                            "li",
                            {
                              key: key,
                              class: { active: tool.state },
                              on: {
                                click: function ($event) {
                                  return _vm.toolToggle(key, tool)
                                },
                              },
                            },
                            [
                              _c("i", { class: [tool.icon] }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(tool.name))]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      tool.type == _vm.ToolType.CLICK
                        ? _c(
                            "li",
                            {
                              key: key,
                              on: {
                                click: function ($event) {
                                  return _vm.toolClick(key, tool)
                                },
                              },
                            },
                            [
                              _c("i", { class: [tool.icon] }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(tool.name))]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "view-actions" },
        [
          _vm._l(_vm.viewToolsList, function (tool, key) {
            return _c("div", { key: key, staticClass: "action" }, [
              tool.type == _vm.ToolType.CLICK
                ? _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toolClick(key, tool)
                        },
                      },
                    },
                    [_c("i", { class: [tool.icon] })]
                  )
                : _vm._e(),
            ])
          }),
          _vm._v(" "),
          _vm.inViewer && _vm.viewData !== null
            ? _c("div", { staticClass: "action view-id" }, [
                _c("i", { staticClass: "ict-monitor-screen" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.id + 1))]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.droppableArea || _vm.viewType === null
        ? _c("div", { staticClass: "droppable-area" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t.get("viewer.drop-the-file-here"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "empty" }),
      _vm._v(" "),
      _vm.viewData !== null
        ? [
            _vm.viewType === _vm.DataType.IMAGE
              ? _c("image-view", {
                  ref: "dataView",
                  attrs: { data: _vm.viewData },
                  on: { "view-data-loaded": _vm.viewDataLoaded },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.viewType === _vm.DataType.DICOM
              ? _c("dicom-view", {
                  ref: "dataView",
                  attrs: { data: _vm.viewData },
                  on: { "view-data-loaded": _vm.viewDataLoaded },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.viewType === _vm.DataType.PDF
              ? _c("pdf-view", {
                  ref: "dataView",
                  attrs: { data: _vm.viewData },
                  on: { "view-data-loaded": _vm.viewDataLoaded },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("resize-observer", { on: { notify: _vm.handleResize } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }