import Vue from "../vue.js"

export default {
  namespaced: true,
  state: {
    shareList: [],
  },
  mutations: {
    setShareList(state, payload) {
      state.shareList = payload
    },
  },
  actions: {
    sendShare({ commit, dispatch }, { component, profile, share }) {
      return Vue.http
        .post(component.url, {
          action: "shareProfile",
          componentId: component.id,
          patientProfileId: profile.patient_id,
          payload: share,
        })
        .then((response) => {
          dispatch("fetchShareList", { component: component, profile: profile, page: 1 })
          return response
        })
    },
    revokeShare({ commit, dispatch }, { component, profile, share }) {
      return Vue.http
        .post(component.url, {
          action: "revokeSharedProfile",
          componentId: component.id,
          patientProfileId: profile.patient_id,
          sharedId: share.shared_id,
        })
        .then((response) => {
          dispatch("fetchShareList", { component: component, profile: profile, page: 1 })
          return response
        })
    },
    fetchShareList({ commit }, { component, profile, page }) {
      return Vue.http
        .post(component.url, {
          componentId: component.id,
          action: "fetchSharedProfiles",
          patientProfileId: profile.patient_id,
          page: page,
        })
        .then((response) => commit("setShareList", response.data.data))
    },
  },
  getters: {
    getShareList: (state) => state.shareList,
  },
}
