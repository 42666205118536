var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form" }, [
      _c("div", { staticClass: "flex flex-col material-form" }, [
        _c("div", { staticClass: "w-full columns" }, [
          _vm._v("\n        " + _vm._s(_vm.description) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-full columns" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _vm.timezones.length > 0
                ? _c(
                    "v-select",
                    {
                      staticClass: "user-select",
                      attrs: { "return-option": true, "enable-search": true },
                      model: {
                        value: _vm.selectedTimezone,
                        callback: function ($$v) {
                          _vm.selectedTimezone = $$v
                        },
                        expression: "selectedTimezone",
                      },
                    },
                    _vm._l(_vm.timezones, function (timezone, index) {
                      return _c("v-option", {
                        key: index,
                        attrs: { value: timezone, label: timezone },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.processing === false && _vm.timezones.length === 0
                ? _c("div", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t.get("common.list-empty")) +
                        "\n          "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-list margin" }, [
      _c(
        "button",
        {
          staticClass: "button accent",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.updateTimezone },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.$t.get("common.save-changes")) + "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button border",
          attrs: { disabled: _vm.processing },
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("common.cancel")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }