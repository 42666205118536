import { Constants } from "../../../vuejs/models/Constants"

export class Participant {
  public participantId: string
  public userId: string
  public name: string
  public email: string
  public permissions: Array<string>
  public type: string
  public avatarUrl: string

  constructor(
    participantId: string,
    userId: string,
    name: string,
    email: string,
    permissions: Array<string>,
    type: string,
    avatarUrl: string
  ) {
    this.participantId = participantId
    this.userId = userId
    this.name = name
    this.email = email
    this.permissions = permissions
    this.type = type
    this.avatarUrl = avatarUrl
  }

  get canManageCall(): boolean {
    return this.permissions.includes(Constants.Call.PARTICIPANT_PERMISSION_MANAGE_CALL)
  }

  toObject() {
    return {
      participant_id: this.participantId,
      user_id: this.userId,
      name: this.name,
      email: this.email,
      permissions: this.permissions,
      type: this.type,
      avatar_url: this.avatarUrl,
    }
  }

  serialize() {
    return JSON.stringify(this.toObject())
  }

  static fromJSON(serialized: string): Participant {
    const participant: ReturnType<Participant["toObject"]> = JSON.parse(serialized)

    return new Participant(
      participant.participant_id,
      participant.user_id,
      participant.name,
      participant.email,
      participant.permissions,
      participant.type,
      participant.avatar_url
    )
  }

  static fromObject(object: ReturnType<Participant["toObject"]>): Participant {
    return new Participant(
      object.participant_id,
      object.user_id,
      object.name,
      object.email,
      object.permissions,
      object.type,
      object.avatar_url
    )
  }
}
