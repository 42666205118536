import Module from "./module"

class Events {
  constructor(scrollbar) {
    this.scrollbar = scrollbar

    this.scrollbar.bar.onmousedown = (event) => this.mouseDown(event)
    this.scrollbar.bar.ontouchstart = (event) => this.touchStart(event)

    this.scrollbar.el.onscroll = (event) => this.userScrolled(event)

    document.onmouseup = (event) => this.mouseUp(event)
    document.ontouchend = (event) => this.touchEnd(event)
  }

  mouseDown(event) {
    event.preventDefault()
    this.currentY = event.pageY
    document.onmousemove = (e) => this.mouseMove(e)
  }

  mouseMove(event) {
    event.preventDefault()

    const moveDistance = event.pageY - this.currentY
    const scrollDistance =
      (moveDistance / this.scrollbar.maxPosition) *
      (this.scrollbar.scrollHeight - this.scrollbar.height)
    this.currentY = event.pageY

    this.scrollbar.move(scrollDistance)
  }

  mouseUp(event) {
    document.onmousemove = null
  }

  touchStart(event) {
    const touch = event.touches[0] || null
    if (event.touches.length === 1 && touch !== null) {
      this.currentY = touch.pageY
      this.scrollbar.bar.ontouchmove = (e) => this.touchMove(e)
    }
  }

  touchMove(event) {
    event.preventDefault()
    const touch = event.touches[0] || null
    if (event.touches.length === 1 && touch !== null) {
      const moveDistance = touch.pageY - this.currentY
      const scrollDistance =
        (moveDistance / this.scrollbar.maxPosition) *
        (this.scrollbar.scrollHeight - this.scrollbar.height)
      this.currentY = touch.pageY

      this.scrollbar.move(scrollDistance)
    }
  }

  touchEnd(event) {
    document.ontouchmove = null
  }

  userScrolled(event) {
    this.scrollbar.setBarPosition()
  }
}

export default class Scrollbar extends Module {
  constructor(el) {
    super(el)

    this.maxPosition = 0
    this.position = 0
    this.scrollClass = null

    this.wrap()
    this.createScroll()
    this.calculateSizes()
  }

  wrap() {
    this.el.classList.add("scrollbar")

    this.wrapper = document.createElement("div")
    this.wrapper.className = "scrollbar-wrapper"
    this.el.parentNode.insertBefore(this.wrapper, this.el)
    this.wrapper.appendChild(this.el)
  }

  calculateSizes() {
    this.height = this.el.clientHeight
    this.scrollHeight = this.el.scrollHeight

    this.el.style.width = null
    this.width = this.el.clientWidth

    const visibleProportion = this.height / this.scrollHeight

    if (this.height * visibleProportion > 30) {
      if (visibleProportion >= 1) {
        this.barHeight = 0
      } else {
        this.visibleProportion = visibleProportion
        this.barHeight = this.height * visibleProportion
      }
    } else {
      this.visibleProportion = 30 / this.height
      this.barHeight = 30
    }

    this.maxPosition = this.height - this.barHeight

    this.scroll.style.height = this.height + "px"
    this.bar.style.height = this.barHeight + "px"

    const systemScrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    this.el.style.width = "calc(100% + " + systemScrollbarWidth + "px)"
    this.el.style.padding = "0 calc(19px + " + systemScrollbarWidth + "px) 0 0"

    this.setBarPosition()
  }

  move(distance) {
    this.el.scrollTop = distance + this.el.scrollTop
  }

  setBarPosition() {
    const position = (this.el.scrollTop / (this.scrollHeight - this.height)) * this.maxPosition

    if (position <= 0) {
      this.position = 0
    } else if (position < this.maxPosition) {
      this.position = position
    } else {
      this.position = this.maxPosition
    }

    this.bar.style.marginTop = this.position + "px"

    this.scroll.classList.add("scroll-active")
    clearTimeout(this.scrollClass)
    this.scrollClass = setTimeout(() => {
      this.scroll.classList.remove("scroll-active")
    }, 500)
  }

  onTick() {
    if (this.el.scrollHeight !== this.scrollHeight) {
      this.calculateSizes.call(this)
    }

    if (this.height !== this.el.clientHeight || this.width !== this.el.clientWidth) {
      this.calculateSizes.call(this)
    }
  }

  createScroll() {
    this.scroll = document.createElement("div")
    this.scroll.className = "scrollbar-handler"

    this.bar = document.createElement("div")
    this.bar.className = "bar"

    this.scroll.appendChild(this.bar)

    this.events = new Events(this)

    this.el.parentElement.insertBefore(this.scroll, this.el.nextSibling)
  }
}
