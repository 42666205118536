var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.detachTab || _vm.isActive
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.detachTab || (!_vm.detachTab && _vm.isActive),
              expression: "detachTab || (!detachTab && isActive)",
            },
          ],
          staticClass: "tabs-panel is-active",
          attrs: { role: "tabpanel", "aria-hidden": "false" },
        },
        [
          _vm.component !== null
            ? _c(
                _vm.component,
                _vm._b({ tag: "component" }, "component", _vm.props, false)
              )
            : _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }