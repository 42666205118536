//
//
//
//

import ActionMixin from "./ActionMixin"
import { appendObjectAsQueryString } from "../../../../../../utils"

export default {
  mixins: [ActionMixin],
  props: {
    detailsPageUrl: { type: String },
  },
  computed: {
    detailsUrl() {
      if (
        this.customer.hasOwnProperty("group_customer_id") === true &&
        this.customer.group_customer_id
      ) {
        return appendObjectAsQueryString(this.detailsPageUrl, {
          identifier: this.customer.group_customer_id,
        })
      }

      return appendObjectAsQueryString(this.detailsPageUrl, this.customer.detailsLinkParams)
    },
  },
}
