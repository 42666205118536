//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, minLength, maxLength } from "vuelidate/lib/validators"
export default {
  data() {
    return {
      description: "",
    }
  },
  validations: {
    description: {
      required,
      minLength: minLength(17),
      maxLength: maxLength(20000),
    },
  },
  methods: {
    handleInput(validator) {
      validator.$touch()
      this.emitDescription()
    },
    emitDescription() {
      this.$v.$touch()

      if (this.$v.$invalid === true) {
        this.$emit("data", "description", null)
        return
      }

      this.$emit("data", "description", this.description)
    },
  },
}
