//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Scrollbar from "../../../../ui/scrollbar"

import DataType from "./DataType.js"
import DataListThumbnail from "./DataListThumbnail.vue"

import Translate from "../../../../helpers/translate"

import { ResizeObserver } from "vue-resize"

export default {
  components: {
    thumbnail: DataListThumbnail,
    "resize-observer": ResizeObserver,
  },
  props: {
    data: {
      required: true,
    },
    selectedItems: {
      default: () => [],
    },
  },
  data() {
    return {
      itemHeight: 188,
      DataType: DataType,
      filter: null,
    }
  },
  computed: {
    dicoms() {
      return this.filter === null
        ? this.data.dicoms || []
        : this.data.dicoms.filter((item) => item.parent_id === this.filter)
    },
    pdfs() {
      return this.filter === null
        ? this.data.pdfs || []
        : this.data.pdfs.filter((item) => item.parent_id === this.filter)
    },
    images() {
      return this.filter === null
        ? this.data.images || []
        : this.data.images.filter((item) => item.parent_id === this.filter)
    },
    filters() {
      return [].concat(this.data.dicoms || [], this.data.pdfs || [], this.data.images || []).reduce(
        (acc, item) => {
          if (acc[item.parent_id] === void 0) {
            acc[item.parent_id] = {
              value: item.parent_id,
              label: item.parent_name,
            }
            return acc
          } else return acc
        },
        {
          default: {
            value: null,
            label: Translate.get("viewer.all-folders"),
          },
        }
      )
    },
  },
  mounted() {
    if (this.$el.querySelector(".dicoms.scrollbar") !== void 0) {
      new Scrollbar(this.$el.querySelector(".dicoms.scrollbar"))
    }
    if (this.$el.querySelector(".pdfs.scrollbar") !== void 0) {
      new Scrollbar(this.$el.querySelector(".pdfs.scrollbar"))
    }
    if (this.$el.querySelector(".images.scrollbar") !== void 0) {
      new Scrollbar(this.$el.querySelector(".images.scrollbar"))
    }

    if (this.data.dicoms !== void 0 && this.data.dicoms[0] !== void 0) {
      const item = this.data.dicoms[0]
      this.itemClicked({
        type: DataType.DICOM,
        hash: item.hash,
        download_url: item.download_url,
        name: item.name,
        object_id: item.object_id,
      })
    }

    this.$refs.resizeObserver.$emit("notify")
  },
  methods: {
    itemDragged(state) {
      this.$emit("item-dragged", state)
    },
    itemClicked(payload) {
      this.$emit("item-clicked", payload)
    },
    handleResize() {
      this.itemHeight = this.$el.clientWidth - 20
    },
    isSelected(hash) {
      return this.selectedItems.indexOf(hash) > -1
    },
  },
}
