//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from "lodash"
import { Constants } from "../../../models/Constants"

export default {
  props: {
    enablePreloader: {
      default: true,
      type: Boolean,
    },
    organizationId: { type: String, required: true },
    customerId: { type: String },
    appointmentType: { type: Number, required: true },
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
  },
  data() {
    return {
      constants: Constants.Appointment,
      isCalculating: false,
      processing: false,
      price: {
        amount: "-",
        amount_netto: "-",
        amount_brutto: "-",
        commission: "-",
        commission_vat: "-",
        commission_pay: "-",
        charity: "-",
        charity_vat: "-",
        currency: "-",
        period: 60,
        duration: 60,
        for_free: false,
      },
    }
  },
  computed: {
    isVideoCall() {
      return this.appointmentType === this.constants.CONSULTATION_CALL_OPINION
    },
    isSecondOpinionOnly() {
      return this.appointmentType === this.constants.CONSULTATION_OPINION
    },
    readOnly() {
      return (
        this.appointmentType === this.constants.CONSULTATION_DIAGNOSTIC_TEST ||
        this.appointmentType === this.constants.CONSULTATION_OVERSEAS_TREATMENT
      )
    },
  },
  watch: {
    customerId() {
      this.fetchPriceForType()
    },
    appointmentType() {
      this.fetchPriceForType()
    },
  },
  mounted() {
    this.fetchPriceForType()
  },
  methods: {
    fetchPriceForType() {
      if (this.customerId === null) {
        return
      }
      this.isCalculating = true
      this.$emit("is-calculating", true)
      const params = {
        action: "fetchPriceForType",
        componentId: this.componentId,
        appointmentType: this.appointmentType,
        customerId: this.customerId,
      }
      this.$http.post(this.componentUrl, params).then(
        (response) => {
          this.price = response.data.data
          this.$emit("calculated-price", response.data.data)
          this.isCalculating = false
          this.$emit("is-calculating", false)
          this.$emit("data", "price", this.price)
        },
        (error) => {
          this.isCalculating = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.$emit("is-calculating", false)
        }
      )
    },
    toggleForFree() {
      if (!this.isCalculating) {
        this.price.for_free = !this.price.for_free
        this.price.amount = this.price.for_free ? "0" : "100"
        this.calculate()
      }
    },
    calculate: debounce(function () {
      if (this.price.amount === "") {
        this.price.for_free = true
        this.price.amount = "0"
        this.calculate()
        return
      }
      if (this.price.amount === "0") {
        this.price.for_free = true
      }
      this.isCalculating = true
      this.$emit("is-calculating", true)

      const params = {
        action: "calculatePrice",
        componentId: this.componentId,
        appointmentType: this.appointmentType,
        customerId: this.customerId,
        price: this.price,
      }

      this.$http.post(this.componentUrl, params).then(
        (response) => {
          this.price = response.data.data
          this.$emit("calculated-price", response.data.data)
          this.isCalculating = false
          this.$emit("is-calculating", false)
          this.$emit("data", "price", this.price)
        },
        (error) => {
          this.isCalculating = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.$emit("is-calculating", false)
        }
      )
    }, 500),
  },
}
