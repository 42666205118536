export default {
  data() {
    return {
      inputsIds: {},
    }
  },
  methods: {
    inputId(name) {
      if (this.inputsIds[name] === void 0) {
        this.inputsIds[name] = Math.random().toString(36).substring(3)
      }

      return this.inputsIds[name]
    },
  },
}
