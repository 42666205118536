var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      staticClass: "context-menu",
      class: { active: _vm.opened },
    },
    [
      _c(
        "button",
        { staticClass: "activator", on: { click: _vm.toggle } },
        [
          _vm._t("activator", function () {
            return [
              _c("i", {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color.hover",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "color",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "ict-menu",
              }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flow", style: _vm.style, on: { click: _vm.hide } },
        [_c("ul", [_vm._t("default")], 2)]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }