var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                ) +
                "\n  "
            ),
          ]
        : [
            _vm.customer && _vm.customer.user
              ? _c("div", {
                  staticClass: "avatar",
                  style: {
                    backgroundImage:
                      "url(" + _vm.customer.user.avatar_url + ")",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.customer && _vm.customer.name && _vm.customer.surname
              ? _c("div", { staticClass: "name" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.customer.surname + " " + _vm.customer.name) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }