var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      attrs: { disabled: _vm.buttonDisabled },
      on: {
        click: function ($event) {
          return _vm.onboardCustomer()
        },
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.$t.get("product.onboarding-send")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }