var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-method", attrs: { id: "tdh" } },
    [
      _vm.tdhClient !== null
        ? _c("token-gateway", {
            ref: "tokenGateway",
            attrs: {
              "auto-init": false,
              contract: _vm.tdhClient.contract,
              "accepted-networks": _vm.tdhClient.acceptedNetworks,
              abi: _vm.tdhClient.abi,
              "dest-address": _vm.tdhClient.destAddress,
              user: _vm.user,
              amount: _vm.payment.amount_brutto,
              "amount-token":
                _vm.payment.amount_brutto *
                _vm.tdhClient.exchangeRatio[_vm.payment.currency],
              payment: _vm.payment,
              "payment-method-id": _vm.tdhClient.method_id,
            },
            on: {
              "token-cancel-pay": function ($event) {
                return _vm.cancelPay()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }