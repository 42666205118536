//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {},
  data() {
    return {
      hour: null,
      minute: null,
    }
  },
  computed: {
    availableHours() {
      let times = []
      let id = 1
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          times.push({
            id: id,
            value: "0" + i,
          })
        } else {
          times.push({
            id: id,
            value: i + "",
          })
        }

        id++
      }

      return times
    },
    availableMinutes() {
      let times = []
      let id = 1
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          times.push({
            id: id,
            value: "0" + i,
          })
        } else {
          times.push({
            id: id,
            value: i,
          })
        }
      }

      return times
    },
    time() {
      if (
        this.hour !== null &&
        this.hour.toString().length > 0 &&
        this.minute !== null &&
        this.minute.toString().length > 0
      ) {
        return this.hour + ":" + this.minute
      } else {
        return null
      }
    },
  },

  watch: {
    time(time) {
      if (this.time != null) {
        this.$emit("input", time)
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if (this.value != null && this.value.split(":").length == 2) {
          const time = value.split(":")
          this.hour = time[0]
          this.minute = time[1]
        } else {
          this.hour = ""
          this.minute = ""
        }
      },
    },
  },
}
