var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", {
      staticClass: "description",
      domProps: { innerHTML: _vm._s(this.$t.get("auth.maxLoginAttemptsDesc")) },
    }),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "flex flex-spacing flex-wrap" }, [
        _c("div", { staticClass: "w-full columns submit" }, [
          _c("input", {
            staticClass: "button accent",
            attrs: { type: "submit" },
            domProps: { value: this.$t.get("auth.sendEmail") },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.forgotPassword.apply(null, arguments)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "form-error" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }