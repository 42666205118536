var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " + _vm._s(_vm.$t.get(_vm.headerTranslation)) + "\n  "
            ),
          ]
        : [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.getPolicyValue)),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }