import AbstractTool from "./AbstractTool"
import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"

export default class ViewActions extends AbstractTool {
  get name() {
    return Translate.get("viewer.view-actions")
  }

  get icon() {
    return "ict-grid-3x2"
  }

  get type() {
    return ToolType.SWITCH
  }

  activate(views) {}

  deactivate(views) {}

  constructor(toolsInstance) {
    super(toolsInstance)
  }

  get actions() {
    return {
      template: `<ul class="drop-down view-actions">
          <li><i v-on:click="download" class="ict-attachment"></i><span>{{ $t.get('viewer.view-actions-download-all') }}</span></li>
          <li v-for="(view, index) in views" v-if="view.viewType === 0 || view.viewType === 2" v-bind:key="index"><i v-on:click="copy(index)" class="ict-copy"></i><span>{{ $t.get('viewer.view-actions-copy-view', {id: (view.id + 1)}) }}</span></li>
        </ul>`,
      methods: {
        download() {
          this.views.forEach((view) => {
            if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
              const dataView = view.$refs.dataView
              const cornerstoneElement = dataView.$refs.view
              const canvas = cornerstoneElement.querySelector("canvas")
              const dataURL = canvas.toDataURL()

              const fileName =
                dataView.currentImageParams.patientName +
                ", " +
                dataView.currentImageParams.series +
                " - " +
                (parseInt(dataView.position) + 1) +
                ".png"

              let link = document.createElement("a")
              link.download = fileName
              link.href = dataURL
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            } else if (view.viewType === DataType.PDF) {
              const dataURL = view.viewData[0].download_url
              const fileName = view.viewData[0].name

              let link = document.createElement("a")
              link.download = fileName
              link.target = "_blank"
              link.href = dataURL
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }

            // window.dispatchEvent(
            //   new CustomEvent("ExtensionEvent", {
            //     detail: {
            //       action: "DOWNLOAD",
            //       payload: {
            //         name: fileName,
            //         content: dataURL
            //       }
            //     }
            //   })
            // )
          })
        },
        copy(index) {
          let view = this.views[index]
          // this.views.forEach(view => {
          if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
            const dataView = view.$refs.dataView
            const cornerstoneElement = dataView.$refs.view
            const canvas = cornerstoneElement.querySelector("canvas")
            const dataURL = canvas.toDataURL()

            let img = document.createElement("img")
            img.src = dataURL
            let div = document.createElement("div")

            let select = (element) => {
              if (document.body.createTextRange) {
                let range = document.body.createTextRange()
                range.moveToElementText(element)
                range.select()
                return true
              } else if (window.getSelection) {
                let selection = window.getSelection()
                let range = document.createRange()
                range.selectNodeContents(element)
                selection.removeAllRanges()
                selection.addRange(range)
                return true
              } else {
                return false
              }
            }

            div.contentEditable = true
            div.appendChild(img)
            document.body.appendChild(div)

            if (select(div) === false) {
              this.$bridge.emit(
                "addToast",
                "An error has occurred. Image can not be copied",
                "error"
              )
            }
            document.execCommand("Copy")
            document.body.removeChild(div)
            this.$bridge.emit("addToast", "Image has been copied", "success")

            // window.dispatchEvent(
            //   new CustomEvent("ExtensionEvent", {
            //     detail: {
            //       action: "COPY",
            //       payload: {
            //         content: dataURL
            //       }
            //     }
            //   })
            // )
          }
          // })
        },
      },
    }
  }
}
