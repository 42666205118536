var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "list", duration: 300 } }, [
    _vm.isPanelVisible
      ? _c(
          "div",
          {
            staticClass: "downloads panel",
            class: {
              "top-left": _vm.position === "top-left",
              "top-tight": _vm.position === "top-right",
              "bottom-left": _vm.position === "bottom-left",
              "bottom-right": _vm.position === "bottom-right",
            },
          },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "close" }, [
                _c("span", {
                  staticClass: "ict-close",
                  on: { click: _vm.close },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.$t.get("cloud-manager.downloads")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "list" }, [
              _c(
                "ul",
                [
                  _c(
                    "transition-group",
                    { staticClass: "item", attrs: { name: "list", tag: "li" } },
                    _vm._l(_vm.getJobs, function (job) {
                      return _c("div", { key: job.job_id }, [
                        _c("div", { staticClass: "left" }, [
                          _c("div", { staticClass: "name" }, [
                            job.name
                              ? _c("span", [_vm._v(_vm._s(job.name))])
                              : _c("span", [_vm._v(_vm._s(job.job_id))]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stage" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(job.stage) +
                                  "\n                "
                              ),
                              job.status === "working" &&
                              job.progress !== void 0 &&
                              job.progress < 100
                                ? [_vm._v(_vm._s(job.progress) + "%")]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "right" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    job.hasOwnProperty("progress") &&
                                    job.status !== "done" &&
                                    job.status !== "error",
                                  expression:
                                    "\n                  job.hasOwnProperty('progress') &&\n                  job.status !== 'done' &&\n                  job.status !== 'error'\n                ",
                                },
                              ],
                              staticClass: "progress",
                            },
                            [
                              job.progress < 100
                                ? _c("progress-circle", {
                                    attrs: {
                                      progress: job.progress,
                                      width: "30",
                                      height: "30",
                                      border: "4",
                                      background: "#2b619a",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              job.progress === 100
                                ? _c("div", { staticClass: "preloader" })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    job.status === "done" && job.download_url,
                                  expression:
                                    "job.status === 'done' && job.download_url",
                                },
                              ],
                              staticClass: "action",
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: job.download_url,
                                    title: _vm.$t.get(
                                      "cloud-manager.click-to-download"
                                    ),
                                  },
                                },
                                [_c("i", { staticClass: "ict-download" })]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          job.status === "done" || job.status === "error"
                            ? _c("div", { staticClass: "action" }, [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFromList(job)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "ict-trash" })]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          job.status !== "done" && job.status !== "error"
                            ? _c("div", { staticClass: "action" }, [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFromList(job)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "ict-close" })]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: job.status === "error",
                                  expression: "job.status === 'error'",
                                },
                              ],
                              staticClass: "info",
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "ict-alert" }),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }