var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-form" }, [
    _c("h3", [
      _vm._v(_vm._s(_vm.$t.get("appointment.appointment-type-and-term"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-box content" }, [
      _c(
        "div",
        { staticClass: "checkboxes inline" },
        [
          _vm.availableConsultationTypes.indexOf(
            _vm.constants.CONSULTATION_OPINION
          ) !== -1
            ? [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consultationType,
                      expression: "consultationType",
                    },
                  ],
                  staticClass: "inside",
                  attrs: { type: "radio", id: "type-video-second-opinion" },
                  domProps: {
                    value: _vm.constants.CONSULTATION_OPINION,
                    checked: _vm._q(
                      _vm.consultationType,
                      _vm.constants.CONSULTATION_OPINION
                    ),
                  },
                  on: {
                    input: function ($event) {
                      _vm.term = null
                    },
                    change: function ($event) {
                      _vm.consultationType = _vm.constants.CONSULTATION_OPINION
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "type-video-second-opinion" } }, [
                  _vm._v(_vm._s(_vm.$t.get("appointment.second-opinion"))),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.availableConsultationTypes.indexOf(
            _vm.constants.CONSULTATION_CALL_OPINION
          ) !== -1
            ? [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consultationType,
                      expression: "consultationType",
                    },
                  ],
                  staticClass: "inside",
                  attrs: { type: "radio", id: "type-video-call" },
                  domProps: {
                    value: _vm.constants.CONSULTATION_CALL_OPINION,
                    checked: _vm._q(
                      _vm.consultationType,
                      _vm.constants.CONSULTATION_CALL_OPINION
                    ),
                  },
                  on: {
                    change: function ($event) {
                      _vm.consultationType =
                        _vm.constants.CONSULTATION_CALL_OPINION
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "type-video-call" } }, [
                  _vm._v(_vm._s(_vm.$t.get("appointment.video-call")) + " "),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.availableConsultationTypes.indexOf(
            _vm.constants.CONSULTATION_DIAGNOSTIC_TEST
          ) !== -1
            ? [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consultationType,
                      expression: "consultationType",
                    },
                  ],
                  staticClass: "inside",
                  attrs: { type: "radio", id: "type-diagnostic-test" },
                  domProps: {
                    value: _vm.constants.CONSULTATION_DIAGNOSTIC_TEST,
                    checked: _vm._q(
                      _vm.consultationType,
                      _vm.constants.CONSULTATION_DIAGNOSTIC_TEST
                    ),
                  },
                  on: {
                    change: function ($event) {
                      _vm.consultationType =
                        _vm.constants.CONSULTATION_DIAGNOSTIC_TEST
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "type-diagnostic-test" } }, [
                  _vm._v(
                    _vm._s(_vm.$t.get("appointment.diagnostic-test")) + " "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.availableConsultationTypes.indexOf(
            _vm.constants.CONSULTATION_OVERSEAS_TREATMENT
          ) !== -1
            ? [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consultationType,
                      expression: "consultationType",
                    },
                  ],
                  staticClass: "inside",
                  attrs: { type: "radio", id: "type-overseas-treatment" },
                  domProps: {
                    value: _vm.constants.CONSULTATION_OVERSEAS_TREATMENT,
                    checked: _vm._q(
                      _vm.consultationType,
                      _vm.constants.CONSULTATION_OVERSEAS_TREATMENT
                    ),
                  },
                  on: {
                    change: function ($event) {
                      _vm.consultationType =
                        _vm.constants.CONSULTATION_OVERSEAS_TREATMENT
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "type-overseas-treatment" } }, [
                  _vm._v(
                    _vm._s(_vm.$t.get("appointment.overseas-treatment")) +
                      "\n        "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.availableConsultationTypes.indexOf(
            _vm.constants.CONSULTATION_FRACTURE_COVER
          ) !== -1
            ? [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.consultationType,
                      expression: "consultationType",
                    },
                  ],
                  staticClass: "inside",
                  attrs: { type: "radio", id: "type-fracture-cover" },
                  domProps: {
                    value: _vm.constants.CONSULTATION_FRACTURE_COVER,
                    checked: _vm._q(
                      _vm.consultationType,
                      _vm.constants.CONSULTATION_FRACTURE_COVER
                    ),
                  },
                  on: {
                    input: function ($event) {
                      _vm.term = null
                    },
                    change: function ($event) {
                      _vm.consultationType =
                        _vm.constants.CONSULTATION_FRACTURE_COVER
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "type-fracture-cover" } }, [
                  _vm._v(_vm._s(_vm.$t.get("appointment.fracture-cover"))),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.termVisible
            ? _c(
                "div",
                { staticClass: "term-choice" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.termLabel
                          ? _vm.termLabel
                          : _vm.$t.get("appointment.no-term-selected")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.chooseTerm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t.get("appointment.choose-term")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-input-error", { attrs: { validation: _vm.$v.term } }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }