import Vue from "../../vue"

import { cornerstone, cornerstoneTools } from "./cornerstone"
import appStore from "../../stores/store"

import * as jsonpatch from "fast-json-patch"
import { debounce, cloneDeep } from "lodash"

import DataType from "./data/DataType"

import { v4 as uuid } from "uuid"

import PointerTool from "./tools/cornerstone/PointerTool"

const MutationSource = {
  API: 0,
  USER: 1,
}

const storeState = {
  views: {},
  toolState: {},
  cursors: {},
  layout: {
    views: 1,
    orientation: "flex",
  },
}

const store = {
  namespaced: true,
  state: Object.assign({}, storeState),
  mutations: {
    layout(state, { views, orientation }) {
      Vue.set(state, "layout", { views: views, orientation: orientation })
    },
    stackData(state, { viewId, viewType, viewData }) {
      const modifiedState = Object.assign(state.views[viewId] || {}, {
        type: viewType,
        data: viewData,
        position: 0,
      })
      Vue.set(state.views, viewId, modifiedState)
    },
    stackPosition(state, { viewId, position }) {
      const modifiedState = Object.assign(state.views[viewId] || {}, { position: position })
      Vue.set(state.views, viewId, modifiedState)
    },
    toolData(state, { imageId, tool, diff }) {
      if (!state.toolState.hasOwnProperty(imageId)) {
        Vue.set(state.toolState, imageId, {})
      }

      if (!state.toolState[imageId].hasOwnProperty(tool)) {
        Vue.set(state.toolState[imageId], tool, { data: [] })
      }

      let toolState = Object.assign(state.toolState[imageId][tool], {})

      const patched = jsonpatch.applyPatch(toolState, diff)

      Vue.set(state.toolState[imageId], tool, patched.newDocument)
    },
    toolDataState(state, toolState) {
      Vue.set(state, "toolState", toolState)
    },
    cursorPosition(state, { userId, viewId, position }) {
      if (!state.cursors.hasOwnProperty(userId)) {
        Vue.set(state.cursors, userId, {})
      }

      Vue.set(state.cursors, userId, {
        userId,
        viewId,
        position,
      })
    },
    resetState(state) {},
  },
  getters: {
    views: (state) => {
      return state.views
    },
  },
}

class ToolState {
  constructor() {
    this.toolState = {}
  }

  add(element, toolType, data) {
    const enabledImage = cornerstone.getEnabledElement(element)

    if (
      !enabledImage.image ||
      this.toolState.hasOwnProperty(enabledImage.image.imageId) === false
    ) {
      this.toolState[enabledImage.image.imageId] = {}
    }

    const imageIdToolState = this.toolState[enabledImage.image.imageId]

    if (imageIdToolState.hasOwnProperty(toolType) === false) {
      imageIdToolState[toolType] = {
        data: [],
      }
    }

    const toolData = imageIdToolState[toolType]

    toolData.data.push(data)
  }

  marge(imageId, toolType, diff) {
    if (this.toolState.hasOwnProperty(imageId) === false) {
      this.toolState[imageId] = {}
    }

    if (this.toolState[imageId].hasOwnProperty(toolType) === false) {
      this.toolState[imageId][toolType] = { data: [] }
    }

    let toolState = Object.assign(this.toolState[imageId][toolType], {})

    const patched = jsonpatch.applyPatch(toolState, diff)

    this.toolState[imageId][toolType] = patched.newDocument
  }

  get(element, toolType) {
    const enabledImage = cornerstone.getEnabledElement(element)

    if (
      !enabledImage.image ||
      this.toolState.hasOwnProperty(enabledImage.image.imageId) === false
    ) {
      return
    }

    const imageIdToolState = this.toolState[enabledImage.image.imageId]

    if (imageIdToolState.hasOwnProperty(toolType) === false) {
      return
    }

    const toolData = imageIdToolState[toolType]

    return toolData
  }

  replace(toolState) {
    this.toolState = JSON.parse(JSON.stringify(toolState))
  }
}

class StateManager {
  constructor() {
    this.sessionId = uuid()

    this.store = store
    this.subscription = appStore.subscribe((mutation) => {
      this.storeModified(mutation)
    })
    this.synchronizationSocket = null
    this.synchronizationId = null

    this.toolState = new ToolState()

    this.elements = []

    this.enabledTools = ["ArrowAnnotate", "Pencil", "Length", "RectangleRoi"]
  }

  isEnabled() {
    return this.synchronizationId !== null
  }

  bindElement(viewType, element, viewId) {
    let triggerToolsEvent = (event) => {
      this.measurementModified(viewId, event.detail)
    }
    let triggerStackScrollEvent = (event) => {
      this.stackScrolled(viewId, event.detail)
    }
    let triggerMouseMoveEvent = (event) => {
      this.mouseMove(event.detail.imageId, event.detail.position)
    }

    if (viewType === DataType.DICOM || viewType === DataType.IMAGE) {
      cornerstoneTools.setElementToolStateManager(element, this.toolState)
      element.addEventListener("cornerstonetoolsmeasurementadded", debounce(triggerToolsEvent, 33))
      element.addEventListener(
        "cornerstonetoolsmeasurementmodified",
        debounce(triggerToolsEvent, 33)
      )
      element.addEventListener(
        "cornerstonetoolsmeasurementremoved",
        debounce(triggerToolsEvent, 33)
      )
      element.addEventListener("cornerstonetoolsmouseup", debounce(triggerToolsEvent, 33))
      element.addEventListener("cornerstonetoolsmousedrag", debounce(triggerToolsEvent, 33))
    }

    element.addEventListener("cornerstonetoolsstackscroll", debounce(triggerStackScrollEvent, 33))

    element.addEventListener("cornerstonetoolscursormoved", triggerMouseMoveEvent) // debounce(triggerMouseMoveEvent, 33))

    this.elements.push(element)

    cornerstoneTools.addToolForElement(element, PointerTool, {
      userId: appStore.state.user.user_id,
      cursors: appStore.state.viewer.cursors,
      participants: this.participants,
    })
    cornerstoneTools.setToolActive("PointerTool", { mouseButtonMask: 2 })
  }

  // unbindElement(element) {
  //   // TODO
  // }

  bindSynchronization(socket, id, participants) {
    this.synchronizationSocket = socket
    this.synchronizationId = id

    this.participants = participants || {}

    this.synchronizationSocket.on("viewer/session", function (data) {
      appStore.commit("viewer/toolDataState", data.toolState)

      Object.keys(data.views).forEach((viewId) => {
        const view = data.views[viewId]
        appStore.commit("viewer/stackData", {
          viewId: viewId,
          viewType: view.viewType,
          viewData: view.viewData,
        })

        appStore.commit("viewer/stackPosition", { viewId: viewId, position: view.position })
      })

      appStore.commit("viewer/layout", data.layout)
    })

    this.synchronizationSocket.on(
      "viewer/layout",
      function ({ views: views, orientation: orientation }) {
        appStore.commit("viewer/layout", {
          views: views,
          orientation: orientation,
        })
      }
    )

    this.synchronizationSocket.on("viewer/stack-data", function (payload) {
      appStore.commit("viewer/stackData", {
        viewId: payload.viewId,
        viewType: payload.viewType,
        viewData: payload.viewData,
      })
    })

    this.synchronizationSocket.on("viewer/stack-position", function (data) {
      appStore.commit("viewer/stackPosition", { viewId: data.viewId, position: data.position })
    })

    this.synchronizationSocket.on("viewer/tool-data", function ({ imageId, tool, diff }) {
      appStore.commit("viewer/toolData", { imageId, tool, diff })
    })

    this.synchronizationSocket.on(
      "viewer/cursor-position",
      function ({ userId, viewId, position }) {
        appStore.commit("viewer/cursorPosition", { userId, viewId, position })
      }
    )

    this.synchronizationSocket.emit("viewer/connected", { sessionId: id })
  }

  unbindSynchronization() {
    this.synchronizationSocket = null
    this.synchronizationId = null
    appStore.commit("viewer/resetState")
  }

  layoutChanged(views, orientation) {
    if (this.synchronizationSocket !== null) {
      this.synchronizationSocket.emit("viewer/layout", {
        sessionId: this.synchronizationId,
        views,
        orientation,
      })
    }
  }

  dataChanged(viewId, viewType, viewData) {
    appStore.commit("viewer/stackData", { viewId, viewType, viewData })

    if (this.synchronizationSocket !== null) {
      this.synchronizationSocket.emit("viewer/stack-data", {
        sessionId: this.synchronizationId,
        viewId,
        viewType,
        viewData,
      })
    }
  }

  stackScrolled(viewId, detail) {
    if (this.synchronizationSocket !== null && detail !== 0) {
      this.synchronizationSocket.emit("viewer/stack-position", {
        sessionId: this.synchronizationId,
        viewId,
        position: detail.newImageIdIndex,
      })
    }
  }

  measurementModified(viewId, { element, measurementData, toolType, toolName }) {
    if (appStore.state.viewer === void 0 || appStore.state.viewer.toolState === void 0) {
      return
    }

    if (this.enabledTools.indexOf(toolType) > -1 || this.enabledTools.indexOf(toolName) > -1) {
      const tool = toolType || toolName
      const toolState = cloneDeep(cornerstoneTools.getToolState(element, tool))
      const enabledImage = cornerstone.getEnabledElement(element)

      let storeState = { data: [] }
      if (
        appStore.state.viewer.toolState[enabledImage.image.imageId] !== void 0 &&
        appStore.state.viewer.toolState[enabledImage.image.imageId][tool] !== void 0
      ) {
        storeState = appStore.state.viewer.toolState[enabledImage.image.imageId][tool]
      }

      if (this.synchronizationSocket !== null) {
        const diff = jsonpatch.compare(storeState, toolState)
        appStore.commit("viewer/toolData", {
          imageId: enabledImage.image.imageId,
          tool,
          diff,
          local: true,
        })

        this.synchronizationSocket.emit("viewer/tool-data", {
          sessionId: this.synchronizationId,
          imageId: enabledImage.image.imageId,
          tool,
          diff,
        })
      }
    }
  }

  mouseMove(viewId, position) {
    if (this.synchronizationSocket !== null) {
      this.synchronizationSocket.emit("viewer/cursor-position", {
        sessionId: this.synchronizationId,
        userId: appStore.state.user.user_id,
        viewId,
        position,
      })
    }
  }

  mouseEnter(event) {
    // console.log("mouseEnter")
  }

  mouseLeave(event) {
    // console.log("mouseLeave")
  }

  storeModified(mutation) {
    let [source, type] = mutation.type.split("/")

    if (source === "viewer" && this.synchronizationId !== null) {
      switch (type) {
        case "toolData":
          const { local, imageId, tool, diff } = mutation.payload
          if (!local) {
            this.toolState.marge(imageId, tool, diff)
            this.elements.forEach((element) => {
              try {
                cornerstone.updateImage(element)
              } catch (_) {
                // Empty catch to handle not enabled elements
              }
            })
          }
          break
        case "cursorPosition":
          this.elements.forEach((element) => {
            try {
              cornerstone.invalidate(element)
            } catch (e) {
              // Catch if element is not enabled by cornerstone
            }
          })
          break
        case "toolDataState":
          this.toolState.replace(mutation.payload)
          break
        case "stackPosition":
          break
        case "stackData":
          break
      }
    }
  }
}

export { MutationSource }
export default StateManager
