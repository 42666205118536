import { componentEventBus, componentEvents } from "../../components/form-builder/events"
export default {
  props: {
    value: {},
    validation: {
      default: false,
    },
    id: {
      default() {
        return "text-input"
      },
    },
    fieldKey: null,
    disabled: {
      default: false,
    },
    readOnly: {
      default: false,
    },
  },
  computed: {
    inputId() {
      return this.generateId(this.id)
    },
  },
  beforeMount: function () {
    const handler = (callback) => callback.call(this, this)
    componentEventBus.$on(this.fieldKey, handler)
  },
  methods: {
    sendEventToComponent(fieldKey, callback) {
      if (fieldKey === null || callback === null) {
        return
      }
      componentEventBus.$emit(fieldKey, callback)
    },
    handleComponentEvent(prop) {
      prop.split(";").forEach((val) => {
        let [cmd, component] = val.match(/(\w)(\w)+/gi)
        let callbackFn = componentEvents[cmd] || null
        this.sendEventToComponent(component, callbackFn)
      })
    },
    input(event) {
      if (event !== null && (event instanceof InputEvent || event.type === "input")) {
        this.$emit("input", event.target.value)
      } else if (event instanceof Event && event.target.type === "checkbox") {
        this.$emit("input", event.target.checked)
      } else {
        this.$emit("input", event)
      }
      if (this.validation) {
        this.validation.$touch()
      }
    },
    generateId(name) {
      return this.id + "-" + Math.random().toString(36).substring(3)
    },
  },
}
