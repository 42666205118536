import Hammer from "hammerjs"

import * as cornerstone from "cornerstone-core"
import * as cornerstoneMath from "cornerstone-math"
import * as cornerstoneTools from "cornerstone-tools"
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader"
import * as dicomParser from "dicom-parser"
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader"

// BIND TOOLS
cornerstoneTools.external.Hammer = Hammer
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.cornerstoneMath = cornerstoneMath

// BIND IMAGE LOADER
cornerstoneWebImageLoader.external.cornerstone = cornerstone

// BIND DICOM LOADER
cornerstoneWADOImageLoader.external.cornerstone = cornerstone
cornerstoneWADOImageLoader.external.dicomParser = dicomParser

try {
  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    webWorkerTaskPaths: [],
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  })
} catch (error) {}

export { cornerstone, cornerstoneTools, cornerstoneMath }
