//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    slideClass: { default: "" },
    slideTitle: { default: "" },
  },
  data() {
    return {
      display: false,
    }
  },
  mounted() {},
  methods: {
    show() {
      this.display = true
    },
    hide() {
      this.display = false
    },
  },
}
