var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cloud-attached-files" }, [
    _c(
      "div",
      { staticClass: "flex cloud-attached-files-title flex-wrap flex-spacing" },
      [
        _c("div", { staticClass: "w-full lg:w-6/12" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t.get("cloud-manager.my-attached-files"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full lg:w-6/12 text-right" },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color",
                    value: _vm.palette.colors.primary.alt,
                    expression: "palette.colors.primary.alt",
                    arg: "color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "background",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:border-color",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "border-color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background.hover",
                    value: _vm.palette.colors.hover.alt,
                    expression: "palette.colors.hover.alt",
                    arg: "background",
                    modifiers: { hover: true },
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:color.hover",
                    value: _vm.palette.colors.hover.main,
                    expression: "palette.colors.hover.main",
                    arg: "color",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "button accent small",
                attrs: {
                  disabled:
                    _vm.state !== _vm.states.READY ||
                    _vm.state === _vm.states.ERROR,
                },
                on: {
                  click: function ($event) {
                    return _vm.openCloudManager()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("cloud-manager.attach-files")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isProviderEnabled("dropbox")
              ? _c("dropbox-button", {
                  attrs: {
                    "api-key":
                      _vm.getExternalProviderAppKey("__DROPBOX_APP_KEY"),
                    disabled:
                      _vm.state !== _vm.states.READY ||
                      _vm.state === _vm.states.ERROR,
                  },
                  on: {
                    selected: function ($event) {
                      return _vm.onExternalCloudAttach($event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "flex flex-wrap flex-spacing",
        class: {
          "preloader preloader-cover": _vm.state === _vm.states.FETCHING,
        },
      },
      [
        _c(
          "div",
          { staticClass: "w-full files" },
          [
            _vm.isEmptyAttached
              ? _c("div", { staticClass: "empty" }, [
                  _c(
                    "p",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t.get(
                              "cloud-manager.there-are-no-medical-records-attached"
                            )
                          ) +
                          "\n          "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _vm.state === _vm.states.READY
                        ? [
                            _vm.state === _vm.states.READY
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "color",
                                      },
                                    ],
                                    staticClass: "accent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCloudManager()
                                      },
                                    },
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get("cloud-manager.click-here")
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "cloud-manager.to-choose-and-attach-your-medical-records"
                                  )
                                ) +
                                ".\n          "
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state === _vm.states.ERROR
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "cloud-manager.medical-records-temporary-unavailable-upload-files-is-disabled"
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              : _c(
                  "v-scrollbar",
                  [
                    _c(
                      "v-accordion",
                      [
                        _vm._l(_vm.folders, function (folder, folderKey) {
                          return _c(
                            "v-accordion-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.haveItemsAttached(folder.item_id) ||
                                    folder.attached === true,
                                  expression:
                                    "haveItemsAttached(folder.item_id) || folder.attached === true",
                                },
                              ],
                              key: folderKey,
                              on: {
                                expanded: function ($event) {
                                  return _vm.fetchFiles(folder.item_id)
                                },
                              },
                            },
                            [
                              _c(
                                "template",
                                {
                                  attrs: { draggable: "false" },
                                  slot: "title",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "accordion-name" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: folderKey } },
                                        [_vm._v(_vm._s(folder.name))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "delete",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.detachItem(
                                            folder.item_id,
                                            folder.item_id,
                                            "folder"
                                          )
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "ict-close" })]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "stack" },
                                [
                                  _c("div", { staticClass: "header" }, [
                                    _c("div", { staticClass: "flex" }, [
                                      _c(
                                        "div",
                                        { staticClass: "w-6/12 column name" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t.get(
                                                "cloud-manager.file-name"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "w-2/12 column type" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t.get("cloud-manager.format")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "w-2/12 column size" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t.get("cloud-manager.size")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-2/12 column status text-right",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t.get(
                                                  "cloud-manager.actions"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(folder.data, function (items, type) {
                                    return _c(
                                      "div",
                                      { key: type, staticClass: "stack-items" },
                                      _vm._l(items, function (stack, key) {
                                        return _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.isItemAttached(
                                                    folder.item_id,
                                                    stack[0].stack_name,
                                                    "file"
                                                  ) ||
                                                  stack[0].attached === true,
                                                expression:
                                                  "\n                    isItemAttached(folder.item_id, stack[0].stack_name, 'file') ||\n                    stack[0].attached === true\n                  ",
                                              },
                                            ],
                                            key: key,
                                            staticClass: "flex item",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-6/12 column name",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "stack-" + key,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          type === "dicoms"
                                                            ? stack[0]
                                                                .stack_name
                                                            : stack[0].name
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-2/12 column type",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(type.replace("s", ""))
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-2/12 column size",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatSize(
                                                      stack[0].size,
                                                      1
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-2/12 column status text-right",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "delete",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.detachItem(
                                                          folder.item_id,
                                                          stack[0].stack_name,
                                                          "file"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "ict-close",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: folder.dataFetched === false,
                                    expression: "folder.dataFetched === false",
                                  },
                                ],
                                staticClass: "preloader",
                              }),
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        _vm.externalCloud.dropbox.length > 0
                          ? _c("external-cloud-item", {
                              attrs: {
                                "folder-name": _vm.importFolderName("Dropbox"),
                                "external-attached": _vm.externalCloud.dropbox,
                                "can-detach-all":
                                  _vm.externalCloudAttachImmidiately === false,
                                "can-detach-subitem":
                                  _vm.externalCloudAttachImmidiately === false,
                              },
                              on: {
                                "attached-deleted": function ($event) {
                                  return _vm.onExternalCloudAttach($event)
                                },
                                "attached-changed": function ($event) {
                                  return _vm.onExternalCloudAttach($event)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "w-full legal-info" }, [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t.get("cloud-manager.your-personal-data-will-be-processed")
              ),
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }