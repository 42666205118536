//
//
//
//
//

export default {
  props: {
    singleActive: {
      default: false,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    addItem(item) {
      this.items.push(item)
      item.$on("expanded", this.watch.bind(this))
    },
    collapseAll() {
      this.items.forEach((item) => item.collapse())
    },
    watch(changed) {
      if (this.singleActive) {
        this.items.filter((item) => item._uid !== changed._uid).forEach((item) => item.collapse())
      }
    },
  },
}
