//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email, maxLength, required } from "vuelidate/lib/validators"
import { Constants } from "../../models/Constants"
import LanguageSelect from "../common/LanguageSelect.vue"

export default {
  components: {
    "language-select": LanguageSelect,
  },
  data() {
    return {
      processing: false,
      form: {
        email: "",
        message: "",
        withTakeTheCase: false,
        locale: null,
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      message: {
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    appointment() {
      return this.$store.state.appointment
    },
  },
  methods: {
    sendInvitation() {
      this.processing = true
      let params = {
        appointment_id: this.appointment.appointment_id,
        email: this.form.email,
        message: this.form.message,
        scope: Constants.Invitation.SCOPE_SHARE_PARTICIPANT_APPOINTMENT,
        mode: this.form.withTakeTheCase ? "take-the-case" : undefined,
        locale: this.form.locale,
      }
      this.$store.dispatch("invitations/inviteAppointmentParticipant", params).then(
        () => {
          this.$bridge.emit("addToast", this.$t.get("appointment.participant-shared"), "success")
          this.processing = false
          this.close()
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    close() {
      this.$emit("cancel")
    },
  },
}
