//
//
//
//
//
//

import InputMixin from "./InputMixin"
import Viewer from "../viewer/Viewer"
import DownloadPdf from "../viewer/tools/list/DownloadPdf"

export default {
  mixins: [InputMixin],
  props: {
    objectId: {
      default: null,
    },
    classes: {
      default: function () {
        return ["button", "accent"]
      },
    },
    text: {
      default: function () {
        return this.$t.get("profile.medical-records")
      },
    },
  },
  methods: {
    showMedicalFiles() {
      Viewer.create(this)
        .data(Viewer.Loaders.APPOINTMENT_LOADER, this.objectId)
        //.setPatientInfo(this.contextRecipient.name)
        .addTools({
          DownloadPdf: new DownloadPdf(this.objectId),
        })
        .show()
    },
  },
}
