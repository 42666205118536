//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatSize } from "../../../utils"
export default {
  props: {
    folderName: {
      type: String,
      required: true,
    },
    canDetachAll: {
      type: Boolean,
      default: true,
    },
    canDetachSubitem: {
      type: Boolean,
      default: true,
    },
    canDetach: {
      type: Boolean,
      default: true,
    },
    externalAttached: {
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      attached: [],
    }
  },
  components: {},
  mounted() {
    this.attached = this.externalAttached
  },
  methods: {
    deleteAttached() {
      this.$emit("attached-deleted", { provider: "dropbox", attached: [] })
    },
    detachItem(id) {
      const removeIndex = this.attached.map((item) => item.id).indexOf(id)
      if (removeIndex >= 0) {
        this.$delete(this.attached, removeIndex)
        this.$emit("attached-changed", { provider: "dropbox", attached: this.attached })
      }
    },
    formatSize(bytes, decimals) {
      return formatSize(bytes, decimals)
    },
  },
}
