export default {
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },

    customer: {
      type: Object,
    },
  },
}
