var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("div", { staticClass: "actions" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "w-full lg:w-full columns" }, [
            _c("span", { staticClass: "description right" }, [
              _vm._v(
                _vm._s(
                  _vm.$t.get("cloud-manager.please-select-files-from-right")
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "description bottom" }, [
              _vm._v(
                _vm._s(
                  _vm.$t.get("cloud-manager.please-select-files-from-bottom")
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "files" },
          [
            _c(
              "v-scrollbar",
              [
                _c(
                  "v-accordion",
                  { ref: "medicalCloudAccordion" },
                  _vm._l(_vm.folders, function (folder, folderKey) {
                    return _vm.showFolders === true ||
                      (_vm.showFolders === false && !folder.fetched)
                      ? _c(
                          "v-accordion-item",
                          {
                            key: folderKey,
                            staticClass: "folder",
                            on: {
                              expanded: function ($event) {
                                return _vm.fetchFiles(folder.item_id)
                              },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("input", {
                                staticClass: "inside",
                                attrs: { type: "checkbox", id: folderKey },
                                domProps: {
                                  checked: _vm.isItemAttached(
                                    folder.item_id,
                                    folder.item_id,
                                    "folder"
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "flex items-center",
                                  attrs: { for: folderKey },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.attachItem(
                                        folder.item_id,
                                        folder.item_id,
                                        "folder"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _vm.isItemAttached(
                                        folder.item_id,
                                        folder.item_id,
                                        "folder"
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "styled",
                                                  rawName:
                                                    "v-styled:background",
                                                  value:
                                                    _vm.palette.colors.primary
                                                      .main,
                                                  expression:
                                                    "palette.colors.primary.main",
                                                  arg: "background",
                                                },
                                                {
                                                  name: "styled",
                                                  rawName:
                                                    "v-styled:border-color",
                                                  value:
                                                    _vm.palette.colors.primary
                                                      .main,
                                                  expression:
                                                    "palette.colors.primary.main",
                                                  arg: "border-color",
                                                },
                                              ],
                                              staticClass:
                                                "checkmark is-attached",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "ict-checkmark",
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "checkmark" },
                                            [
                                              _c("i", {
                                                staticClass: "ict-checkmark",
                                              }),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c("i"),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(folder.name) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "dd-actions" },
                                    [
                                      _vm.showActions
                                        ? _c(
                                            "dropdown-button",
                                            {
                                              attrs: {
                                                id: folder.item_id,
                                                "dropdown-class":
                                                  "td-actions-dropdown",
                                                "current-context-menu-id":
                                                  _vm.current_context_menu,
                                              },
                                              on: {
                                                "dropdown-button-toggle":
                                                  _vm.handleDropdownClick,
                                              },
                                            },
                                            [
                                              _c(
                                                "li",
                                                {
                                                  attrs: { slot: "item" },
                                                  slot: "item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.renameFolder(
                                                            folder,
                                                            "modal"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t.get(
                                                            "cloud-manager.rename"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("li", {
                                                staticClass: "line",
                                                attrs: { slot: "item" },
                                                slot: "item",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                {
                                                  attrs: { slot: "item" },
                                                  slot: "item",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "warning",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.deleteItem(
                                                            folder.item_id,
                                                            folder.item_id,
                                                            folder.name,
                                                            "folder"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t.get(
                                                            "cloud-manager.delete"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(folder.data, function (items, type) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: Object.keys(items).length > 0,
                                      expression:
                                        "Object.keys(items).length > 0",
                                    },
                                  ],
                                  key: type,
                                  staticClass: "stack",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex header type" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "w-full column name" },
                                        [_vm._v(_vm._s(type.toUpperCase()))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "flex header" }, [
                                    _c(
                                      "div",
                                      { staticClass: "w-7/12 column name" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t.get(
                                              "cloud-manager.file-name"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "w-1/12 column type" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t.get("cloud-manager.format")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "w-1/12 column size" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t.get("cloud-manager.size")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "w-2/12 column size" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t.get("cloud-manager.actions")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(items, function (stack, key) {
                                    return _c(
                                      "div",
                                      { key: key, staticClass: "flex item" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "w-7/12 column name" },
                                          [
                                            _c("input", {
                                              staticClass: "inside",
                                              attrs: {
                                                value: "true",
                                                type: "checkbox",
                                                id: "stack-" + key,
                                              },
                                              domProps: {
                                                checked: _vm.isItemAttached(
                                                  folder.item_id,
                                                  stack[0].stack_name,
                                                  "file"
                                                ),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: { for: "stack-" + key },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.attachItem(
                                                      folder.item_id,
                                                      stack[0].stack_name,
                                                      "file"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex items-center",
                                                  },
                                                  [
                                                    _vm.isItemAttached(
                                                      folder.item_id,
                                                      stack[0].stack_name,
                                                      "file"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "styled",
                                                                rawName:
                                                                  "v-styled:background",
                                                                value:
                                                                  _vm.palette
                                                                    .colors
                                                                    .primary
                                                                    .main,
                                                                expression:
                                                                  "palette.colors.primary.main",
                                                                arg: "background",
                                                              },
                                                              {
                                                                name: "styled",
                                                                rawName:
                                                                  "v-styled:border-color",
                                                                value:
                                                                  _vm.palette
                                                                    .colors
                                                                    .primary
                                                                    .main,
                                                                expression:
                                                                  "palette.colors.primary.main",
                                                                arg: "border-color",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "checkmark is-attached",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "ict-checkmark",
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "checkmark",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "ict-checkmark",
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      type === "dicoms"
                                                        ? stack[0].stack_name
                                                        : stack[0].name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "w-1/12 column type" },
                                          [
                                            _c("i", {
                                              class: [
                                                "ict-" + type.replace("s", ""),
                                              ],
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "w-1/12 column size" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatSize(stack[0].size, 1)
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-2/12 column dd-actions",
                                          },
                                          [
                                            _vm.showActions
                                              ? _c(
                                                  "dropdown-button",
                                                  {
                                                    attrs: {
                                                      id:
                                                        folder.item_id +
                                                        "stack-" +
                                                        key,
                                                      "dropdown-class":
                                                        "td-actions-dropdown",
                                                      "current-context-menu-id":
                                                        _vm.current_context_menu,
                                                    },
                                                    on: {
                                                      "dropdown-button-toggle":
                                                        _vm.handleDropdownClick,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        attrs: { slot: "item" },
                                                        slot: "item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "warning",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteItem(
                                                                  folder.item_id,
                                                                  stack[0]
                                                                    .stack_name,
                                                                  stack[0]
                                                                    .stack_name,
                                                                  "file"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t.get(
                                                                  "cloud-manager.delete"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEmptyFolder(folder),
                                    expression: "isEmptyFolder(folder)",
                                  },
                                ],
                                staticClass: "empty-folder",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t.get(
                                        "cloud-manager.this-folder-is-empty"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: folder.dataFetched === false,
                                  expression: "folder.dataFetched === false",
                                },
                              ],
                              staticClass: "preloader",
                            }),
                          ],
                          2
                        )
                      : _vm._e()
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          Object.keys(_vm.folders).length == 0 &&
                          _vm.state === _vm.states.READY,
                        expression:
                          "Object.keys(folders).length == 0 && state === states.READY",
                      },
                    ],
                    staticClass: "empty-manager",
                  },
                  [
                    _vm._t("noFolders", function () {
                      return [void 0]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropzone" }, [
          _c("input", {
            attrs: {
              type: "file",
              id: "browseFile-" + _vm.objectId,
              multiple: "",
            },
            on: { change: _vm.onFileChange },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "file",
              id: "browseFolder-" + _vm.objectId,
              directory: "",
              mozdirectory: "",
              webkitdirectory: "",
            },
            on: { change: _vm.onFileChange },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.state === _vm.states.READY ||
                    _vm.state === _vm.states.FETCHING,
                  expression:
                    "state === states.READY || state === states.FETCHING",
                },
              ],
              staticClass: "area",
              class: { "file-hover": _vm.dropzoneActive },
              on: {
                dragover: _vm.dropzoneOver,
                dragleave: _vm.dropzoneLeave,
                drop: _vm.onFileChange,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "drop",
                  attrs: { "aria-dropeffect": "copy execute" },
                },
                [
                  _c("img", {
                    staticClass: "hidden xxl:block",
                    attrs: { src: "/assets/app/img/drag_drop_cloud.png" },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get("cloud-manager.drag-and-drop-br-files-here")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t.get("cloud-manager.upload-limit")) +
                        ":\n            "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.formatSize(_vm.maxUpload, 0))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t.get("cloud-manager.accepted-files")) +
                        ":\n            "
                    ),
                    _c(
                      "span",
                      {
                        directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                        staticClass: "accepted-files",
                        attrs: { alt: _vm.allowFormats.join(", ") },
                      },
                      [_vm._v(_vm._s(_vm.$t.get("cloud-manager.show-list")))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button accent",
                    attrs: {
                      for: "browseFile-" + _vm.objectId,
                      disabled: _vm.state !== _vm.states.READY,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t.get("cloud-manager.upload-files")))]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.secondary.main,
                        expression: "palette.colors.secondary.main",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button",
                    attrs: {
                      for: "browseFolder-" + _vm.objectId,
                      disabled: _vm.state !== _vm.states.READY,
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t.get("cloud-manager.upload-folders")))]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state == _vm.states.ERROR,
                  expression: "state == states.ERROR",
                },
              ],
              staticClass: "area",
            },
            [
              _c("div", { staticClass: "preparing" }, [
                _c("div", { staticClass: "info" }, [
                  _c("i", { staticClass: "ict-alert" }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get(
                          "cloud-manager.services-unavailable-br-try-again-later-or-refresh-page"
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state == _vm.states.PREPARING,
                  expression: "state == states.PREPARING",
                },
              ],
              staticClass: "area",
            },
            [
              _c("div", { staticClass: "preparing" }, [
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.please-wait"))),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatSize(_vm.sizeFiles, 0)) + " prepared"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state == _vm.states.UPLOADING,
                  expression: "state == states.UPLOADING",
                },
              ],
              staticClass: "area",
            },
            [
              _c("div", { staticClass: "preparing" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state == _vm.states.UPLOADING,
                        expression: "state == states.UPLOADING",
                      },
                    ],
                    staticClass: "status",
                  },
                  [
                    _c("progress-circle", {
                      attrs: { progress: _vm.uploadedPercent },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.uploadedPercent) + "%")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.uploading-files"))),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.formatSize(_vm.sizeUploadedFiles, 0)) +
                        "\n              " +
                        _vm._s(_vm.$t.get("cloud-manager.uploading-files-of")) +
                        "\n              " +
                        _vm._s(_vm.formatSize(_vm.sizeFiles, 0))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.uploadTimeLeft))]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.secondary.main,
                          expression: "palette.colors.secondary.main",
                          arg: "color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background",
                          value: _vm.palette.colors.primary.alt,
                          expression: "palette.colors.primary.alt",
                          arg: "background",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background.hover",
                          value: _vm.palette.colors.hover.main,
                          expression: "palette.colors.hover.main",
                          arg: "background",
                          modifiers: { hover: true },
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color.hover",
                          value: _vm.palette.colors.hover.alt,
                          expression: "palette.colors.hover.alt",
                          arg: "color",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.cancelUpload()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t.get("cloud-manager.cancel-upload")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "secure" }, [
            _c("i", {
              directives: [
                {
                  name: "styled",
                  rawName: "v-styled:color",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "color",
                },
              ],
              staticClass: "ict-lock",
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t.get("cloud-manager.data-upload-and-sharing-with")
                  ) +
                  "\n          "
              ),
              _c(
                "a",
                {
                  directives: [{ name: "tooltip", rawName: "v-tooltip" }],
                  staticClass: "cursor-pointer",
                  attrs: {
                    alt: _vm.$t.get(
                      "cloud-manager.data-upload-and-sharing-with-alt"
                    ),
                  },
                },
                [_vm._v(_vm._s(_vm.$t.get("cloud-manager.more")))]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showBottomButtonsList
        ? _c("div", { staticClass: "buttons-list margin" }, [
            _vm.exploreMode === false && _vm.hideAttachButton === false
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button accent",
                    attrs: {
                      disabled:
                        _vm.state !== _vm.states.READY ||
                        _vm.state === _vm.states.ERROR ||
                        _vm.isEmptyAttached(),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.attachSelectedFiles()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t.get("cloud-manager.attach-selected-files")
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color",
                    value: _vm.palette.colors.secondary.main,
                    expression: "palette.colors.secondary.main",
                    arg: "color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background",
                    value: _vm.palette.colors.primary.alt,
                    expression: "palette.colors.primary.alt",
                    arg: "background",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:border-color",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "border-color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background.hover",
                    value: _vm.palette.colors.hover.main,
                    expression: "palette.colors.hover.main",
                    arg: "background",
                    modifiers: { hover: true },
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:color.hover",
                    value: _vm.palette.colors.hover.alt,
                    expression: "palette.colors.hover.alt",
                    arg: "color",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "button border-gray",
                attrs: {
                  disabled: !(
                    _vm.state === _vm.states.READY ||
                    _vm.state === _vm.states.ERROR
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.cancelAttach()
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t.get("cloud-manager.cancel")) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-modal",
        {
          ref: "cloudManagerChooseFolder",
          staticClass: "modal-cloud-manager-choose-folder",
          attrs: {
            title: _vm.$t.get("cloud-manager.select-the-destination-folder"),
            closeable: false,
          },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-full lg:w-10/12 large-offset-2 columns" },
              [
                _c("v-scrollbar", [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.folders, function (folder) {
                          return _c(
                            "li",
                            {
                              key: folder.item_id,
                              staticClass: "folder",
                              class: {
                                "selected-folder":
                                  folder.item_id === _vm.selectedFolder,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.selectedFolder = folder.item_id
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-folder" }),
                              _vm._v(" "),
                              _c("label", [_vm._v(_vm._s(folder.name))]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "new-folder material-form" }, [
                          _c("i", { staticClass: "ict-folder" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newFolder,
                                  expression: "newFolder",
                                },
                              ],
                              attrs: {
                                id: "destination-folder-name",
                                type: "text",
                                placeholder: _vm.$t.get("create-folder-label"),
                                "placeholder-sticky": "",
                              },
                              domProps: { value: _vm.newFolder },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.createFolder()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.newFolder = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "destination-folder-name" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t.get("cloud-manager.folder-name")
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "styled",
                                  rawName: "v-styled:color",
                                  value: _vm.palette.colors.secondary.main,
                                  expression: "palette.colors.secondary.main",
                                  arg: "color",
                                },
                                {
                                  name: "styled",
                                  rawName: "v-styled:background",
                                  value: _vm.palette.colors.primary.alt,
                                  expression: "palette.colors.primary.alt",
                                  arg: "background",
                                },
                                {
                                  name: "styled",
                                  rawName: "v-styled:border-color",
                                  value: _vm.palette.colors.primary.main,
                                  expression: "palette.colors.primary.main",
                                  arg: "border-color",
                                },
                                {
                                  name: "styled",
                                  rawName: "v-styled:background.hover",
                                  value: _vm.palette.colors.hover.main,
                                  expression: "palette.colors.hover.main",
                                  arg: "background",
                                  modifiers: { hover: true },
                                },
                                {
                                  name: "styled",
                                  rawName: "v-styled:color.hover",
                                  value: _vm.palette.colors.hover.alt,
                                  expression: "palette.colors.hover.alt",
                                  arg: "color",
                                  modifiers: { hover: true },
                                },
                              ],
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  return _vm.createFolder()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t.get("cloud-manager.create-folder")
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center text-medium-gray",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "flex buttons" }, [
                _c("div", { staticClass: "w-full lg:w-full columns" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.primary.alt,
                          expression: "palette.colors.primary.alt",
                          arg: "color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "background",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background.hover",
                          value: _vm.palette.colors.hover.alt,
                          expression: "palette.colors.hover.alt",
                          arg: "background",
                          modifiers: { hover: true },
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color.hover",
                          value: _vm.palette.colors.hover.main,
                          expression: "palette.colors.hover.main",
                          arg: "color",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "button accent",
                      attrs: { disabled: _vm.selectedFolder === null },
                      on: {
                        click: function ($event) {
                          return _vm.startUpload()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("cloud-manager.start-upload")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.secondary.main,
                          expression: "palette.colors.secondary.main",
                          arg: "color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background",
                          value: _vm.palette.colors.primary.alt,
                          expression: "palette.colors.primary.alt",
                          arg: "background",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background.hover",
                          value: _vm.palette.colors.hover.main,
                          expression: "palette.colors.hover.main",
                          arg: "background",
                          modifiers: { hover: true },
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color.hover",
                          value: _vm.palette.colors.hover.alt,
                          expression: "palette.colors.hover.alt",
                          arg: "color",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "button border-gray",
                      on: {
                        click: function ($event) {
                          return _vm.cancelUpload()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("cloud-manager.cancel-upload")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-modal",
        {
          ref: "cloudManagerRenameFolder",
          staticClass: "modal-cloud-manager-rename-folder",
          attrs: {
            title: _vm.$t.get("cloud-manager.rename-folder"),
            closeable: true,
          },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-full lg:w-full columns" }, [
              _c("div", { staticClass: "flex material-form" }, [
                _c("div", { staticClass: "w-full lg:w-full columns" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.current_folder_rename.name,
                          expression: "current_folder_rename.name",
                        },
                      ],
                      staticClass: "folder-rename",
                      attrs: { id: "rename-folder-name", type: "text" },
                      domProps: { value: _vm.current_folder_rename.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.current_folder_rename,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "rename-folder-name" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t.get(
                            "cloud-manager.enter-the-new-name-for-folder"
                          )
                        ) +
                          ' "' +
                          _vm._s(_vm.current_folder_rename.old_name) +
                          '":'
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center text-medium-gray",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "flex buttons" }, [
                _c("div", { staticClass: "w-full lg:w-full columns" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.primary.alt,
                          expression: "palette.colors.primary.alt",
                          arg: "color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "background",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background.hover",
                          value: _vm.palette.colors.hover.alt,
                          expression: "palette.colors.hover.alt",
                          arg: "background",
                          modifiers: { hover: true },
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color.hover",
                          value: _vm.palette.colors.hover.main,
                          expression: "palette.colors.hover.main",
                          arg: "color",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "button accent",
                      attrs: {
                        disabled:
                          _vm.current_folder_rename.name.length === 0 ||
                          _vm.state === _vm.states.RENAMING,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.renameFolder(
                            _vm.current_folder_rename,
                            "save"
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.state === _vm.states.RENAMING,
                              expression: "state === states.RENAMING",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t.get("cloud-manager.renaming")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.state !== _vm.states.RENAMING,
                              expression: "state !== states.RENAMING",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t.get("cloud-manager.rename")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.secondary.main,
                          expression: "palette.colors.secondary.main",
                          arg: "color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background",
                          value: _vm.palette.colors.primary.alt,
                          expression: "palette.colors.primary.alt",
                          arg: "background",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:background.hover",
                          value: _vm.palette.colors.hover.main,
                          expression: "palette.colors.hover.main",
                          arg: "background",
                          modifiers: { hover: true },
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color.hover",
                          value: _vm.palette.colors.hover.alt,
                          expression: "palette.colors.hover.alt",
                          arg: "color",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "button border-gray",
                      attrs: { disabled: _vm.state === _vm.states.RENAMING },
                      on: {
                        click: function ($event) {
                          return _vm.renameFolder(
                            _vm.current_folder_rename,
                            "reset"
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("cloud-manager.cancel")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-modal",
        {
          ref: "cloudManagerDeleteConfirm",
          staticClass: "modal-cloud-manager-delete-confirm",
          attrs: { title: _vm.$t.get("cloud-manager.delete-confirmation") },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-full lg:w-full columns" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-full lg:w-full" }, [
                  _c("p", {
                    staticClass: "description",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get(
                          "cloud-manager.do-you-really-want-to-delete",
                          {
                            type: _vm.current_item_delete.type,
                            name: _vm.current_item_delete.name,
                          }
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "note",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get(
                          "cloud-manager.note-this-operation-is-irreversible"
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-list margin-20" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:background.hover",
                    value: _vm.palette.colors.hover.main,
                    expression: "palette.colors.hover.main",
                    arg: "background",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "button red",
                attrs: { disabled: _vm.state === _vm.states.DELETING },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteItemConfirmation()
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state === _vm.states.DELETING,
                        expression: "state === states.DELETING",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t.get("cloud-manager.deleting")))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state !== _vm.states.DELETING,
                        expression: "state !== states.DELETING",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t.get("cloud-manager.delete")))]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color",
                    value: _vm.palette.colors.secondary.main,
                    expression: "palette.colors.secondary.main",
                    arg: "color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background",
                    value: _vm.palette.colors.primary.alt,
                    expression: "palette.colors.primary.alt",
                    arg: "background",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:border-color",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "border-color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background.hover",
                    value: _vm.palette.colors.hover.main,
                    expression: "palette.colors.hover.main",
                    arg: "background",
                    modifiers: { hover: true },
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:color.hover",
                    value: _vm.palette.colors.hover.alt,
                    expression: "palette.colors.hover.alt",
                    arg: "color",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "button",
                attrs: { disabled: _vm.state === _vm.states.DELETING },
                on: {
                  click: function ($event) {
                    return _vm.$refs.cloudManagerDeleteConfirm.close()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("cloud-manager.cancel")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }