//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"
import AttachFiles from "../../components/cloud-manager/AttachFiles.vue"
import { differenceWith, unionWith, isEqual } from "lodash"

export default {
  mixins: [InputMixin],
  data() {
    return {
      attached: [],
      detached: [],
      externalCloud: {
        dropbox: [],
      },
    }
  },
  props: {
    objectId: {
      default: null,
    },
    resource: {
      type: String,
      required: true,
    },
    crudURL: {
      type: String,
      required: true,
    },
  },
  methods: {
    onItemsAttached(items) {
      items = JSON.parse(JSON.stringify(items))
      this.attached = unionWith(this.attached, items, isEqual)
      this.detached = differenceWith(this.detached, items, isEqual)

      this.emitValue()
    },
    onItemsDetached(item) {
      item = JSON.parse(JSON.stringify(item))
      this.detached.push(item)
      this.emitValue()
    },
    onExternalItemsAttached({ provider, attached }) {
      this.$set(this.externalCloud, provider, attached)
      this.emitValue()
    },
    emitValue() {
      const value = {
        localFiles: {
          attached: this.attached,
          detached: this.detached,
        },
        externalCloud: this.externalCloud,
      }
      this.$emit("input", value)
      if (this.validation) {
        this.validation.$touch()
      }
    },
  },
  components: {
    "attach-files": AttachFiles,
  },
}
