//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, sameAs, not } from "vuelidate/lib/validators"

export default {
  data() {
    return {
      processing: false,
      passwords: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      checkImg: "/assets/app/img/check_custom.png",
      isAdminAccount: this.$store.state.user.groups.some(
        (item) => item.member.roles.includes("tpa") || item.member.roles.includes("admin")
      ),
    }
  },
  validations() {
    const minLengthValidator = (value) => {
      const minLength = this.isAdminAccount ? 12 : 10
      return value.length >= minLength
    }

    return {
      passwords: {
        current_password: {
          required,
        },
        new_password: {
          required,
          notSameAsPassword: not(sameAs("current_password")),
          minLength: minLengthValidator,
          containsNumber: (value) => /\d/.test(value),
          containsSymbol: (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
          containsUpperAndLower: (value) => /[a-z]/.test(value) && /[A-Z]/.test(value),
        },
        new_password_confirmation: {
          required,
          sameAsPassword: sameAs("new_password"),
        },
      },
    }
  },
  computed: {
    validationsMessages() {
      return [
        {
          message: this.isAdminAccount
            ? this.$t.get("auth.twelveCharacters")
            : this.$t.get("auth.tenCharacters"),
          passed: false,
        },
        { message: this.$t.get("auth.numberCharacter"), passed: false },
        { message: this.$t.get("auth.symbolCharacter"), passed: false },
        { message: this.$t.get("auth.uperLowerCharacter"), passed: false },
      ]
    },
  },
  methods: {
    checkPasswordValidations() {
      const password = this.passwords.new_password

      this.validationsMessages[0].passed = this.isAdminAccount
        ? password.length >= 12
        : password.length >= 10
      this.validationsMessages[1].passed = /\d/.test(password) // Verifica si tiene al menos un número
      this.validationsMessages[2].passed = /[!@#$%^&*(),.?":{}|<>]/.test(password) // Verifica si tiene al menos un símbolo
      this.validationsMessages[3].passed = /[a-z]/.test(password) && /[A-Z]/.test(password) // Verifica si tiene mayúscula y minúscula
    },
    changePassword() {
      this.processing = true
      this.$http.post("/ajax/settings/password", this.passwords).then(
        (response) => {
          this.processing = false
          this.$bridge.emit(
            "addToast",
            this.$t.get("settings.password-has-been-changed"),
            "success"
          )
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
  },
  watch: {
    "passwords.new_password": function () {
      this.checkPasswordValidations()
    },
  },
}
