import UaParser from "ua-parser-js"

export const browserDetect = {
  data() {
    return {
      cacheData: {},
    }
  },
  computed: {
    parseBrowser() {
      return (agent) => {
        let key = this.hash(agent)
        if (this.cacheData.hasOwnProperty(key)) {
          return this.cacheData[key]
        }
        let result = UaParser(agent)
        this.cacheData[key] = result
        return result
      }
    },
    getBrowser() {
      return (agent) => {
        let ua = this.parseBrowser(agent)
        return this.filterName(ua.browser.name) + " " + this.filterName(ua.browser.version)
      }
    },
    getBrowserOS() {
      return (agent) => {
        let ua = this.parseBrowser(agent)
        return this.filterName(ua.os.name) + " " + this.filterName(ua.os.version)
      }
    },
  },
  methods: {
    hash(str) {
      if (typeof str === "undefined" || !str) {
        str = ""
      }
      let hash = 0
      if (str.length === 0) {
        return "-"
      }
      for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash
      }
      return hash
    },
    filterName(name) {
      return typeof name === "undefined" ? "" : name
    },
  },
}
