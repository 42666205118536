var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "material-form",
      attrs: { role: "form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.changePassword.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-spacing flex-col w-1/2",
            class: { "preloader preloader-cover": _vm.processing },
          },
          [
            _c("div", { staticClass: "w-full columns" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.passwords.current_password,
                        expression: "passwords.current_password",
                        modifiers: { trim: true },
                      },
                    ],
                    class: { error: _vm.$v.passwords.current_password.$error },
                    attrs: { type: "password", id: "current_password" },
                    domProps: { value: _vm.passwords.current_password },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.passwords,
                            "current_password",
                            $event.target.value.trim()
                          )
                        },
                        function ($event) {
                          return _vm.$v.passwords.current_password.$touch()
                        },
                      ],
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "current_password" } }, [
                    _vm._v(_vm._s(_vm.$t.get("settings.current-password"))),
                  ]),
                  _vm._v(" "),
                  _c("v-input-error", {
                    attrs: { validation: _vm.$v.passwords.current_password },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full columns" }, [
              _c("div", { staticClass: "input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.passwords.new_password,
                      expression: "passwords.new_password",
                      modifiers: { trim: true },
                    },
                  ],
                  class: {
                    error: _vm.$v.passwords.new_password.$error,
                    success:
                      !_vm.$v.passwords.new_password.$invalid &&
                      _vm.passwords.new_password.length > 0,
                  },
                  attrs: { type: "password", id: "password" },
                  domProps: { value: _vm.passwords.new_password },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.passwords,
                          "new_password",
                          $event.target.value.trim()
                        )
                      },
                      function ($event) {
                        return _vm.$v.passwords.new_password.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v(_vm._s(_vm.$t.get("settings.new-password"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full columns" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.passwords.new_password_confirmation,
                        expression: "passwords.new_password_confirmation",
                        modifiers: { trim: true },
                      },
                    ],
                    class: {
                      error: _vm.$v.passwords.new_password_confirmation.$error,
                    },
                    attrs: { type: "password", id: "confirm_password" },
                    domProps: {
                      value: _vm.passwords.new_password_confirmation,
                    },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.passwords,
                            "new_password_confirmation",
                            $event.target.value.trim()
                          )
                        },
                        function ($event) {
                          return _vm.$v.passwords.new_password_confirmation.$touch()
                        },
                      ],
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "confirm_password" } }, [
                    _vm._v(_vm._s(_vm.$t.get("settings.confirm-new-password"))),
                  ]),
                  _vm._v(" "),
                  _c("v-input-error", {
                    attrs: {
                      validation: _vm.$v.passwords.new_password_confirmation,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex w-1/2 flex-col justify-between" },
          _vm._l(_vm.validationsMessages, function (message, index) {
            return _c("div", { key: index, staticClass: "flex gap-2" }, [
              _c("img", {
                style: message.passed ? "" : "filter: grayscale(100%);",
                attrs: { src: _vm.checkImg, alt: "check-img" },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex items-center text-sm font-medium" },
                [_vm._v(_vm._s(message.message))]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-list" }, [
        _c("input", {
          staticClass: "button accent small",
          attrs: {
            type: "submit",
            disabled: _vm.processing || _vm.$v.passwords.$invalid,
          },
          domProps: { value: _vm.$t.get("settings.change-password") },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }