//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../../../../plugins/Customize"
import ChartLine from "../../../../common/chart-line.vue"
import { browserDetect } from "../../../../../apps/browser-detect-mixin"

export default {
  name: "BenchmarkDetails",
  mixins: [Customize({}), browserDetect],
  components: {
    "chart-line": ChartLine,
  },
  props: {
    benchmark: { type: Object, required: true },
  },
  data() {
    return {
      // set of default fields for benchmark result as some may not exist
      benchmarkDefaultValues: {
        dates: {
          start: null,
          end: null,
        },
        screen: {
          width: "-",
          height: "-",
        },
        twilio: {
          result: -1,
        },
        camera: {
          result: -1,
        },
        socket: {
          result: -1,
        },
        trustedoctor: {
          logs: "-",
        },
        selectedDevice: {
          audio: "-",
          video: "-",
        },
        permissionStatus: {
          microphone: "-",
          camera: "-",
        },
        microphone: {
          logs: [],
        },
        userAgent: null,
      },
      benchmarkData: {},
      ready: false,
      gradient: null,
      displayChart: true,
    }
  },
  computed: {
    micData() {
      return {
        datasets: [
          {
            pointRadius: 1,
            borderWidth: 1,
            data: this.benchmarkData.microphone.logs,
          },
        ],
        labels: this.benchmarkData.microphone.logs.map((value, index) => index),
      }
    },
    chartOptions() {
      return {
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
        },
        tooltips: {
          titleAlign: "center",
          xPadding: 20,
          yPadding: 13,
          cornerRadius: 4,
          displayColors: false,
          backgroundColor: "#fefefe",
          titleMarginBottom: 0,
          xAlign: "center",
          yAlign: "bottom",
        },
        scales: {
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                beginAtZero: true,
                padding: 7,
                fontColor: "#989898",
                fontFamily: "Poppins",
                fontSize: 11,
                fontStyle: "600",
                steps: 4,
              },
              gridLines: {
                drawBorder: false,
                zeroLineWidth: 0,
                borderDash: [8, 4],
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 8,
                beginAtZero: true,
                fontColor: "#07203B",
                fontFamily: "Poppins",
                fontSize: 11,
                fontStyle: "600",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        // hoverMode: "index",
        stacked: false,
        responsive: true,
        maintainAspectRatio: false,
      }
    },
  },
  methods: {
    getDuration(dates) {
      if (typeof dates === "undefined") {
        return this.$t.get("customer.details.common.not-available")
      }
      let dateEnd = dates.end
      let dateStart = dates.start

      if (dateEnd && dateStart) {
        return this.$moment
          .utc(this.$moment(dateEnd).diff(this.$moment(dateStart)))
          .format("HH:mm:ss")
      }
      return this.$t.get("customer.details.common.not-available")
    },
    getTwilioResult(number) {
      switch (number) {
        case 0:
          return this.$t.get("customer.details.benchmark.status-checking")
        case 1:
          return this.$t.get("customer.details.benchmark.status-bad")
        case 2:
          return this.$t.get("customer.details.benchmark.status-sufficient")
        case 3:
          return this.$t.get("customer.details.benchmark.status-good")
        default:
          return "-"
      }
    },
  },
  created() {
    Object.keys(this.benchmarkDefaultValues).forEach((key) => {
      if (this.benchmark.hasOwnProperty(key) === false) {
        this.benchmarkData[key] = this.benchmarkDefaultValues[key]
      } else {
        this.benchmarkData[key] = this.benchmark[key]
      }
    })
    this.ready = true
  },
}
