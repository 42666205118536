var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-storage-uploader" }, [
    _c("div", { staticClass: "main" }, [
      _vm.disableUploader === false
        ? _c("div", { staticClass: "dropzone" }, [
            _c("input", {
              ref: "fileInput",
              attrs: {
                type: "file",
                id: "browseFile-" + _vm.objectId,
                multiple: "",
              },
              on: { change: _vm.onFileChange },
            }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.uploadedWithStatic.length > 0 || _vm.state !== _vm.states.READY
      ? _c(
          "div",
          { staticClass: "files" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.state == _vm.states.ERROR,
                    expression: "state == states.ERROR",
                  },
                ],
                staticClass: "flex item uploader",
              },
              [
                _c("div", { staticClass: "w-7/12 column name" }, [
                  _c("i", { staticClass: "ict-alert" }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get(
                          "cloud-manager.services-unavailable-br-try-again-later-or-refresh-page"
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column size" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.state == _vm.states.PREPARING,
                    expression: "state == states.PREPARING",
                  },
                ],
                staticClass: "flex item uploader",
              },
              [
                _c("div", { staticClass: "w-7/12 column name" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.please-wait"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column size" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column" }, [
                  _vm._v(
                    _vm._s(_vm.formatSize(_vm.sizeFiles, 0)) + " prepared"
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.state == _vm.states.UPLOADING,
                    expression: "state == states.UPLOADING",
                  },
                ],
                staticClass: "flex item uploader",
              },
              [
                _c("div", { staticClass: "w-7/12 column name" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.uploading-files"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column size" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column" }, [
                  _c("div", { staticClass: "progressbar" }, [
                    _c("div", {
                      staticClass: "bar",
                      style: { width: _vm.uploadedPercent + "%" },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.uploadedWithStatic, function (file, key) {
              return _c("div", { key: key, staticClass: "flex item" }, [
                _c("div", { staticClass: "w-7/12 column name" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: file.link,
                        target: "_blank",
                        rel: "noopener nofollow",
                        title: file.name,
                      },
                    },
                    [
                      _vm.showDownloadIcon
                        ? _c("i", { staticClass: "ict-download" })
                        : _vm._e(),
                      _vm._v("\n          " + _vm._s(file.name) + "\n        "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column size" }, [
                  _vm._v(_vm._s(_vm.formatSize(file.size, 1))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 column text-right" }, [
                  file.no_delete === undefined
                    ? _c(
                        "button",
                        {
                          staticClass: "detach",
                          on: {
                            click: function ($event) {
                              return _vm.detachFile(key)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t.get("cloud-manager.delete")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.uploadedWithStatic.length === 0
              ? _c("div", { staticClass: "flex item" }, [
                  _c("div", { staticClass: "w-full column name" }, [
                    _vm._v(
                      _vm._s(_vm.$t.get("cloud-manager.no-files-uploaded"))
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }