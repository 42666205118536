//
//
//
//
//
//

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    url: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    isCurrent() {
      return window.location.href.includes(this.url)
    },
  },
}
