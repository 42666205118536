//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from "vuex"
import Modal from "../../plugins/modal/modal"

const { mapGetters } = createNamespacedHelpers("invitations")

export default {
  props: {
    groupId: { default: 1 },
  },
  data() {
    return {
      processing: false,
      participants: [],
    }
  },
  computed: {
    ...mapGetters(["getPendingInvitations"]),
    emptyList() {
      return Object.keys(this.participants).length === 0
    },
    countInvited() {
      return this.getPendingInvitations.length
    },
    haveInvitations() {
      return this.countInvited > 0
    },
  },
  beforeMount() {
    this.fetch()
  },
  mounted() {},
  methods: {
    fetch() {
      this.$store.dispatch("invitations/fetch", { object_id: this.groupId })
    },
    revokeInvitation(invitation) {
      Modal.confirm(this)
        .title("Revoke invitation")
        .content(
          '<p class="description">Are you sure you want to <b>revoke</b> the invitation for ' +
            invitation.email +
            "?</p>"
        )
        .positive("Revoke", function (modal) {
          return this.$store
            .dispatch("invitations/revokeInvitation", { invitation_id: invitation.invitation_id })
            .then(
              (response) => {
                this.$bridge.emit("addToast", response.data.message, "success")
              },
              (error) => {
                this.$bridge.emit("addToast", error.response.data.message, "error")
              }
            )
        })
        .negative("Cancel")
        .show()
    },
    resendInvitation(invitation) {
      this.$store
        .dispatch("invitations/resendInvitation", { invitation_id: invitation.invitation_id })
        .then(
          (response) => {
            this.$bridge.emit("addToast", response.data.message, "success")
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.message, "error")
          }
        )
    },
  },
}
