var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: { active: _vm.isActive || _vm.isCurrent },
      attrs: { href: _vm.url },
    },
    [
      _vm.icon !== "" ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.label))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }