var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content table-scroll" },
    [
      _c(
        "div",
        { staticClass: "table-header", staticStyle: { "min-width": "500px" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-6/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("customer.details.audit-log.action"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-3/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("customer.details.common.created-at"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-3/12 columns" }, [
              _vm._v(
                _vm._s(_vm.$t.get("customer.details.common.audit-success"))
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "table-row row-hoverable",
            staticStyle: { "min-width": "500px" },
          },
          [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-6/12 columns" }, [
                _vm._v(
                  "\n        " + _vm._s(item.permission_name) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$dateTimeFormat.formatTime(item.created_at)) +
                    ",\n        " +
                    _vm._s(_vm.$dateTimeFormat.formatDate(item.created_at)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      item.result
                        ? _vm.$t.get("common.yes")
                        : _vm.$t.get("common.no")
                    ) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.processing && _vm.items.total === 0
        ? _c("div", { staticClass: "table-empty" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "lg:w-full w-full columns text-center" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("customer.details.common.no-data")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }