import moment from "moment-timezone"

moment.locale(window.__LOCALE)
if (window.__USER && window.__USER.hasOwnProperty("time_zone")) {
  moment.tz.setDefault(window.__USER.time_zone)
}
moment.parseTwoDigitYear = function (input) {
  return input
}

window.moment = moment

export default {
  install(Vue, options) {
    Object.defineProperties(Vue.prototype, {
      $moment: {
        get() {
          return moment
        },
      },
      $momentUtils: {
        get() {
          return {
            format: (date, format) => {
              if (typeof date !== "string" || date === null) return "-"
              if (typeof format === typeof undefined) format = "DD.MM.YYYY H:mm"
              return moment(date).format(format)
            },
            relativeTime: (date) => {
              return this.$moment(date).fromNow()
            },
            timeLeft: (date, diffSize) => {
              if (typeof diffSize == typeof undefined) diffSize = 60
              var diff = this.$moment(date).diff(this.$moment(), "minutes")
              if (diff < diffSize) return this.$moment().to(this.$moment(date))
              else return this.$moment(date).format("DD.MM.YYYY HH:mm")
            },
            timeLeftCall: (start_date, now_date) => {
              start_date = this.$moment(start_date)
              now_date = this.$moment(now_date)
              let diff = start_date.diff(now_date)
              if (diff >= 86400000) {
                // if greater than day
                return now_date.to(start_date)
              }
              if (diff >= 3600000) {
                // if greater than hour
                return this.$moment.utc(diff).format(this.$t.get("common.h-h-mm-m-ss-s"))
              }
              if (diff <= 0) return 0
              return this.$moment.utc(diff).format(this.$t.get("common.mm-m-ss-s"))
            },
            diffAsMoment: (first_date, second_date) => {
              first_date = this.$moment(first_date)
              second_date = this.$moment(second_date)
              return this.$moment(first_date.diff(second_date))
            },
            durationAsMoment: (current_date, from_date) => {
              return this.$momentUtils.diffAsMoment(current_date, from_date)
            },
            momentToTz: (date) => {
              return this.$moment(date).tz(this.$moment.tz.guess())
            },
            isToday: (date) => {
              return this.$moment(date).isSame(this.$moment().clone(), "d")
            },
            isThisWeek: (date) => {
              // OLD version of below return this.$moment(date).isSame(this.$moment().clone(), 'week');
              var monday = this.$moment().add(7 - this.$moment().isoWeekday() - 6, "days")
              var sunday = this.$moment().add(7 - this.$moment().isoWeekday(), "days")
              return this.$moment(date).isBetween(monday, sunday, "days", "[]")
            },
            isNextWeek: (date) => {
              var monday = this.$moment().add(7 - this.$moment().isoWeekday() + 1, "days")
              var sunday = this.$moment().add(7 - this.$moment().isoWeekday() + 7, "days")
              return this.$moment(date).isBetween(monday, sunday, "days", "[]")
            },
          }
        },
      },
    })
  },
}
