//
//
//
//
//

import Scrollbar from "../../ui/scrollbar"

export default {
  data() {
    return {
      scrollbar: null,
    }
  },
  mounted() {
    this.scrollbar = new Scrollbar(this.$el)

    this.$emit("scroll", this.$el.scrollTop)

    this.$el.addEventListener("scroll", (e) => {
      this.$emit("scroll", this.$el.scrollTop)
    })
  },
}
