import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import { cornerstone, cornerstoneTools } from "../../cornerstone"
import PencilTool from "../cornerstone/PencilTool"
import Translate from "../../../../../helpers/translate"

export default class Pencil {
  get name() {
    return Translate.get("viewer.pencil")
  }

  get icon() {
    return "ict-pencil"
  }

  get type() {
    return ToolType.SWITCH
  }

  get mobile() {
    return false
  }

  constructor() {}

  bind(viewerInstance, views) {
    views.forEach((view) => {
      if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
        const dataView = view.$refs.dataView
        const cornerstoneElement = dataView.$refs.view
        cornerstoneTools.addToolForElement(cornerstoneElement, PencilTool)
        cornerstoneTools.setToolPassive("Pencil", { mouseButtonMask: 1 })
      }
    })
  }

  activate(views) {
    cornerstoneTools.setToolActive("Pencil", { mouseButtonMask: 1 })
  }

  deactivate(views) {
    cornerstoneTools.setToolPassive("Pencil", { mouseButtonMask: 1 })
  }

  // setDrawColor(id) {
  //   cornerstoneTools.brush.changeDrawColor(id)
  // }

  // _bindColors() {
  //   this.colorMapId = "TrustedoctorBrushColorMap"

  //   this.colormap = cornerstone.colors.getColormap(this.colorMapId)

  //   this.colormap.setNumberOfColors(4)
  //   this.colormap.setColor(0, [255, 255, 255, 0])
  //   this.colormap.setColor(1, [0, 204, 177, 200])
  //   this.colormap.setColor(2, [255, 255, 255, 200])
  //   this.colormap.setColor(3, [18, 66, 117, 200])

  //   this.configuration = cornerstoneTools.brush.getConfiguration()
  //   this.configuration.colormapId = this.colorMapId
  //   cornerstoneTools.brush.setConfiguration(this.configuration)
  // }

  // get settings() {
  //   return {
  //     template: `<div class="brush-settings">
  //       <div class="colors">
  //         <span v-for="color in colors()" v-bind:style="{ background: color.hash }" v-on:click="changeColor(color.id)"></span>
  //       </div>
  //     </div>`,
  //     methods: {
  //       changeColor(id) {
  //         this.tool.setDrawColor(id)
  //       },
  //       colors() {
  //         const numberOfColors = this.tool.colormap.getNumberOfColors()
  //         let colors = Array.apply(null, Array(numberOfColors)).map((item, index) => {
  //           let color = this.tool.colormap.getColor(index)
  //           return { id: index, hash: "rgb(" + color[0] + "," + color[1] + "," + color[2] + ")" }
  //         })

  //         colors.splice(0, 1)

  //         return colors
  //       }
  //     }
  //   }
  // }
}
