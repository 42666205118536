var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stepper-horizontal" }, [
    _c("div", { staticClass: "steps" }, [
      _c(
        "ul",
        _vm._l(_vm.steps, function (step, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "step",
              class: {
                done: _vm.currentStepIndex > index,
                current: _vm.currentStepIndex == index,
              },
            },
            [
              _vm.currentStepIndex == index
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:border-color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "border-color",
                        },
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "color",
                        },
                      ],
                      staticClass: "number current",
                    },
                    [_vm._v("\n          " + _vm._s(index + 1) + "\n        ")]
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "styled",
                          rawName: "v-styled:color",
                          value: _vm.palette.colors.primary.main,
                          expression: "palette.colors.primary.main",
                          arg: "color",
                        },
                      ],
                      staticClass: "number",
                    },
                    [_vm._v("\n          " + _vm._s(index + 1) + "\n        ")]
                  ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(step.title))]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "step-content" }, [_vm._t(_vm.currentSlotKey)], 2),
    _vm._v(" "),
    _c("div", { staticClass: "buttons" }, [
      _c("div", { staticClass: "flex collapse-sides" }, [
        _c(
          "div",
          { staticClass: "w-full lg:w-6/12 columns" },
          [
            _vm.currentSlot.data.attrs.skippable
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.secondary.main,
                        expression: "palette.colors.secondary.main",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button border-gray",
                    on: { click: _vm.nextStep },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t.get("common.skip")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("buttons-left"),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full lg:w-6/12 columns text-right" },
          [
            _vm._t("buttons-right"),
            _vm._v(" "),
            _vm.currentStepIndex < _vm.steps.length - 1
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button accent",
                    attrs: { disabled: _vm.currentSlot.data.attrs.disable },
                    on: { click: _vm.nextStep },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t.get("common.next-step")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentStepIndex == _vm.steps.length - 1
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button accent",
                    attrs: { disabled: _vm.currentSlot.data.attrs.disable },
                    on: { click: _vm.onFinish },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.finishButtonText) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.onCancel !== null
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.secondary.main,
                        expression: "palette.colors.secondary.main",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button",
                    attrs: { disabled: _vm.currentSlot.data.attrs.disable },
                    on: { click: _vm.onCancel },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t.get("common.cancel")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }