//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal.js"
import TakeTheCaseModal from "./TakeTheCaseModal.vue"
import { Permissions } from "../../models/Permissions"

export default {
  computed: {
    me() {
      return this.$store.getters["appointment/getMe"]()
    },
    canTakeTheCase() {
      return this.hasAppointmentPermission(Permissions.APPOINTMENT_TAKE_THE_CASE)
    },
    hasAppointmentPermission() {
      return this.$store.getters["appointment/hasAppointmentPermission"]
    },
    appointment() {
      return this.$store.state.appointment
    },
  },
  methods: {
    takeCase() {
      Modal.create(this)
        .title(this.$t.get("appointment.take-the-case"))
        .component(TakeTheCaseModal)
        .props({
          appointment_id: this.appointment.appointment_id,
        })
        .show()
    },
  },
}
