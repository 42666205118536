var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category list-resources recent" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "name" }, [
        _c("i", {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:color",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "color",
            },
          ],
          staticClass: "ict-clock",
        }),
        _c("span", [_vm._v(_vm._s(_vm.$t.get("profile.recent-activity")))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c("div", { staticClass: "scroll" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.section.section"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.group.group"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.name"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.created-at"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm._l(_vm.items, function (item) {
                return _c(
                  "div",
                  { key: item.resource_id, staticClass: "single" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.editResource(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "w-3/12 columns group" }, [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", { class: ["ict-" + _vm.icon(item.group)] }),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t.get(item.group))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-3/12 columns group" }, [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", {
                              class: [
                                "ict-" +
                                  _vm.icon("form.section." + item.resource),
                              ],
                            }),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t.get("form.section." + item.resource)
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-3/12 columns" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-3/12 columns" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(item.timestamps.created_at)
                                  .format("YYYY-MM-DD, HH:mm")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canUpdateResource
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "tooltip", rawName: "v-tooltip" },
                            ],
                            staticClass: "open",
                            attrs: { alt: _vm.$t.get("profile.edit-record") },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.editResource(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "ict-angle-right" })]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.items.length === 0 && _vm.processing === false
                ? _c("div", { staticClass: "single" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t.get("common.list-empty"))),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }