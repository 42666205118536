export default class DateTimeFormat {
  static get _settings() {
    const defaults = {
      date_format: "D MMM YYYY",
      time_format: "h:mm A",
    }

    const user = window.__USER || null
    return user && user.preferences ? user.preferences : defaults
  }

  static get time() {
    return this._settings.time_format
  }

  static get date() {
    return this._settings.date_format
  }

  static get datePicker() {
    let date = this._settings.date_format
    date = date.replace(/D/g, "d")
    date = date.replace(/YYYY/g, "yyyy")
    return date
  }

  static formatDate(date) {
    return moment(date).format(this.date)
  }

  static formatTime(date) {
    return moment(date).format(this.time)
  }
}

export const Vue = {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $dateTimeFormat: {
        get() {
          return DateTimeFormat
        },
      },
    })
  },
}
