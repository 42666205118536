var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-method", attrs: { id: "paypal" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadingPayPal,
              expression: "loadingPayPal",
            },
          ],
          staticClass: "paypal-cover",
        },
        [
          _c("div", { staticClass: "paypal-logo-white" }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t.get("appointment.dont-see-the-secure-pay-pal"))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons-list text-center" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color",
                    value: _vm.palette.colors.primary.alt,
                    expression: "palette.colors.primary.alt",
                    arg: "color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "background",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:border-color",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "border-color",
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:background.hover",
                    value: _vm.palette.colors.hover.alt,
                    expression: "palette.colors.hover.alt",
                    arg: "background",
                    modifiers: { hover: true },
                  },
                  {
                    name: "styled",
                    rawName: "v-styled:color.hover",
                    value: _vm.palette.colors.hover.main,
                    expression: "palette.colors.hover.main",
                    arg: "color",
                    modifiers: { hover: true },
                  },
                ],
                staticClass: "button accent",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.focusPayPalPopup()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("appointment.continue")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("form", [
        _c(
          "label",
          { staticClass: "text-center", attrs: { for: "expiration-date" } },
          [
            _vm._v(
              _vm._s(
                _vm.$t.get(
                  "appointment.the-payment-form-will-appear-in-a-separate-window"
                )
              )
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-list text-center" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "styled",
                rawName: "v-styled:color",
                value: _vm.palette.colors.secondary.main,
                expression: "palette.colors.secondary.main",
                arg: "color",
              },
              {
                name: "styled",
                rawName: "v-styled:background",
                value: _vm.palette.colors.primary.alt,
                expression: "palette.colors.primary.alt",
                arg: "background",
              },
              {
                name: "styled",
                rawName: "v-styled:border-color",
                value: _vm.palette.colors.primary.main,
                expression: "palette.colors.primary.main",
                arg: "border-color",
              },
              {
                name: "styled",
                rawName: "v-styled:background.hover",
                value: _vm.palette.colors.hover.main,
                expression: "palette.colors.hover.main",
                arg: "background",
                modifiers: { hover: true },
              },
              {
                name: "styled",
                rawName: "v-styled:color.hover",
                value: _vm.palette.colors.hover.alt,
                expression: "palette.colors.hover.alt",
                arg: "color",
                modifiers: { hover: true },
              },
            ],
            staticClass: "button border",
            attrs: { disabled: _vm.loading },
            on: { click: _vm.cancelPay },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t.get("common.cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "styled",
                rawName: "v-styled:color",
                value: _vm.palette.colors.primary.alt,
                expression: "palette.colors.primary.alt",
                arg: "color",
              },
              {
                name: "styled",
                rawName: "v-styled:background",
                value: _vm.palette.colors.primary.main,
                expression: "palette.colors.primary.main",
                arg: "background",
              },
              {
                name: "styled",
                rawName: "v-styled:border-color",
                value: _vm.palette.colors.primary.main,
                expression: "palette.colors.primary.main",
                arg: "border-color",
              },
              {
                name: "styled",
                rawName: "v-styled:background.hover",
                value: _vm.palette.colors.hover.alt,
                expression: "palette.colors.hover.alt",
                arg: "background",
                modifiers: { hover: true },
              },
              {
                name: "styled",
                rawName: "v-styled:color.hover",
                value: _vm.palette.colors.hover.main,
                expression: "palette.colors.hover.main",
                arg: "color",
                modifiers: { hover: true },
              },
            ],
            staticClass: "button accent",
            attrs: {
              type: "submit",
              id: "paypal-button",
              disabled: _vm.loading || _vm.paypalClient === null,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.payPayPal()
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t.get("appointment.pay-amount-to-pay", {
                    amount: _vm.amountToPay,
                  })
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.$t.get("appointment.click-on-the-button-above-to-continue")
          )
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }