import Vue from "vue"

export default class AbstractTool {
  constructor(toolsInstance) {
    this.toolsInstance = toolsInstance
  }

  get actionsComponent() {
    const toolInstance = this
    if (this.actions !== void 0) {
      const actions = this.actions
      const { render, staticRenderFns } = Vue.compile(actions.template)

      return Vue.extend({
        inject: {
          views: {
            from: "viewsInstances",
          },
        },
        render,
        staticRenderFns,
        methods: actions.methods || {},
        data() {
          return { tool: toolInstance }
        },
      })
    } else {
      return null
    }
  }
}
