var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-participants" }, [
    _c("p", [_vm._v("Add new participants by email")]),
    _vm._v(" "),
    _c("div", { staticClass: "input email" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email",
          },
        ],
        attrs: { type: "email", placeholder: "Address e-mail" },
        domProps: { value: _vm.email },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.addParticipantByEmail.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button border-accent-black",
          attrs: { disabled: _vm.$v.email.$invalid },
          on: { click: _vm.addParticipantByEmail },
        },
        [_vm._v("\n      Add\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("Added Members* (" + _vm._s(_vm.participants.length) + ")"),
        ]),
        _vm._v(" "),
        _c(
          "v-scrollbar",
          { staticClass: "items" },
          _vm._l(_vm.participants, function (participant, key) {
            return _c("div", { key: key, staticClass: "item" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-full lg:w-6/12 columns user" }, [
                  _c("div", {
                    staticClass: "avatar",
                    style: {
                      "background-image": "url(" + participant.avatar_url + ")",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(participant.email)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full lg:w-6/12 columns text-right" },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.roles,
                        "return-option": true,
                        "label-key": "name",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function (props) {
                              return _c("div", {}, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.normalizeRole(props.item.name)) +
                                    "\n              "
                                ),
                              ])
                            },
                          },
                          {
                            key: "placeholder",
                            fn: function (props) {
                              return _c("div", {}, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.normalizeRole(props.selected.name)
                                    ) +
                                    "\n              "
                                ),
                              ])
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: participant.role,
                        callback: function ($$v) {
                          _vm.$set(participant, "role", $$v)
                        },
                        expression: "participant.role",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "line" }),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "delete",
                      on: {
                        click: function ($event) {
                          return _vm.removeParticipant(key)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex requester" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: participant.role.id == _vm.requesterRole.id,
                        expression: "participant.role.id == requesterRole.id",
                      },
                    ],
                    staticClass: "w-8/12 columns text-right",
                  },
                  [
                    _c("p", [_vm._v("Local Center ID")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("To identify above requester")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: participant.role.id == _vm.requesterRole.id,
                        expression: "participant.role.id == requesterRole.id",
                      },
                    ],
                    staticClass: "w-4/12 columns",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: participant.request_prefix,
                          expression: "participant.request_prefix",
                        },
                      ],
                      attrs: {
                        required: "",
                        type: "text",
                        placeholder: "e.g. 001",
                      },
                      domProps: { value: participant.request_prefix },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            participant,
                            "request_prefix",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w-8/12 columns text-right" }, [
                  _c("p", [_vm._v("Notes")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Additional information")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-4/12 columns" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: participant.notes,
                        expression: "participant.notes",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "e.g. country, name etc.",
                    },
                    domProps: { value: participant.notes },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(participant, "notes", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info" }, [
          _vm._v("* Trial members will be invited after submit."),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }