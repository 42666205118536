import Vue from "vue"
import VueRouter from "vue-router"
import ListResource from "./ListResource.vue"
import ListRecentResource from "./ListRecentResource.vue"
import AddResource from "./AddResource.vue"

const actions = {
  LIST: "listResource",
  RECENT: "listRecentResource",
  GET: "getResource",
  ADD: "addResource",
  UPDATE: "updateResource",
  DELETE: "deleteResource",
}

function componentResolver(declaredRoute, { components = {} }) {
  if (
    components.hasOwnProperty(declaredRoute.name) &&
    typeof components[declaredRoute.name] === "function"
  ) {
    return components[declaredRoute.name]
  }
  return declaredRoute.component
}

function propsResolver(declaredRoute, { sections, globalProps = {} }) {
  // get route specific props, merge it with global props and pass to resolver
  const routeSpecificProps = Object.assign(declaredRoute.props || {}, globalProps)
  return function resolvePropsFn(route) {
    const crudURL = { crudURL: sections.find((section) => section.crudURL).crudURL }
    const settings = sections.find((section) => section.resource === route.params.resource)
    return { ...crudURL, ...route.params, ...routeSpecificProps, ...settings }
  }
}

const defaultRoutes = [
  {
    path: "/recent",
    name: actions.RECENT,
    component: ListRecentResource,
  },
  {
    path: "/:resource",
    name: actions.LIST,
    component: ListResource,
  },
  {
    path: "/:resource/add",
    name: actions.ADD,
    component: AddResource,
  },
  {
    path: "/:resource/:resourceId",
    name: actions.GET,
    component: AddResource,
  },
]

function FormBuilderRouter(config) {
  let { base, sections, additionalRoutes = [], mode = "history" } = config

  // merge basic crud routes with additional routes if provided
  let routing = additionalRoutes
    .map((route) => {
      route.additional = true
      return route
    })
    .concat(defaultRoutes)
    .map((route) => {
      // dynamically load components if specified
      route.component = componentResolver(route, config)
      // bind properties from route declarations
      route.props = propsResolver(route, config)
      return route
    })
  // add section information to ListResource component
  routing.find((route) => route.name === actions.LIST)["sections"] = sections
  return new VueRouter({
    mode: mode,
    base: base,
    routes: routing,
  })
}

export { actions, defaultRoutes, FormBuilderRouter }
