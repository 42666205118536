//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"
import { Constants } from "../../../../models/Constants"

export default {
  name: "status",
  mixins: [ColumnMixin],
  props: {
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-3/12 columns",
          row: "w-3/12 columns",
        }
      },
    },
    defaultTranslation: {
      type: String,
      default: "common.status",
    },
  },
  data() {
    return {
      statuses: Constants.GroupCustomer,
    }
  },
}
