//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    download() {
      this.processing = true
      this.$http
        .get("/account/settings/data-portability/intercom", { responseType: "arraybuffer" })
        .then(
          (response) => {
            const blob = new Blob([response.data], { type: "application/json" })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(blob)
            link.download = response.headers["filename"]
            link.click()
            URL.revokeObjectURL(link.href)
            this.processing = false
          },
          (error) => {
            this.processing = false
            if (error.response.status === 404) {
              this.$bridge.emit("addToast", this.$t.get("gdpr.user-not-found"), "error")
              return
            }

            this.$bridge.emit(
              "addToast",
              this.$t.get("common.something-went-wrong-please-try-again"),
              "error"
            )
          }
        )
    },
  },
}
