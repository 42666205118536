//
//
//
//
//
//

import ActionMixin from "./ActionMixin"
import { Constants } from "../../../../../models/Constants"
import Modal from "../../../../../plugins/modal/modal"

export default {
  mixins: [ActionMixin],
  props: {
    translationOverride: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      statuses: Constants.GroupCustomer,
    }
  },
  computed: {
    translation() {
      if (this.translationOverride !== null) {
        return this.$t.get(this.translationOverride)
      }
      return this.$t.get("product.customer-status-toggle-to", {
        target: this.getStatus(this.customer, true),
      })
    },
  },
  methods: {
    getStatus(customer, opposite = false, translationPrefix = "product.customer-") {
      if (customer === null || customer.hasOwnProperty("status") === false) {
        return null
      }
      let status = parseInt(customer.status)
      if (opposite === true) {
        if (status > 0) {
          status = -Math.abs(status)
        } else {
          status = Math.abs(status)
        }
      }
      const currentStatus = Object.keys(this.statuses).find((key) => this.statuses[key] === status)
      if (currentStatus === undefined) {
        return null
      }
      const str = `${translationPrefix}${currentStatus.split("_")[1]}`.toLowerCase()
      return this.$t.get(str).toLowerCase()
    },
    toggleCustomerStatus() {
      Modal.confirm(this)
        .title(this.$t.get("product.customer-toggle-status"))
        .content(
          this.$t.get("product.customer-toggle-status-prompt", {
            status: this.getStatus(this.customer),
          })
        )
        .positive(this.$t.get("common.yes"), function () {
          let params = {
            action: "toggleCustomerStatus",
            componentId: this.componentId,
            customerId: this.customer.group_customer_id || this.customer.tdCustomerId,
          }
          this.processing = true
          return this.$http
            .post(this.componentUrl, params)
            .then((response) => {
              this.processing = false
              this.$emit("reload-list")
              this.$bridge.emit("addToast", response.data.message, "success")
            })
            .catch((response) => {
              this.processing = false
              this.$bridge.emit("addToast", response.response.data.message, "error")
            })
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
  },
}
