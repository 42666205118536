//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"
import * as datePickerLanguages from "vuejs-datepicker/dist/locale"

export default {
  name: "birthday",
  mixins: [ColumnMixin],
  props: {
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-2/12 columns",
          input: "w-2/12 columns",
          row: "w-2/12 columns",
        }
      },
    },
    format: {
      default: "yyyy-MM-dd",
    },
    defaultTranslation: {
      type: String,
      default: "common.birthday",
    },
  },
  computed: {
    datepickerLanguage() {
      const languageAdaptable = {
        af: "af",
        ar: "ar",
        bg: "bg",
        bs: "bs",
        ca: "ca",
        cs: "cs",
        da: "da",
        de: "de",
        ee: "ee",
        el: "el",
        en: "en",
        es: "es",
        fa: "fa",
        fi: "fi",
        fo: "fo",
        fr: "fr",
        ge: "ge",
        gl: "gl",
        he: "he",
        hu: "hu",
        hr: "hr",
        id: "id",
        is: "is",
        it: "it",
        ja: "ja",
        kk: "kk",
        ko: "ko",
        lb: "lb",
        lt: "lt",
        lv: "lv",
        mk: "mk",
        mn: "mn",
        "nb-No": "nbNO",
        nl: "nl",
        pl: "pl",
        "pt-br": "ptBR",
        ro: "ro",
        ru: "ru",
        sk: "sk",
        "sl-si": "slSI",
        sv: "sv",
        sr: "sr",
        "sr-cyrl": "srCyrl",
        th: "th",
        tr: "tr",
        uk: "uk",
        ur: "ur",
        vi: "vi",
        zh: "zh",
        "zh-hk": "zhHK",
      }
      return datePickerLanguages[languageAdaptable[this.$locale]]
    },
  },
  methods: {
    emitDate(event) {
      if (event === null) {
        this.$emit("input", null)
        return
      }
      const date = event.toISOString().slice(0, 10)
      this.$emit("input", date)
    },
  },
}
