var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "term-component" }, [
    _vm.userId === null && _vm.canManageGroupTimeSlots
      ? _c(
          "div",
          {
            staticClass: "filters filters-box",
            class: { "preloader preloader-cover": _vm.processingFilters },
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-wrap flex-spacing material-form" },
              [
                _c("div", { staticClass: "w-4/12 columns" }, [
                  _c("span", { staticClass: "filter-title" }, [
                    _vm._v(_vm._s(_vm.$t.get("common.filters")) + ":"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-8/12 text-right columns" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button text gray",
                      attrs: { disabled: _vm.termsFilterMember === null },
                      on: {
                        click: function ($event) {
                          _vm.termsFilterMember = null
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t.get("common.clear-filters")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full columns" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-4/12 end columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("v-select", {
                        staticClass: "user-select",
                        attrs: {
                          options: _vm.groupMembersFilter,
                          "return-option": true,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (props) {
                                return _c("div", {}, [
                                  _c("div", {
                                    staticClass: "avatar",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        props.item.user.avatar_url +
                                        ")",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      _vm._s(props.item.user.name) +
                                        " " +
                                        _vm._s(props.item.user.surname)
                                    ),
                                  ]),
                                ])
                              },
                            },
                            {
                              key: "placeholder",
                              fn: function (props) {
                                return _c("div", {}, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(props.selected.user.name) +
                                        " " +
                                        _vm._s(props.selected.user.surname) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              },
                            },
                          ],
                          null,
                          false,
                          4128719928
                        ),
                        model: {
                          value: _vm.termsFilterMember,
                          callback: function ($$v) {
                            _vm.termsFilterMember = $$v
                          },
                          expression: "termsFilterMember",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t.get("common.select-doctor"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "controls" }, [
      _c("div", { staticClass: "control-prev" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.prevWeek.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "ict-angle-left" })]
        ),
      ]),
      _vm._v(" "),
      _vm.$moment(_vm.startDate).get("month") ===
      _vm.$moment(_vm.endDate).get("month")
        ? _c("div", { staticClass: "date" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$momentUtils.format(_vm.startDate, "DD")) +
                " - " +
                _vm._s(_vm.$momentUtils.format(_vm.endDate, "DD")) +
                "\n      " +
                _vm._s(_vm.$momentUtils.format(_vm.startDate, "MMMM YYYY")) +
                "\n      "
            ),
            _c("div", { staticClass: "timezone-change" }, [
              _vm._v(
                "\n        (" + _vm._s(_vm.timeZoneLabel) + " -\n        "
              ),
              _c("button", { on: { click: _vm.changeTimezone } }, [
                _vm._v(_vm._s(_vm.$t.get("common.change"))),
              ]),
              _vm._v(")\n      "),
            ]),
          ])
        : _c("div", { staticClass: "date" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$momentUtils.format(_vm.startDate, "DD")) +
                " " +
                _vm._s(_vm.$momentUtils.format(_vm.startDate, "MMMM")) +
                " -\n      " +
                _vm._s(_vm.$momentUtils.format(_vm.endDate, "DD")) +
                " " +
                _vm._s(_vm.$momentUtils.format(_vm.endDate, "MMMM YYYY")) +
                "\n      "
            ),
            _c("div", { staticClass: "timezone-change" }, [
              _vm._v(
                "\n        (" + _vm._s(_vm.timeZoneLabel) + " -\n        "
              ),
              _c("button", { on: { click: _vm.changeTimezone } }, [
                _vm._v(_vm._s(_vm.$t.get("common.change"))),
              ]),
              _vm._v(")\n      "),
            ]),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "control-next" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.nextWeek.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "ict-angle-right" })]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "days-scroll",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c(
          "div",
          { staticClass: "days" },
          [
            _vm._l(_vm.terms, function (day, dayString) {
              return _c("div", { key: dayString, staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass: "day",
                    class: {
                      today: _vm
                        .$moment(dayString, "YYYY-MM-DD")
                        .isSame(_vm.now, "day"),
                    },
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$momentUtils.format(dayString, "D"))),
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$momentUtils.format(dayString, "ddd")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hours" },
                  [
                    _vm.canManageTimeSlots
                      ? _c(
                          "button",
                          {
                            staticClass: "hour new-term",
                            attrs: {
                              disabled:
                                _vm
                                  .$moment(dayString, "YYYY-MM-DD")
                                  .isBefore(_vm.now) &&
                                _vm
                                  .$moment(dayString, "YYYY-MM-DD")
                                  .isSame(_vm.now, "day") === false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.createTerm(dayString)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ict-plus-round" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t.get("common.add-a-time-slot"))
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(day, function (term) {
                      return _c(
                        "div",
                        {
                          key: term.id,
                          ref: "slot-" + term.id,
                          refInFor: true,
                        },
                        [
                          _c("term-tooltip", {
                            attrs: {
                              term: term,
                              "attached-to": _vm.$refs["slot-" + term.id],
                            },
                          }),
                          _vm._v(" "),
                          term.status === 0
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "hour free",
                                    class: {
                                      "assigned-to-appointment":
                                        term.appointment_id !== null,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.infoTerm(term)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$dateTimeFormat.formatTime(
                                              term.start
                                            )
                                          ) +
                                          " -\n                  " +
                                          _vm._s(
                                            _vm.$dateTimeFormat.formatTime(
                                              term.end
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                      _vm.canManageTimeSlots
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "remove",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.removeSlot(term)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "ict-trash",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _vm.canManageTimeSlots &&
                                    _vm.$moment(term.start).diff(_vm.now) >=
                                      -900000
                                      ? [
                                          term.appointment_id !== null
                                            ? _c(
                                                "span",
                                                { staticClass: "meta" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "common.available-to-book-by"
                                                      ) +
                                                        " " +
                                                        term.patient_name
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "meta" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "common.available-to-book"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            : term.status === 1
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "hour busy",
                                    attrs: {
                                      href:
                                        "/appointment/" + term.appointment_id,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$momentUtils.format(
                                              term.start,
                                              "HH:mm"
                                            )
                                          ) +
                                          " -\n                  " +
                                          _vm._s(
                                            _vm.$momentUtils.format(
                                              term.end,
                                              "HH:mm"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "meta" }, [
                                      _vm._v(_vm._s(term.patient_name)),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                ),
              ])
            }),
            _vm._v(" "),
            _vm.processing === false &&
            Object.values(_vm.terms).every(function (item) {
              return item.length === 0
            })
              ? _c("div", { staticClass: "no-terms" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("common.your-calendar-is-empty")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _vm.inModal
      ? _c("div", { staticClass: "buttons-list" }, [
          _c(
            "button",
            {
              staticClass: "button primary",
              on: {
                click: function ($event) {
                  return _vm.$emit("success")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t.get("common.done")) + "\n    ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }