import ColumnName from "./columns/ColumnName.vue"
import ColumnPlan from "./columns/ColumnPlan.vue"
import ColumnCreatedAt from "./columns/ColumnCreatedAt.vue"
import ColumnStatus from "./columns/ColumnStatus.vue"
import ColumnBirthday from "./columns/ColumnBirthday.vue"
import ColumnIdentifier from "./columns/ColumnIdentifier.vue"
import ColumnPolicyNumber from "./columns/ColumnPolicyNumber.vue"
import ColumnActions from "./columns/ColumnActions.vue"
import Vue from "vue"
import ColumnFullname from "./columns/ColumnFullname.vue"
import ColumnSurname from "./columns/ColumnSurname.vue"
import { set } from "lodash"
import ColumnEmail from "./columns/ColumnEmail.vue"
import ColumnOnboardedAt from "./columns/ColumnOnboardedAt.vue"
import ColumnGender from "./columns/ColumnGender.vue"
import ColumnPhone from "./columns/ColumnPhone.vue"
import ColumnPolicyKey from "./columns/ColumnPolicyKey.vue"
import ColumnOnboardSentAt from "./columns/ColumnOnboardSentAt.vue"

export const componentEventBus = new Vue()

export default {
  props: {
    organizationId: { type: String, required: true },
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    searchColumns: {
      type: Object,
      default: function () {
        return {}
      },
    },
    visibleColumns: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    preparedSearchComponents() {
      return this.restructureProps(this.searchColumns)
    },
    preparedComponents() {
      return this.restructureProps(this.visibleColumns)
    },
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    restructureProps(columns) {
      let result = []
      Object.keys(columns).forEach((type) => {
        result.push({
          type: type,
          props: this.getColumnPseudoProps(columns[type]),
        })
      })
      return result
    },
    getColumnComponent(type) {
      switch (type) {
        case "name":
          return ColumnName
        case "surname":
          return ColumnSurname
        case "identifier":
          return ColumnIdentifier
        case "birthday":
          return ColumnBirthday
        case "policy.number":
          return ColumnPolicyNumber
        case "policy.previous_insured_id":
        case "policy.client_name":
        case "policy.product_commencement_date":
        case "policy.product_paid_to_date":
        case "policy.product_expiry_date":
        case "policy.product_auto_renewal":
        case "policy.underlying_policy":
        case "policy.policy_status":
        case "policy.policy_commencement_date":
        case "policy.policy_expiry_date":
        case "policy.policy_cancellation_date":
        case "policy.policy_latest_renewal_date":
        case "policy.policy_new_renewal":
        case "policy.country":
        case "policy.insured_address_postcode":
        case "policy.mult_id_change_flag":
        case "policy.insured_address_line_1":
        case "policy.insured_address_line_2":
        case "policy.insured_address_line_3":
        case "policy.insured_address_line_4":
        case "policy.upload_id":
        case "policy.policy_authorisation_date":
          return ColumnPolicyKey
        case "gender":
          return ColumnGender
        case "tdOnboardedAt":
          return ColumnOnboardedAt
        case "tdOnboardSentAt":
          return ColumnOnboardSentAt
        case "email":
          return ColumnEmail
        case "phone":
          return ColumnPhone
        case "fullname":
          return ColumnFullname
        case "plan":
          return ColumnPlan
        case "created_at":
          return ColumnCreatedAt
        case "status":
          return ColumnStatus
        case "actions":
          return ColumnActions
      }
    },
    getColumnPseudoProps(type) {
      if (type === "" || type === null || type === undefined) {
        return {}
      }
      type = type.replaceAll("..", " ")

      let result = {}
      const pairs = type.split("&")
      for (const pair of pairs) {
        const [path, value] = pair.split("=")
        set(result, path.trim(), value.trim())
      }

      return result
    },
    emitProcessing(value) {
      this.processing = value
      this.$emit("processing", value)
    },
  },
}
