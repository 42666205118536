var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.apiKey !== null && _vm.apiKey !== undefined
    ? _c(
        "button",
        {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:color",
              value: _vm.palette.colors.primary.alt,
              expression: "palette.colors.primary.alt",
              arg: "color",
            },
            {
              name: "styled",
              rawName: "v-styled:background",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "background",
            },
            {
              name: "styled",
              rawName: "v-styled:border-color",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-color",
            },
            {
              name: "styled",
              rawName: "v-styled:background.hover",
              value: _vm.palette.colors.hover.alt,
              expression: "palette.colors.hover.alt",
              arg: "background",
              modifiers: { hover: true },
            },
            {
              name: "styled",
              rawName: "v-styled:color.hover",
              value: _vm.palette.colors.hover.main,
              expression: "palette.colors.hover.main",
              arg: "color",
              modifiers: { hover: true },
            },
          ],
          class: _vm.classes,
          on: { click: _vm.startImport },
        },
        [
          _vm._v(
            "\n  " +
              _vm._s(_vm.$t.get("cloud-manager.import-from-dropbox")) +
              "\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }