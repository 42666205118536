import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import { cornerstoneTools } from "../../cornerstone"
import Translate from "../../../../../helpers/translate"
import { RectangleRoiTool } from "cornerstone-tools"

export default class RectangleROI {
  get name() {
    return Translate.get("viewer.rectangle-roi")
  }

  get icon() {
    return "ict-square"
  }

  get type() {
    return ToolType.SWITCH
  }

  get mobile() {
    return false
  }

  bind(viewerInstance, views) {
    views.forEach((view) => {
      if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
        const dataView = view.$refs.dataView
        const cornerstoneElement = dataView.$refs.view
        cornerstoneTools.addToolForElement(cornerstoneElement, RectangleRoiTool)
        cornerstoneTools.setToolPassive("RectangleRoi", { mouseButtonMask: 1 })
      }
    })
  }

  activate(views) {
    cornerstoneTools.setToolActive("RectangleRoi", { mouseButtonMask: 1 })
  }

  deactivate(views) {
    cornerstoneTools.setToolPassive("RectangleRoi", { mouseButtonMask: 1 })
  }
}
