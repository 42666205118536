import axios from "axios"
import Quill from "quill"
import DOMPurify from "dompurify"

const Clipboard = Quill.import("modules/clipboard")
const Delta = Quill.import("delta")

import { forEach } from "lodash"

class CloudClipboard extends Clipboard {
  onPaste(event) {
    let imageUpload = false

    if (this.quill.options.objectId !== void 0 && this.quill.options.objectId !== null) {
      const regexImage = /<img.*src="([^\"]+)".*>/g
      const htmlImages = regexImage.exec(event.clipboardData.getData("text/html"))

      if (htmlImages !== null && htmlImages.length > 0) {
        const src = htmlImages[1]
        if (src.startsWith("data:image/")) {
          imageUpload = true
          event.preventDefault()
          this.quill.options.container.classList.add("preloader", "preloader-overflow")
          const image = this.dataURItoBlob(src)

          this.upload(image).then(
            (cloud) => {
              this.quill.options.container.classList.remove("preloader", "preloader-overflow")
              this.quill.insertEmbed(
                this.quill.getSelection(true).index,
                "cloud-image",
                cloud,
                "cloud"
              )
            },
            (error) => {
              this.quill.options.container.classList.remove("preloader", "preloader-overflow")
              console.log("error", error)
            }
          )
        }
      }
    }

    if (imageUpload === false) {
      event.preventDefault()

      const range = this.quill.getSelection()

      const text = event.clipboardData.getData("text/plain")
      const html = event.clipboardData.getData("text/html")

      let delta = new Delta().retain(range.index).delete(range.length)

      let content = text
      if (html) {
        content = DOMPurify.sanitize(html, {
          ALLOWED_TAGS: ["b", "q", "img", "strong", "i", "u", "br", "p", "ul", "ol", "li"],
          ALLOWED_ATTR: ["src"],
        })
        delta = delta.concat(this.convert(content))
      } else {
        delta = delta.insert(content)
      }

      this.quill.updateContents(delta, Quill.sources.USER)
    }
  }

  upload(blob) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          window.__CLOUD_URL + "/objects/" + this.quill.options.objectId + "/storage",
          { content_type: "image/jpeg", size: blob.size + "" },
          {
            withCredentials: true,
            headers: { Authorization: "Bearer " + window.USER_JWT },
          }
        )
        .then((response) => {
          let { link, item_id, aws_params } = response.data
          let fd = new FormData()
          let xhr = new XMLHttpRequest()

          forEach(aws_params, function (value, key) {
            if (key !== "attributes") fd.append(key, value)
          })
          fd.append("file", blob, item_id + ".jpg")
          xhr.open(aws_params.attributes.method, aws_params.attributes.action, true)

          xhr.onerror = (error) => {
            reject(error)
          }
          xhr.onload = () => {
            resolve({
              link: link,
              cloudProxy: "/objects/" + this.quill.options.objectId + "/storage/" + item_id,
              id: item_id,
            })
          }
          xhr.send(fd)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(",")[1])

    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    let ab = new ArrayBuffer(byteString.length)
    let ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })
  }
}

export default CloudClipboard
