// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
div[data-v-05437d56] {
  font-size: 14px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/components/settings/ConsentModal.vue"],"names":[],"mappings":";AAaA;EACA,eAAA;EACA,iBAAA;AACA","sourcesContent":["<template>\n  <div v-html=\"content\" />\n</template>\n\n<script>\nexport default {\n  name: \"ConsentModal\",\n  props: {\n    content: { type: String, required: true },\n  },\n}\n</script>\n<style lang=\"postcss\" scoped>\ndiv {\n  font-size: 14px;\n  line-height: 20px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
