//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultSendMethod } from "../../stores/twoFactor"
export default {
  data() {
    return {
      inputs: {
        code: "",
      },
      hint: null,
      showMethods: false,
      lastUsedMethod: null,
    }
  },
  computed: {
    isProcessing() {
      return this.$store.getters["twoFactor/isProcessing"]
    },
    isGenerated() {
      return this.$store.state.twoFactor.generated
    },
    sendMethods() {
      return this.$store.state.twoFactor.sendMethods.filter((method) => method.available)
    },
  },
  watch: {
    sendMethods: {
      immediate: true,
      handler(methods) {
        if (methods.length === 1) {
          this.generateCode(defaultSendMethod)
        }
      },
    },
  },
  beforeDestroy() {
    this.$store.commit("twoFactor/clearState")
  },
  methods: {
    generateCode(method) {
      this.lastUsedMethod = method
      this.$store
        .dispatch("twoFactor/generateCode", { method_id: method.method_id })
        .then((response) => {
          this.hint = method.hint
            ? `${method.hint}. ${this.$t.get("auth.two-factor-code-will-be-valid", {
                minutes: response.data.data.expire / 60,
              })}`
            : null
          this.$bridge.emit("addToast", method.onSuccessLabel, "success")
        })
        .catch((response) => {
          this.$bridge.emit(
            "addToast",
            response.data?.message || this.$t.get("something-went-wrong-please-try-again"),
            "error"
          )
        })
    },
    inputCode() {
      this.$store
        .dispatch("twoFactor/inputCode", {
          twoFactorAuthCode: this.inputs.code,
          method_id: this.lastUsedMethod.method_id,
        })
        .catch((response) => {
          this.inputs.code = ""
          this.$bridge.emit(
            "addToast",
            response.data?.message || this.$t.get("something-went-wrong-please-try-again"),
            "error"
          )
        })
    },
    generateAgain() {
      this.generateCode(this.lastUsedMethod)
    },
  },
}
