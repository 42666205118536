var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.paginationInit.last_page > 1,
          expression: "paginationInit.last_page > 1",
        },
      ],
      staticClass: "pagination text-center",
    },
    [
      _c(
        "li",
        {
          class: { disabled: _vm.paginationInit.current_page === 1 },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.setPage(_vm.paginationInit.current_page - 1)
            },
          },
        },
        [_c("i", { staticClass: "ict-angle-left" })]
      ),
      _vm._v(" "),
      _vm._l(_vm.limited, function (page) {
        return _c(
          "li",
          { class: { active: page == _vm.paginationInit.current_page } },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.setPage(page)
                  },
                },
              },
              [_vm._v(_vm._s(page))]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "li",
        {
          class: {
            disabled:
              _vm.paginationInit.current_page >= _vm.paginationInit.last_page,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.setPage(_vm.paginationInit.current_page + 1)
            },
          },
        },
        [_c("i", { staticClass: "ict-angle-right" })]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }