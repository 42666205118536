//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../models/Constants"

import { orderBy, reject, find } from "lodash"
import Customize from "../../plugins/Customize"

export default {
  props: {
    isAvailable: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: String,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  mixins: [Customize()],
  data() {
    return {
      state: 0,
      states: {
        ERROR: -1,
        MOUNTED: 0,
        READY: 1,
      },
      message: "",
      messageCollection: [],
      sending: false,
    }
  },
  sockets: {
    "chat/messages": function (messages) {
      this.messageCollection = messages
    },
    "chat/message": function (message) {
      this.messageCollection.push(message)
    },
    disconnect() {
      this.state = this.states.ERROR
    },
    connect() {
      this.init()
    },
  },
  computed: {
    isChatOnline() {
      return this.state === this.states.READY && this.isAvailable
    },
    messages() {
      return orderBy(reject(this.messageCollection, { hidden: true }), ["timestamp"])
    },
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    isAvailable() {
      if (this.state !== this.states.READY) {
        this.init()
      }
    },
  },
  mounted() {
    this.$root.$on("add-system-chat-message", (name, body) => {
      const message = {
        author: "system",
        name: name,
        body: body,
        is_system: true,
        timestamp: this.now,
      }
      this.addChatMessage(message)
    })

    this.init()
  },
  methods: {
    init() {
      if (this.isAvailable && this.state !== this.states.READY) {
        this.joinRoom()
          .then(() => {
            this.state = this.states.READY
            this.chatScrollToTop()
          })
          .catch((error) => {
            this.state = this.states.ERROR
          })
      }
    },
    joinRoom() {
      return new Promise((resolve, reject) => {
        if (this.$socket.connected === true) {
          this.$socket.emit("chat/join", {
            roomId: this.roomId,
            endDate: this.$moment().add(1, "month").toISOString(),
          })
          resolve()
        } else {
          reject()
        }
      })
    },
    reset() {
      this.state = this.states.MOUNTED
      this.messageCollection = []
      this.init()
    },
    chatScrollToTop() {
      setTimeout(() => {
        if (this.$refs.chat !== void 0 && this.$refs.chat.$el !== void 0) {
          this.$refs.chat.$el.scrollTop =
            this.$refs.chat.$el.scrollHeight - this.$refs.chat.$el.offsetHeight
        }
      }, 300)
    },
    getParticipantName(userId) {
      return "Unknown"
    },
    bindHyperlinks(value) {
      return value.replace(
        /(http[s]?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" rel="noopener nofollow">$1</a>'
      )
    },
    addChatMessage(message) {
      this.messageCollection.push(message)
      this.chatScrollToTop()
    },
    sendChatMessage() {
      const cleanedMessage = this.message.replace(/<[\s]?[^>]*>?/gm, "")

      if (cleanedMessage === "" || this.state !== this.states.READY || this.sending === true) {
        return
      }

      let messageObject = {
        body: cleanedMessage,
        author: this.user.user_id,
        name: "",
        isSystem: false,
        timestamp: new Date(),
      }

      this.sending = true
      this.$socket.emit("chat/send-message", {
        roomId: this.roomId,
        message: messageObject,
      })
      this.message = ""
      this.addChatMessage(messageObject)
      this.sending = false
    },
    throwRavenException(label, data) {
      try {
        throw new Error(label + JSON.stringify(data))
      } catch (e) {
        console.log(e)
        if (process.env.production) Sentry.captureException(e)
      }
    },
  },
}
