import moment from "moment-timezone"

import { required } from "vuelidate/lib/validators"
import { withParams } from "vuelidate"

const regex = (pattern) => (value) => value.match(new RegExp(pattern, "")) != null

const isTrue = (value) => value

const isDate = function (value) {
  let format = "YYYY-MM-DD"
  return moment(value, format, true).isValid()
}
const isPastDate = () =>
  withParams({ type: "isPastDate" }, (value) => {
    let format = "DD.MM.YYYY"
    return moment(value, format, true).add(1, "days").isBefore(moment())
  })

const isBeforeDate = (date, format) =>
  withParams({ type: "isBeforeDate", date, format }, (value, parentVm) => {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (date === null || date === undefined || date === "") {
      return true
    }
    if (parentVm[date] === null || parentVm[date] === undefined || parentVm[date] === "") {
      return true
    }

    if (format !== null && format !== undefined && format !== "") {
      return moment(value, format).isBefore(moment(parentVm[date], format))
    } else {
      return moment(value).isBefore(parentVm[date])
    }
  })

const isAfterDate = (date, format) =>
  withParams({ type: "isAfterDate", date, format }, (value, parentVm) => {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (date === null || date === undefined || date === "") {
      return true
    }
    if (parentVm[date] === null || parentVm[date] === undefined || parentVm[date] === "") {
      return true
    }

    if (format !== null && format !== undefined && format !== "") {
      return moment(value, format).isAfter(moment(parentVm[date], format))
    } else {
      return moment(value).isAfter(parentVm[date])
    }
  })

const isAfter = function (date, format) {
  return function (value, parentVm) {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (date === null || date === undefined || date === "") {
      return true
    }

    if (format !== null && format !== undefined && format !== "") {
      return moment(value, format).isAfter(moment(date, format))
    } else {
      return moment(value).isAfter(date)
    }
  }
}

const isInArray = function (array) {
  return function (value, parentVm) {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (array === null || array === undefined || array === "") {
      return true
    }
    if (parentVm[array] === null || parentVm[array] === undefined || parentVm[array] === "") {
      return true
    }

    return parentVm[array].indexOf(value) > -1
  }
}

const notInArray = function (array) {
  return function (value, parentVm) {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (array === null || array === undefined || array === "") {
      return true
    }
    if (parentVm[array] === null || parentVm[array] === undefined || parentVm[array] === "") {
      return true
    }

    return parentVm[array].indexOf(value) == -1
  }
}

const notInObjectsArray = function (array, key) {
  return function (value, parentVm) {
    if (value === null || value === undefined || value === "") {
      return true
    }
    if (array === null || array === undefined || array === "") {
      return true
    }
    if (parentVm[array] === null || parentVm[array] === undefined || parentVm[array] === "") {
      return true
    }

    return (
      parentVm[array]
        .map((o) => {
          return o[key]
        })
        .indexOf(value) == -1
    )
  }
}

const requiredIf = function (item, check) {
  return function (value, parentVm) {
    if (check === null || check === undefined || check === "") {
      return true
    }
    if (item === null || item === undefined || item === "") {
      return true
    }
    if (parentVm[item] === null || parentVm[item] === undefined || parentVm[item] === "") {
      return true
    }

    return parentVm[item] == check && required(value)
  }
}

export {
  regex,
  isTrue,
  isDate,
  isPastDate,
  isBeforeDate,
  isAfterDate,
  isAfter,
  isInArray,
  notInArray,
  requiredIf,
  notInObjectsArray,
}
