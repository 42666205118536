//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      required: true,
    },
    inputValue: {
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      required: true,
    },
    multiple: {
      default: true,
    },
  },
  computed: {
    type: {
      get() {
        return this.multiple ? "checkbox" : "radio"
      },
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
