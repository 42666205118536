// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
hr[data-v-5d849bc4] {
  margin: 43px -50px 58px;
  border-style: dashed;
  border-color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/plugins/form-decorators/FormSeparator.vue"],"names":[],"mappings":";AAIA;EACA,uBAAA;EACA,oBAAA;EACA,kBAAA;AACA","sourcesContent":["<template>\n  <hr />\n</template>\n<style lang=\"postcss\" scoped>\nhr {\n  margin: 43px -50px 58px;\n  border-style: dashed;\n  border-color: #ccc;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
