//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../../../models/Constants.js"
import ActionsMixin from "../columns/actions/ActionsMixin"

export default {
  mixins: [ActionsMixin],
  props: {
    componentId: { type: String, required: true },
    organizationId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    showContact: { type: Boolean, default: true },
    mailUrlScheme: { type: String, default: "mailto:" },
    phoneUrlScheme: { type: String, default: "tel:" },
    customer: { type: Object },
    providerName: { type: String },
    actions: {
      type: Array,
      default: function () {
        return []
      },
    },
    sfEnabled: { type: Boolean, default: false },
  },
  computed: {
    filteredActions() {
      // omit itself
      const actions = this.actions.filter((action) => action.component !== "details")
      return this.actionsForScope(actions, "details")
    },
    statusDesc() {
      if (this.customer === null || this.customer.hasOwnProperty("status") === false) {
        return null
      }
      if (this.customer.status === Constants.GroupCustomer.STATUS_ACTIVE.toString()) {
        return this.$t.get("profile.status-active")
      } else if (this.customer.status === Constants.GroupCustomer.STATUS_INACTIVE.toString()) {
        return this.$t.get("profile.status-inactive")
      } else {
        return null
      }
    },
  },
  methods: {
    changeRoute(name) {
      this.$router.push({ name: name, query: this.$route.query })
    },
    refreshPage() {
      window.location.reload()
    },
  },
}
