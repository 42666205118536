//
//
//
//

export default {
  name: "ConsentModal",
  props: {
    content: { type: String, required: true },
  },
}
