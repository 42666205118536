var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "preloader preloader-cover": _vm.enablePreloader && _vm.processing,
      },
    },
    [
      _vm.showHeader
        ? _c(
            "p",
            {
              staticClass: "description large section",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t.get("appointment.please-choose-a-consultation-type")
                  ) +
                  ":\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-group" },
        _vm._l(_vm.consultationTypes, function (type) {
          return _c(
            "div",
            {
              key: type.type,
              staticClass: "card clickable hoverable",
              class: {
                active:
                  _vm.markActiveType &&
                  _vm.appointment.consultation_type === type.type,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setType(type.type)
                },
              },
            },
            [
              _c("div", { staticClass: "indicator" }, [
                _c("div", { staticClass: "icon fa fa-check-circle" }),
                _vm._v(" "),
                _c("small", [
                  _vm._v(_vm._s(_vm.$t.get("appointment.current"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-description" }, [
                _c("div", { staticClass: "icon fa", class: type.icon }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-label" }, [
                _vm._v(_vm._s(_vm.$t.get(type.label))),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }