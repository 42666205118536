var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                ) +
                "\n  "
            ),
          ]
        : _vm.mode === "input"
        ? [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.value,
                    expression: "value",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t.get(_vm.placeholderTranslation),
                  "placeholder-sticky": "",
                },
                domProps: { value: _vm.value },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.value = $event.target.value
                    },
                    _vm.input,
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "first-name" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                  )
                ),
              ]),
            ]),
          ]
        : [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.customer.name || "-")),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }