var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.joinAppointment },
    },
    [
      _vm._v(
        "\n  " + _vm._s(_vm.buttonText || _vm.$t.get("appointment.join")) + "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }