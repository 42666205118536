var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input" },
    [
      _c("v-select", {
        class: { error: _vm.validation && _vm.validation.$error },
        attrs: {
          disabled: _vm.disabled,
          id: _vm.inputId,
          value: _vm.value,
          options: _vm.translatedOptions,
        },
        on: { change: _vm.input },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: _vm.inputId } }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }