export default {
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    prevStepButtonEnabled: { type: Boolean, default: true },
  },
  inject: ["currentStep", "totalSteps", "sending"],
  computed: {
    me() {
      return this.$store.state.user
    },
    isCurrentStep() {
      if (this.step === undefined) {
        return false
      }
      return this.currentStep.value === this.step
    },
    isDone() {
      if (this.step === undefined) {
        return false
      }
      return this.currentStep.value > this.step
    },
    isFirstStep() {
      if (this.step === undefined) {
        return false
      }
      return this.step === 1
    },
    isLastStep() {
      if (this.step === undefined) {
        return false
      }
      return this.step + 1 > this.totalSteps
    },
    hasPreviousStep() {
      if (this.isFirstStep === true) {
        return false
      }
      return this.prevStepButtonEnabled
    },
    isSending() {
      return this.sending.value
    },
  },
  methods: {
    prevStep() {
      this.$emit("prev-step")
    },
    nextStep(payload) {
      this.$emit("next-step", { componentId: this.componentId, payload: payload })
    },
    sendRequest(payload) {
      this.$emit("send-request", { componentId: this.componentId, payload: payload })
    },
  },
}
