var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "form position-relative",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c("div", { staticClass: "flex flex-wrap material-form" }, [
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email",
                  },
                ],
                class: { error: _vm.$v.form.email.$error },
                attrs: { type: "text", id: "share-email" },
                domProps: { value: _vm.form.email },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                    function ($event) {
                      return _vm.$v.form.email.$touch()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "share-email" } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t.get("share.email")) +
                    "\n            "
                ),
                _c("span", { staticClass: "text-alert" }, [_vm._v("*")]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.message,
                    expression: "form.message",
                  },
                ],
                class: { error: _vm.$v.form.message.$error },
                attrs: { type: "text", id: "guest-message" },
                domProps: { value: _vm.form.message },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "message", $event.target.value)
                    },
                    function ($event) {
                      return _vm.$v.form.message.$touch()
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "guest-message" } }, [
                _vm._v(_vm._s(_vm.$t.get("share.short-message"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("language-select", {
                  model: {
                    value: _vm.form.locale,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "locale", $$v)
                    },
                    expression: "form.locale",
                  },
                }),
                _vm._v(" "),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t.get("common.notification-language"))),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("div", { staticClass: "checkboxes" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.withTakeTheCase,
                    expression: "form.withTakeTheCase",
                  },
                ],
                staticClass: "inside",
                attrs: { type: "checkbox", id: "allowTakeTheCase" },
                domProps: {
                  checked: Array.isArray(_vm.form.withTakeTheCase)
                    ? _vm._i(_vm.form.withTakeTheCase, null) > -1
                    : _vm.form.withTakeTheCase,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.form.withTakeTheCase,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.form,
                            "withTakeTheCase",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.form,
                            "withTakeTheCase",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.form, "withTakeTheCase", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                attrs: { for: "allowTakeTheCase" },
                domProps: {
                  textContent: _vm._s(_vm.$t.get("share.can-take-the-case")),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full" }, [
            _c("p", {
              staticClass: "info",
              domProps: {
                textContent: _vm._s(
                  _vm.$t.get("share.share-permissions-information")
                ),
              },
            }),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-list margin" }, [
      _c(
        "button",
        {
          staticClass: "button accent",
          attrs: { disabled: _vm.$v.$invalid || _vm.processing },
          on: { click: _vm.sendInvitation },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("share.send")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button border",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.close },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("common.cancel")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }