import { Client, Message } from "@stomp/stompjs"

export default class Socket {
  constructor({ debug, location, vuex }) {
    this.debug = debug
    this.location = location
    this.vuex = vuex
    this.listeners = []
    this.broadcastListeners = []
    this.subscriptions = {}
    this.jwt = window.USER_JWT

    this.client = new Client({
      brokerURL: location,
      connectHeaders: {
        "X-Authorization": this.jwt,
      },
      debug: (str) => {
        if (this.debug) {
          console.log(str)
        }
      },
      splitLargeFrames: true,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    })

    this.client.onConnect = this.connected.bind(this)
    this.client.activate()
  }

  install(Vue) {
    const context = this

    const helper = {
      on: (topic, callback) => {
        context.listeners.push([topic, callback])
        if (context.client && context.client.connected) {
          context.bindListeners()
        }
      },
      off: (topic) => {
        context.subscriptions[topic].unsubscribe()
        delete context.subscriptions[topic]
      },
      emit: (topic, body) => {
        context.client.publish({
          destination: `/app/${topic}`,
          body: JSON.stringify(body),
        })
      },
      reconnectWithAuthorization: (jwt) => {
        return new Promise((resolve, reject) => {
          context.client.forceDisconnect()
          context.jwt = jwt
          context.client.configure({
            connectHeaders: {
              "X-Authorization": jwt,
            },
          })
          context.client.onConnect = function (frame) {
            context.connected(frame)
            resolve()
          }
          context.client.activate()
        })
      },
      _addListener: (listener) => {
        context.listeners.push(listener)
        if (context.client.connected) {
          context.bindListeners()
        }
      },
      _addBroadcastListener: (listener) => {
        context.broadcastListeners.push(listener)
        if (context.client.connected) {
          context.bindListeners()
        }
      },
      get connected() {
        return (context.client && context.client.active) || false
      },
    }

    Vue.mixin({
      mounted: this.bindHooks,
    })

    Vue.prototype.$socket = helper
  }

  connected(frame) {
    this.bindStores()
    this.bindListeners()
  }

  bindHooks() {
    Object.entries(this.$options.sockets || {}).forEach(([topic, callback]) => {
      this.$socket._addListener([topic, callback.bind(this)])
    })
  }

  bindStores() {
    const actions = Object.keys(this.vuex.store._actions).reduce((acc, path) => {
      const [_, action] = path.split("/")
      if (action.startsWith(this.vuex.actionPrefix)) {
        const handler = action.replace(this.vuex.actionPrefix, "")
        const callback = (payload) => this.vuex.store.dispatch(path, payload)
        return [...acc, [handler, callback]]
      } else {
        return acc
      }
    }, [])

    this.listeners = [...this.listeners, ...actions]
  }

  bindListeners() {
    while (this.listeners.length) {
      const [topic, callback] = this.listeners.shift()
      this.subscriptions[topic] = this.client.subscribe(`/user/topic/${topic}`, (message) => {
        const data = this.parseBody(message.body)
        callback(data)
      })
    }

    while (this.broadcastListeners.length) {
      const [topic, callback] = this.broadcastListeners.shift()
      this.subscriptions[topic] = this.client.subscribe(`/topic/broadcast_${topic}`, (message) => {
        const data = this.parseBody(message.body)
        callback(data)
      })
    }
  }

  parseBody(body) {
    try {
      return JSON.parse(body)
    } catch (_) {
      return body
    }
  }
}
