//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    moreStr: {
      type: String,
      default: "read more",
    },
    lessStr: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: "#",
    },
    maxChars: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isReadMore: false,
    }
  },
  computed: {
    formattedString() {
      let text = this.text

      if (!this.isReadMore && this.text.length > this.maxChars) {
        text = text.substring(0, this.maxChars) + "..."
      }

      return text
    },
  },
  methods: {
    triggerReadMore(e, b) {
      if (this.link == "#") {
        e.preventDefault()
      }
      if (this.lessStr !== null || this.lessStr !== "") this.isReadMore = b
    },
  },
}
