import Vue from "vue"
import Vuex from "vuex"
import appointment from "./appointment"
import call from "./call"
import VideoCallStore from "../../modules/call/store"
import thread from "./thread"
import note from "./note"
import downloads from "./downloads"
import invitations from "./invitations"
import patientLink from "./patientLink"
import user from "./user"
import payments from "./payments"
import { twoFactorStore } from "./twoFactor"
import { createProxy, extractVuexModule } from "vuex-class-component"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    appointment: appointment,
    call: call,
    ...extractVuexModule(VideoCallStore),
    thread: thread,
    note: note,
    downloads: downloads,
    invitations: invitations,
    patientLink: patientLink,
    user: user,
    payments: payments,
    twoFactor: twoFactorStore,
  },
})

export const vxm = {
  call: createProxy(store, VideoCallStore),
}

export default store
