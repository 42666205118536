//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import braintree from "braintree-web"
import braintreeMixin from "./braintree"
import Customize from "../../../plugins/Customize"

export default {
  mixins: [braintreeMixin, Customize()],
  props: {
    paymentClient: {
      required: true,
    },
    payment: {
      required: true,
    },
    method: {
      required: true,
    },
  },
  data() {
    return {
      paypalClient: null,
      loadingPayPal: false,
    }
  },
  computed: {
    amountToPay() {
      if (this.payment === null) return ""
      if (typeof this.payment !== typeof undefined)
        return this.payment.amount_brutto + " " + this.payment.currency
    },
  },
  watch: {
    paymentClient: {
      immediate: true,
      handler() {
        if (this.paymentClient !== null) {
          this.initPayPalMethod(this.paymentClient)
        }
      },
    },
  },
  methods: {
    initPayPalMethod(client) {
      let $this = this
      braintree.paypal.create({ client: client }, (paypalErr, paypalInstance) => {
        console.log("braintree.paypal.create", paypalErr, paypalInstance)
        if (paypalErr) {
          $this.throwRavenException("PaypalCreate: ", paypalErr)
          $this.$bridge.emit(
            "addToast",
            this.$t.get("appointment.payment-method-disabled", { method: "Paypal" }),
            "error"
          )
          return
        }
        $this.paypalClient = paypalInstance
      })
    },
    payPayPal() {
      console.info("PayPal submit")
      let $this = this
      this.loading = true
      this.loadingPayPal = true

      $this.paypalClient.tokenize($this.payment.paypal_options, function (tokenizeErr, payload) {
        $this.loadingPayPal = false
        if (tokenizeErr) {
          if (tokenizeErr.type !== "CUSTOMER") {
            $this.$bridge.emit(
              "addToast",
              this.$t.get("common.something-went-wrong-please-try-again"),
              "error"
            )
          }
          $this.sendLogs("PayPalTokenize", $this.payment.paypal_options, payload, tokenizeErr)
          $this.loading = false
          return
        }
        $this.confirmPayment($this.method, $this.payment, payload, tokenizeErr)
      })
    },
    focusPayPalPopup() {
      if (this.paypalClient !== null) this.paypalClient.focusWindow()
    },
    cancelPay() {
      this.$emit("cancel-pay")
    },
  },
}
