//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    paginationInit: {
      type: Object,
      required: true,
    },
    offset: {
      type: Number,
      default: 4,
    },
    limitPages: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    setPage(page) {
      if (page < 1 || page > this.paginationInit.last_page) return
      this.$emit("set-page", page)
    },
  },
  computed: {
    pagesNumber: function () {
      if (!this.paginationInit.to) {
        return []
      }
      let from = this.paginationInit.current_page - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + this.offset * 2
      if (to >= this.paginationInit.last_page) {
        to = this.paginationInit.last_page
      }
      let pagesArray = []
      for (from = 1; from <= to; from++) {
        pagesArray.push(from)
      }
      return pagesArray
    },
    prevBatch: function () {
      let prevBatch = this.paginationInit.current_page - this.limitPages
      return prevBatch < 1 ? 1 : prevBatch
    },
    nextBatch: function () {
      let nextBatch = this.paginationInit.current_page + this.limitPages
      return nextBatch > this.paginationInit.last_page ? this.paginationInit.last_page : nextBatch
    },
    limited: function () {
      if (this.limitPages === this.paginationInit.total) return this.pagesNumber

      if (this.limitPages > 0) {
        let slice = this.prevBatch
        return this.pagesNumber.slice(slice <= 1 ? 0 : slice - 1, this.nextBatch)
      }

      return this.pagesNumber
    },
  },
}
