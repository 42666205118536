import Tooltip from "../../ui/tooltip"

const tooltip = {
  inserted(el, binding) {
    if (!binding.hasOwnProperty("value") || binding.value) {
      el.tooltip = new Tooltip(el)
    }
  },
}

export default tooltip
