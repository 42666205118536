import Cleave from "cleave.js"
import { get, set, isEqualWith } from "lodash"

import "cleave.js/dist/addons/cleave-phone.i18n.js"

class VueCleave {
  static init(el, binding, vnode) {
    new VueCleave(el, binding, vnode)
  }

  constructor(el, binding, vnode) {
    this.destroyed = true

    this.el = el
    this.binding = binding
    this.vnode = vnode

    this.config = this.getConfig()

    this.bind()
  }

  bind() {
    let initialValue = get(this.vnode.context, this.config.model)

    if (initialValue !== void 0) {
      this.cleave = new Cleave(this.el, this.config)
      this.destroyed = false
      this.cleave.setRawValue(initialValue)
      this.cleave.callOnValueChanged()
    } else {
      throw new Error(
        "[v-cleave] Model that you provide `" + this.binding.expression + "` does not exists."
      )
    }
  }

  update(el, binding, vnode) {
    this.el = el
    this.binding = binding
    this.vnode = vnode

    const cusomizer = (a, b) => {
      let tempA = Object.assign({}, a)
      let tempB = Object.assign({}, b)

      delete tempA.onValueChanged
      delete tempA.initValue
      delete tempB.onValueChanged
      delete tempB.initValue

      return Object.entries(tempA).toString() === Object.entries(tempB).toString()
    }

    if (isEqualWith(this.config, this.getConfig(), cusomizer) === false) {
      this.destroyed = true
      this.config = this.getConfig()
      this.cleave.destroy()

      this.bind()
    }

    if (this.binding.value !== this.binding.oldValue) {
      this.cleave.setRawValue(this.binding.value)
      this.cleave.callOnValueChanged()
    }
  }

  onValueChanged(event) {
    if (this.destroyed === false && typeof this.cleave !== typeof undefined) {
      let value = event.target.value
      set(this.vnode.context, this.config.model, value)
    }
  }

  getConfig() {
    let config = {}
    if (this.binding.modifiers.config) {
      config = get(this.vnode.context, this.binding.expression, null)
      if (config === null) {
        throw new Error(
          "[v-cleave] Config that you provide `" + this.binding.expression + "` does not exists."
        )
      }
    } else if (this.binding.modifiers.date) {
      config = { delimiter: ".", date: true, datePattern: ["d", "m", "Y"] }
    } else if (this.binding.modifiers.time) {
      config = { time: true, timePattern: ["h", "m"] }
    } else if (this.binding.modifiers.phone) {
      const country = Object.keys(this.binding.modifiers)[1]
      config = { phone: true, phoneRegionCode: country }
    } else if (this.binding.modifiers.creditCard) {
      config = { creditCard: true }
    } else if (this.binding.modifiers.numeral) {
      config = { numeral: true }
    } else {
      throw new Error(
        "[v-cleave] Unknown type of mask, available masks: [date, time, phone, creditCard, numeral]"
      )
    }

    return Object.assign(
      {
        model: this.binding.expression,
        onValueChanged: (ev) => this.onValueChanged(ev),
      },
      config
    )
  }
}

export default {
  inserted: (el, binding, vnode) => {
    el.cleave = new VueCleave(el, binding, vnode)
  },
  update: (el, binding, vnode) => {
    el.cleave.update(el, binding, vnode)
  },
}
