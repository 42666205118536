var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-list" },
    [
      _c("resize-observer", {
        ref: "resizeObserver",
        on: { notify: _vm.handleResize },
      }),
      _vm._v(" "),
      Object.keys(this.filters).length > 2
        ? _c("v-select", {
            staticClass: "select-filter",
            attrs: { options: this.filters },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          staticClass: "title-auto-height",
          class: { "with-filters": Object.keys(this.filters).length > 2 },
        },
        [
          _c(
            "v-tab",
            {
              attrs: { name: _vm.$t.get("viewer.dicoms"), "detach-tab": false },
            },
            [
              Object.keys(_vm.dicoms).length === 0
                ? _c("div", { staticClass: "text-center accent empty-list" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t.get("viewer.there-is-no-dicom-files"))
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("recycle-scroller", {
                staticClass: "dicoms scrollbar",
                attrs: {
                  items: _vm.dicoms,
                  "item-size": _vm.itemHeight,
                  "content-tag": "div",
                  "key-field": "hash",
                  "pool-size": 2000,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            key: item.hash,
                            staticClass: "data-item",
                            class: { selected: _vm.isSelected(item.hash) },
                          },
                          [
                            _c("thumbnail", {
                              attrs: { item: item, type: _vm.DataType.DICOM },
                              on: {
                                "item-dragged": _vm.itemDragged,
                                "item-clicked": _vm.itemClicked,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            { attrs: { name: _vm.$t.get("viewer.pdfs"), "detach-tab": false } },
            [
              Object.keys(_vm.pdfs).length === 0
                ? _c("div", { staticClass: "text-center accent empty-list" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t.get("viewer.there-is-no-pd-fs"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("recycle-scroller", {
                staticClass: "pdfs scrollbar",
                attrs: {
                  items: _vm.pdfs,
                  "item-size": 188,
                  "content-tag": "div",
                  "key-field": "hash",
                  "pool-size": 2000,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            key: item.hash,
                            staticClass: "data-item",
                            class: { selected: _vm.isSelected(item.hash) },
                          },
                          [
                            _c("thumbnail", {
                              attrs: { item: item, type: _vm.DataType.PDF },
                              on: {
                                "item-dragged": _vm.itemDragged,
                                "item-clicked": _vm.itemClicked,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            {
              attrs: { name: _vm.$t.get("viewer.images"), "detach-tab": false },
            },
            [
              Object.keys(_vm.images).length === 0
                ? _c("div", { staticClass: "text-center accent empty-list" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t.get("viewer.there-is-no-images"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("recycle-scroller", {
                staticClass: "images scrollbar",
                attrs: {
                  items: _vm.images,
                  "item-size": 188,
                  "content-tag": "div",
                  "key-field": "hash",
                  "pool-size": 2000,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            key: item.hash,
                            staticClass: "data-item",
                            class: { selected: _vm.isSelected(item.hash) },
                          },
                          [
                            _c("thumbnail", {
                              attrs: { item: item, type: _vm.DataType.IMAGE },
                              on: {
                                "item-dragged": _vm.itemDragged,
                                "item-clicked": _vm.itemClicked,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }