//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    abi: {
      type: Array,
      required: true,
    },
    contract: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    payment: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    destAddress: {
      type: String,
      required: true,
    },
    amount: {
      required: true,
    },
    amountToken: {
      type: Number,
      required: true,
    },
    autoInit: {
      type: Boolean,
      default: true,
    },
    acceptedNetworks: {
      // mainnet, Ropsten for dev, mainnet for prod
      type: Array,
      required: true,
    },
    paymentMethodId: {
      required: true,
    },
  },
  data() {
    return {
      state: 0,
      states: {
        ERROR: -1,
        IDLE: 0,
        INITIALIZING: 1,
        READY: 2,
        TX_WAITING_ACCEPT: 3,
        TX_WAITING_CONFIRM: 4,
        TX_MINED: 5,
      },
      error: null,
      errors: {
        FAILED_UNSUPPORTED_BROWSER: -1, // MetaMask not installed
        FAILED_USER_ACCESS_REVOKED: -2, // MetaMask accounts access revoked
        FAILED_USER_NOT_LOGGED: -3,
        FAILED_UNSUPPORTED_NETWORK: -4, // MetaMask connected to wrong network
        FAILED_TRANSACTION_INSUFFICIENT_FUNDS: -5,
        FAILED_TRANSACTION_REJECTED_BY_USER: -6, // User rejected transaction in MetaMask dialog
        FAILED_TRANSACTION_FAILED: -7, // Transaction failed in network
        FAILED_CALLBACK: -8, // Payment callback failed
        FAILED_UNKNOWN_ERROR: -9, // Payment callback failed
      },
      token: null,
      currentNetwork: null,
      account: null, // user account taken from MetaMask
      accountWatcherRef: null,
      txWatcherRef: null,
      tokenSupply: {
        name: null,
        totalSupply: 0,
        balance: 0,
        percentOwned: 0,
      },
      txReceipt: null,
    }
  },
  computed: {
    txEtherscanURL() {
      if (this.txReceipt === null) return null
      let networkName = ""
      if (this.currentNetwork === "3") networkName = "ropsten."

      return `https://${networkName}etherscan.io/tx/${this.txReceipt}`
    },
  },
  watch: {
    state: function (newState) {
      if (newState === this.states.INITIALIZING) this.initialize()
    },
  },
  mounted() {
    if (this.autoInit) {
      this.state = this.states.INITIALIZING
    }
  },
  methods: {
    reset() {
      this.state = this.states.IDLE
      this.account = null
      this.txReceipt = null
      this.error = null
      if (this.accountWatcherRef !== null) clearInterval(this.accountWatcherRef)
      if (this.txWatcherRef !== null) clearInterval(this.txWatcherRef)
    },
    cancelPay() {
      this.reset()
      this.$emit("token-cancel-pay")
    },
    setError(errorCode) {
      this.state = this.states.ERROR
      this.error = errorCode
    },
    watchForAccountChange() {
      if (this.accountWatcherRef !== null) clearInterval(this.accountWatcherRef)
      this.accountWatcherRef = setInterval(() => {
        Vue.nextTick(() => {
          // Check if user is still logged in
          if (web3.eth.accounts.length === 0) {
            if (this.accountWatcherRef !== null) clearInterval(this.accountWatcherRef)
            this.setError(this.errors.FAILED_USER_NOT_LOGGED)
            return
          }
          // Check if account has changed
          if (web3.eth.accounts[0] !== this.account) {
            this.account = web3.eth.accounts[0]
            this.getTokenSupplyForAccount(this.token, this.account)
            this.state = this.states.READY
          }
        })
      }, 100)
    },
    checkNetwork() {
      let version = web3.version.network
      if (this.acceptedNetworks.indexOf(version) < 0) {
        this.setError(this.errors.FAILED_UNSUPPORTED_NETWORK)
        return false
      }
      this.currentNetwork = version
      return true
    },
    configure() {
      this.token = web3.eth.contract(this.abi).at(this.contract)
      this.watchForAccountChange()
    },
    async initialize() {
      this.reset()
      // Modern dapp browsers...
      if (window.ethereum) {
        window.web3 = new Web3(ethereum)
        try {
          if (this.checkNetwork()) {
            // Request account access if needed
            await ethereum.enable()
            this.configure()
          }
        } catch (error) {
          this.setError(this.errors.FAILED_USER_ACCESS_REVOKED)
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        window.web3 = new Web3(web3.currentProvider)
        if (this.checkNetwork()) {
          this.configure()
        }
      }
      // Non-dapp browsers...
      else {
        this.setError(this.errors.FAILED_UNSUPPORTED_BROWSER)
        console.log("Non-Ethereum browser detected. You should consider trying MetaMask!")
      }
    },

    getTokenSupplyForAccount(token, account) {
      token.totalSupply.call((err, totalSupply) => {
        token.decimals.call((err, decimals) => {
          token.name.call((err, name) => {
            token.balanceOf.call(account, (err, balance) => {
              let divisor = new web3.BigNumber(10).toPower(decimals)

              this.tokenSupply = {
                name: name,
                totalSupply: totalSupply.div(divisor).round(5),
                balance: balance.div(divisor).round(5),
                percentOwned: balance.div(totalSupply).mul(100).round(5),
              }
              if (this.tokenSupply.balance < this.amountToken) {
                this.setError(this.errors.FAILED_TRANSACTION_INSUFFICIENT_FUNDS)
              }
            })
          })
        })
      })
    },
    transfer() {
      if (this.amount <= 0) return
      let amount = web3.toWei(this.amountToken, "ether")
      this.state = this.states.TX_WAITING_ACCEPT
      return this.token.transfer(
        this.destAddress,
        amount,
        { from: this.account },
        (err, txHash) => {
          if (!err) {
            console.log("Transaction sent")
            this.state = this.states.TX_WAITING_CONFIRM
            this.waitForTxToBeMined(txHash)
            return
          }
          this.setError(this.errors.FAILED_TRANSACTION_REJECTED_BY_USER)
          this.sendLogs()
          console.log("Payment process aborted")
        }
      )
    },
    waitForTxToBeMined(txHash) {
      this.txWatcherRef = setInterval(() => {
        web3.eth.getTransactionReceipt(txHash, (err, txReceipt) => {
          console.log(txReceipt)
          if (err) {
            this.setError(this.errors.FAILED_TRANSACTION_FAILED)
            clearInterval(this.txWatcherRef)
            this.sendLogs(err)
            return
          }
          if (txReceipt === null) {
            return
          }
          this.txReceipt = txReceipt.transactionHash
          this.state = this.states.TX_MINED
          this.sendCallback()
          this.$emit("success", txReceipt)
          clearInterval(this.txWatcherRef)
        })
      }, 1000)
    },
    throwRavenException(label, data) {
      try {
        throw new Error(label + JSON.stringify(data))
      } catch (e) {
        console.log(e)
        if (process.env.production) Sentry.captureException(e)
      }
    },
    getLog(payload) {
      return {
        state: this.state,
        error: this.error,
        currentNetwork: this.currentNetwork,
        account: this.account,
        user: this.user.user_id,
        contract: this.contract,
        destAddress: this.destAddress,
        amount: this.amount,
        amountToken: this.amountToken,
        txEtherscanURL: this.txEtherscanURL,
        txReceipt: this.txReceipt,
        paymentMethodId: this.paymentMethodId,
        payload: typeof payload !== "undefined" ? JSON.stringify(payload) : null,
      }
    },
    sendLogs(payload) {
      const label = "TDHpayment"
      let log = this.getLog(payload)
      this.throwRavenException(label, log)
      this.$http.post("/ajax/payment/logs", { payment_id: this.payment.payment_id, log: log }).then(
        (response) => {
          //
        },
        (error) => {
          this.throwRavenException(label + "Callback: ", error)
        }
      )
    },
    sendCallback(payload) {
      this.$http.post("/ajax/payment/token/callback", {
        payment_id: this.payment.payment_id,
        method_id: this.paymentMethodId,
        callback: this.getLog(payload),
      })
    },
  },
}
