import AbstractTool from "./AbstractTool"
import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"
import { v4 as uuid } from "uuid"

export default class ClearDraws extends AbstractTool {
  get name() {
    return Translate.get("viewer.view-actions-download")
  }

  get icon() {
    return "ict-download"
  }

  get type() {
    return ToolType.CLICK
  }

  bind(viewerInstance, views) {}

  click(views) {
    const view = views[0]

    if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
      const dataView = view.$refs.dataView
      const cornerstoneElement = dataView.$refs.view
      const canvas = cornerstoneElement.querySelector("canvas")
      const dataURL = canvas.toDataURL()

      let fileName = uuid() + ".png"
      if (dataView.currentImageParams !== void 0) {
        fileName =
          dataView.currentImageParams.patientName +
          ", " +
          dataView.currentImageParams.series +
          " - " +
          (parseInt(dataView.position) + 1) +
          ".png"
      }

      let link = document.createElement("a")
      link.download = fileName
      link.href = dataURL
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else if (view.viewType === DataType.PDF) {
      const dataURL = view.viewData[0].download_url
      const fileName = view.viewData[0].name

      let link = document.createElement("a")
      link.download = fileName
      link.target = "_blank"
      link.href = dataURL
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
