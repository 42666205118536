//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomCheckbox from "../../common/Checkbox.vue"

export default {
  components: { CustomCheckbox },
  data() {
    return {
      reports: [],
      checkedReport: null,
    }
  },
  mounted() {
    this.$http.get("/account/settings/data-portability/reports").then((response) => {
      this.reports = response.data
    })
  },
  methods: {
    download() {
      window.location.href = `/account/settings/data-portability/reports/${this.checkedReport.report_id}`
    },
  },
}
