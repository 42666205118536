var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.formattedString) } }),
        _vm._v(" "),
        _vm.text.length > _vm.maxChars
          ? [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isReadMore,
                      expression: "!isReadMore",
                    },
                  ],
                  attrs: { href: _vm.link, id: "readmore" },
                  on: {
                    click: function ($event) {
                      return _vm.triggerReadMore($event, true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.moreStr))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isReadMore,
                      expression: "isReadMore",
                    },
                  ],
                  attrs: { href: _vm.link, id: "readmore" },
                  on: {
                    click: function ($event) {
                      return _vm.triggerReadMore($event, false)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.lessStr))]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }