//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToolsMixin from "./ToolsMixin.js"

export default {
  mixins: [ToolsMixin],
  computed: {
    toolsList() {
      return this.tools.list
    },
  },
}
