import { Participant } from "./Participant"
import { CallStatus } from "./ValueObjects/CallStatus"

export class Call {
  callId: string
  groupId: string
  name: string
  status: CallStatus
  isRecordingEnabled: boolean
  lastStartEventDate: string | null
  participants: Array<Participant>
  isAllowedToJoinAnyTime: boolean = false

  constructor(
    callId: string,
    groupId: string,
    name: string,
    status: CallStatus,
    isRecordingEnabled: boolean,
    lastStartEventDate: string | null,
    participants: Array<Participant> = [],
    isAllowedToJoinAnyTime: boolean
  ) {
    this.callId = callId
    this.groupId = groupId
    this.name = name
    this.status = status
    this.isRecordingEnabled = isRecordingEnabled
    this.lastStartEventDate = lastStartEventDate
    this.participants = participants
    this.isAllowedToJoinAnyTime = isAllowedToJoinAnyTime
  }

  isNotStartedAndShouldWaitForStart(participant: Participant | null): boolean {
    // todo: should be extended on new business requirements
    if (this.isAllowedToJoinAnyTime === true) {
      return false
    }
    if (participant?.canManageCall) {
      return false
    }

    return this.status !== CallStatus.STARTED
  }

  canFinishCall(participant: Participant | null): boolean {
    // todo: should be extended on new business requirements
    if (participant?.canManageCall) {
      return true
    }

    return false
  }

  toObject() {
    return {
      call_id: this.callId,
      group_id: this.groupId,
      name: this.name,
      status: this.status,
      is_recording_enabled: this.isRecordingEnabled,
      last_start_event_date: this.lastStartEventDate,
      participants: this.participants,
      is_allowed_to_join_anytime: this.isAllowedToJoinAnyTime,
    }
  }

  serialize() {
    return JSON.stringify(this.toObject())
  }

  static fromJSON(serialized: string): Call {
    const call: ReturnType<Call["toObject"]> = JSON.parse(serialized)

    return new Call(
      call.call_id,
      call.group_id,
      call.name,
      call.status as CallStatus,
      call.is_recording_enabled,
      call.last_start_event_date,
      call.participants.map((participant: any) => Participant.fromObject(participant)),
      call.is_allowed_to_join_anytime
    )
  }

  static fromObject(object: ReturnType<Call["toObject"]>): Call {
    return new Call(
      object.call_id,
      object.group_id,
      object.name,
      object.status as CallStatus,
      object.is_recording_enabled,
      object.last_start_event_date,
      object.participants.map((participant: any) => Participant.fromObject(participant)),
      object.is_allowed_to_join_anytime
    )
  }
}
