//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { browserDetect } from "../../../../../apps/browser-detect-mixin"

export default {
  name: "CustomerLoginHistory",
  mixins: [browserDetect],
  props: {
    items: { type: Array, required: true },
    processing: { type: Boolean, required: true },
  },
  methods: {
    getSourceName(permission) {
      switch (permission) {
        case "web_login":
          return this.$t.get("customer.details.login-history.web-app")
        case "mobile_login":
          return this.$t.get("customer.details.login-history.mobile-app")
        default:
          return permission
      }
    },
  },
}
