//
//
//

export default {
  props: {
    text: {
      type: String,
    },
  },
}
