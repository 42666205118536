var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-input",
      class: [
        { opened: _vm.isOpened, disabled: _vm.disabled },
        _vm.dropdownPosition,
      ],
      attrs: { tabindex: "0" },
      on: {
        focus: _vm.show,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          ) {
            return null
          }
          return _vm.hide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "label" }, [
        _vm.multi && _vm.value !== null && _vm.value.length > 0
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:background",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "background",
                  },
                ],
                staticClass: "count",
              },
              [_vm._v("\n      " + _vm._s(_vm.value.length) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c("i", {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:color",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "color",
            },
          ],
          staticClass: "ict-angle-down",
        }),
      ]),
      _vm._v(" "),
      _vm.isOpened
        ? _c(
            "div",
            { ref: "dropdown", staticClass: "dropdown" },
            [
              _c(
                "div",
                { staticClass: "filter", class: { space: _vm.multi } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter,
                        expression: "filter",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t.get("common.search"),
                    },
                    domProps: { value: _vm.filter },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.filter = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-scrollbar",
                { ref: "scrollBar", staticClass: "select-scroll" },
                [
                  _c(
                    "div",
                    { staticClass: "options" },
                    [
                      _vm._l(_vm.filteredItems, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "option",
                            class: {
                              selected: _vm.isSelected(item),
                              disabled: item.$disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.optionClicked(item)
                              },
                            },
                          },
                          [
                            _vm.multi
                              ? _c("div", {
                                  directives: [
                                    {
                                      name: "styled",
                                      rawName: "v-styled:background.after",
                                      value: _vm.palette.colors.primary.main,
                                      expression: "palette.colors.primary.main",
                                      arg: "background",
                                      modifiers: { after: true },
                                    },
                                  ],
                                  staticClass: "checkbox",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isSelected(item) === false
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t.get(item[_vm.labelKey]))
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isSelected(item) === true
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "color",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t.get(item[_vm.labelKey]))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.filteredItems.length === 0
                        ? _c("div", { staticClass: "option empty" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t.get(
                                    "common.no-elements-found-consider-changing-the-search-query"
                                  )
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "clear" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:colors",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "colors",
                      },
                    ],
                    on: { click: _vm.clear },
                  },
                  [_vm._v(_vm._s(_vm.$t.get("common.clear-selected")))]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }