var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.noteSaving,
            expression: "noteSaving",
          },
        ],
        staticClass: "info saving",
      },
      [_vm._v(_vm._s(_vm.$t.get("appointment.saving")))]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imageInserting,
            expression: "imageInserting",
          },
        ],
        staticClass: "info inserting",
      },
      [_vm._v(_vm._s(_vm.$t.get("appointment.inserting-image")))]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.noteSaving && !_vm.imageInserting && _vm.updated_at !== null,
            expression: "!noteSaving && !imageInserting && updated_at !== null",
          },
        ],
        staticClass: "info last-edit",
      },
      [
        _vm._v(
          _vm._s(_vm.$t.get("appointment.last-edit")) +
            ": " +
            _vm._s(_vm.$momentUtils.relativeTime(_vm.updated_at))
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "messages" },
      [
        _c("quill-editor", {
          ref: "quill",
          attrs: {
            options: { objectId: _vm.objectId },
            disabled: _vm.imageInserting,
          },
          on: {
            change: function ($event) {
              _vm.noteSaving = true
            },
            input: function ($event) {
              return _vm.saveNote(_vm.message)
            },
          },
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }