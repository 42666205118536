var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-in" },
    [
      _vm.isUnsupportedBrowser
        ? [
            _c("span", { staticClass: "description unsupported-browser-box" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t.get("common.not-supported-browser")) +
                  "\n      "
              ),
              _vm._m(0),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t.get(
                      "common.copy-the-following-link-and-open-it-in-supported-browser"
                    )
                  ) +
                  "\n      "
              ),
              _c("input", {
                staticClass: "link-to-copy",
                attrs: { type: "text", readonly: "readonly" },
                domProps: { value: _vm.redirectUrl },
              }),
            ]),
          ]
        : [
            _c("form", { staticClass: "flex flex-col material-form" }, [
              _c("div", { staticClass: "w-full" }, [
                _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputs.email,
                        expression: "inputs.email",
                      },
                    ],
                    attrs: { type: "email", name: "email", id: "email" },
                    domProps: { value: _vm.inputs.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.inputs, "email", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "email" } }, [
                    _vm._v(_vm._s(_vm.$t.get("auth.email"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "form-error" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full" }, [
                _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputs.password,
                        expression: "inputs.password",
                      },
                    ],
                    attrs: {
                      type: "password",
                      name: "password",
                      id: "password",
                      value: "",
                    },
                    domProps: { value: _vm.inputs.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.inputs, "password", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v(_vm._s(_vm.$t.get("auth.password"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "form-error" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full submit" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button accent",
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t.get("auth.sign-in")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "inline" }, [
                  _vm._v(
                    "\n          * " +
                      _vm._s(_vm.$t.get("auth.forgot-your-password")) +
                      "\n          "
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.forgotPassword()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t.get("common.click-here")) + ".")]
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "supported-browser" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "chrome",
            attrs: {
              href: "https://www.google.com/chrome/browser/desktop/index.html",
              target: "_blank",
            },
          },
          [
            _c("div", { staticClass: "ic" }),
            _vm._v(" "),
            _c("span", [_vm._v("Chrome")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "opera",
            attrs: { href: "https://www.opera.com/", target: "_blank" },
          },
          [
            _c("div", { staticClass: "ic" }),
            _vm._v(" "),
            _c("span", [_vm._v("Opera")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            staticClass: "firefox",
            attrs: {
              href: "https://www.mozilla.org/pl/firefox/new",
              target: "_blank",
            },
          },
          [
            _c("div", { staticClass: "ic" }),
            _vm._v(" "),
            _c("span", [_vm._v("Firefox")]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }