// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.flag[data-v-87119262] {
  display: inline-block;
  width: 24px;
  margin-right: 5px;
}
span[data-v-87119262] {
  display: inline-block !important;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/components/common/LanguageSelect.vue"],"names":[],"mappings":";AAqDA;EAAA,qBAAA;EACA,WAAA;EACA,iBAAA;AAFA;AAKA;EACA,gCAAA;AACA","sourcesContent":["<template>\n  <v-select v-model=\"selected\">\n    <template v-slot:selected=\"prop\">\n      <div class=\"language\">\n        <img class=\"flag\" :src=\"`/assets/app/img/flags/${prop.selectedItem.value}.svg`\" />\n        <span v-text=\"prop.selectedItem.label\"></span>\n      </div>\n    </template>\n    <template v-slot:item=\"prop\">\n      <img class=\"flag\" :src=\"`/assets/app/img/flags/${prop.item.value}.svg`\" />\n      <span v-text=\"prop.item.label\"></span>\n    </template>\n    <v-option\n      v-for=\"(locale, index) in locales\"\n      v-bind:key=\"index\"\n      v-bind:label=\"locale.label\"\n      v-bind:value=\"locale.value\"\n    >\n    </v-option>\n  </v-select>\n</template>\n\n<script>\nexport default {\n  props: {\n    value: {},\n  },\n  data() {\n    return {\n      selected: null,\n    }\n  },\n  computed: {\n    defaultLocale() {\n      return window.__LOCALE\n    },\n    locales() {\n      return JSON.parse(window.__LOCALES)\n    },\n  },\n  mounted() {\n    this.selected = this.value ? this.value : this.defaultLocale\n  },\n  watch: {\n    selected() {\n      this.$emit(\"input\", this.selected)\n    },\n  },\n}\n</script>\n\n<style lang=\"postcss\" scoped>\n.flag {\n  @apply inline-block;\n  width: 24px;\n  margin-right: 5px;\n}\n\nspan {\n  display: inline-block !important;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
