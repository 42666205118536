var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.maxInvitedParticipantsQuantityReached === false
      ? _c("div", { staticClass: "input w-full smaller-margin" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            class: { error: _vm.$v.email.$error },
            attrs: { name: "invite", id: "invite" },
            domProps: { value: _vm.email },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
                _vm.emailInputChanged,
              ],
              blur: _vm.emailInputUnfocus,
            },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "invite" } }, [
            _vm._v(_vm._s(_vm.$t.get("calls.invite-participants"))),
          ]),
          _vm._v(" "),
          _vm.$v.email.$invalid === false && _vm.email && _vm.email.length > 0
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:color.hover",
                      value: _vm.palette.colors.hover.alt,
                      expression: "palette.colors.hover.alt",
                      arg: "color",
                      modifiers: { hover: true },
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background.hover",
                      value: _vm.palette.colors.hover.main,
                      expression: "palette.colors.hover.main",
                      arg: "background",
                      modifiers: { hover: true },
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:border-color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "border-color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:border-color.hover",
                      value: _vm.palette.colors.hover.alt,
                      expression: "palette.colors.hover.alt",
                      arg: "border-color",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "add-participant",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addParticipant.apply(null, arguments)
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addParticipant.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      +\n    ")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$v.email.$error
      ? _c("p", { staticClass: "error" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.$t.get("calls.enter-valid-email")) + "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-wrap" },
      _vm._l(_vm.participants, function (participant, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "styled",
                rawName: "v-styled:color",
                value: _vm.palette.colors.primary.alt,
                expression: "palette.colors.primary.alt",
                arg: "color",
              },
              {
                name: "styled",
                rawName: "v-styled:background",
                value: _vm.palette.colors.primary.main,
                expression: "palette.colors.primary.main",
                arg: "background",
              },
            ],
            key: index,
            staticClass: "email-badge",
          },
          [
            _c("span", [_vm._v(_vm._s(participant))]),
            _vm.existingParticipants.includes(participant) === false
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "color",
                      },
                    ],
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.removeParticipant(index)
                      },
                    },
                  },
                  [_vm._v("×")]
                )
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3" }, [
      _vm.maxInvitedParticipantsQuantityReached
        ? _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t.get("calls.added-maximal-amount")) +
                "\n    "
            ),
          ])
        : _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t.get("calls.remaining-available-participants")) +
                "\n      "
            ),
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v(" " + _vm._s(_vm.remainingInvitedParticipantsQuantity)),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }