var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input" },
    [
      _c("div", { staticClass: "checkbox" }, [
        _c("input", {
          staticClass: "inside",
          class: { error: _vm.validation && _vm.validation.$error },
          attrs: {
            type: "checkbox",
            disabled: _vm.disabled || _vm.readOnly,
            id: _vm.inputId,
          },
          domProps: { checked: _vm.value },
          on: {
            input: _vm.input,
            change: function ($event) {
              return _vm.change($event)
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.inputId } }),
      ]),
      _vm._v(" "),
      _c("label", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }