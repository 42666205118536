import AbstractTool from "./AbstractTool"
import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"
import { cornerstoneTools } from "../../cornerstone"
import { EraserTool } from "cornerstone-tools"

export default class ClearDraws extends AbstractTool {
  get name() {
    return Translate.get("viewer.clear")
  }

  get icon() {
    return "ict-eraser"
  }

  get type() {
    return ToolType.SWITCH
  }

  get mobile() {
    return false
  }

  bind(viewerInstance, views) {
    views.forEach((view) => {
      if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
        const dataView = view.$refs.dataView
        const cornerstoneElement = dataView.$refs.view
        cornerstoneTools.addToolForElement(cornerstoneElement, EraserTool)
      }
    })
  }

  activate(views) {
    cornerstoneTools.setToolActive("Eraser", { mouseButtonMask: 1 })
  }

  deactivate(views) {
    cornerstoneTools.setToolPassive("Eraser", { mouseButtonMask: 1 })
  }
}
