//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { endsWith } from "lodash"

export default {
  props: {
    header: { type: String, required: true },
    items: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  methods: {
    processKey(key) {
      return this.$t.get(key.replaceAll("_", "-"))
    },
    processPair(key, value) {
      if (value === null || value === "" || value === "-") {
        return "-"
      }
      if (key.includes("date") === true || endsWith(key, "_at") === true) {
        return this.$moment(value).format("DD.MM.YYYY")
      }

      return value
    },
  },
}
