//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatSize } from "../../../../utils"
import Customize from "../../../plugins/Customize"

export default {
  mixins: [Customize({})],
  data() {
    return {
      folders: [],
      selectedFile: null,
    }
  },
  methods: {
    async fetchFolders() {
      try {
        const response = await this.$http.get(window.__CLOUD_URL + "/items", {
          headers: {
            Authorization: "Bearer " + window.USER_JWT,
          },
        })

        this.folders = response.data.map((folder) => {
          folder.loaded = false
          folder.files = []

          return folder
        })

        this.folders.push({
          name: this.$t.get("gdpr.thread-message-attachments"),
          type: "thread-attachments",
          loaded: false,
          files: [],
        })
      } catch (_) {}
    },
    async refreshFiles(folder) {
      if (folder.loaded === true) {
        return
      }

      if (folder.type === "thread-attachments") {
        this.fetchThreadAttachments(folder)
        return
      }

      const response = await this.$http.get(
        `${window.__CLOUD_URL}/items/${folder.item_id}/unstacked`,
        {
          headers: {
            Authorization: "Bearer " + window.USER_JWT,
          },
        }
      )

      folder.files = Object.values(response.data)
      folder.loaded = true
    },
    async fetchThreadAttachments(folder) {
      this.$http
        .get("/account/settings/data-portability/thread-messages-files")
        .then((response) => {
          folder.files = response.data.map((file) => {
            file.item_id = file.id
            file.download_url = file.link

            return file
          })
        })
    },
    download() {
      if (this.selectedFile === null) {
        return
      }

      window.location.href = this.selectedFile.download_url
    },
    selectFile(file) {
      this.selectedFile =
        this.selectedFile && this.selectedFile.item_id === file.item_id ? null : file
    },
    formatIcon(type) {
      switch (type) {
        case "application/dicom":
          return "dicom"
        case "application/pdf":
          return "pdf"
        case "image/jpeg":
        case "image/png":
        case "image/jpg":
          return "image"
      }
    },
    formatSize(bytes, decimals) {
      return formatSize(bytes, decimals)
    },
  },
  mounted() {
    this.fetchFolders()
  },
}
