import moment from "moment-timezone"
import Vue from "../vue.js"

export default class RxTime {
  constructor() {
    this.subscribers = []

    let $this = this
    $this.time = moment().utc().format()
    setInterval(() => {
      $this.time = moment().utc().format()

      $this.subscribers.forEach((fn) => fn($this.time))
    }, 1000)
  }

  subscribe(fn) {
    return this.subscribers.push(fn)
  }

  unsubscribe(index) {
    delete this.subscribers[index - 1]
  }

  static mixin() {
    return {
      data() {
        return {
          now: null,
        }
      },
      beforeMount() {
        let $this = this
        this._ts = window.RxTime.subscribe((time) => {
          Vue.set($this, "now", time)
        })
      },
      beforeDestroy() {
        if (this._ts !== void 0) {
          window.RxTime.unsubscribe(this._ts)
        }
      },
    }
  }
}
