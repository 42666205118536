var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                ) +
                "\n  "
            ),
          ]
        : _vm.mode === "input"
        ? [
            _c(
              "label",
              { staticClass: "datepicker-label", attrs: { for: "first-name" } },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("datepicker", {
              attrs: {
                language: _vm.datepickerLanguage,
                format: _vm.format,
                placeholder: _vm.$t.get(_vm.placeholderTranslation),
                "clear-button": true,
                "calendar-class": "datepicker-picker-year",
                "input-class": "datepicker-input",
                "wrapper-class": "datepicker-wrapper",
              },
              on: { input: _vm.emitDate },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ]
        : [_vm._v("\n    " + _vm._s(_vm.customer.birthday || "") + "\n  ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }