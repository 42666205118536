//
//
//
//
//
//
//

import { clearTokenResponse, hasCodeParameter, isServerError } from "./FhirPatientImport.vue"
import FhirPatientImport from "./FhirPatientImport.vue"
import Modal from "../../plugins/modal/modal"
export default {
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
    fhirEndpoints: {
      type: Array,
      default: function () {
        return window.fhir_endpoints || []
      },
    },
  },
  data() {
    return {}
  },
  mounted() {
    if (this.isAuthorized === true) {
      this.showAsModal(3)
    }
    if (this.serverError !== null) {
      this.showAsModal(-1)
    }
  },
  computed: {
    isAuthorized() {
      return hasCodeParameter()
    },
    serverError() {
      return isServerError()
    },
  },
  methods: {
    showAsModal(step = 1) {
      if (step === 1 || this.serverError !== null) {
        clearTokenResponse()
      }
      Modal.create(this)
        .title(this.$t.get("fhir.import-data-from-provider"))
        .component(FhirPatientImport)
        .props({ fhirEndpoints: this.fhirEndpoints, step: step })
        .events({
          "fhir-patient-imported": this.passEvent,
        })
        .show()
    },
    passEvent(event) {
      this.$emit("fhir-patient-imported", event)
    },
  },
}
