var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-button", class: [_vm.containerClass] },
    [
      _c(
        "button",
        {
          class: [_vm.buttonClass],
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("buttonText", function () {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:color.hover",
                      value: _vm.palette.colors.hover.main,
                      expression: "palette.colors.hover.main",
                      arg: "color",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass:
                    "flex items-center justify-center space-x-1 w-10 dots-container text-xl text-primary",
                },
                [
                  _c("div", [_vm._v("•")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("•")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("•")]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [_vm.isVisible ? "display" : "", _vm.dropdownClass],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.itemClicked.apply(null, arguments)
            },
          },
        },
        [_vm._t("item")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }