//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"
import Modal from "../../plugins/modal/modal"

const Actions = {
  FETCH_PROFILE: "fetchProfile",
}

export default {
  mixins: [Customize()],
  data() {
    return {
      product: JSON.parse(window.__CURRENT_PRODUCT || "{}"),
      processing: false,
      profileAvatar: {
        processing: false,
        url: "",
      },
      profile: null,
    }
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    initialProfile: {
      require: true,
    },
    readOnly: {
      default: false,
    },
  },
  beforeMount() {
    this.profile = this.initialProfile
  },
  mounted() {
    if (this.readOnly === false) {
      this.fetchProfile()
    }
  },
  methods: {
    getActionParams(action, data) {
      const params = {
        componentId: this.component.id,
        patientProfileId: this.profile.patient_id,
        action: action,
      }

      return { ...params, ...data }
    },
    shareProfile() {
      Modal.create(this)
        .title(this.$t.get("profile.share-profile"))
        .component(() => import("../../components/dashboard/ShareProfile.vue"))
        .props({
          component: this.component,
          profile: this.profile,
          product_id: this.product.product_id,
        })
        .classes(["modal-share-profile"])
        .show()
    },
    fetchProfile() {
      this.processing = true
      this.$http.post(this.component.url, this.getActionParams(Actions.FETCH_PROFILE)).then(
        (response) => {
          this.profile = response.data.data
          this.processing = false
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.processing = false
        }
      )
    },
    updateProfile() {
      const $this = this
      Modal.create(this)
        .title(this.$t.get("profile.edit-information"))
        .component(() => import("./EditProfile.vue"))
        .props({
          patientProfile: Object.assign({}, this.profile),
          productId: this.product.product_id,
          component: this.component,
        })
        .classes(["modal-patient-profile-edit"])
        .events({
          success() {
            $this.fetchProfile()
          },
          close() {
            $this.fetchProfile()
          },
        })
        .show()
    },
  },
}
