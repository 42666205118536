//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { capitalize, padStart as pad } from "lodash"
import AutocompleteDisable from "./AutocompleteDisable"
import Customize from "./Customize"

export default {
  mixins: [AutocompleteDisable, Customize()],
  props: ["value"],
  data() {
    return {
      day: "",
      month: "",
      year: "",
    }
  },
  computed: {
    years() {
      return new Array(100).fill(0).map((item, index) => {
        const year = new Date().getFullYear() - index
        return {
          label: year + "",
          value: year + "",
        }
      })
    },
    months() {
      return new Array(12).fill(0).map((item, index) => {
        return {
          label: capitalize(moment(index + 1, "MM").format("MMMM")),
          value: pad(index + 1, 2, "0"),
        }
      })
    },
    days() {
      const days = moment(this.year + "-" + this.month, "YYYY-MM").daysInMonth()
      if (Number.isNaN(days) === false) {
        return new Array(days).fill(0).map((item, index) => {
          return {
            label: index + 1,
            value: pad(index + 1, 2, "0"),
          }
        })
      } else {
        return []
      }
    },
    birthday() {
      if (this.day.length > 0 && this.month.length > 0 && this.year.length > 0) {
        return this.day + "." + this.month + "." + this.year
      } else {
        return null
      }
    },
  },
  watch: {
    birthday(birthday) {
      if (this.birthday != null && this.$moment(this.birthday, "DD.MM.YYYY").isValid()) {
        this.$emit("input", birthday)
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if (this.value != null && this.value.length > 0) {
          const date = this.$moment(this.value, "DD.MM.YYYY").locale("en")
          this.day = date.format("DD")
          this.month = date.format("MM")
          this.year = date.format("YYYY")
        } else {
          this.day = ""
          this.month = ""
          this.year = ""
        }
      },
    },
  },
}
