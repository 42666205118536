//
//
//
//
//
//
//
//
//
//
//
//
//

import ComponentMixin from "./ComponentMixin"
import LocalProviderResults from "./search-results/LocalProviderResults.vue"

export default {
  mixins: [ComponentMixin],
  name: "CustomerSearchResults",
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getResultsForProvider(provider) {
      return this.results.filter((result) => result.provider === provider)[0]
    },
    getSearchResultComponent(type) {
      switch (type) {
        case "local":
          return LocalProviderResults
        default:
          return LocalProviderResults
      }
    },
  },
}
