/**
 * Helpers functions in js
 */
import { helpers } from "vuelidate/lib/validators"

const validateUrl = function (value) {
  var urlregex =
    /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gm
  if (urlregex.test(value)) {
    return true
  }
  return false
}

const showBrowserNotification = function (title, body, link, avatar_url) {
  if ("Notification" in window === false || Notification.permission !== "granted") {
    return
  }
  var options = {
    body: body,
    data: link,
  }

  if ("__DOMAIN" in window) {
    options.icon = window.__DOMAIN + "/assets/app/img/icon.png"
  }

  if (typeof avatar_url !== typeof undefined) {
    options.icon = avatar_url
  }

  let notification = new Notification(title, options)
  if (link !== "undefined") {
    notification.onclick = (e) => {
      window.location.href = e.target.data
      window.focus()
      e.target.close()
    }
  }
}

const formatSize = function (bytes, decimals) {
  if (bytes === undefined || bytes === null || bytes === "" || bytes === "-") {
    return "-"
  }
  if (bytes === 0) {
    return "0.00 MB"
  }
  let k = 1000,
    dm = decimals + 1 || 3,
    sizes = ["Bytes", "KB", "MB", "GB"],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const calculateJitterInterval = function (base_interval, jitter) {
  jitter = Math.random() * base_interval * jitter
  return Math.round(base_interval + jitter)
}

const phoneRegexValidator = helpers.regex("alpha", /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)

const findPath = (object, key, path = []) => {
  const keys = Object.keys(object)

  for (let i = 0; i < keys.length; i++) {
    const item = keys[i]
    if (item === key) {
      return path
    } else if (typeof object[item] === "object" && object[item] !== null) {
      const deeper = findPath(object[item], key, [...path, item])
      if (deeper) {
        return deeper
      }
    }
  }

  return null
}

const castQueryStringToObject = () => {
  const url = new URL(window.location.href)
  const result = {}
  for (const [key, value] of url.searchParams.entries()) {
    result[key] = value
  }
  return result
}

const appendObjectAsQueryString = (url, obj) => {
  if (obj === null || obj === undefined) {
    return url
  }
  let query = new URLSearchParams()
  for (const [key, value] of Object.entries(obj)) {
    query.append(key, value)
  }
  if (url.includes("?") === false) {
    return `${url}?${query}`
  }

  return `${url}&${query}`
}

export {
  validateUrl,
  showBrowserNotification,
  formatSize,
  calculateJitterInterval,
  phoneRegexValidator,
  findPath,
  castQueryStringToObject,
  appendObjectAsQueryString,
}
