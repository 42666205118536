var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkboxes" },
    [
      _c("label", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._l(_vm.options, function (option, index) {
        return _c("div", { key: index }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected",
              },
            ],
            staticClass: "inside",
            class: { error: _vm.validation && _vm.validation.$error },
            attrs: {
              type: "checkbox",
              disabled: _vm.disabled || _vm.readOnly,
              id: _vm.generateInputId(option.value),
            },
            domProps: {
              value: typeof option.label === "object" ? option : option.value,
              checked: Array.isArray(_vm.selected)
                ? _vm._i(
                    _vm.selected,
                    typeof option.label === "object" ? option : option.value
                  ) > -1
                : _vm.selected,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.selected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v =
                        typeof option.label === "object"
                          ? option
                          : option.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selected = $$c
                  }
                },
                function ($event) {
                  return _vm.change($event, option)
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.generateInputId(option.value) } }, [
            _vm._v(_vm._s(_vm.$t.get(option.label))),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }