import Translate from "../../helpers/translate"

export default {
  install(Vue) {
    // Object.defineProperties(Vue.prototype, {
    //   $t: Translate
    // })
    Object.defineProperty(Vue.prototype, "$t", {
      value: Translate,
      writable: false,
    })

    Object.defineProperty(Vue.prototype, "$locale", {
      value: window.__LOCALE,
      writable: false,
    })
  },
}
