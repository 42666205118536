//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Translate from "../../../helpers/translate"

export default {
  props: {
    text: {
      default: Translate.get("common.download-mobil-app-text"),
    },
  },
  data() {
    return {}
  },
  mounted() {},
}
