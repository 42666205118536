//
//
//
//
//
//

import ActionMixin from "./ActionMixin"
import Modal from "../../../../../plugins/modal/modal"

export default {
  mixins: [ActionMixin],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    jwt: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: "cloud-manager.medical-cloud",
    },
    modalHeader: {
      type: String,
      default: "cloud-manager.medical-cloud",
    },
    allowFormats: {
      type: Array,
      default: function () {
        return ["jpg", "jpeg", "png", "pdf", "dcm", "mri"]
      },
    },
    showFolders: { type: Boolean, default: true },
    showActions: { type: Boolean, default: true },
  },
  methods: {
    openMedicalCloud() {
      Modal.create(this)
        .title(this.$t.get(this.modalHeader))
        .component(() => import("../../../../cloud-manager/CloudManager.vue"))
        .classes(["modal-cloud-manager"])
        .closeable(false)
        .props({
          jwt: this.jwt,
          showFolders: this.showFolders,
          showActions: this.showActions,
          allowFormats: this.allowFormats,
          hideAttachButton: true,
        })
        .show()
    },
  },
}
