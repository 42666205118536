
import { Line, mixins } from "vue-chartjs"

const { reactiveProp } = mixins

export default {
  extends: Line,
  props: {
    options: { type: Object },
    gradientColor: { default: null }, // pass hex color to get gradient
  },
  mixins: [reactiveProp],
  data() {
    return {
      gradient: null,
    }
  },
  mounted() {
    if (this.gradientColor !== null) {
      this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 250)

      let r = parseInt(this.gradientColor.substring(1, 3), 16)
      let g = parseInt(this.gradientColor.substring(3, 5), 16)
      let b = parseInt(this.gradientColor.substring(5, 7), 16)
      this.gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.5)`)
      this.gradient.addColorStop(0.5, `rgba(${r}, ${g}, ${b}, 0.25)`)
      this.gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`)

      this.renderChart(
        {
          datasets: [
            {
              ...this.chartData.datasets[0],
              backgroundColor: this.gradient,
            },
          ],
          labels: this.chartData.labels,
        },
        this.options
      )
    } else {
      this.renderChart(this.chartData, this.options)
    }
  },
}
