var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-two-factor-auth",
      class: { "preloader preloader-cover": _vm.isProcessing },
    },
    [
      _c("div", { staticClass: "w-full lg:w-full header" }, [
        _c("p", [
          _vm._v(_vm._s(_vm.$t.get("auth.two-factor-auth-account-secured"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.sendMethods.length > 1
        ? _c("div", { staticClass: "w-full lg:w-full" }, [
            _c(
              "div",
              { staticClass: "card-group" },
              _vm._l(_vm.sendMethods, function (method) {
                return _c(
                  "div",
                  {
                    staticClass: "card clickable hoverable",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.generateCode(method)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "card-description" }, [
                      _c("div", { staticClass: "icon fa", class: method.icon }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-label" }, [
                      _vm._v(_vm._s(method.label)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-full hint" }, [
        _vm.hint ? _c("div", [_vm._v(_vm._s(_vm.hint))]) : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-full lg:w-full" }, [
        _vm.isGenerated
          ? _c("form", { staticClass: "material-form" }, [
              _c("div", { staticClass: "input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputs.code,
                      expression: "inputs.code",
                    },
                  ],
                  attrs: { type: "text", name: "code", id: "code" },
                  domProps: { value: _vm.inputs.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.inputs, "code", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "code" } }, [
                  _vm._v(_vm._s(_vm.$t.get("auth.two-factor-code"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input resend" }, [
                _vm.lastUsedMethod
                  ? _c(
                      "a",
                      {
                        attrs: {
                          title: _vm.$t.get("auth.two-factor-resend-code"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.generateAgain.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t.get("auth.two-factor-resend-code"))
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "form-error" }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button accent",
                  attrs: { disabled: _vm.isProcessing },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.inputCode.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("common.authenticate")) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }