var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "viewer", class: { attached: _vm.attachTo !== null } },
    [
      _c(
        "div",
        { staticClass: "tools" },
        [
          _vm.options.tools
            ? _c("tools", { staticClass: "dataview-tools" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "viewer-tools" }, [
            _c("ul", { staticClass: "right" }, [
              _vm.options.tools
                ? _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.switchLayoutHide,
                          expression: "switchLayoutHide",
                        },
                      ],
                      staticClass: "viewer-tools-switch-layout",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.switchLayoutToggle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "ict-switch-layout" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t.get("viewer.switch-layout"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.switchLayoutVisible,
                              expression: "switchLayoutVisible",
                            },
                          ],
                          staticClass: "drop-down",
                        },
                        [
                          _c(
                            "li",
                            {
                              class: { active: _vm.views.length === 1 },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    1,
                                    "row",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-1x1" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("1x1")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                active:
                                  _vm.views.length === 2 &&
                                  _vm.orientation == "row",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    2,
                                    "row",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-2x1" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("1x2")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                active:
                                  _vm.views.length === 2 &&
                                  _vm.orientation == "column",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    2,
                                    "column",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-1x2" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("2x1")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: { active: _vm.views.length === 4 },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    4,
                                    "row",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-2x2" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("2x2")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                active:
                                  _vm.views.length === 6 &&
                                  _vm.orientation == "row",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    6,
                                    "row",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-3x2" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("2x3")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                active:
                                  _vm.views.length === 6 &&
                                  _vm.orientation == "column",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.switchLayout(
                                    6,
                                    "column",
                                    _vm.MutationSource.USER
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ict-grid-2x3" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("3x2")]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.options.closeable && _vm.attachTo === null
                ? _c("li", { on: { click: _vm.close } }, [
                    _c("i", { staticClass: "ict-close" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t.get("viewer.close")))]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.data === null ? _c("div", { staticClass: "preloader" }) : _vm._e(),
        _vm._v(" "),
        _c("div", { ref: "viewsPanel", staticClass: "views-panel" }, [
          _c(
            "div",
            { staticClass: "bootstrap-views" },
            _vm._l(_vm.views, function (view, index) {
              return _c(view, {
                key: index,
                ref: "viewsInstances",
                refInFor: true,
                tag: "component",
                style: _vm.gridFlex,
                attrs: { id: index },
                on: {
                  "view-mounted": _vm.viewMounted,
                  "view-destroyed": _vm.viewDestroyed,
                  "view-data-changed": _vm.viewDataChanged,
                },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _vm.options.dataList
          ? _c(
              "div",
              { staticClass: "data-panel" },
              [
                _vm._t("duration"),
                _vm._v(" "),
                _vm.data !== null
                  ? _c("data-list", {
                      attrs: {
                        data: _vm.data,
                        "selected-items": _vm.selectedItems,
                      },
                      on: {
                        "item-dragged": _vm.itemDragged,
                        "item-clicked": _vm.itemClicked,
                      },
                    })
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }