import AbstractTool from "./AbstractTool"
import DataType from "../../data/DataType"
import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"

export default class ClearDraws extends AbstractTool {
  get name() {
    return Translate.get("viewer.view-actions-copy")
  }

  get icon() {
    return "ict-copy"
  }

  get type() {
    return ToolType.CLICK
  }

  bind(viewerInstance, views) {}

  click(views) {
    const view = views[0]

    if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
      const dataView = view.$refs.dataView
      const cornerstoneElement = dataView.$refs.view
      const canvas = cornerstoneElement.querySelector("canvas")
      const dataURL = canvas.toDataURL()

      let img = document.createElement("img")
      img.src = dataURL
      let div = document.createElement("div")

      let select = (element) => {
        if (document.body.createTextRange) {
          let range = document.body.createTextRange()
          range.moveToElementText(element)
          range.select()
          return true
        } else if (window.getSelection) {
          let selection = window.getSelection()
          let range = document.createRange()
          range.selectNodeContents(element)
          selection.removeAllRanges()
          selection.addRange(range)
          return true
        } else {
          return false
        }
      }

      div.contentEditable = true
      div.appendChild(img)
      document.body.appendChild(div)

      if (select(div) === false) {
        view.$bridge.emit(
          "addToast",
          Translate.get("appointment.an-error-has-occurred-image-can-not-be-copied"),
          "error"
        )
      }
      document.execCommand("Copy")
      document.body.removeChild(div)
      view.$bridge.emit("addToast", Translate.get("viewer.image-has-been-copied"), "success")
    }
  }
}
