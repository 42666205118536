export default {
  install(Vue, options) {
    var Movable = /** @class */ (function () {
      function Movable() {
        this.startX = 0
        this.startY = 0
        this.dragging = false
        this.el = null
      }
      Movable.bind = function (el) {
        var instance = new Movable()
        instance.el = el
        instance.el.onmousedown = function (e) {
          instance.mouseDown.call(instance, e)
        }
      }
      Movable.prototype.mouseDown = function (event) {
        var _this = this
        this.dragging = true
        var computedStyles = window.getComputedStyle(this.el)
        var left = computedStyles.left ? parseInt(computedStyles.left) : 0
        var top = computedStyles.top ? parseInt(computedStyles.top) : 0
        this.startX = event.pageX - left
        this.startY = event.pageY - top
        document.onmousemove = function (e) {
          _this.mouseMove.call(_this, e)
        }
        document.onmouseup = function (e) {
          _this.mouseUp.call(_this, e)
        }
      }
      Movable.prototype.mouseMove = function (event) {
        this.el.style.left = (event.pageX - this.startX > 0 ? event.pageX - this.startX : 0) + "px"
        this.el.style.top = (event.pageY - this.startY > 0 ? event.pageY - this.startY : 0) + "px"
      }
      Movable.prototype.mouseUp = function (event) {
        document.onmousemove = null
        document.onmouseup = null
        this.dragging = false
      }
      return Movable
    })()

    const bind = (el, binding) => {
      if (binding.value) {
        Movable.bind(el)
      } else {
        Movable.unbind(el)
      }
    }

    Vue.directive("draggable", {
      bind: Movable.bind,
      update: Movable.bind,
    })
  },
}
