//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Quill from "quill"
import QuillCloudImage from "./QuillCloudImage.js"
import CloudClipboard from "./CloudClipboard.js"

import { debounce } from "lodash"

Quill.register(QuillCloudImage)
Quill.register("modules/clipboard", CloudClipboard, true)

export default {
  props: {
    objectId: { default: null },
    canAttach: { default: false },
  },
  data() {
    return {
      message: "",
      noteSaving: false,
      imageInserting: false,
      viewerPath: null,
    }
  },
  computed: {
    updated_at() {
      return this.$store.state.note.updated_at
    },
    stored_message() {
      return this.$store.state.note.message
    },
  },
  watch: {
    stored_message: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== this.message && newVal !== null) this.message = newVal
      },
    },
  },
  mounted() {
    let $this = this

    this.viewerPath = this.$parent.$parent.$refs["viewer"]
    this.$refs.quill.quill.on("text-change", function (delta, oldDelta, source) {
      if (source == "cloud") {
        $this.imageInserting = false
      }
    })
  },
  methods: {
    saveNote: debounce(function (message) {
      this.noteSaving = true
      this.$store.dispatch("note/sendNote", message).then(
        () => {
          this.noteSaving = false
          this.sendingMessage = false
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.sendingMessage = false
        }
      )
    }, 5000),
  },
}
