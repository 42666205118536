//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    download() {
      window.location.href = "/account/settings/data-portability/feedbacks"
    },
  },
}
