//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"
import RxTime from "../../plugins/RxTime"

export default {
  mixins: [RxTime.mixin(), Customize()],
  props: {
    term: {
      required: true,
    },
    attachedTo: {
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      updater: null,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler(visible) {
        if (visible) {
          this.updater = setInterval(this.bindPositions.bind(this), 38)
        } else {
          clearInterval(this.updater)
          this.updater = null
        }
      },
    },
  },
  mounted() {
    let unwatch = this.$watch("attachedTo", (attachedTo) => {
      if (attachedTo !== void 0) {
        attachedTo[0].addEventListener("mouseenter", () => this.setVisible(true))
        attachedTo[0].addEventListener("mouseleave", () => this.setVisible(false))

        unwatch()
      }
    })
  },
  methods: {
    setVisible(state) {
      this.visible = state
    },
    bindPositions() {
      if (this.attachedTo !== void 0) {
        let left
        let top

        if (this.attachedTo[0] !== void 0) {
          const position = this.attachedTo[0].getBoundingClientRect()

          left = position.left + this.attachedTo[0].offsetWidth / 2 - this.$el.offsetWidth / 2
          top = position.top - this.$el.offsetHeight - 10

          left = Math.round(left)
          top = Math.round(top)

          this.$el.style.left = left + "px"
          this.$el.style.top = top + "px"
        }
      }
    },
  },
}
