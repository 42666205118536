//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal.js"

import { Permissions } from "../../models/Permissions"
import RxTime from "../../plugins/RxTime"

import TermTooltip from "./TermTooltip.vue"
import TimezoneChoice from "../common/TimezoneChoice.vue"

export default {
  mixins: [RxTime.mixin()],
  components: {
    "term-tooltip": TermTooltip,
  },
  props: {
    displayDays: {
      default: 7,
    },
    userId: {
      default: null,
    },
    timeZone: {
      type: String,
    },
    appointmentId: {
      default: null,
      required: false,
    },
    inModal: {
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      processingFilters: false,
      date: this.$moment().startOf("isoWeek"),
      group: window._GROUP,
      members: null,
      terms: [],
      termsFilterMember: null,
      tooltips: {},
      createTermModal: null,
    }
  },
  sockets: {
    appointment: function () {
      this.getAvailableTerms()
    },
    dashboardCalendarUpdate: function () {
      if (this.createTermModal !== null && this.createTermModal?.options?.data?.opened === true) {
        return
      }
      this.getAvailableTerms()
    },
  },
  computed: {
    timeZoneLabel() {
      return this.timeZone || "no timezone set"
    },
    user() {
      return this.$store.state.user
    },
    appointment() {
      return this.$store.state.appointment
    },
    startDate() {
      return this.$moment(this.date)
        .set({ hour: 0, minute: 0 })
        .locale("en")
        .format("YYYY-MM-DD[T]HH:mmZ")
    },
    endDate() {
      return this.$moment(this.date)
        .set({ hour: 23, minute: 59 })
        .add(this.displayDays - 1, "days")
        .locale("en")
        .format("YYYY-MM-DD[T]HH:mmZ")
    },
    canManageTimeSlots() {
      return (
        this.group !== void 0 &&
        (this.hasGroupPermission(
          this.group.group_id,
          Permissions.GROUP_GLOBAL_REQUESTS_MANAGE_SLOTS
        ) ||
          this.hasGroupPermission(this.group.group_id, Permissions.GROUP_GLOBAL_REQUESTS_RECEIVE))
      )
    },
    canManageGroupTimeSlots() {
      return (
        this.group !== void 0 &&
        this.hasGroupPermission(this.group.group_id, Permissions.GROUP_GLOBAL_REQUESTS_MANAGE_SLOTS)
      )
    },
    hasGroupPermission() {
      return this.$store.getters["user/hasGroupPermission"]
    },
    groupMembersFilter() {
      if (this.members !== null) {
        return this.members.filter((member) => {
          return (
            member.permissions.findIndex(
              (permission) => permission.name === Permissions.GROUP_GLOBAL_REQUESTS_RECEIVE
            ) > -1
          )
        })
      }

      return []
    },
    termsFilterUserId() {
      if (this.userId !== null) {
        return this.userId
      }

      if (this.canManageGroupTimeSlots === false) {
        return this.user.user_id
      }

      if (this.termsFilterMember !== null) {
        return this.termsFilterMember.user_id
      }

      return undefined
    },
  },
  watch: {
    termsFilterMember(value) {
      this.getAvailableTerms()
    },
  },
  mounted() {
    this.getAvailableTerms()

    if (this.userId === null) {
      this.getMembers()
    }
  },
  methods: {
    getMembers() {
      this.processingFilters = true
      this.$http.get("/ajax/organizations/" + this.group.group_id + "/members").then(
        (response) => {
          this.processingFilters = false
          this.members = response.data.data
        },
        (error) => {}
      )
    },
    getAvailableTerms() {
      if (this.processing === true) {
        return
      }

      this.processing = true
      this.$http
        .get("/ajax/organizations/" + this.group.group_id + "/appointments/terms", {
          params: {
            date_start: this.startDate,
            date_end: this.endDate,
            user_id: this.termsFilterUserId,
          },
        })
        .then(
          (response) => {
            this.terms = response.data.data
            this.processing = false
          },
          (error) => {
            this.processing = false
          }
        )
    },
    nextWeek() {
      this.date = this.$moment(this.date).add(this.displayDays, "days").set({ hour: 0, minute: 0 })

      this.getAvailableTerms()
    },
    prevWeek() {
      this.date = this.$moment(this.date)
        .subtract(this.displayDays, "days")
        .set({ hour: 0, minute: 0 })

      this.getAvailableTerms()
    },
    createTerm(date) {
      this.createTermModal = Modal.create(this)
        .title(this.$t.get("appointment.add-a-time-slot"))
        .component(() =>
          import(/* webpackChunkName: "term-modals" */ "../appointment/TermPicker.vue")
        )
        .props({
          initialDate: this.$moment(date).toDate(),
          assignedId: this.userId,
          appointmentId: this.appointmentId,
        })
        .events({
          success: () => {
            this.getAvailableTerms()
          },
        })

      this.createTermModal.show()
    },
    infoTerm(term) {
      Modal.create(this)
        .title(this.$t.get("common.time-slot-details"))
        .component(() => import(/* webpackChunkName: "term-modals" */ "./TermInfo.vue"))
        .props({
          term: term,
        })
        .events({
          success: () => {
            this.getAvailableTerms()
          },
        })
        .show()
    },
    removeSlot(term) {
      Modal.confirm(this)
        .title(this.$t.get("common.remove-time-slot"))
        .content(
          this.$t.get("common.are-you-sure-to-remove-slot-at-date-date", {
            date: this.$moment(term.start).format("DD MMM, hh:mm A"),
          })
        )
        .positive(this.$t.get("appointment.yes"), function (modal) {
          if (this.group === void 0 || this.canManageTimeSlots === false) {
            return false
          }
          return this.$http
            .delete(`/ajax/organizations/${this.group.group_id}/appointments/terms/` + term.id)
            .then(
              (response) => {
                this.getAvailableTerms()
              },
              (error) => {
                this.$bridge.emit("addToast", error.response.message, "error")
                this.processing = false
              }
            )
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
    changeTimezone() {
      Modal.create(this)
        .title(this.$t.get("common.timezone"))
        .component(TimezoneChoice)
        .props({
          description: this.$t.get("appointment.timezone-please-select"),
          timeZone: { label: this.user.time_zone, value: this.user.time_zone },
        })
        .show()
    },
  },
}
