//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import braintree from "braintree-web"
import braintreeMixin from "./braintree"
import Customize from "../../../plugins/Customize"

export default {
  mixins: [braintreeMixin, Customize()],
  props: {
    paymentClient: {
      required: true,
    },
    threeDSecureInstance: {
      required: true,
    },
    payment: {
      required: true,
    },
    method: {
      required: true,
    },
  },
  data() {
    return {
      paypalClient: null,
      creditCardClient: null,
      creditCardType: "",
    }
  },
  computed: {
    amountToPay() {
      if (this.payment === null) return ""
      if (typeof this.payment !== typeof undefined)
        return this.payment.amount_brutto + " " + this.payment.currency
    },
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    paymentClient: {
      immediate: true,
      handler() {
        if (this.paymentClient !== null) {
          this.initCreditCardMethod(this.paymentClient)
        }
      },
    },
  },
  methods: {
    initCreditCardMethod(client) {
      this.creditCardType = ""
      braintree.hostedFields.create(
        {
          client: client,
          styles: {
            input: {
              "font-size": "14px",
              color: "#3A3A3A",
            },
          },
          fields: {
            number: {
              selector: "#card-number",
              placeholder: "4111 1111 1111 1111",
            },
            cvv: {
              selector: "#cvv",
              placeholder: "123",
            },
            expirationDate: {
              selector: "#expiration-date",
              placeholder: "MM/YYYY",
            },
          },
        },
        (hostedFieldsErr, hostedFieldsInstance) => {
          console.log("braintree.hostedFields.create", hostedFieldsErr, hostedFieldsInstance)
          if (hostedFieldsErr) {
            this.throwRavenException("CreditCardCreate: ", hostedFieldsErr)
            this.$bridge.emit(
              "addToast",
              this.$t.get("appointment.payment-method-disabled", { method: "CreditCard" }),
              "error"
            )
            return
          }
          this.creditCardClient = hostedFieldsInstance
          this.creditCardClient.on("cardTypeChange", (event) => {
            this.creditCardClient.setAttribute({
              field: "cvv",
              attribute: "placeholder",
              value: "123",
            })
            this.creditCardType = ""
            if (event.cards.length === 1) {
              this.creditCardType = event.cards[0].type

              // Change the CVV length for AmericanExpress cards
              if (event.cards[0].code.size === 4) {
                this.creditCardClient.setAttribute({
                  field: "cvv",
                  attribute: "placeholder",
                  value: "1234",
                })
              }
            }
          })
        }
      )
    },
    payCreditCard() {
      console.info("CreditCard submit")
      let formValid = true
      let invalidClass = "braintree-hosted-fields-invalid"
      let state = this.creditCardClient.getState()

      Object.keys(state.fields).every(function (key) {
        let element = state.fields[key].container
        if (state.fields[key].isValid) {
          element.className = element.className.replace(
            new RegExp("\\s*" + invalidClass + "\\s*", "g"),
            ""
          )
        } else {
          element.className = element.className + " " + invalidClass
        }

        formValid = !state.fields[key].isValid ? false : formValid
        return true
      })
      if (!formValid) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("appointment.payment-method-fields-form-invalid"),
          "error"
        )
        return
      }

      this.loading = true
      this.creditCardClient.tokenize((tokenizeErr, payload) => {
        if (tokenizeErr) {
          let toast = ""
          switch (tokenizeErr.code) {
            case "HOSTED_FIELDS_FIELDS_EMPTY":
              toast = this.$t.get("appointment.payment-method-hosted-fields-fields-empty")
              break
            case "HOSTED_FIELDS_FIELDS_INVALID":
              toast = this.$t.get("appointment.payment-method-hosted-fields-fields-invalid")
              break
            case "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE":
              toast = this.$t.get(
                "appointment.payment-method-hosted-fields-tokenization-fail-on-duplicate"
              )
              break
            case "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED":
              toast = this.$t.get(
                "appointment.payment-method-hosted-fields-tokenization-cvv-verification-failed"
              )
              break
            case "HOSTED_FIELDS_FAILED_TOKENIZATION":
              toast = this.$t.get("appointment.payment-method-hosted-fields-failed-tokenization")
              break
            case "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR":
              toast = this.$t.get(
                "appointment.payment-method-hosted-fields-tokenization-network-error"
              )
              break
            default:
              toast = this.$t.get("appointment.payment-method-form-other-error")
          }

          this.$bridge.emit("addToast", toast, "error")
          this.sendLogs("CreditCardTokenize", null, payload, tokenizeErr)
          this.loading = false
          return
        }
        // 3DSecure
        // https://developers.braintreepayments.com/guides/3d-secure/migration/javascript/v3
        this.threeDSecureInstance.verifyCard(
          {
            amount: this.payment.amount_brutto,
            bin: payload.binData,
            nonce: payload.nonce,
            email: this.user.email,
            billingAddress: {
              givenName: window.__USER_NAME_ASCII,
              surname: window.__USER_SURNAME_ASCII,
              phoneNumber: this.user.phone || null,
              countryCodeAlpha2: this.getCountryCode,
            },
            // additionalInformation: {} todo: fill if available
            onLookupComplete: (data, next) => {
              next()
            },
          },
          (verifyError, threeDSresponse) => {
            if (verifyError) {
              const toast = this.$t.get("appointment.payment-method-3dsecure-failed")
              this.$bridge.emit("addToast", toast, "error")
              this.sendLogs("CreditCardVerify", null, threeDSresponse, verifyError)
              this.loading = false
              this.step = this.initialStep
              this.initClientPayment()
              return
            }
            const success =
              (threeDSresponse.liabilityShiftPossible === false &&
                threeDSresponse.liabilityShifted === false) ||
              (threeDSresponse.liabilityShiftPossible === true &&
                threeDSresponse.liabilityShifted === true)
            if (success) {
              this.confirmPayment(this.method, this.payment, threeDSresponse, verifyError)
              return
            }
            const toast = this.$t.get("appointment.payment-method-3dsecure-failed")
            this.$bridge.emit("addToast", toast, "error")
            this.sendLogs("CreditCardVerify", null, threeDSresponse, verifyError)
            this.loading = false
            this.step = this.initialStep
            this.initClientPayment()
          }
        )
      })
    },
    cancelPay() {
      this.$emit("cancel-pay")
    },
  },
}
