var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      [
        _vm._l(_vm.toolsList, function (tool, key) {
          return [
            tool.type == _vm.ToolType.SWITCH
              ? _c(
                  "li",
                  {
                    key: key,
                    class: {
                      active: _vm.current == key,
                      "hidden lg:block": tool.mobile === false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toolSwitch(key, tool)
                      },
                    },
                  },
                  [
                    _c("i", { class: [tool.icon] }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(tool.name))]),
                    _vm._v(" "),
                    _vm.current == key && tool.actionsComponent !== null
                      ? _c(tool.actionsComponent, { tag: "component" })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            tool.type == _vm.ToolType.TOGGLE
              ? _c(
                  "li",
                  {
                    key: key,
                    class: {
                      active: tool.state,
                      "hidden lg:block": tool.mobile === false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toolToggle(key, tool)
                      },
                    },
                  },
                  [
                    _c("i", { class: [tool.icon] }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(tool.name))]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            tool.type == _vm.ToolType.CLICK
              ? _c(
                  "li",
                  {
                    key: key,
                    class: { "hidden lg:block": tool.mobile === false },
                    on: {
                      click: function ($event) {
                        return _vm.toolClick(key, tool)
                      },
                    },
                  },
                  [
                    _c("i", { class: [tool.icon] }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(tool.name))]),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }