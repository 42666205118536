import axios from "axios"
import Translate from "../../../../helpers/translate"

export default class AppointmentLoader {
  static isAsync() {
    return true
  }

  static load(objectId) {
    return axios
      .get(window.__CLOUD_URL + "/objects/" + objectId + "/stacks", {
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + window.USER_JWT,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(Translate.get("viewer.cant-load-appointment-data"))
      })
  }
}
