//
//
//
//
//
//
//
//
//

import ActionMixin from "./ActionMixin"
import { Constants } from "../../../../../models/Constants"

export default {
  mixins: [ActionMixin],
  props: {
    setupAppointmentPageUrl: { type: String },
  },
  data() {
    return {
      statuses: Constants.GroupCustomer,
    }
  },
  computed: {
    customerActive() {
      if (this.customer === null || this.customer.hasOwnProperty("status") === false) {
        return false
      }
      return (
        this.customer.status == this.statuses.STATUS_ACTIVE || this.customer.status === "Active"
      )
    },
  },
  methods: {
    buildSetupAppointmentPageUrl() {
      if (this.customerActive === false) {
        return null
      }
      return (
        this.setupAppointmentPageUrl +
        "?customerId=" +
        (this.customer.group_customer_id || this.customer.tdCustomerId)
      )
    },
  },
}
