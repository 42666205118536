//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      state: "init", // [init, sending, done]
    }
  },
}
