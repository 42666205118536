var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.results, function (provider) {
      return _c(
        _vm.getSearchResultComponent(provider.component),
        _vm._g(
          _vm._b(
            {
              key: provider.provider,
              tag: "component",
              staticClass: "provider-results",
              attrs: {
                results: _vm.getResultsForProvider(
                  provider.provider || "local"
                ),
              },
            },
            "component",
            _vm.$props,
            false
          ),
          _vm.$listeners
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }