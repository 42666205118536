import axios from "axios"

import Modal from "../plugins/modal/modal"
import Translate from "../../helpers/translate"

const http = axios.create({
  mode: "no-cors",
  withCredentials: true,
  credentials: "same-origin",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
})

export default {
  install(Vue) {
    http.interceptors.request.use(
      (config) => {
        config.headers.common["X-CSRF-TOKEN"] = window.TD_APP.csrfToken
        config.headers.common["X-TIMEZONE"] = moment.tz.guess()
        config.withCredentials = true

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 419) {
          Modal.confirm(null)
            .title(Translate.get("common.session-expired"))
            .content(Translate.get("common.session-expired-refresh"))
            .positive(Translate.get("common.refresh-page"), () => window.location.reload(true))
            .show()

          error.response.data.message = Translate.get("common.session-expired")

          return Promise.reject(error)
        }

        return Promise.reject(error)
      }
    )

    Vue.http = http

    Object.defineProperty(Vue.prototype, "$http", {
      value: http,
      writable: false,
    })
  },
}

export { http }
