var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reserve-appointment term-component" }, [
    _c("div", { staticClass: "reserve-appointment" }, [
      _vm.step == 0
        ? _c("div", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              {
                staticClass: "call-appointment-grid",
                attrs: { "data-type": "online", "data-doctor-id": "" },
              },
              [
                _c("div", { staticClass: "controls" }, [
                  _c("div", { staticClass: "control-prev" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color:ict-angle-left",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color:ict-angle-left",
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:color:ict-angle-left.hover",
                            value: _vm.palette.colors.primary.alt,
                            expression: "palette.colors.primary.alt",
                            arg: "color:ict-angle-left",
                            modifiers: { hover: true },
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:border-color.hover",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "border-color",
                            modifiers: { hover: true },
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:background.hover",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "background",
                            modifiers: { hover: true },
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.prevWeek.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "ict-angle-left" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.$moment(_vm.startDate).get("month") ===
                  _vm.$moment(_vm.endDate).get("month")
                    ? _c("div", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$momentUtils.format(_vm.startDate, "DD")
                            ) +
                            " -\n            " +
                            _vm._s(_vm.$momentUtils.format(_vm.endDate, "DD")) +
                            "\n            " +
                            _vm._s(
                              _vm.$momentUtils.format(
                                _vm.startDate,
                                "MMMM YYYY"
                              )
                            ) +
                            "\n          "
                        ),
                      ])
                    : _c("div", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$momentUtils.format(_vm.startDate, "DD")
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm.$momentUtils.format(_vm.startDate, "MMMM")
                            ) +
                            " -\n            " +
                            _vm._s(_vm.$momentUtils.format(_vm.endDate, "DD")) +
                            "\n            " +
                            _vm._s(
                              _vm.$momentUtils.format(_vm.endDate, "MMMM YYYY")
                            ) +
                            "\n          "
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control-next" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color:ict-angle-right",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color:ict-angle-right",
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:color:ict-angle-right.hover",
                            value: _vm.palette.colors.primary.alt,
                            expression: "palette.colors.primary.alt",
                            arg: "color:ict-angle-right",
                            modifiers: { hover: true },
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:border-color.hover",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "border-color",
                            modifiers: { hover: true },
                          },
                          {
                            name: "styled",
                            rawName: "v-styled:background.hover",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "background",
                            modifiers: { hover: true },
                          },
                        ],
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.nextWeek.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "ict-angle-right" })]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "days-scroll",
                    class: { "preloader preloader-cover": _vm.loading },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "days" },
                      [
                        _vm._l(_vm.availableTerms, function (day, dayString) {
                          return _c(
                            "div",
                            { key: dayString, staticClass: "col" },
                            [
                              _c("div", { staticClass: "day" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$momentUtils.format(dayString, "D")
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$momentUtils.format(dayString, "ddd")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              !_vm.loading
                                ? _c(
                                    "div",
                                    { staticClass: "hours" },
                                    _vm._l(day, function (hour) {
                                      return _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:border-color:hour",
                                              value:
                                                _vm.palette.colors.primary.main,
                                              expression:
                                                "palette.colors.primary.main",
                                              arg: "border-color:hour",
                                            },
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:background:hour.hover",
                                              value:
                                                _vm.palette.colors.primary.main,
                                              expression:
                                                "palette.colors.primary.main",
                                              arg: "background:hour",
                                              modifiers: { hover: true },
                                            },
                                            {
                                              name: "styled",
                                              rawName: "v-styled:color:time",
                                              value:
                                                _vm.palette.colors.primary.main,
                                              expression:
                                                "palette.colors.primary.main",
                                              arg: "color:time",
                                            },
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:color:time.hover",
                                              value:
                                                _vm.palette.colors.primary.alt,
                                              expression:
                                                "palette.colors.primary.alt",
                                              arg: "color:time",
                                              modifiers: { hover: true },
                                            },
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:background:selected",
                                              value:
                                                _vm.palette.colors.primary.main,
                                              expression:
                                                "palette.colors.primary.main",
                                              arg: "background:selected",
                                            },
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:color:selected:time",
                                              value:
                                                _vm.palette.colors.primary.alt,
                                              expression:
                                                "palette.colors.primary.alt",
                                              arg: "color:selected:time",
                                            },
                                          ],
                                          key: hour.id,
                                          ref: "slot-" + hour.id,
                                          refInFor: true,
                                        },
                                        [
                                          hour !== null && hour !== void 0
                                            ? [
                                                _c("term-tooltip", {
                                                  attrs: {
                                                    term: hour,
                                                    "attached-to":
                                                      _vm.$refs[
                                                        "slot-" + hour.id
                                                      ],
                                                  },
                                                }),
                                                _vm._v(" "),
                                                hour.status === 0
                                                  ? [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: _vm.picked,
                                                            expression:
                                                              "picked",
                                                          },
                                                        ],
                                                        attrs: {
                                                          name: "appointment",
                                                          type: "radio",
                                                          id:
                                                            dayString +
                                                            "T" +
                                                            _vm.$momentUtils.format(
                                                              hour.start,
                                                              "HH:mm"
                                                            ),
                                                          disabled:
                                                            _vm
                                                              .$moment(
                                                                hour.start
                                                              )
                                                              .diff(_vm.now) <
                                                            -900000,
                                                        },
                                                        domProps: {
                                                          value: hour.id,
                                                          checked: _vm._q(
                                                            _vm.picked,
                                                            hour.id
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.picked = hour.id
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "hour free",
                                                          class: {
                                                            disabled:
                                                              _vm
                                                                .$moment(
                                                                  hour.start
                                                                )
                                                                .diff(_vm.now) <
                                                              -900000,
                                                            selected:
                                                              hour.id ===
                                                              _vm.picked,
                                                          },
                                                          attrs: {
                                                            for:
                                                              dayString +
                                                              "T" +
                                                              _vm.$momentUtils.format(
                                                                hour.start,
                                                                "HH:mm"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "time",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$dateTimeFormat.formatTime(
                                                                      hour.start
                                                                    )
                                                                  ) +
                                                                  " -\n                          " +
                                                                  _vm._s(
                                                                    _vm.$dateTimeFormat.formatTime(
                                                                      hour.end
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm
                                                            .$moment(hour.start)
                                                            .diff(_vm.now) <
                                                          -900000
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "meta",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t.get(
                                                                          "common.unavailable-to-book"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm
                                                            .$moment(hour.start)
                                                            .diff(_vm.now) >=
                                                          -900000
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "meta",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t.get(
                                                                          "common.available-to-book"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm
                                                            .$moment(hour.start)
                                                            .diff(_vm.now) >=
                                                            -900000 &&
                                                          hour.appointment_id !==
                                                            null
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "meta recommended",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t.get(
                                                                          "common.recommended"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  : hour.status === 1
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "hour busy disabled",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "time",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$momentUtils.format(
                                                                      hour.start,
                                                                      "HH:mm"
                                                                    )
                                                                  ) +
                                                                  " -\n                          " +
                                                                  _vm._s(
                                                                    _vm.$momentUtils.format(
                                                                      hour.end,
                                                                      "HH:mm"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "meta",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  hour.patient_name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        !_vm.haveAvailableTerms && !_vm.loading
                          ? _c("div", { staticClass: "no-terms" }, [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t.get(
                                        "appointment.there-is-no-available-slots"
                                      )
                                    ) +
                                    "\n                "
                                ),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color",
                                        value: _vm.palette.colors.primary.alt,
                                        expression:
                                          "palette.colors.primary.alt",
                                        arg: "color",
                                      },
                                      {
                                        name: "styled",
                                        rawName: "v-styled:background",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "background",
                                      },
                                      {
                                        name: "styled",
                                        rawName: "v-styled:border-color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "border-color",
                                      },
                                      {
                                        name: "styled",
                                        rawName: "v-styled:background.hover",
                                        value: _vm.palette.colors.hover.alt,
                                        expression: "palette.colors.hover.alt",
                                        arg: "background",
                                        modifiers: { hover: true },
                                      },
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color.hover",
                                        value: _vm.palette.colors.hover.main,
                                        expression: "palette.colors.hover.main",
                                        arg: "color",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "button small accent",
                                    attrs: {
                                      href:
                                        "/appointment/" +
                                        _vm.appointment.appointment_id +
                                        "/messages",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t.get("appointment.send-message")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "selected-term" },
              [
                _vm.pickedDate !== null && _vm.pickedDate !== void 0
                  ? [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t.get("appointment.selected-date")) +
                            "\n            "
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "color",
                              },
                            ],
                            staticClass: "accent",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$dateTimeFormat.formatDate(
                                    _vm.pickedDate.start
                                  )
                                ) +
                                ",\n              " +
                                _vm._s(
                                  _vm.$dateTimeFormat.formatTime(
                                    _vm.pickedDate.start
                                  )
                                ) +
                                " -\n              " +
                                _vm._s(
                                  _vm.$dateTimeFormat.formatTime(
                                    _vm.pickedDate.end
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.pickedDate.price
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "appointment.price-for-selected-date"
                                  )
                                ) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "color",
                                  },
                                ],
                                staticClass: "accent",
                              },
                              [_vm._v(_vm._s(_vm.pickedDate.price))]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-list" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color",
                      value: _vm.palette.colors.primary.alt,
                      expression: "palette.colors.primary.alt",
                      arg: "color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "background",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:border-color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "border-color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background.hover",
                      value: _vm.palette.colors.hover.alt,
                      expression: "palette.colors.hover.alt",
                      arg: "background",
                      modifiers: { hover: true },
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:color.hover",
                      value: _vm.palette.colors.hover.main,
                      expression: "palette.colors.hover.main",
                      arg: "color",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "button accent",
                  attrs: { disabled: !_vm.haveSelectedTerm },
                  on: { click: _vm.reserveTerm },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t.get("appointment.book-selected-date")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color",
                      value: _vm.palette.colors.secondary.main,
                      expression: "palette.colors.secondary.main",
                      arg: "color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background",
                      value: _vm.palette.colors.primary.alt,
                      expression: "palette.colors.primary.alt",
                      arg: "background",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:border-color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "border-color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background.hover",
                      value: _vm.palette.colors.hover.main,
                      expression: "palette.colors.hover.main",
                      arg: "background",
                      modifiers: { hover: true },
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:color.hover",
                      value: _vm.palette.colors.hover.alt,
                      expression: "palette.colors.hover.alt",
                      arg: "color",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "button border",
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t.get("common.cancel")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ])
        : _vm.step == 1
        ? _c(
            "div",
            {
              staticClass: "form-horizontal payment-form",
              class: { "preloader preloader-cover": _vm.loading },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(
                      "appointment.please-choose-payment-method-and-enter-details-below"
                    )
                  ) + ":"
                ),
              ]),
              _vm._v(" "),
              _vm.paymentMethods !== null &&
              _vm.paymentMethods.length !== void 0
                ? _c(
                    "ul",
                    [
                      _vm._l(
                        _vm.paymentMethods.filter(function (m) {
                          return m.available
                        }),
                        function (method) {
                          return [
                            _vm.paymentMethodSelected === method.payment_name
                              ? _c("li", {
                                  directives: [
                                    {
                                      name: "styled",
                                      rawName: "v-styled:border-color.before",
                                      value: _vm.palette.colors.primary.main,
                                      expression: "palette.colors.primary.main",
                                      arg: "border-color",
                                      modifiers: { before: true },
                                    },
                                  ],
                                  key: method.method_id,
                                  staticClass: "active",
                                  class: [method.payment_name],
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getPaymentMethod(method.payment_name)
                                        .label
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setPaymentMethod(
                                        method.payment_name
                                      )
                                    },
                                  },
                                })
                              : _c("li", {
                                  key: method.method_id,
                                  class: [method.payment_name],
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getPaymentMethod(method.payment_name)
                                        .label
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setPaymentMethod(
                                        method.payment_name
                                      )
                                    },
                                  },
                                }),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _vm.paymentMethod.component,
                _vm._b(
                  {
                    tag: "component",
                    on: {
                      "loading-changed": _vm.loadingChanged,
                      "cancel-pay": _vm.cancelPay,
                      "confirm-payment": _vm.confirmPayment,
                    },
                  },
                  "component",
                  _vm.paymentMethod.props,
                  false
                )
              ),
            ],
            1
          )
        : _vm.step == 2
        ? _c("div", { staticClass: "form-horizontal" }, [
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.$t.get("appointment.payment-information"))),
            ]),
            _vm._v(" "),
            _vm.bookingType === "video-call"
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.when-the-payment-is-processed-your-appointment"
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.bookingType === "second-opinion"
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.when-the-payment-is-processed-the-doctor"
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-list margin" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color",
                      value: _vm.palette.colors.primary.alt,
                      expression: "palette.colors.primary.alt",
                      arg: "color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "background",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:border-color",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "border-color",
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:background.hover",
                      value: _vm.palette.colors.hover.alt,
                      expression: "palette.colors.hover.alt",
                      arg: "background",
                      modifiers: { hover: true },
                    },
                    {
                      name: "styled",
                      rawName: "v-styled:color.hover",
                      value: _vm.palette.colors.hover.main,
                      expression: "palette.colors.hover.main",
                      arg: "color",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "button accent",
                  on: { click: _vm.finish },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t.get("appointment.finish")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ])
        : _vm.step == 3 && _vm.bookingType === "video-call"
        ? _c("div", { staticClass: "form-horizontal confirm" }, [
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.$t.get("appointment.booking-confirmation"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "result" }, [
              _vm._v(
                _vm._s(
                  _vm.$t.get(
                    "appointment.your-appointment-date-has-been-booked"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "description" }, [
              _vm._v(
                _vm._s(_vm.$t.get("appointment.information-about-the-call"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex collapse-sides" }, [
              _c("div", { staticClass: "w-full lg:w-full columns" }, [
                _c("ul", [
                  _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t.get("appointment.date"))),
                    ]),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.appointment.term
                            ? _vm.$dateTimeFormat.formatDate(
                                _vm.appointment.term.start_date
                              )
                            : "-"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t.get("appointment.time"))),
                    ]),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.appointment.term
                            ? _vm.$dateTimeFormat.formatTime(
                                _vm.appointment.term.start_date
                              )
                            : "-"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t.get("appointment.duration"))),
                    ]),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.appointment.term
                            ? _vm.appointment.term.duration
                            : "- "
                        ) +
                        " min\n            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex collapse-sides buttons-list margin" },
              [
                _c("div", { staticClass: "w-full columns" }, [
                  _c(
                    "button",
                    { staticClass: "button accent", on: { click: _vm.finish } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("appointment.finish")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm.step == 3 && _vm.bookingType === "second-opinion"
        ? _c("div", { staticClass: "form-horizontal confirm" }, [
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.$t.get("appointment.booking-confirmation"))),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t.get("appointment.booking-confirmation-second-opinion")
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex collapse-sides buttons-list margin" },
              [
                _c("div", { staticClass: "w-full columns text-right" }, [
                  _c(
                    "button",
                    { staticClass: "button accent", on: { click: _vm.finish } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("appointment.finish")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }