//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
import { capitalize } from "lodash"
import { actions } from "./builder"

export default {
  props: {
    resource: {
      type: String,
      required: true,
    },
    resourceDisplayName: {
      type: String,
      default: null,
    },
    canAddResource: {
      type: Boolean,
      default: true,
    },
    canDeleteResource: {
      type: Boolean,
      default: true,
    },
    canUpdateResource: {
      type: Boolean,
      default: true,
    },
    crudURL: {
      type: String,
      required: true,
    },
    extraRequestParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      processing: false,
      items: [],
    }
  },
  computed: {
    resourceName() {
      const name = this.resourceDisplayName ? this.resourceDisplayName : this.resource
      return capitalize(this.$t.get("form.section." + name))
    },
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.processing = true
      this.$http
        .post(this.crudURL, {
          action: actions.LIST,
          params: Object.assign(this.$route.params, this.extraRequestParams),
        })
        .then(
          (response) => {
            this.items = response.data
            this.processing = false
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.processing = false
          }
        )
    },
    addResource() {
      this.$router.push({
        name: actions.ADD,
        params: {
          resource: this.resource,
        },
      })
    },
    editResource(resource) {
      this.$router.push({
        name: actions.GET,
        params: {
          resource: resource.resource,
          resourceId: resource.resource_id,
        },
      })
    },
    deleteResource(resource) {
      Modal.confirm(this)
        .title(this.$t.get("common.perform-action-prompt"))
        .content(this.$t.get("common.perform-action"))
        .classes(["modal-small"])
        .positive(this.$t.get("common.yes"), function (modal) {
          modal.processing = true

          return this.$http
            .post(this.crudURL, {
              action: actions.DELETE,
              payload: resource,
              params: Object.assign(
                this.$route.params,
                { resourceId: resource.resource_id },
                this.extraRequestParams
              ),
            })
            .then(
              (response) => {
                this.$bridge.emit("addToast", response.data.message, "success")
                this.fetchData()
                return true
              },
              (error) => {
                this.processing = false
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("common.something-went-wrong-please-try-again"),
                  "error"
                )
              }
            )
        })
        .negative(this.$t.get("common.cancel"))
        .show()
    },
  },
}
