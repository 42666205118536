var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "assign-user material-form",
      class: { "preloader preloader-cover": _vm.processing },
    },
    [
      _c(
        "div",
        { staticClass: "input" },
        [
          _vm.members.length > 0
            ? _c("v-select", {
                staticClass: "user-select",
                class: { error: _vm.$v.member.$error },
                attrs: {
                  options: _vm.members,
                  "return-option": true,
                  "enable-search": true,
                  "internal-search": true,
                  "label-key": "slug",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.user.$touch()
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (props) {
                        return _c("div", {}, [
                          _c("div", {
                            staticClass: "avatar",
                            style: {
                              backgroundImage:
                                "url(" + props.item.user.avatar_url + ")",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(props.item.user.name) +
                                " " +
                                _vm._s(props.item.user.surname)
                            ),
                          ]),
                        ])
                      },
                    },
                    {
                      key: "placeholder",
                      fn: function (props) {
                        return _c("div", {}, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              _vm._s(props.selected.user.name) +
                                " " +
                                _vm._s(props.selected.user.surname)
                            ),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  2604082520
                ),
                model: {
                  value: _vm.member,
                  callback: function ($$v) {
                    _vm.member = $$v
                  },
                  expression: "member",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.members.length === 0 && _vm.processing === false
            ? [_vm._v(_vm._s(_vm.$t.get("common.list-empty")))]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-list text-right" }, [
        _c(
          "button",
          {
            staticClass: "button accent",
            attrs: {
              disabled:
                _vm.$v.$invalid || _vm.members.length === 0 || _vm.processing,
            },
            on: { click: _vm.assign },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t.get("appointment.assign")) + "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }