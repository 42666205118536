var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "enquiry-details-component material-form" }, [
    _c("h3", [_vm._v(_vm._s(_vm.$t.get("appointment.enquiry-details")))]),
    _vm._v(" "),
    _c("div", { staticClass: "card-box content" }, [
      _c("div", { staticClass: "flex flex-wrap flex-spacing" }, [
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t.get("appointment.reason-for-consultation")) +
                "\n          "
            ),
            _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            _vm._v(":"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxes" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.reason,
                    expression: "enquiry.reason",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.reason.$error },
                attrs: {
                  type: "checkbox",
                  id: "reason-second-opinion",
                  value:
                    "appointment.second-opinion-treatment-and-or-diagnosis",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.reason)
                    ? _vm._i(
                        _vm.enquiry.reason,
                        "appointment.second-opinion-treatment-and-or-diagnosis"
                      ) > -1
                    : _vm.enquiry.reason,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.reason,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v =
                            "appointment.second-opinion-treatment-and-or-diagnosis",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "reason", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "reason",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "reason", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.reason)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "reason-second-opinion" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(
                      "appointment.second-opinion-treatment-and-or-diagnosis"
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.reason,
                    expression: "enquiry.reason",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.reason.$error },
                attrs: {
                  type: "checkbox",
                  id: "reason-follow-up",
                  value: "appointment.existing-patient-follow-up",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.reason)
                    ? _vm._i(
                        _vm.enquiry.reason,
                        "appointment.existing-patient-follow-up"
                      ) > -1
                    : _vm.enquiry.reason,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.reason,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "appointment.existing-patient-follow-up",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "reason", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "reason",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "reason", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.reason)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "reason-follow-up" } }, [
                _vm._v(
                  _vm._s(_vm.$t.get("appointment.existing-patient-follow-up"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.reason,
                    expression: "enquiry.reason",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.reason.$error },
                attrs: {
                  type: "checkbox",
                  id: "reason-organise",
                  value: "appointment.organise-treatment",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.reason)
                    ? _vm._i(
                        _vm.enquiry.reason,
                        "appointment.organise-treatment"
                      ) > -1
                    : _vm.enquiry.reason,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.reason,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "appointment.organise-treatment",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "reason", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "reason",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "reason", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.reason)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "reason-organise" } }, [
                _vm._v(_vm._s(_vm.$t.get("appointment.organise-treatment"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t.get("appointment.type-of-consultation")) +
                "\n          "
            ),
            _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
            _vm._v(":"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkboxes" }, [
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.type,
                    expression: "enquiry.type",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.type.$error },
                attrs: {
                  type: "checkbox",
                  id: "type-video",
                  value: "appointment.video-consultation",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.type)
                    ? _vm._i(
                        _vm.enquiry.type,
                        "appointment.video-consultation"
                      ) > -1
                    : _vm.enquiry.type,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.type,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "appointment.video-consultation",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "type", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "type",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "type", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.type)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "type-video" } }, [
                _vm._v(_vm._s(_vm.$t.get("appointment.video-consultation"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.type,
                    expression: "enquiry.type",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.type.$error },
                attrs: {
                  type: "checkbox",
                  id: "type-written",
                  value: "appointment.written-medical-opinion",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.type)
                    ? _vm._i(
                        _vm.enquiry.type,
                        "appointment.written-medical-opinion"
                      ) > -1
                    : _vm.enquiry.type,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.type,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "appointment.written-medical-opinion",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "type", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "type",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "type", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.type)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "type-written" } }, [
                _vm._v(
                  _vm._s(_vm.$t.get("appointment.written-medical-opinion"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enquiry.type,
                    expression: "enquiry.type",
                  },
                ],
                staticClass: "inside",
                class: { error: _vm.$v.enquiry.type.$error },
                attrs: {
                  type: "checkbox",
                  id: "type-physical",
                  value: "appointment.physical-appointment",
                },
                domProps: {
                  checked: Array.isArray(_vm.enquiry.type)
                    ? _vm._i(
                        _vm.enquiry.type,
                        "appointment.physical-appointment"
                      ) > -1
                    : _vm.enquiry.type,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.enquiry.type,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "appointment.physical-appointment",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.enquiry, "type", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.enquiry,
                              "type",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.enquiry, "type", $$c)
                      }
                    },
                    function ($event) {
                      return _vm.handleInput(_vm.$v.enquiry.type)
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "type-physical" } }, [
                _vm._v(_vm._s(_vm.$t.get("appointment.physical-appointment"))),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex material-form flex-wrap flex-spacing margin-top" },
        [
          _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c(
                  "v-select",
                  {
                    class: { error: _vm.$v.enquiry.insurance.type.$error },
                    attrs: { id: "health-insurance" },
                    on: {
                      change: function ($event) {
                        return _vm.handleInput(_vm.$v.enquiry.insurance.type)
                      },
                    },
                    model: {
                      value: _vm.enquiry.insurance.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.enquiry.insurance, "type", $$v)
                      },
                      expression: "enquiry.insurance.type",
                    },
                  },
                  [
                    _c("v-option", {
                      attrs: {
                        label: _vm.$t.get("appointment.insurance-self-pay"),
                        value: "appointment.insurance-self-pay",
                      },
                    }),
                    _vm._v(" "),
                    _c("v-option", {
                      attrs: {
                        label: _vm.$t.get(
                          "appointment.insurance-private-insurance"
                        ),
                        value: "appointment.insurance-private-insurance",
                      },
                    }),
                    _vm._v(" "),
                    _c("v-option", {
                      attrs: {
                        label: _vm.$t.get(
                          "appointment.insurance-national-insurance"
                        ),
                        value: "appointment.insurance-national-insurance",
                      },
                    }),
                    _vm._v(" "),
                    _c("v-option", {
                      attrs: {
                        label: _vm.$t.get(
                          "appointment.insurance-embassy-sponsored"
                        ),
                        value: "appointment.insurance-embassy-sponsored",
                      },
                    }),
                    _vm._v(" "),
                    _c("v-option", {
                      attrs: {
                        label: _vm.$t.get("appointment.insurance-other"),
                        value: "appointment.insurance-other",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("label", { attrs: { for: "health-insurance" } }, [
                  _vm._v(
                    _vm._s(_vm.$t.get("appointment.health-insurance")) + " "
                  ),
                  _c("strong", { staticClass: "accent" }, [_vm._v("*")]),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
            _vm.enquiry.insurance.type ===
            "appointment.insurance-private-insurance"
              ? _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.enquiry.insurance.text,
                        expression: "enquiry.insurance.text",
                      },
                    ],
                    class: { error: _vm.$v.enquiry.insurance.text.$error },
                    attrs: { type: "text", id: "insurance-private" },
                    domProps: { value: _vm.enquiry.insurance.text },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.enquiry.insurance,
                            "text",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.handleInput(_vm.$v.enquiry.insurance.text)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "insurance-private" } }, [
                    _vm._v(
                      _vm._s(_vm.$t.get("appointment.private-insurance-name"))
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.enquiry.insurance.type ===
            "appointment.insurance-embassy-sponsored"
              ? _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.enquiry.insurance.text,
                        expression: "enquiry.insurance.text",
                      },
                    ],
                    class: { error: _vm.$v.enquiry.insurance.text.$error },
                    attrs: { type: "text", id: "insurance-embassy-sponsored" },
                    domProps: { value: _vm.enquiry.insurance.text },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.enquiry.insurance,
                            "text",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.handleInput(_vm.$v.enquiry.insurance.text)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "insurance-embassy-sponsored" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t.get("appointment.insurance-embassy-sponsored")
                        )
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.enquiry.insurance.type === "appointment.insurance-other"
              ? _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.enquiry.insurance.text,
                          expression: "enquiry.insurance.text",
                        },
                      ],
                      class: { error: _vm.$v.enquiry.insurance.text.$error },
                      attrs: { type: "text", id: "insurance-other" },
                      domProps: { value: _vm.enquiry.insurance.text },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.enquiry.insurance,
                              "text",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.handleInput(
                              _vm.$v.enquiry.insurance.text
                            )
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "insurance-other" } }, [
                      _vm._v(_vm._s(_vm.$t.get("appointment.insurance-other"))),
                    ]),
                    _vm._v(" "),
                    _c("v-input-error", {
                      attrs: { validation: _vm.$v.enquiry.insurance.text },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }