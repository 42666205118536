import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"
import AbstractTool from "./AbstractTool"

export default class DownloadPdf extends AbstractTool {
  constructor(appointmentId) {
    super(null)
    this.appointmentId = appointmentId
  }

  get name() {
    return Translate.get("viewer.download")
  }

  get icon() {
    return "ict-download"
  }

  get type() {
    return ToolType.SWITCH
  }

  activate(views) {}

  deactivate(views) {}

  bind(viewerInstance, views) {
    this.viewerInstance = viewerInstance
  }

  get actions() {
    let tool = this

    return {
      template: `<ul class="drop-down view-actions">
          <li><a v-bind:href="downloadAllUrl()" target="_blank"><i class="ict-attachment"></i><span>{{ $t.get("appointment.download-records") }}</span></a></li>
          <li><a v-bind:href="downloadPdfUrl()" target="_blank"><i class="ict-pdf"></i><span v-html="$t.get('appointment.download-pdfs')"></span></a></li>
          </ul>`,
      methods: {
        downloadAllUrl() {
          return (
            window.__CAMEL_URL +
            "/cloud/objects/" +
            tool.appointmentId +
            "/download?authorization=" +
            window.USER_JWT
          )
        },
        downloadPdfUrl() {
          return (
            window.__CAMEL_URL +
            "/cloud/objects/" +
            tool.appointmentId +
            "/download?itemFilters=pdf&authorization=" +
            window.USER_JWT
          )
        },
      },
    }
  }
}
