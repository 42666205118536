var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-finish-consultation" }, [
    _c("div", { staticClass: "flex material-form margin" }, [
      _c("div", { staticClass: "w-4/12" }, [
        _c("div", { staticClass: "description" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t.get(
                  "appointment.finish-consultation-modal-finish-description"
                )
              ) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button red", on: { click: _vm.finishConsultation } },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t.get("appointment.finish-consultation")) +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-4/12" }, [
        _c("div", { staticClass: "description" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t.get(
                  "appointment.finish-consultation-modal-reschedule-description"
                )
              ) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button accent with-icon",
            on: { click: _vm.rescheduleCall },
          },
          [
            _c("i", { staticClass: "ict-calendar" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t.get("appointment.reschedule")) +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-4/12" }, [
        _c("div", { staticClass: "description" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t.get(
                  "appointment.finish-consultation-modal-leave-description"
                )
              ) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button accent with-icon",
            on: { click: _vm.leaveCall },
          },
          [
            _c("i", { staticClass: "ict-logout" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t.get("appointment.leave-the-call")) +
                "\n      "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-list margin text-right" }, [
      _c(
        "button",
        {
          staticClass: "button border",
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.$t.get("appointment.cancel")) + "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }