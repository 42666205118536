//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal.js"
import { createNamespacedHelpers } from "vuex"
const { mapGetters } = createNamespacedHelpers("patientLink")

export default {
  props: {
    component: { type: Object, required: true },
    profile: {},
    product_id: {
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      shared: {
        data: [],
      },
      sharedPage: 1,
    }
  },
  computed: {
    ...mapGetters(["getShareList"]),
  },
  mounted() {
    this.fetchShared(1)
  },
  methods: {
    fetchShared(page) {
      this.processing = true
      this.sharedPage = page
      this.$store
        .dispatch("patientLink/fetchShareList", {
          page: this.sharedPage,
          profile: this.profile,
          component: this.component,
        })
        .then(
          () => {
            this.processing = false
          },
          (error) => {
            this.processing = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
          }
        )

      this.sharedPage = page
      this.processing = true
    },
    revokeShare(share) {
      Modal.confirm(this)
        .title(this.$t.get("profile.revoke-share"))
        .content('<p class="description">' + this.$t.get("profile.revoke-share-question") + "</p>")
        .positive(this.$t.get("common.yes"), function (modal) {
          modal.processing = true

          return this.$store
            .dispatch("patientLink/revokeShare", {
              component: this.component,
              profile: this.profile,
              share: share,
            })
            .then(
              (response) => {
                this.$bridge.emit("addToast", response.data.message, "success")
                return true
              },
              () => {
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("common.something-went-wrong-please-try-again"),
                  "error"
                )
                return false
              }
            )
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
  },
}
