var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showButton
    ? _c("div", [
        _c(
          "button",
          {
            attrs: { title: _vm.$t.get("fhir.import-data-from-provider") },
            on: {
              click: function ($event) {
                return _vm.showAsModal(1)
              },
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t.get("fhir.import-data-from-provider")) +
                "\n  "
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }