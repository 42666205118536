import { Constants } from "../../../models/Constants"

export default {
  data() {
    return {
      loading: false,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(value) {
        this.$emit("loading-changed", value)
      },
    },
  },
  methods: {
    sendLogs(label, options, payload, tokenizeErr) {
      let log = { options: options, payload: payload, tokenize: tokenizeErr }
      this.throwRavenException(label, tokenizeErr)
      this.$http.post("/ajax/payment/logs", { payment_id: this.payment.payment_id, log: log }).then(
        (response) => {
          //
        },
        (error) => {
          this.throwRavenException(label + "Callback: ", error)
        }
      )
    },
    throwRavenException(label, data) {
      try {
        throw new Error(label + JSON.stringify(data))
      } catch (e) {
        console.log(e)
        if (process.env.production) Sentry.captureException(e)
      }
    },
    confirmPayment(method, payment, payload, tokenizeErr) {
      this.$http
        .post("/ajax/payment/callback", {
          payment_id: payment.payment_id,
          method_id: method.method_id,
          callback: {
            payload: payload,
            tokenizeErr: tokenizeErr,
          },
        })
        .then(
          (response) => {
            this.loading = false

            if (response.data.data.status === Constants.Payment.STATUS_SUCCESS) {
              this.$emit("confirm-payment", true)
            } else {
              this.$bridge.emit("addToast", response.data.message, "error")
              this.throwRavenException("ConfirmPaymentCallback: ", response)
            }
          },
          (error) => {
            this.loading = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.throwRavenException("ConfirmPaymentCallback: ", error)
          }
        )
    },
  },
}
