var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "mb-8" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.reports-description"))),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-8" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.reports-info"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "space-y-2 mb-8" },
      [
        _c("div", { staticClass: "table-header" }, [
          _c("div"),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-6 pl-8" }, [
            _vm._v(_vm._s(_vm.$t.get("gdpr.reports-date"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-5" }, [
            _vm._v(_vm._s(_vm.$t.get("gdpr.reports-author"))),
          ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.reports, function (report) {
          return _c(
            "div",
            { key: report.id, staticClass: "form-select-group" },
            [
              _c(
                "custom-checkbox",
                {
                  attrs: {
                    label: report.id,
                    id: report.id,
                    inputValue: report,
                    multiple: false,
                  },
                  model: {
                    value: _vm.checkedReport,
                    callback: function ($$v) {
                      _vm.checkedReport = $$v
                    },
                    expression: "checkedReport",
                  },
                },
                [
                  _c("div", { staticClass: "col-span-6 py-6 pl-8" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$dateTimeFormat.formatDate(report.created_at)
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.$dateTimeFormat.formatTime(report.created_at)
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile-name col-span-5" }, [
                    report.user.avatar_url
                      ? _c("div", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage:
                              "url(" + report.user.avatar_url + ")",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(report.user.name))]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between" }, [
      _c(
        "button",
        {
          staticClass: "button accent big symmetric py-4 shrink-0",
          class: _vm.checkedReport !== null ? "" : "disabled",
          on: { click: _vm.download },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("gdpr.download")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }