//
//
//
//
//

export default {
  props: {
    tracks: {
      required: true,
    },
  },
  watch: {
    tracks: {
      immediate: true,
      handler(tracks) {
        if (tracks !== void 0 && tracks !== null) {
          this.$nextTick(() => {
            tracks.forEach(this.attachTrack.bind(this))
          })
        }
      },
    },
  },
  methods: {
    attachTrack(track) {
      let ref = this.$refs.media
      if (ref !== void 0) {
        ref.appendChild(track.attach())
      }
    },
  },
}
