var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-form consents-list" },
    [
      _vm.consentRequired.length > 0
        ? [
            _vm.showTextDesc
              ? [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t.get("auth.terms-and-conditions")) + ":"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t.get("auth.terms-and-conditions-desc"))
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkboxes margin" },
              _vm._l(_vm.consentRequired, function (consent) {
                return _c("div", { key: consent["legal_translation_id"] }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model[consent["legal_translation_id"]],
                        expression: "model[consent['legal_translation_id']]",
                      },
                    ],
                    staticClass: "inside",
                    class: {
                      error:
                        _vm.valid[consent.legal_translation_id] !== void 0
                          ? _vm.valid[consent.legal_translation_id].$error
                          : false,
                    },
                    attrs: {
                      type: "checkbox",
                      id: consent["legal_translation_id"],
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.model[consent["legal_translation_id"]]
                      )
                        ? _vm._i(
                            _vm.model[consent["legal_translation_id"]],
                            null
                          ) > -1
                        : _vm.model[consent["legal_translation_id"]],
                    },
                    on: {
                      input: function ($event) {
                        _vm.valid[consent.legal_translation_id].$touch()
                      },
                      change: function ($event) {
                        var $$a = _vm.model[consent["legal_translation_id"]],
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.model,
                                consent["legal_translation_id"],
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                consent["legal_translation_id"],
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.model,
                            consent["legal_translation_id"],
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: {
                        for: consent["legal_translation_id"],
                        tabindex: "-1",
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            consent.consent +
                              "<strong class='text-primary'> *</strong>"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      consent.has_additional_content
                        ? _c(
                            "button",
                            {
                              directives: [
                                { name: "tooltip", rawName: "v-tooltip" },
                              ],
                              staticClass: "button text",
                              attrs: {
                                alt: _vm.$t.get("common.click_to_open"),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openConsentModal(
                                    consent.consent_desc
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t.get("common.see-more")) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }