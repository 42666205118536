//
//
//

export default {
  props: {
    progress: {
      default: 0,
    },
    width: {
      default: 142,
    },
    height: {
      default: 142,
    },
    border: {
      default: 10.0,
    },
    background: {
      default: "#dbdbdb",
    },
    foreground: {
      default: "#00ccb1",
    },
  },
  data() {
    return {
      context: null,
      imd: null,
    }
  },
  mounted() {
    this.context = this.$el.getContext("2d")

    this.context.beginPath()
    this.context.lineCap = "square"
    this.context.lineWidth = this.border
    this.context.closePath()
    this.context.fill()

    this.setProgress(this.progress)
  },
  watch: {
    progress(val) {
      this.setProgress(val)
    },
  },
  methods: {
    setProgress(s) {
      if (s == 0) s = 0.001
      if (s >= 100) s = 99
      let current = s / 100

      let start = Math.PI * -0.5
      let progress = Math.PI * (-0.5 + current * 2)
      let end = Math.PI * 1.5

      this.context.clearRect(0, 0, this.width, this.height)

      this.context.strokeStyle = this.background

      this.context.beginPath()
      this.context.arc(this.width / 2, this.width / 2, this.width * 0.42, start, progress, true)
      this.context.stroke()
      this.context.closePath()

      this.context.strokeStyle = this.foreground

      this.context.beginPath()
      this.context.arc(this.width / 2, this.width / 2, this.width * 0.42, progress, end, true)
      this.context.stroke()
      this.context.closePath()
    },
  },
}
