import { Constants } from "./Constants"
import Translate from "../../helpers/translate"

export const video = [
  { value: null, label: Translate.get("common.choose-option") },
  {
    value: Constants.Appointment.STATUS_REUQESTED,
    label: Translate.get("appointment.video-status-requested"),
  },
  {
    value: Constants.Appointment.STATUS_ACCEPTED,
    label: Translate.get("appointment.video-status-accepted"),
  },
  {
    value: Constants.Appointment.STATUS_PAID,
    label: Translate.get("appointment.video-status-paid"),
  },
  {
    value: Constants.Appointment.STATUS_RESCHEDULED,
    label: Translate.get("appointment.video-status-rescheduled"),
  },
  {
    value: Constants.Appointment.STATUS_IN_PROGRESS,
    label: Translate.get("appointment.video-status-in-progress"),
  },
  {
    value: Constants.Appointment.STATUS_CALL_ENDED,
    label: Translate.get("appointment.video-status-call-ended"),
  },
  {
    value: Constants.Appointment.STATUS_DONE,
    label: Translate.get("appointment.video-status-done"),
  },
  {
    value: Constants.Appointment.STATUS_REVOKED,
    label: Translate.get("appointment.video-status-revoked"),
  },
]

export const secondOpinion = [
  { value: null, label: Translate.get("common.choose-option") },
  {
    value: Constants.Appointment.STATUS_REVOKED,
    label: Translate.get("appointment.opinion-revoked"),
  },
  {
    value: Constants.Appointment.STATUS_REUQESTED,
    label: Translate.get("appointment.opinion-requested"),
  },
  {
    value: Constants.Appointment.STATUS_ACCEPTED,
    label: Translate.get("appointment.opinion-accepted"),
  },
  {
    value: Constants.Appointment.STATUS_PAID,
    label: Translate.get("appointment.opinion-payment-paid"),
  },
  {
    value: Constants.Appointment.STATUS_DONE,
    label: Translate.get("appointment.video-status-done"),
  },
]

export const diagnostic = [
  { value: null, label: Translate.get("common.choose-option") },
  {
    value: Constants.Appointment.STATUS_REUQESTED,
    label: Translate.get("appointment.diagnostic-test-requested"),
  },
  {
    value: Constants.Appointment.STATUS_ACCEPTED,
    label: Translate.get("appointment.diagnostic-test-accepted"),
  },
  {
    value: Constants.Appointment.STATUS_PAID,
    label: Translate.get("appointment.diagnostic-test-paid"),
  },
  {
    value: Constants.Appointment.STATUS_DONE,
    label: Translate.get("appointment.diagnostic-test-done"),
  },
  {
    value: Constants.Appointment.STATUS_REVOKED,
    label: Translate.get("appointment.diagnostic-test-revoked"),
  },
]

export const overseasTreatment = [
  { value: null, label: Translate.get("common.choose-option") },
  {
    value: Constants.Appointment.STATUS_REUQESTED,
    label: Translate.get("appointment.overseas-treatment-requested"),
  },
  {
    value: Constants.Appointment.STATUS_ACCEPTED,
    label: Translate.get("appointment.overseas-treatment-accepted"),
  },
  {
    value: Constants.Appointment.STATUS_PAID,
    label: Translate.get("appointment.overseas-treatment-paid"),
  },
  {
    value: Constants.Appointment.STATUS_DONE,
    label: Translate.get("appointment.overseas-treatment-done"),
  },
  {
    value: Constants.Appointment.STATUS_REVOKED,
    label: Translate.get("appointment.overseas-treatment-revoked"),
  },
]

export const fractureCover = [
  { value: null, label: Translate.get("common.choose-option") },
  {
    value: Constants.Appointment.STATUS_REUQESTED,
    label: Translate.get("appointment.fracture-cover-requested"),
  },
  {
    value: Constants.Appointment.STATUS_ACCEPTED,
    label: Translate.get("appointment.fracture-cover-accepted"),
  },
  {
    value: Constants.Appointment.STATUS_DONE,
    label: Translate.get("appointment.fracture-cover-done"),
  },
  {
    value: Constants.Appointment.STATUS_REVOKED,
    label: Translate.get("appointment.fracture-cover-revoked"),
  },
]
