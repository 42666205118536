var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.objectId !== null
    ? _c("div", { staticClass: "input" }, [
        _c(
          "button",
          { class: _vm.classes, on: { click: _vm.showMedicalFiles } },
          [_vm._v(_vm._s(_vm.text))]
        ),
      ])
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }