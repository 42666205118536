//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CustomerAuditLog",
  props: {
    items: { type: Array, required: true },
    processing: { type: Boolean, required: true },
  },
}
