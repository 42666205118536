import Modal from "../../../vuejs/plugins/modal/modal"

export default class LocalMediaPermissionError extends Error {
  static render(app) {
    Modal.create(app)
      .title(app.$t.get("calls.errors.we-were-unable-to-detect-your-media-devices"))
      .component({
        template:
          `<div>
                <p><b>` +
          app.$t.get("calls.errors.we-noticed-some-media-issues") +
          `</b></p>
                <p>` +
          app.$t.get("calls.errors.grant-permissions-and-reload-page") +
          `</p>
                <p>` +
          app.$t.get("calls.errors.try-another-browser") +
          `</p>
                <p>` +
          app.$t.get("calls.errors.contact-customer-service") +
          `</p>
            </div>`,
      })
      .show()
  }
}
