var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "price-component",
      class: {
        "preloader preloader-cover":
          _vm.enablePreloader && (_vm.processing || _vm.isCalculating),
      },
    },
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t.get("appointment.pricing")))]),
      _vm._v(" "),
      _c("div", { staticClass: "card-box content" }, [
        _c("div", { staticClass: "flex flex-wrap flex-spacing" }, [
          _c("div", { staticClass: "w-full lg:w-5/12 columns material-form" }, [
            _c("div", { staticClass: "flex flex-wrap flex-spacing" }, [
              _c("div", { staticClass: "w-full columns price-input" }, [
                _c("div", { staticClass: "input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.price.amount,
                        expression: "price.amount",
                      },
                    ],
                    staticClass: "rate",
                    attrs: {
                      id: "price",
                      type: "number",
                      disabled: _vm.price.for_free || _vm.readOnly,
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      "placeholder-sticky": "",
                    },
                    domProps: { value: _vm.price.amount },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.price, "amount", $event.target.value)
                        },
                        function ($event) {
                          return _vm.calculate()
                        },
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price-free" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.price.for_free,
                        expression: "price.for_free",
                      },
                    ],
                    staticClass: "inside price-free",
                    attrs: {
                      type: "checkbox",
                      disabled: _vm.readOnly,
                      id: "for-free",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.price.for_free)
                        ? _vm._i(_vm.price.for_free, null) > -1
                        : _vm.price.for_free,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.price.for_free,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.price, "for_free", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.price,
                                "for_free",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.price, "for_free", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: { for: "for-free" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleForFree()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t.get("settings.for-free")))]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-full columns" }, [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("v-select", {
                      attrs: {
                        id: "currency",
                        disabled: _vm.readOnly,
                        "label-key": "value",
                        options: [
                          { value: "CHF" },
                          { value: "EUR" },
                          { value: "GBP" },
                          { value: "PLN" },
                          { value: "USD" },
                        ],
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.price.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.price, "currency", $$v)
                        },
                        expression: "price.currency",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "currency" } }, [
                      _vm._v(_vm._s(_vm.$t.get("settings.currency"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full lg:w-7/12 columns end summary" }, [
            _c("div", { staticClass: "single" }, [
              _c("div", { staticClass: "info text-right" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t.get("settings.your-rate")) + ":"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.price.amount_netto) +
                      " " +
                      _vm._s(_vm.price.currency)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single" }, [
              _c("div", { staticClass: "info text-right" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t.get("settings.total")) + ":"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                parseFloat(_vm.price.amount_brutto) > 0.0
                  ? _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.price.amount_brutto + " " + _vm.price.currency
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                parseFloat(_vm.price.amount_brutto) == 0.0
                  ? _c("strong", [
                      _vm._v(_vm._s(_vm.$t.get("settings.for-free"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "alt" }, [
                  _vm._v(
                    "(" +
                      _vm._s(_vm.$t.get("settings.the-real-patients-cost")) +
                      ")"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }