import Tick from "./tick"

export default class Module {
  constructor(el) {
    if (el instanceof HTMLElement) {
      this.el = el
    } else {
      this.el = document.querySelector(el)
    }

    if (this.el === null) {
      throw new Error("Provided Element is null or cannot be found.")
    }

    if (this.onTick !== undefined) {
      Tick.get.addTickInstance(this)
    }
  }
}
