//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { browserDetect } from "../../../../../apps/browser-detect-mixin"
import Modal from "../../../../../plugins/modal/modal"
import BenchmarkDetails from "./BenchmarkDetails.vue"

export default {
  name: "CustomerBenchmarkList",
  mixins: [browserDetect],
  props: {
    items: { type: Array, required: true },
    processing: { type: Boolean, required: true },
  },
  data() {
    return {
      fields: ["camera", "socket", "twilio", "trustedoctor", "microphone"],
    }
  },
  methods: {
    getScore(data) {
      let sum = 0
      // each field has points from 0 to 3, overall 40% from points
      let fieldCoefficient = 40 / this.fields.length / 3

      this.fields.forEach((field) => {
        if (data.hasOwnProperty(field)) {
          sum += fieldCoefficient * data[field].result
        }
      })

      // another 60% is for following data
      let logsColumns = ["networkQuality", "microphone", "speakers"]

      logsColumns.forEach((column) => {
        if (data.hasOwnProperty(column) && data[column].logs.length > 0) {
          // add 10% for network logs
          sum += 10
        }
      })

      if (data.hasOwnProperty("permissionStatus")) {
        // add 15% for camera/mic granted permission
        if (data.permissionStatus.camera === "granted") {
          sum += 15
        }
        if (data.permissionStatus.microphone === "granted") {
          sum += 15
        }
      }

      return Math.round(sum) + "%"
    },
    openBenchmarkDetails(benchmark) {
      Modal.create(this)
        .title(this.$t.get("customer.details.benchmark.benchmark-details"))
        .component(BenchmarkDetails)
        .props({
          benchmark: benchmark,
        })
        .show()
    },
  },
}
