//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
import CloudMixin from "./CloudMixin"
import Dropbox from "./Dropbox.vue"
import ExternalCloudAttachedFilesItem from "./ExternalCloudAttachedFilesItem.vue"
import AttachedFilesState from "./AttachFilesState"
import Translate from "../../../helpers/translate"

import { forEach, remove } from "lodash"
import Customize from "../../plugins/Customize"

export default {
  mixins: [Object.assign({}, CloudMixin), Customize()],
  props: {
    headerText: { default: Translate.get("cloud-manager.your-attached-files") },
    selectFolder: { default: null },
    showFolders: { default: true },
    showActions: { default: true },
    externalCloudAttachImmidiately: {
      type: Boolean,
      default: false,
    },
    externalCloudProviders: {
      type: Array,
      default: function () {
        return ["dropbox"]
      },
    },
  },
  components: {
    "dropbox-button": Dropbox,
    "external-cloud-item": ExternalCloudAttachedFilesItem,
  },
  data() {
    return {
      state: 0,
      states: AttachedFilesState,
      externalCloud: {
        dropbox: [],
      },
      attached: [],
      attaching: false,
    }
  },
  computed: {
    isEmptyAttached() {
      const noLocalFiles =
        this.attached.length === 0 &&
        this.folders.filter((folder) => {
          return folder.attached === true
        }).length === 0

      const noExternalFiles =
        Object.keys(this.externalCloud).filter((key) => this.externalCloud[key].length > 0)
          .length === 0

      return noLocalFiles && noExternalFiles
    },
  },
  mounted() {
    this.attached = []
  },
  methods: {
    openCloudManager() {
      Modal.create(this)
        .title(this.$t.get("cloud-manager.my-medical-cloud"))
        .component(() => import("./CloudManager.vue"))
        .classes(["modal-cloud-manager"])
        .closeable(false)
        .props({
          objectId: this.objectId,
          selectFolder: this.selectFolder,
          showFolders: this.showFolders,
          showActions: this.showActions,
          allowFormats: this.allowFormats,
          alreadyAttached: this.attached,
        })
        .events({
          success: this.onAttach.bind(this),
        })
        .show()
    },
    getExternalProviderAppKey(key) {
      return window[key]
    },
    isProviderEnabled(provider) {
      return this.externalCloudProviders.includes(provider)
    },
    onExternalCloudAttach({ provider, attached }) {
      this.$set(this.externalCloud, provider, attached)

      this.$emit("external-attached", { provider, attached })

      if (this.externalCloudAttachImmidiately === true) {
        this.enqueueImportFromExternalCloud(
          this.importFolderName(provider),
          attached,
          this.objectId
        )
      }
    },
    onAttach(attached, objectId, countFileHistory) {
      this.attached = attached

      this.$emit("attached", attached, objectId, countFileHistory)
      this.$forceUpdate()
    },
    haveItemsAttached(parent_id) {
      return (
        this.attached.filter(function (item) {
          return item.parent_id == parent_id
        }).length > 0
      )
    },
    getIndexItemAttached(parent_id, item_id, type) {
      return this.attached.findIndex(
        (item) => item.parent_id == parent_id && item.item_id === item_id && item.type === type
      )
    },
    isItemAttached(parent_id, item_id, type) {
      return (
        this.getIndexItemAttached(parent_id, item_id, type) > -1 ||
        this.getIndexItemAttached(parent_id, parent_id, "folder") > -1
      )
    },

    deleteItemAttached(parent_id, item_id, type) {
      let index = this.getIndexItemAttached(parent_id, item_id, type)
      if (index > -1) this.attached.splice(index, 1)
    },
    detachItem(parent_id, item_id, type) {
      this.deleteItemAttached(parent_id, item_id, type) // delete item first
      if (type === "folder") {
        remove(this.attached, (item) => {
          return item.parent_id === item_id
        })
        this.$forceUpdate()
      } //if detach folder do nothing
      if (type === "file") {
        // if detach file - check all other conditions
        if (this.isItemAttached(parent_id, parent_id, "folder")) {
          //if only folder was selected: ,
          this.detachItem(parent_id, parent_id, "folder") // 1. deselect folder
          forEach(
            this.folders[this.folders.findIndex((item) => item.item_id === parent_id)].data,
            (items, key) => {
              forEach(items, (file, subkey) => {
                this.attachItem(parent_id, subkey, "file") //2 - select all files without thie one
              })
            }
          )
          this.deleteItemAttached(parent_id, item_id, type) // delete item first
        }
      }
      this.$emit("attached-updated", this.attached, this.objectId)
    },
    attachItem(parent_id, item_id, type, check_is_attach = true) {
      if (this.isItemAttached(parent_id, item_id, type) && check_is_attach === true) {
        this.detachItem(parent_id, item_id, type)
      } else {
        remove(this.attached, (item) => {
          return item.parent_id === item_id
        })

        if (this.isItemAttached(parent_id, item_id, type) === false) {
          this.attached.push({
            parent_id: parent_id,
            item_id: item_id,
            type: type,
          })
        }
      }
      this.$forceUpdate()
    },
  },
}
