var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "filters",
    {
      on: {
        "clear-filters": function ($event) {
          return _vm.clearFilters()
        },
      },
    },
    _vm._l(_vm.preparedSearchComponents, function (column) {
      return _c(
        _vm.getColumnComponent(column.type),
        _vm._g(
          _vm._b(
            {
              key: column.type,
              tag: "component",
              attrs: { mode: "input", type: column.type },
              model: {
                value: _vm.search[column.type],
                callback: function ($$v) {
                  _vm.$set(_vm.search, column.type, $$v)
                },
                expression: "search[column.type]",
              },
            },
            "component",
            Object.assign({}, _vm.$props, column.props, {
              columnsCount: _vm.preparedSearchComponents.length,
            }),
            false
          ),
          _vm.$listeners
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }