var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          id: "duration-minutes",
          value: _vm.value,
          options: _vm.availableDurationMinutes,
          "label-key": "label",
          "value-key": "value",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "custom-select-label",
          attrs: { for: "duration-minutes" },
        },
        [_vm._v(_vm._s(_vm.$t.get("calls.duration")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }