var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-cropper" },
    [
      _c("vue-croppie", {
        ref: "croppie",
        attrs: {
          viewport: _vm.viewport,
          boundary: _vm.boundary,
          enableResize: _vm.resizable,
        },
        on: { result: _vm.result },
      }),
      _vm._v(" "),
      _vm.buttons
        ? _c("div", { staticClass: "buttons-list text-right" }, [
            _c(
              "button",
              { staticClass: "button accent", on: { click: _vm.crop } },
              [_vm._v(_vm._s(_vm.$t.get("common.save")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button border-gray", on: { click: _vm.cancel } },
              [_vm._v(_vm._s(_vm.$t.get("common.cancel")))]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }