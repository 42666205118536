//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import braintree from "braintree-web"
import { Constants } from "../../models/Constants"
import { createNamespacedHelpers } from "vuex"
import RxTime from "../../plugins/RxTime"
import Modal from "../../plugins/modal/modal"
import TermPicker from "../../components/appointment/TermPicker.vue"

import { clone, forEach, find, findIndex } from "lodash"
import TermTooltip from "../dashboard/TermTooltip.vue"

import TDH from "./payment/TDH.vue"
import Paypal from "./payment/Paypal.vue"
import Card from "./payment/Card.vue"
import Customize from "../../plugins/Customize"

const { mapGetters } = createNamespacedHelpers("appointment")

export default {
  mixins: [RxTime.mixin(), Customize()],
  props: {
    initialStep: {
      default: 0,
      type: Number,
    },
    bookingType: {
      type: String,
    },
    displayDays: {
      default: 7,
    },
  },
  data() {
    return {
      step: 0, // 0 - pick a date, 1 - choose payment,  2 - payed, 3 - confirm book
      loading: false,
      loadingPayPal: false, // overlay with button help focus on popup
      picked: "",
      pickedTimeout: null,
      date: this.$moment(),
      availableTerms: {},
      payment: null,
      paymentMethods: {},
      paymentMethodSelected: "", //default method payment
      paymentClient: null,
      threeDSecureInstance: null,
    }
  },
  watch: {
    step: {
      handler: function (val, oldVal) {
        if (this.step === 0) {
          this.payment = null
          this.paymentClient = null
          this.getAvailableTerms()
          if (this.pickedTimeout !== null) clearTimeout(this.pickedTimeout)
        }
        if (this.step === 1) {
          if (this.isVideoCall)
            // no need to crate payment- user reserves a term and gets payment object
            this.getAvailablePaymentMethods()
          else {
            // payment object needs to be created separately
            this.createPaymentForOpinion()
          }
          this.pickedTimeout = setTimeout(() => this.cancelPay(), 600000)
        }
      },
      immediate: true,
    },
    paymentMethods() {
      if (this.paymentMethods !== null && this.payment !== null) {
        this.initClientPayment()
      }
    },
  },
  computed: {
    startDate() {
      return this.$moment(this.date)
        .set({ hour: 0, minute: 0 })
        .locale("en")
        .format("YYYY-MM-DD[T]HH:mmZ")
    },
    endDate() {
      return this.$moment(this.date)
        .set({ hour: 23, minute: 59 })
        .add(this.displayDays - 1, "days")
        .locale("en")
        .format("YYYY-MM-DD[T]HH:mmZ")
    },
    pickedDate() {
      if (this.picked === "") {
        return null
      } else {
        return Object.values(this.availableTerms)
          .flat()
          .find((item) => item.id === this.picked)
      }
    },
    appointment() {
      return this.$store.state.appointment
    },
    ...mapGetters(["getMe", "isSecondOpinionOnly", "isVideoCall"]),
    me() {
      return this.getMe(this.user.user_id)
    },
    haveAvailableTerms() {
      let have = false
      forEach(this.availableTerms, function (key, value) {
        if (key.length > 0) {
          have = true
          return false
        }
      })
      return have
    },
    haveSelectedTerm() {
      if (this.loading) return false
      else if (this.picked.length > 0) return true
      else return false
    },
    getCountryCode() {
      return window.hasOwnProperty("__USER_COUNTRY") ? window.__USER_COUNTRY : null
    },
    user() {
      return this.$store.state.user
    },
    paymentMethod() {
      return this.getPaymentMethod(this.paymentMethodSelected)
    },
  },
  components: {
    "term-tooltip": TermTooltip,
  },
  sockets: {
    dashboardCalendarUpdate() {
      this.getAvailableTerms()
    },
  },
  mounted() {
    this.step = this.initialStep
    const status = clone(this.appointment.status)
  },
  beforeDestroy() {
    if (this.picked !== "") {
      this.cancelPay()
    }
  },
  methods: {
    nextWeek() {
      this.picked = ""
      this.date = this.$moment(this.date).add(this.displayDays, "days").set({ hour: 0, minute: 0 })

      this.getAvailableTerms()
    },
    prevWeek() {
      this.picked = ""
      this.date = this.$moment(this.date)
        .subtract(this.displayDays, "days")
        .set({ hour: 0, minute: 0 })

      this.getAvailableTerms()
    },
    goToStep(step) {
      this.step = step
    },
    cancel() {
      this.$emit("cancel")
    },
    getAvailableTerms() {
      this.loading = true

      this.$http
        .get("/ajax/appointment/" + this.appointment.appointment_id + "/terms", {
          params: {
            date_start: this.startDate,
            date_end: this.endDate,
          },
        })
        .then(
          (response) => {
            this.availableTerms = response.data.data
            this.loading = false
          },
          (error) => {
            this.loading = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.throwRavenException("Terms: ", error)
          }
        )
    },
    getAvailablePaymentMethods() {
      this.loading = true
      this.$http.get("/ajax/payment/methods").then(
        (response) => {
          this.paymentMethods = response.data.data.reverse()
          this.setDefaultPaymentMethod()
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.throwRavenException("Payment methods: ", error)
        }
      )
    },
    getPaymentMethod(paymentName) {
      const method = find(this.paymentMethods, { payment_name: paymentName })

      switch (paymentName) {
        case "tdh":
          return {
            label: this.$t.get("appointment.pay-with") + '<span class="icon-tdh"></span>',
            component: TDH,
            props: {
              payment: this.payment,
              method: method,
            },
          }
        case "paypal":
          return {
            label: this.$t.get("appointment.pay-with") + '<span class="icon-paypal"></span>',
            component: Paypal,
            props: {
              paymentClient: this.paymentClient,
              payment: this.payment,
              method: method,
            },
          }
        case "credit-card":
          return {
            label: this.$t.get("appointment.pay-with-credit-card"),
            component: Card,
            props: {
              paymentClient: this.paymentClient,
              threeDSecureInstance: this.threeDSecureInstance,
              payment: this.payment,
              method: method,
            },
          }
        default:
          return {
            label: "",
            component: null,
            props: {},
          }
      }
    },
    setDefaultPaymentMethod() {
      let credit = find(this.paymentMethods, { payment_name: "credit-card", available: true })
      let paypal = find(this.paymentMethods, { payment_name: "paypal", available: true })
      this.paymentMethodSelected = credit ? credit.payment_name : paypal ? paypal.payment_name : ""
    },
    reserveTerm() {
      this.loading = true
      this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/terms/reserve", {
          term_id: this.picked,
        })
        .then(
          (response) => {
            this.payment = response.data.data.payment
            this.step = this.payment === null ? 3 : 1
          },
          (error) => {
            this.loading = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.throwRavenException("Reserve: ", error)
          }
        )
    },
    createPaymentForOpinion() {
      this.loading = true
      this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/terms/reserve", {
          term_id: null,
        })
        .then(
          (response) => {
            this.payment = response.data.data.payment
            this.getAvailablePaymentMethods()
            this.step = this.payment === null ? 3 : 1
          },
          (error) => {
            this.loading = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.throwRavenException("SecondOpinionOnlyPayment: ", error)
          }
        )
    },
    loadingChanged(value) {
      this.loading = value
    },
    cancelPay() {
      if (this.initialStep === 1) {
        this.$emit("cancel")
      } else {
        this.loading = true
        this.$http
          .post("/ajax/appointment/" + this.appointment.appointment_id + "/terms/release", {
            term_id: this.picked,
          })
          .then(
            (response) => {
              this.loading = false
              this.goToStep(0)
            },
            (error) => {
              this.loading = false
              this.goToStep(0)
              this.throwRavenException("Reserve: ", error)
            }
          )
      }
    },
    setPaymentMethod(method) {
      this.paymentMethodSelected = method
    },
    initClientPayment() {
      braintree.client.create(
        { authorization: this.payment.token },
        (clientErr, clientInstance) => {
          console.log("braintree.client.create: ", clientErr, clientInstance)
          if (clientErr) {
            this.throwRavenException("BraintreeClient: ", clientErr)
            this.$bridge.emit(
              "addToast",
              this.$t.get("appointment.payment-methods-init-failed"),
              "error"
            )
            return
          }

          braintree.threeDSecure.create(
            {
              client: clientInstance,
              version: 2,
            },
            (threeDSecureErr, threeDSecureInstance) => {
              if (threeDSecureErr) {
                this.throwRavenException("BraintreeClient: ", threeDSecureErr)
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("appointment.payment-methods-init-failed"),
                  "error"
                )
                return
              }

              this.threeDSecureInstance = threeDSecureInstance
            }
          )

          this.paymentClient = clientInstance
          this.loading = false
        }
      )
    },
    confirmPayment(result) {
      if (result === true) {
        this.step = 2
      }
    },
    finish() {
      this.$emit("success")
    },
    throwRavenException(label, data) {
      try {
        throw new Error(label + JSON.stringify(data))
      } catch (e) {
        console.log(e)
        if (process.env.production) Sentry.captureException(e)
      }
    },
  },
}
