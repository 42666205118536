//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Filters from "../../../plugins/Filters.vue"
import ComponentMixin, { componentEventBus } from "./ComponentMixin"
import { debounce } from "lodash"

export default {
  mixins: [ComponentMixin],
  name: "CustomerSearchFilters",
  data() {
    return {
      search: {},
    }
  },
  components: {
    filters: Filters,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isSearchEmpty() {
      return Object.keys(this.nonEmptySearchFields).length === 0
    },
    nonEmptySearchFields() {
      return Object.keys(this.search)
        .filter((k) => this.search[k] != null && this.search[k] !== "")
        .reduce((a, k) => ({ ...a, [k]: this.search[k] }), {})
    },
  },
  watch: {
    search: {
      deep: true,
      handler: debounce(function () {
        if (this.processing === true) {
          return
        }
        if (this.isSearchEmpty) {
          // user cleared all fields so fire an clear-filters event
          this.clearFilters()
          return
        }
        this.searchCustomers(1)
      }, 1000),
    },
  },
  created() {
    this.clearInputs()
  },
  methods: {
    clearInputs() {
      componentEventBus.$emit("clear-input", true)
    },
    clearFilters() {
      this.clearInputs()
      this.$emit("clear-filters")
      this.search = {}
      this.$forceUpdate()
    },
    getRequiredAtLeastOneFields() {
      return this.preparedSearchComponents.filter(
        (column) => column.props.requiredAtLeastOne === "true"
      )
    },
    checkForRequiredAtLeastOne() {
      const requiredSearch = this.getRequiredAtLeastOneFields()

      return (
        requiredSearch.length > 0 &&
        requiredSearch.some((item) =>
          Object.keys(this.nonEmptySearchFields).includes(item.type)
        ) === false
      )
    },
    searchCustomers(page) {
      if (this.checkForRequiredAtLeastOne()) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("product.customer-filters-required-at-least-one", {
            fields: this.getRequiredAtLeastOneFields().map((field) =>
              this.$t.get(
                this.getColumnComponent(field.type).props.defaultTranslation.default ??
                  this.getColumnComponent(field.type).props.headerTranslation.default ??
                  field.type
              )
            ),
          }),
          "error"
        )

        return
      }
      if (this.isSearchEmpty) {
        return
      }
      this.emitProcessing(true)
      let params = {
        page: page,
        action: "searchCustomers",
        componentId: this.componentId,
      }
      params = { ...params, ...{ search: this.nonEmptySearchFields } }

      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.emitProcessing(false)
          this.$emit("customers-results", response.data.results)
        })
        .catch((response) => {
          this.emitProcessing(false)
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
  },
}
