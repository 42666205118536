//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {},
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    defaultLocale() {
      return window.__LOCALE
    },
    locales() {
      return JSON.parse(window.__LOCALES)
    },
  },
  mounted() {
    this.selected = this.value ? this.value : this.defaultLocale
  },
  watch: {
    selected() {
      this.$emit("input", this.selected)
    },
  },
}
