//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {
    onChecked: {
      default: null,
    },
    onUnchecked: {
      default: null,
    },
  },
  methods: {
    change(e) {
      if (this.onChecked !== null && e.target.checked) {
        this.handleComponentEvent(this.onChecked)
      }
      if (this.onUnchecked !== null && e.target.checked === false) {
        this.handleComponentEvent(this.onUnchecked)
      }
    },
    setCheckedState(value) {
      if (this.value === value) {
        return
      }
      this.$emit("input", value)
      this.$forceUpdate()
    },
  },
}
