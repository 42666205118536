//
//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"

export default {
  name: "gender",
  mixins: [ColumnMixin],
  props: {
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-2/12 columns",
          input: "w-2/12 columns",
          row: "w-2/12 columns",
        }
      },
    },
    defaultTranslation: {
      type: String,
      default: "auth.gender",
    },
  },
}
