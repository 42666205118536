var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box margin-top cases-details" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t.get("customer.details.menu.cases")) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.cases.length != 0 || _vm.cases.length === 0
          ? _c("div", { staticClass: "table-empty" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "lg:w-full w-full columns text-center" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t.get("common.there-is-no-available-data-yet")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass: "table-header",
                    staticStyle: { "min-width": "500px" },
                  },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("appointment.salesforce.date-opened")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns pl-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t.get("appointment.salesforce.case-number")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("appointment.salesforce.assigned-to")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          _vm._s(_vm.$t.get("appointment.salesforce.case-type"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          _vm._s(_vm.$t.get("appointment.salesforce.priority"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-1/12 columns" }, [
                        _vm._v(_vm._s(_vm.$t.get("common.status"))),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.cases, function (sfcase, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "table-row",
                      staticStyle: { "min-width": "500px" },
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "w-2/12 columns" }, [
                          sfcase.request_date
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$dateTimeFormat.formatDate(
                                        sfcase.request_date
                                      )
                                    ) + ", "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$dateTimeFormat.formatTime(
                                          sfcase.request_date
                                        )
                                      )
                                  ),
                                ]),
                              ])
                            : _c("span", [_vm._v("---")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-2/12 columns pl-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(sfcase.number) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-2/12 columns pr-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(sfcase.owner_name) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-2/12 columns pr-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(sfcase.type) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-2/12 columns" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-3 py-2 rounded-md font-semibold text-white",
                              class: _vm.getPriorityClass(sfcase.priority),
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(sfcase.priority) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-1/12 columns" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(sfcase.status) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-1/12 colums" },
                          [
                            _c(
                              "vue-context-menu",
                              {
                                staticClass: "right-top",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "styled",
                                                rawName: "v-styled:color",
                                                value:
                                                  _vm.palette.colors.primary
                                                    .main,
                                                expression:
                                                  "palette.colors.primary.main",
                                                arg: "color",
                                              },
                                              {
                                                name: "styled",
                                                rawName: "v-styled:color.hover",
                                                value:
                                                  _vm.palette.colors.hover.main,
                                                expression:
                                                  "palette.colors.hover.main",
                                                arg: "color",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "ict-menu",
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "styled",
                                          rawName: "v-styled:color",
                                          value:
                                            _vm.palette.colors.primary.main,
                                          expression:
                                            "palette.colors.primary.main",
                                          arg: "color",
                                        },
                                        {
                                          name: "styled",
                                          rawName: "v-styled:color.hover",
                                          value: _vm.palette.colors.hover.alt,
                                          expression:
                                            "palette.colors.hover.alt",
                                          arg: "color",
                                          modifiers: { hover: true },
                                        },
                                        {
                                          name: "styled",
                                          rawName: "v-styled:background.hover",
                                          value: _vm.palette.colors.hover.main,
                                          expression:
                                            "palette.colors.hover.main",
                                          arg: "background",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        target: "_blank",
                                        href: sfcase.case_url,
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "ict-report" }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t.get(
                                              "appointment.salesforce.go-to-case"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                sfcase.type === "Private Diagnostics" &&
                                sfcase.status !== "closed"
                                  ? _c("li", [
                                      _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "styled",
                                              rawName: "v-styled:color",
                                              value:
                                                _vm.palette.colors.primary.main,
                                              expression:
                                                "palette.colors.primary.main",
                                              arg: "color",
                                            },
                                            {
                                              name: "styled",
                                              rawName: "v-styled:color.hover",
                                              value:
                                                _vm.palette.colors.hover.alt,
                                              expression:
                                                "palette.colors.hover.alt",
                                              arg: "color",
                                              modifiers: { hover: true },
                                            },
                                            {
                                              name: "styled",
                                              rawName:
                                                "v-styled:background.hover",
                                              value:
                                                _vm.palette.colors.hover.main,
                                              expression:
                                                "palette.colors.hover.main",
                                              arg: "background",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createAssesment(sfcase)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ict-payments",
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t.get(
                                                  "appointment.salesforce.create-assessment"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "table-header", staticStyle: { "min-width": "500px" } },
      [_c("div", { staticClass: "flex" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }