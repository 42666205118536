var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input" },
    [
      _c("div", { staticClass: "checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.checked,
              expression: "checked",
            },
          ],
          staticClass: "inside",
          class: { error: _vm.validation && _vm.validation.$error },
          attrs: {
            type: "checkbox",
            disabled: _vm.disabled || _vm.readOnly,
            id: _vm.inputId,
          },
          domProps: {
            checked: _vm.checked,
            checked: Array.isArray(_vm.checked)
              ? _vm._i(_vm.checked, null) > -1
              : _vm.checked,
          },
          on: {
            input: _vm.input,
            change: [
              function ($event) {
                var $$a = _vm.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checked = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checked = $$c
                }
              },
              function ($event) {
                return _vm.change($event)
              },
            ],
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: _vm.inputId } }),
      ]),
      _vm._v(" "),
      _c("label", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }