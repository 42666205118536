var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c("div", { staticClass: "material-form term-picker" }, [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap flex-spacing",
          class: { "preloader preloader-cover": _vm.processing },
        },
        [
          _c("div", { staticClass: "w-full end columns" }, [
            _c("div", { staticClass: "checkboxes inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.slotType,
                    expression: "form.slotType",
                  },
                ],
                attrs: { type: "radio", id: "single-slot" },
                domProps: {
                  value: "single",
                  checked: _vm._q(_vm.form.slotType, "single"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.form, "slotType", "single")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "single-slot" } }, [
                _vm._v(_vm._s(_vm.$t.get("common.single-slot"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.slotType,
                    expression: "form.slotType",
                  },
                ],
                attrs: { type: "radio", id: "range-slot" },
                domProps: {
                  value: "range",
                  checked: _vm._q(_vm.form.slotType, "range"),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.form, "slotType", "range")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "range-slot" } }, [
                _vm._v(_vm._s(_vm.$t.get("common.range-of-slots"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.form.slotType === "single"
            ? [
                _c("div", { staticClass: "w-full end columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("datepicker", {
                        class: { error: _vm.$v.form.date.$error },
                        attrs: {
                          language: _vm.datepickerLanguage,
                          id: "custom-Date",
                          format: _vm.customFormatter,
                          disabledDates: {
                            to: _vm.$moment().subtract(1, "days").toDate(),
                          },
                          placeholder: _vm.$t.get("common.select-date"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.date.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "custom-date" } }, [
                        _vm._v(_vm._s(_vm.$t.get("common.date"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.date },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("v-select", {
                        class: { error: _vm.$v.form.hour.$error },
                        attrs: {
                          id: "custom-hour-from",
                          placeholder: _vm.$t.get("common.select-hour"),
                          options: _vm.availableHours,
                          "label-key": "label",
                          "value-key": "value",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.hour.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.hour,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "hour", $$v)
                          },
                          expression: "form.hour",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(_vm.$t.get("common.time")))]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.hour },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("v-select", {
                        class: { error: _vm.$v.form.minute.$error },
                        attrs: {
                          id: "custom-hour-from",
                          placeholder: _vm.$t.get("common.select-minute"),
                          options: _vm.availableMinutes,
                          "label-key": "value",
                          "value-key": "value",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.minute.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.minute,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "minute", $$v)
                          },
                          expression: "form.minute",
                        },
                      }),
                      _vm._v(" "),
                      _c("label"),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.minute },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full columns duration" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "v-select",
                        {
                          class: { error: _vm.$v.form.duration.$error },
                          on: {
                            input: function ($event) {
                              return _vm.$v.form.duration.$touch()
                            },
                          },
                          model: {
                            value: _vm.form.duration,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "duration", $$v)
                            },
                            expression: "form.duration",
                          },
                        },
                        [
                          _c("v-option", {
                            attrs: {
                              value: 15,
                              label: "15" + _vm.$t.get("common.min"),
                            },
                          }),
                          _vm._v(" "),
                          _c("v-option", {
                            attrs: {
                              value: 30,
                              label: "30" + _vm.$t.get("common.min"),
                            },
                          }),
                          _vm._v(" "),
                          _c("v-option", {
                            attrs: {
                              value: 60,
                              label: "60" + _vm.$t.get("common.min"),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t.get("common.duration"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.duration },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.canManageTimeSlots &&
                _vm.appointmentId &&
                _vm.isAppointmentForFree
                  ? _c("div", { staticClass: "w-full" }, [
                      _c("div", { staticClass: "checkboxes" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bookAutomatically,
                              expression: "bookAutomatically",
                            },
                          ],
                          staticClass: "inside",
                          attrs: { type: "checkbox", id: "bookAutomatically" },
                          domProps: {
                            checked: Array.isArray(_vm.bookAutomatically)
                              ? _vm._i(_vm.bookAutomatically, null) > -1
                              : _vm.bookAutomatically,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.bookAutomatically,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.bookAutomatically = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.bookAutomatically = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.bookAutomatically = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "bookAutomatically" } }, [
                          _vm._v(
                            _vm._s(_vm.$t.get("appointment.book-selected-date"))
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.members.length > 0 && _vm.canManageGroupTimeSlots
                  ? _c("div", { staticClass: "w-full columns" }, [
                      _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c("v-select", {
                            staticClass: "user-select",
                            class: { error: _vm.$v.form.member.$error },
                            attrs: {
                              options: _vm.members,
                              "return-option": true,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.form.member.$touch()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return _c("div", {}, [
                                      _c("div", {
                                        staticClass: "avatar",
                                        style: {
                                          backgroundImage:
                                            "url(" +
                                            props.item.user.avatar_url +
                                            ")",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(props.item.user.name) +
                                            " " +
                                            _vm._s(props.item.user.surname)
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                                {
                                  key: "placeholder",
                                  fn: function (props) {
                                    return _c("div", {}, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(props.selected.user.name) +
                                            " " +
                                            _vm._s(
                                              props.selected.user.surname
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2281863608
                            ),
                            model: {
                              value: _vm.form.member,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "member", $$v)
                              },
                              expression: "form.member",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t.get("common.select-doctor"))),
                          ]),
                          _vm._v(" "),
                          _c("v-input-error", {
                            attrs: { validation: _vm.$v.form.member },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm.form.slotType === "range"
            ? [
                _c("div", { staticClass: "w-full lg:w-6/12 end columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("datepicker", {
                        class: { error: _vm.$v.form.date.$error },
                        attrs: {
                          language: _vm.datepickerLanguage,
                          id: "custom-Date",
                          format: _vm.customFormatter,
                          disabledDates: {
                            to: _vm.$moment().subtract(1, "days").toDate(),
                          },
                          placeholder: _vm.$t.get("common.select-date"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.date.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.from_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "from_date", $$v)
                          },
                          expression: "form.from_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "custom-date" } }, [
                        _vm._v(_vm._s(_vm.$t.get("common.from-the-date"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.date },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-6/12 end columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("datepicker", {
                        class: { error: _vm.$v.form.date.$error },
                        attrs: {
                          language: _vm.datepickerLanguage,
                          id: "custom-Date",
                          format: _vm.customFormatter,
                          disabledDates: _vm.disabledDates,
                          placeholder: _vm.$t.get("common.select-date"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.date.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.to_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "to_date", $$v)
                          },
                          expression: "form.to_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "custom-date" } }, [
                        _vm._v(_vm._s(_vm.$t.get("common.to-the-date"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.date },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full columns week-days" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(
                      _vm._s(_vm.$t.get("common.select-days-of-the-week"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _vm._l(_vm.weekDays, function (day) {
                        return [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.days,
                                expression: "form.days",
                              },
                            ],
                            key: "input-" + day.label,
                            attrs: {
                              type: "checkbox",
                              id: day.label,
                              disabled:
                                _vm.daysRangeCount < 7 &&
                                _vm.isDisabledDay(day.value),
                            },
                            domProps: {
                              value: day.value,
                              checked: Array.isArray(_vm.form.days)
                                ? _vm._i(_vm.form.days, day.value) > -1
                                : _vm.form.days,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.days,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = day.value,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "days",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "days",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "days", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              key: "label-" + day.label,
                              staticClass: "day",
                              attrs: { for: day.label },
                            },
                            [_vm._v(_vm._s(day.label))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("v-select", {
                        class: { error: _vm.$v.form.from_time.$error },
                        attrs: {
                          id: "custom-time-from",
                          placeholder: _vm.$t.get("common.select-hour"),
                          options: _vm.availableTimes,
                          "label-key": "value",
                          "value-key": "value",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.from_time.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.from_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "from_time", $$v)
                          },
                          expression: "form.from_time",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t.get("common.from-time"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.from_time },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("v-select", {
                        class: { error: _vm.$v.form.to_time.$error },
                        attrs: {
                          id: "custom-time-to",
                          placeholder: _vm.$t.get("common.select-hour"),
                          options: _vm.availableTimes,
                          "label-key": "value",
                          "value-key": "value",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.form.to_time.$touch()
                          },
                        },
                        model: {
                          value: _vm.form.to_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "to_time", $$v)
                          },
                          expression: "form.to_time",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t.get("common.to-time"))),
                      ]),
                      _vm._v(" "),
                      _c("v-input-error", {
                        attrs: { validation: _vm.$v.form.to_time },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full lg:w-6/12 columns duration" },
                  [
                    _c(
                      "div",
                      { staticClass: "input" },
                      [
                        _c(
                          "v-select",
                          {
                            class: { error: _vm.$v.form.duration.$error },
                            on: {
                              input: function ($event) {
                                return _vm.$v.form.duration.$touch()
                              },
                            },
                            model: {
                              value: _vm.form.duration,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "duration", $$v)
                              },
                              expression: "form.duration",
                            },
                          },
                          [
                            _c("v-option", {
                              attrs: {
                                value: 15,
                                label: "15" + _vm.$t.get("common.min"),
                              },
                            }),
                            _vm._v(" "),
                            _c("v-option", {
                              attrs: {
                                value: 30,
                                label: "30" + _vm.$t.get("common.min"),
                              },
                            }),
                            _vm._v(" "),
                            _c("v-option", {
                              attrs: {
                                value: 60,
                                label: "60" + _vm.$t.get("common.min"),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t.get("common.duration"))),
                        ]),
                        _vm._v(" "),
                        _c("v-input-error", {
                          attrs: { validation: _vm.$v.form.duration },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.members.length > 0 && _vm.canManageGroupTimeSlots
                  ? _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                      _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c("v-select", {
                            staticClass: "user-select",
                            class: { error: _vm.$v.form.member.$error },
                            attrs: {
                              options: _vm.members,
                              "return-option": true,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.form.member.$touch()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return _c("div", {}, [
                                      _c("div", {
                                        staticClass: "avatar",
                                        style: {
                                          backgroundImage:
                                            "url(" +
                                            props.item.user.avatar_url +
                                            ")",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(props.item.user.name) +
                                            " " +
                                            _vm._s(props.item.user.surname)
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                                {
                                  key: "placeholder",
                                  fn: function (props) {
                                    return _c("div", {}, [
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(props.selected.user.name) +
                                            " " +
                                            _vm._s(
                                              props.selected.user.surname
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              2281863608
                            ),
                            model: {
                              value: _vm.form.member,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "member", $$v)
                              },
                              expression: "form.member",
                            },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t.get("common.select-doctor"))),
                          ]),
                          _vm._v(" "),
                          _c("v-input-error", {
                            attrs: { validation: _vm.$v.form.member },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-list" }, [
        _c(
          "button",
          {
            staticClass: "button accent",
            class: _vm.saveButtonClasses,
            attrs: { disabled: _vm.processing },
            on: { click: _vm.addTimeSlot },
          },
          [_vm._v("\n        " + _vm._s(_vm.saveButtonLabel) + "\n      ")]
        ),
        _vm._v(" "),
        _vm.showCancelButton
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.processing },
                on: { click: _vm.cancel },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("common.cancel")) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }