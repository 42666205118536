import { cornerstone, cornerstoneTools } from "../../cornerstone"
const BaseTool = cornerstoneTools.import("base/BaseTool")
const scroll = cornerstoneTools.import("util/scroll")

export default class ArrowsStackScrollTool extends BaseTool {
  constructor(configuration) {
    super({
      name: "ArrowsStackScroll",
      supportedInteractionTypes: [],
      mixins: ["activeOrDisabledBinaryTool"],
      configuration: {},
    })

    this._configuration = configuration
  }

  activeCallback(element) {
    element.addEventListener("focus", this.focus)
    element.addEventListener("blur", this.blur)

    element.setAttribute("tabindex", "-1")
    element.focus()
    element.addEventListener("keydown", this.keyboardEvent)
  }

  disabledCallback(element) {
    element.removeEventListener("keydown", this.keyboardEvent)
    element.removeAttribute("tabindex")

    element.removeEventListener("focus", this.focus)
    element.removeEventListener("blur", this.blur)
    element.classList.remove("focused")
  }

  keyboardEvent(event) {
    if (event.isComposing || event.keyCode === 37) {
      scroll(event.target, -1)
    }

    if (event.isComposing || event.keyCode === 39) {
      scroll(event.target, 1)
    }
  }

  focus(event) {
    event.target.classList.add("focused")
  }

  blur(event) {
    event.target.classList.remove("focused")
  }
}
