import { includes } from "lodash"
import Modal from "./modal/modal"
import VueCropper from "../plugins/VueCropper.vue"

export default {
  methods: {
    setAvatar(event, callback) {
      let $this = this
      let avatarImageTypes = ["image/jpeg", "image/png", "image/jpg"]
      if (!event.target.files[0]) return

      let file = event.target.files[0]
      if (!includes(avatarImageTypes, file.type)) {
        $this.$bridge.emit("addToast", "File must be format: jpeg or png", "error")
        return
      }

      let reader = new FileReader()

      reader.onload = function (e) {
        Modal.create($this)
          .title("Crop your avatar")
          .component(VueCropper)
          .classes(["modal-cropper"])
          .props({
            image: e.target.result,
          })
          .events({
            success: (result) => callback(result),
          })
          .show()
      }

      reader.readAsDataURL(file)

      if (event.target) {
        event.target.value = null
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
  },
}
