import Vue from "../../vue"
import VueModal from "./VueModal.vue"
import VueConfirm from "./VueConfirm.vue"

class ComponentModal {
  modal = null
  constructor(context) {
    this.options = {
      el: document.createElement("div"),
      propsData: {
        programmatic: true,
      },
      data: {
        opened: false,
      },
    }
    this.options.parent = context
  }

  title(title) {
    this.options.propsData.title = title
    return this
  }

  component(component) {
    this.options.propsData.component = component
    return this
  }

  footer(slot) {
    return this
  }

  classes(classes) {
    this.options.propsData.classes = classes
    return this
  }

  closeable(value) {
    this.options.propsData.closeable = value
    return this
  }

  props(props) {
    this.options.propsData.props = props
    return this
  }

  data(data) {
    this.options.data = { ...this.options.data, ...data }
    return this
  }

  events(events) {
    this.options.propsData.events = events
    return this
  }

  create() {
    let ComponentClass = Vue.extend(VueModal)
    this.modal = new ComponentClass(this.options)
    return this.modal
  }

  show() {
    this.options.data.opened = true
    this.options.propsData.destroy = true

    this.create.call(this)
  }

  close() {
    this.modal.close()
  }
}

class ConfirmModal {
  constructor(context) {
    this.options = {
      el: document.createElement("div"),
      propsData: {
        programmatic: true,
        closeable: false,
      },
      data: {
        opened: false,
      },
    }
    this.options.parent = context
  }

  title(title) {
    this.options.propsData.title = title
    return this
  }

  content(html) {
    this.options.data.content = html
    return this
  }

  classes(classes) {
    this.options.propsData.classes = ["modal-confirm", ...classes]
    return this
  }

  positive(text, action, className = null) {
    this.options.data.positive = { text, action, class: className }
    return this
  }

  negative(text, action) {
    this.options.data.negative = { text, action: action || null }
    return this
  }

  create() {
    let ComponentClass = Vue.extend(VueConfirm)
    return new ComponentClass(this.options)
  }

  show() {
    this.options.data.opened = true
    this.options.propsData.destroy = true

    this.create.call(this)
  }
}

export default class Modal {
  static create(context) {
    return new ComponentModal(context)
  }

  static confirm(context) {
    return new ConfirmModal(context)
  }
}
