//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { normalizeRole } from "./utils"
import { createNamespacedHelpers } from "vuex"
import { email, required } from "vuelidate/lib/validators"
import { notInObjectsArray } from "../../plugins/vuelidateAdditional"

import { findIndex, head } from "lodash"

const { mapGetters } = createNamespacedHelpers("clinicalTrial")

export default {
  props: {
    participants: {
      required: true,
    },
    roles: {
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      email: "",
      default_avatar: "/assets/app/img/default_avatar.png",
    }
  },
  validations: {
    email: {
      email,
      required,
      notInObjectsArray: notInObjectsArray("participants", "email"),
    },
  },
  computed: {
    emptyList() {
      return Object.keys(this.participants).length === 0
    },
    requesterRole() {
      return this.roles.find((role) => role.name == "trial_requester")
    },
  },
  mounted() {},
  methods: {
    addParticipantByEmail() {
      let exist = findIndex(this.participants, { email: this.email })
      if (this.$v.email.$invalid || exist !== -1) {
        this.$bridge.emit("addToast", "Invalid email or exist on list. Please correct.", "error")
        return
      }
      this.addParticipant(this.email, this.email)
      this.email = ""
    },
    addParticipant(name, email) {
      let role = head(this.roles)
      let avatar_url = this.default_avatar

      this.participants.push({
        name: name,
        email: typeof email !== "undefined" ? email : null,
        role: role,
        avatar_url: avatar_url,
        request_prefix: "",
        notes: "",
      })
    },
    removeParticipant(key) {
      this.participants.splice(key, 1)
    },
    normalizeRole,
  },
}
