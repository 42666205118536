//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, sameAs, minLength } from "vuelidate/lib/validators"

export default {
  props: {
    token: {
      default: null,
    },
  },
  data() {
    return {
      state: "init", // [init, sending, done]
      resendForm: false,
      inputs: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      checkImg: "/assets/app/img/check_custom.png",
    }
  },
  validations() {
    return {
      inputs: {
        password: {
          required,
          minLength: minLength(12),
          containsNumber: (value) => /\d/.test(value),
          containsSymbol: (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
          containsUpperAndLower: (value) => /[a-z]/.test(value) && /[A-Z]/.test(value),
        },
        password_confirmation: { sameAsPassword: sameAs("password"), required },
      },
    }
  },
  computed: {
    validationsMessages() {
      return [
        { message: this.$t.get("auth.twelveCharacters"), passed: false },
        { message: this.$t.get("auth.numberCharacter"), passed: false },
        { message: this.$t.get("auth.symbolCharacter"), passed: false },
        { message: this.$t.get("auth.uperLowerCharacter"), passed: false },
      ]
    },
  },
  mounted() {
    if (this.token !== "") this.inputs.token = this.token
  },
  methods: {
    validatePassword() {
      const password = this.inputs.password

      this.validationsMessages[0].passed = password.length >= 12
      this.validationsMessages[1].passed = /\d/.test(password) // Verifica si tiene al menos un número
      this.validationsMessages[2].passed = /[!@#$%^&*(),.?":{}|<>]/.test(password) // Verifica si tiene al menos un símbolo
      this.validationsMessages[3].passed = /[a-z]/.test(password) && /[A-Z]/.test(password) // Verifica si tiene mayúscula y minúscula
    },
    showResend() {
      this.resendForm = true
    },
    resetPassword(e) {
      e.preventDefault()
      this.state = "sending"
      this.$http.post("/ajax/password/reset", this.inputs).then(
        (response) => {
          this.$bridge.emit("addToast", this.$t.get("auth.email-instructions-sent"), "success")
          this.state = "done"
          this.$emit("success")
        },
        (error) => {
          this.state = "done"
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    setNewPassword(e) {
      e.preventDefault()
      this.state = "sending"
      this.inputs.token = this.token
      this.$http.post("/ajax/password/new", this.inputs).then(
        (response) => {
          this.state = "done"
          this.$bridge.emit("addToast", this.$t.get("auth.forgot-password-set"), "success")
          if (response.data.data.redirect_url) {
            window.location.href = response.data.data.redirect_url
          }
          this.$emit("success")
        },
        (error) => {
          this.state = "done"
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
  },
  watch: {
    "inputs.password": function () {
      this.validatePassword()
    },
  },
}
