//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
import Booking from "./Booking.vue"
export default {
  data() {
    return {
      reason: null,
    }
  },
  computed: {
    appointment() {
      return this.$store.state.appointment
    },
  },
  methods: {
    getRedirectURL(no_start_prompt = false) {
      let url = "/appointment/" + this.appointment.appointment_id
      if (no_start_prompt === true) {
        url = url + "?no_start_prompt=1"
      }
      return url
    },
    finishConsultation() {
      return this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/call/finish")
        .then(
          (response) => {
            return true
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.message, "error")
            return false
          }
        )
    },
    leaveCall() {
      Modal.create(this)
        .component(() => import("./CallReviewModal.vue"))
        .title(this.$t.get("appointment.you-leave-the-call-now-please-leave-the-review"))
        .classes(["modal-call-finished"])
        .closeable(false)
        .props({
          redirectURL: this.getRedirectURL(true),
        })
        .show()
    },
    rescheduleCall() {
      Modal.create(this)
        .title(this.$t.get("appointment.reschedule-an-appointment"))
        .component(Booking)
        .props({
          bookingType: "video-call",
        })
        .events({
          success: () => {
            this.$emit("success")
            this.$bridge.emit(
              "addToast",
              this.$t.get("appointment.redirecting-to-the-enquiry-page"),
              "success"
            )
            window.location.href = this.getRedirectURL()
          },
        })
        .classes(["modal-reserve-appointment"])
        .show()
    },
  },
}
