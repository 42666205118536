var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c("div", { staticClass: "material-form" }, [
      _vm.hasOneGroup
        ? _c("div", [
            false === _vm.canTakeTheCaseToGroup(_vm.user.groups[0].group_id)
              ? _c("p", { staticClass: "info" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.you-have-already-taken-this-case-in-organization",
                          {
                            group: _vm.userGroups[0].name,
                          }
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _c("p", { staticClass: "info" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.the-case-will-be-assigned-to-you-in-organization",
                          {
                            group: _vm.userGroups[0].name,
                          }
                        )
                      ) +
                      "\n      "
                  ),
                ]),
          ])
        : _c("div", [
            _vm.userAvailableGroups.length === 0
              ? _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.you-cannot-take-this-case-to-any-of-your-groups"
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t.get(
                          "appointment.the-case-will-be-assigned-to-you-in-selected-organization"
                        )
                      ) +
                      "\n        "
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            id: "custom-hour-from",
                            placeholder: _vm.$t.get("common.select-group"),
                          },
                          model: {
                            value: _vm.selectedGroup,
                            callback: function ($$v) {
                              _vm.selectedGroup = $$v
                            },
                            expression: "selectedGroup",
                          },
                        },
                        _vm._l(
                          _vm.userAvailableGroups,
                          function (group, index) {
                            return _c("v-option", {
                              key: index,
                              attrs: {
                                value: group.group_id,
                                label: group.name,
                              },
                            })
                          }
                        ),
                        1
                      ),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(_vm.$t.get("common.group")))]),
                    ],
                    1
                  ),
                ]),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row collapse-sides material-form" }, [
        _c("div", { staticClass: "small-24 columns" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("language-select", {
                model: {
                  value: _vm.locale,
                  callback: function ($$v) {
                    _vm.locale = $$v
                  },
                  expression: "locale",
                },
              }),
              _vm._v(" "),
              _c("label", [
                _vm._v(_vm._s(_vm.$t.get("common.notification-language"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons-list margin" }, [
        _c(
          "button",
          {
            staticClass: "button accent",
            attrs: {
              disabled:
                _vm.processing ||
                (_vm.hasOneGroup === false && _vm.selectedGroup === null) ||
                (_vm.hasOneGroup === true &&
                  false ===
                    _vm.canTakeTheCaseToGroup(_vm.user.groups[0].group_id)),
            },
            on: { click: _vm.takeTheCase },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.type === "takeTheCase"
                    ? _vm.$t.get("appointment.take-the-case")
                    : _vm.$t.get("appointment.forward-the-enquiry")
                ) +
                "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { disabled: _vm.processing },
            on: { click: _vm.cancel },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t.get("common.cancel")) + "\n      "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }