var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category add-resource" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "name" }, [
        _c("span", [_vm._v(_vm._s(_vm.header))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c(
          "div",
          { staticClass: "material-form" },
          _vm._l(_vm.fields, function (field) {
            return _vm.form[field.key] !== undefined
              ? _c(
                  _vm.getComponent(field.type),
                  _vm._b(
                    {
                      key: field.key,
                      tag: "component",
                      attrs: {
                        "field-key": field.key,
                        "read-only": field.readOnly || false,
                        validation: _vm.$v.form[field.key],
                      },
                      on: { "modify-field-prop": _vm.modifyFieldProp },
                      model: {
                        value: _vm.form[field.key],
                        callback: function ($$v) {
                          _vm.$set(_vm.form, field.key, $$v)
                        },
                        expression: "form[field.key]",
                      },
                    },
                    "component",
                    _vm.mergeProps(_vm.props[field.key]),
                    false
                  ),
                  [_vm._v(_vm._s(_vm.$t.get(field.label)))]
                )
              : _vm._e()
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons-list margin" },
          [
            _vm.canUpdateResource
              ? _c(
                  "button",
                  {
                    staticClass: "button accent medium",
                    attrs: { disabled: _vm.processing },
                    on: { click: _vm.save },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t.get("common.save")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "button medium",
                attrs: { to: { path: "/" + _vm.resource }, replace: "" },
              },
              [_vm._v(_vm._s(_vm.$t.get("common.back")))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }