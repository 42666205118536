//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {
    options: {
      default: function () {
        return {}
      },
    },
  },
  computed: {
    quillOptions() {
      let options = { readOnly: this.readOnly }
      if (this.readOnly) {
        options.modules = { toolbar: false }
      }
      return Object.assign({}, this.options, options)
    },
  },
  methods: {},
}
