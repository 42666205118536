import { cornerstone, cornerstoneTools } from "../../cornerstone"
const BaseAnnotationTool = cornerstoneTools.import("base/BaseAnnotationTool")

const draw = cornerstoneTools.import("drawing/draw")
const getNewContext = cornerstoneTools.import("drawing/getNewContext")
const drawTextBox = cornerstoneTools.import("drawing/drawTextBox")

const textStyle = cornerstoneTools.textStyle

export default class StackPrefetchBarTool extends BaseAnnotationTool {
  constructor(configuration) {
    super({
      name: "StackPrefetchBar",
      supportedInteractionTypes: [],
      mixins: ["activeOrDisabledBinaryTool"],
      configuration: {},
    })

    this._configuration = configuration

    this._imageLoaded = this.imageLoaded.bind(this)
  }

  activeCallback(element) {
    console.log("StackPrefetchBarTool", "active")
    cornerstone.events.addEventListener(cornerstone.EVENTS.IMAGE_CACHE_CHANGED, this._imageLoaded)
  }

  disabledCallback(element) {
    console.log("StackPrefetchBarTool", "disabled")
    cornerstone.events.removeEventListener(
      cornerstone.EVENTS.IMAGE_CACHE_CHANGED,
      this._imageLoaded
    )
  }

  imageLoaded(event) {
    setTimeout(() => {
      cornerstone.invalidate(this.element)
    }, 300)
  }

  renderToolData(event) {
    const detail = event.detail

    const element = this.element
    const enabledElement = cornerstone.getEnabledElement(element)
    const canvas = enabledElement.canvas

    const image = detail.image
    const width = canvas.width
    const height = canvas.height

    const barHeight = 28

    const stack = enabledElement.toolStateManager.get(element, "stack")
    if (stack !== void 0) {
      const length = stack.data[0].imageIds.length
      const leftToLoad = enabledElement.toolStateManager.get(element, "stackPrefetch").data[0]
        .indicesToRequest.length
      const loaded = length - leftToLoad

      const percent = loaded / length

      if (percent < 1) {
        const context = getNewContext(canvas)

        const defaultTextStyle = textStyle.getFont()
        textStyle.setFont("bold 15px Arial")

        draw(context, (context) => {
          context.setTransform(1, 0, 0, 1, 0, 0)

          context.fillStyle = "#000000"
          context.fillRect(0, height - barHeight, width, height)

          context.fillStyle = "#00ccb0"
          context.fillRect(0, height - barHeight, width * percent, height)

          const text = Math.round(percent * 100) + "%"
          const textMeasure = context.measureText(text)

          drawTextBox(
            context,
            [text],
            canvas.width / 2 - textMeasure.width / 2,
            canvas.height - 25,
            "#FFFFFF"
          )
        })

        textStyle.setFont(defaultTextStyle)
      }
    }
  }

  createNewMeasurement() {}
}
