export const Permissions = {
  // GROUP PERMISSIONS

  GROUP_MANAGE_MEMBERS: "group_manage_members",
  GROUP_MANAGE_BASIC_INFO: "group_manage_basic_info",
  GROUP_MANAGE_DEFAULT_PRICING: "group_manage_default_pricing",
  GROUP_MANAGE_SET_SETTLEMENTS_INFO: "group_manage_set_settlements_info",
  GROUP_MANAGE_VIEW_SETTLEMENTS: "group_manage_view_settlements",
  GROUP_MANAGE_CONSENTS: "group_manage_consents",
  GROUP_MANAGE_VIEW_ANALYTICS: "group_manage_view_analytics",
  GROUP_GLOBAL_REQUESTS_RECEIVE: "group_global_requests_receive",
  GROUP_GLOBAL_REQUESTS_ASSIGN: "group_global_requests_assign",
  GROUP_GLOBAL_REQUESTS_ACCEPT: "group_global_requests_accept",
  GROUP_GLOBAL_REQUESTS_DECLINE: "group_global_requests_decline",
  GROUP_GLOBAL_REQUESTS_VIEW_MEDICAL_RECORDS: "group_global_requests_view_medical_records",
  GROUP_GLOBAL_REQUESTS_ATTACH_RECORDS: "group_global_requests_attach_records",
  GROUP_GLOBAL_REQUESTS_DETACH_RECORDS: "group_global_requests_detach_records",
  GROUP_GLOBAL_REQUESTS_SHARE: "group_global_requests_share",
  GROUP_GLOBAL_REQUESTS_SET_PRICING: "group_global_requests_set_pricing",
  GROUP_GLOBAL_REQUESTS_SET_CONSULTATION_TYPE: "group_global_requests_set_consultation_type",
  GROUP_GLOBAL_REQUESTS_RESCHEDULE: "group_global_requests_reschedule",
  GROUP_GLOBAL_REQUESTS_CANCEL: "group_global_requests_cancel",
  GROUP_GLOBAL_REQUESTS_MANAGE_SLOTS: "group_global_requests_manage_slots",
  GROUP_GLOBAL_REQUESTS_JOIN_TO_PARTICIPANTS: "group_global_requests_join_to_participants",
  GROUP_GLOBAL_REQUESTS_INVITE_PARTICIPANTS: "group_global_requests_invite_participants",
  GROUP_GLOBAL_REQUESTS_REMOVE_PARTICIPANTS: "group_global_requests_remove_participants",
  GROUP_GLOBAL_REQUESTS_SET_PARTICIPANT_CALL: "group_global_requests_set_participant_call",
  GROUP_GLOBAL_REQUESTS_SET_REPORT_AUTHOR: "group_global_requests_set_report_author",
  GROUP_GLOBAL_REQUESTS_SEND_REPORT_BEHALF_OF: "group_global_requests_send_report_behalf_of",
  GROUP_GLOBAL_REQUESTS_GET_NOTIFICATIONS: "group_global_requests_get_notifications",
  GROUP_GLOBAL_REQUESTS_MUTE_PARTICIPANTS_NOTIFICATIONS:
    "group_global_requests_mute_participants_notifications",
  GROUP_GLOBAL_REQUESTS_MANAGE_ACTIVITY: "group_global_requests_manage_activity",
  GROUP_GLOBAL_ENQUIRY_MANAGE_THREADS: "group_global_enquiry_manage_threads",
  GROUP_GLOBAL_REQUESTS_VIEW_ALL_ENQUIRIES: "group_global_requests_view_all_enquiries",

  // APPOINTMENT PARTICIPANT PERMISSIONS

  APPOINTMENT_INVITE_PARTICIPANTS: "appointment_invite_participants",
  APPOINTMENT_VIEW_MEDICAL_RECORDS: "appointment_view_medical_records",
  APPOINTMENT_CALL_EXTEND: "appointment_call_extend",
  APPOINTMENT_CALL_FINISH: "appointment_call_finish",
  APPOINTMENT_RESCHEDULE: "appointment_reschedule",
  APPOINTMENT_CANCEL: "appointment_cancel",
  APPOINTMENT_SET_CONSULTATION_TYPE: "appointment_set_consultation_type",
  APPOINTMENT_BOOK: "appointment_book",
  APPOINTMENT_INTERNAL_NOTE: "appointment_internal_note",
  APPOINTMENT_TAKE_THE_CASE: "appointment_take_the_case",
  APPOINTMENT_REMOVE_PARTICIPANTS: "appointment_remove_participants",
  APPOINTMENT_SET_PARTICIPANT_CALL: "appointment_set_participant_call",
  APPOINTMENT_MANAGE_CALL: "appointment_manage_call",
}
