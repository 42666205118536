export const Constants = {
  Appointment: {
    /**
     * Consultation types
     */
    CONSULTATION_CALL_OPINION: 1,
    CONSULTATION_OPINION: 2,
    CONSULTATION_DIAGNOSTIC_TEST: 3,
    CONSULTATION_OVERSEAS_TREATMENT: 4,
    CONSULTATION_FRACTURE_COVER: 5,

    CONSULTATION_ELIGIBILITY_STATUS_ELIGIBLE: 1,
    CONSULTATION_ELIGIBILITY_STATUS_NOT_ELIGIBLE: -1,

    FILTER_UNASSIGNED: 1,
    FILTER_ASSIGNEE_ALL: 2,

    /**
     * Appointment status
     */
    STATUS_REUQESTED: 0,
    STATUS_ACCEPTED: 1,
    STATUS_REVOKED: -1,
    STATUS_PAYMENT_WAIT: 2,
    STATUS_PAYMENT_FAIL: -2,
    STATUS_PAID: 3,
    STATUS_CANCELED_CALL: -3,
    STATUS_RESCHEDULED: 4,
    STATUS_IN_PROGRESS: 5,
    STATUS_CALL_ENDED: 6,
    STATUS_DONE: 7,
  },
  Call: {
    STATUS_CREATED: 0,
    STATUS_STARTED: 1,
    STATUS_FINISHED: 2,
    STATUS_CANCELED: -1,

    PARTICIPANT_PERMISSION_MANAGE_CALL: "manage_call",
  },
  ClinicalTrialRequest: {
    RECEIVED: 0,
    ANONYMIZED: 1,
    ARCHIVE_CREATION_INVOKED: 2,
  },
  Invitation: {
    /**
     * Invitation status
     */
    STATUS_EXPIRED: -3,
    STATUS_REVOKED: -2,
    STATUS_REJECTED: -1,
    STATUS_CREATED: 0,
    STATUS_ACCEPTED: 1,

    /**
     * Invitation handler
     */
    HANDLER_APPOINTMENT_INVITATION: "appointment-invitation",
    HANDLER_CLINICAL_TRIAL_INVITATION: "clinical-trial-invitation",
    HANDLER_ORGANIZATION_INVITATION: "organization-invitation",
    HANDLER_APPOINTMENT_SHARE: "appointment-share",

    /**
     * Invitation scope
     */
    SCOPE_SHARE_PARTICIPANT_APPOINTMENT: "share-participant-appointment",
    SCOPE_INVITE_PARTICIPANT_APPOINTMENT: "invite-participant-appointment",
    SCOPE_INVITE_MEMBER_ORGANIZATION: "invite-member-organization",
    SCOPE_INVITE_MEMBER_CLINICAL_TRIAL: "invite-member-clinical-trial",
  },
  Payment: {
    STATUS_WAITING: 0,
    STATUS_SUCCESS: 1,
    STATUS_FAILED: -1,
    STATUS_REFUND_SUCCESS: 2,
    STATUS_REFUND_FAIL: -2,
    STATUS_SETTLED: 3,
    STATUS_CANCELLED: -3,
  },
  Settlement: {
    STATUS_FAILED: -1,
    STATUS_WAITING: 0,
    STATUS_SETTLED: 1,
  },
  Thread: {
    TYPE_APPOINTMENT: 1,
    TYPE_MESSAGE: 2,
    STATUS_OPENED: 1,
    STATUS_CLOSED: 2,
  },
  Message: {
    TYPE_SUPPORT: 3,
  },
  AppointmentParticipant: {
    TYPE_RECIPIENT: 1,
    TYPE_SENDER: 2,
    TYPE_GUEST: 3,
    TYPE_SHARED: 4,
  },
  GroupCustomer: {
    STATUS_ACTIVE: 1,
    STATUS_INACTIVE: -1,
  },
  Notification: {
    APPOINTMENT_ASSIGNED: 20,
    APPOINTMENT_BOOKED: 4,
    APPOINTMENT_CALL_STARTED: 13,
    APPOINTMENT_CANCELLED: 7,
    APPOINTMENT_CREATED: 1,
    APPOINTMENT_DELAYED: 8,
    APPOINTMENT_FORWARDED: 21,
    APPOINTMENT_REMINDER: 12,
    APPOINTMENT_REMINDER_REPORT: 31,
    APPOINTMENT_REQUEST_REMINDER: 32,
    APPOINTMENT_RESCHEDULED: 10,
    APPOINTMENTS_CLOSED: 33,
    APPOINTMENT_SECOND_OPINION_BOOKED: 19,
    APPOINTMENT_SETUP: 24,
    APPOINTMENT_TERM_ASSIGNED: 25,
    MEDICAL_RECORDS_ATTACHED: 16,
    REPORT_CREATED: 3,
    REQUEST_SENT: 34,
    MESSAGE_SENT: 9,
  },
  Salesforce: {
    CaseType: {
      PRIVATE_DIAGNOSTIC: "Private Diagnostics",
      FRACTURE_COVER: "Fracture cover",
    },
  },
}
