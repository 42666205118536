var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", class: [{ visible: _vm.opened }, _vm.classes] },
    [
      _c("div", {
        ref: "backdrop",
        staticClass: "modal-backdrop",
        on: { click: _vm.cancel },
      }),
      _vm._v(" "),
      _c("div", { ref: "content", staticClass: "modal-content" }, [
        _vm.closeable
          ? _c(
              "button",
              {
                staticClass: "modal-close-button",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "modal-header" }, [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ]),
        _vm._v(" "),
        _vm.opened
          ? _c(
              "div",
              {
                staticClass: "modal-main",
                class: { "preloader preloader-cover": _vm.processing },
              },
              [
                _c("resize-observer", {
                  ref: "resizeObserver",
                  on: { notify: _vm.handleResize },
                }),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color:accent",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "color:accent",
                    },
                  ],
                  domProps: { innerHTML: _vm._s(_vm.content) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "buttons-list margin-20" }, [
                  _vm.positive && _vm.positive.class
                    ? _c(
                        "button",
                        {
                          ref: "confirm",
                          staticClass: "button",
                          class: _vm.positive.class,
                          attrs: { disabled: _vm.processing },
                          on: { click: _vm.actionPositive },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.positive.text) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm.positive
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:color",
                              value: _vm.palette.colors.primary.alt,
                              expression: "palette.colors.primary.alt",
                              arg: "color",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:background",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "background",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:border-color",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "border-color",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:background.hover",
                              value: _vm.palette.colors.hover.alt,
                              expression: "palette.colors.hover.alt",
                              arg: "background",
                              modifiers: { hover: true },
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:color.hover",
                              value: _vm.palette.colors.hover.main,
                              expression: "palette.colors.hover.main",
                              arg: "color",
                              modifiers: { hover: true },
                            },
                          ],
                          ref: "confirm",
                          staticClass: "button accent",
                          attrs: { disabled: _vm.processing },
                          on: { click: _vm.actionPositive },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.positive.text) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.negative
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:color",
                              value: _vm.palette.colors.secondary.main,
                              expression: "palette.colors.secondary.main",
                              arg: "color",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:background",
                              value: _vm.palette.colors.primary.alt,
                              expression: "palette.colors.primary.alt",
                              arg: "background",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:border-color",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "border-color",
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:background.hover",
                              value: _vm.palette.colors.hover.main,
                              expression: "palette.colors.hover.main",
                              arg: "background",
                              modifiers: { hover: true },
                            },
                            {
                              name: "styled",
                              rawName: "v-styled:color.hover",
                              value: _vm.palette.colors.hover.alt,
                              expression: "palette.colors.hover.alt",
                              arg: "color",
                              modifiers: { hover: true },
                            },
                          ],
                          staticClass: "button",
                          attrs: { disabled: _vm.processing },
                          on: { click: _vm.actionNegative },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.negative.text) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }