/**
 * @return {Object}
 */
var LocalPromise =
  typeof Promise !== "undefined"
    ? Promise
    : function () {
        return {
          then: function () {
            throw new Error("Queue.configure() before use Queue")
          },
        }
      }

var noop = function () {}

/**
 * @param {*} value
 * @returns {LocalPromise}
 */
var resolveWith = function (value) {
  if (value && typeof value.then === "function") {
    return value
  }

  return new LocalPromise(function (resolve) {
    resolve(value)
  })
}

/**
 * It limits concurrently executed promises
 *
 * @param {Number} [maxPendingPromises=Infinity] max number of concurrently executed promises
 * @param {Number} [maxQueuedPromises=Infinity]  max number of queued promises
 * @param {Boolean} [autoStart=true] auto start queue
 * @constructor
 *
 * @example
 *
 * var queue = new Queue(1);
 *
 * queue.add(function () {
 *     // resolve of this promise will resume next request
 *     return downloadTarballFromGithub(url, file);
 * })
 * .then(function (file) {
 *     doStuffWith(file);
 * });
 *
 * queue.add(function () {
 *     return downloadTarballFromGithub(url, file);
 * })
 * // This request will be paused
 * .then(function (file) {
 *     doStuffWith(file);
 * });
 */
function Queue(maxPendingPromises, maxQueuedPromises, autoStart) {
  this.pendingPromises = 0
  this.maxPendingPromises =
    typeof maxPendingPromises !== "undefined" ? maxPendingPromises + 1 : Infinity
  this.maxQueuedPromises = typeof maxQueuedPromises !== "undefined" ? maxQueuedPromises : Infinity
  this.queue = []
  this.autoStart = typeof autoStart == typeof undefined ? true : autoStart
  this.finished = function () {}
}

/**
 * Defines promise promiseFactory
 * @param {Function} GlobalPromise
 */
Queue.configure = function (GlobalPromise) {
  LocalPromise = GlobalPromise
}

/**
 * @param {Function} promiseGenerator
 * @return {LocalPromise}
 */
Queue.prototype.add = function (promiseGenerator, context, ...args) {
  var self = this
  return new LocalPromise(function (resolve, reject, notify) {
    // Do not queue to much promises
    if (self.queue.length >= self.maxQueuedPromises) {
      reject(new Error("Queue limit reached"))
      return
    }

    // Add to queue
    self.queue.push({
      promiseGenerator: promiseGenerator,
      promiseGeneratorContext: context,
      promiseGeneratorArgs: args,
      resolve: resolve,
      reject: reject,
      notify: notify || noop,
      attempts: 0,
    })

    if (self.autoStart) self._dequeue()
  })
}

/**
 * Set on finish listener
 *
 * @return {number}
 */
Queue.prototype.setOnFinishListener = function (listener) {
  this.finished = listener
}

/**
 * Number of simultaneously running promises (which are resolving)
 *
 * @return {number}
 */
Queue.prototype.getPendingLength = function () {
  return this.pendingPromises
}

/**
 * Number of queued promises (which are waiting)
 *
 * @return {number}
 */
Queue.prototype.getQueueLength = function () {
  return this.queue.length
}

/**
 * Status of queue
 *
 * @return {boolean}
 */
Queue.prototype.isWorking = function () {
  return this.queue.length + this.pendingPromises > 0
}

/**
 * set autostart
 */
Queue.prototype.setAutostart = function (value) {
  this.autoStart = value
}

/**
 * start queue
 */
Queue.prototype.start = function () {
  this._dequeue()
}

/**
 * clear queue
 */
Queue.prototype.clear = function () {
  this.queue = []
}

/**
 * @returns {boolean} true if first item removed from queue
 * @private
 */
Queue.prototype._dequeue = function () {
  var self = this

  if (this.pendingPromises >= this.maxPendingPromises) {
    return false
  }

  // Remove from queue
  var item = this.queue.shift()
  if (!item) {
    return false
  }

  try {
    this.pendingPromises++

    resolveWith(
      item.promiseGenerator.apply(item.promiseGeneratorContext, item.promiseGeneratorArgs)
    )
      // Forward all stuff
      .then(
        function (value) {
          // It is not pending now
          self.pendingPromises--
          // It should pass values
          item.resolve(value)
          if (typeof self.finishedCall != typeof undefined) {
            clearTimeout(self.finishedCall)
          }
          if (!self.isWorking()) {
            self.finishedCall = setTimeout(() => {
              self.finished()
            }, 1000)
          }
          self._dequeue()
        },
        function (err) {
          // Add item again to queue and retry until 3 times
          if (++item.attempts < 4) {
            self.queue.push(item)
            // console.log('queue attempt: ' + item.attempts, item);
          } else {
            // It should not mask errors
            item.reject(err)
          }
          // It is not pending now
          self.pendingPromises--
          if (typeof self.finishedCall != typeof undefined) {
            clearTimeout(self.finishedCall)
          }
          if (!self.isWorking()) {
            self.finishedCall = setTimeout(() => {
              self.finished()
            }, 1000)
          }
          self._dequeue()
        },
        function (message) {
          // It should pass notifications
          item.notify(message)
        }
      )
  } catch (err) {
    self.pendingPromises--
    item.reject(err)
    self._dequeue()
  }

  if (this.pendingPromises < this.maxPendingPromises) {
    self._dequeue()
  }

  return true
}

export default Queue
