//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComponentMixin from "../ComponentMixin"

export default {
  mixins: [ComponentMixin],
  name: "LocalProviderResults",
  props: {
    results: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
}
