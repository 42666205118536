//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { actions } from "./builder"
import * as validatorsDefault from "vuelidate/lib/validators"
import * as validatorsAdditional from "../../plugins/vuelidateAdditional"

const validators = { ...validatorsDefault, ...validatorsAdditional }

import InputText from "../../plugins/inputs/InputText.vue"
import InputSelect from "../../plugins/inputs/InputSelect.vue"
import InputDate from "../../plugins/inputs/InputDate.vue"
import InputQuill from "../../plugins/inputs/InputQuill.vue"
import InputCheckbox from "../../plugins/inputs/InputCheckbox.vue"
import InputFiles from "../../plugins/inputs/InputFiles.vue"
import ButtonViewer from "../../plugins/inputs/ButtonViewer.vue"
import InputTime from "../../plugins/inputs/InputTime.vue"
import InputNumber from "../../plugins/inputs/InputNumber.vue"

export default {
  components: {
    "input-text": InputText,
    "input-select": InputSelect,
    "input-date": InputDate,
    "input-quill": InputQuill,
    "input-checkbox": InputCheckbox,
    "input-files": InputFiles,
    "button-viewer": ButtonViewer,
    "input-time": InputTime,
    "input-number": InputTime,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    resourceId: {
      type: String,
      default: null,
    },
    fields: {
      type: Array,
      default: function () {
        return []
      },
    },
    canDeleteResource: {
      type: Boolean,
      default: true,
    },
    canUpdateResource: {
      type: Boolean,
      default: true,
    },
    crudURL: {
      type: String,
      required: true,
    },
    extraRequestParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      processing: false,
      form: {},
      props: {},
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    if (this.resourceId !== null) {
      return
    }
    this.form = this.fields.reduce((acc, item) => {
      acc[item.key] = ""
      return acc
    }, {})
  },
  validations() {
    return this.validation
      ? {
          form: this.validation,
        }
      : {}
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case "text":
          return InputText
        case "select":
          return InputSelect
        case "date":
          return InputDate
        case "quill":
          return InputQuill
        case "checkbox":
          return InputCheckbox
        case "files":
          return InputFiles
        case "buttonViewer":
          return ButtonViewer
        case "time":
          return InputTime
        case "number":
          return InputNumber
      }
    },
    modifyFieldProp({ fieldKey, prop, value }) {
      let propSet = {}
      propSet[prop] = value
      this.$set(this.props, fieldKey, Object.assign({}, this.props[fieldKey] || {}, propSet))
    },
    mergeProps(props) {
      return Object.assign({}, this.$props, props)
    },
    fetchData() {
      if (this.resourceId === null) {
        // set props of components when new resource form is shown
        this.props = this.fields.reduce((acc, item) => {
          let props = item.props || {}
          Object.keys(props).map((key, idx) => {
            // workaround for CloudMixin fetching folders
            if (props[key] === "!prop!" && item.type === "files" && key === "objectId") {
              props[key] = new Date().getTime()
            }
            // prop value is not initialized, cast to null
            if (props[key] === "!prop!") {
              props[key] = null
            }
          })
          acc[item.key] = props
          return acc
        }, {})
        return
      }

      this.$http
        .post(
          this.crudURL,
          Object.assign(
            {},
            {
              action: actions.GET,
              fields: this.form,
              params: Object.assign(this.$route.params, this.extraRequestParams),
            }
          )
        )
        .then(
          (response) => {
            this.processing = false
            const fields = response.data[0].fields

            // set field values
            this.form = this.fields.reduce((acc, item) => {
              const val = fields.find((field) => field.key === item.key)
              if (val === undefined) {
                return acc
              }
              acc[item.key] = val ? val.value : ""
              return acc
            }, {})
            // set props values
            this.props = fields.reduce((acc, item) => {
              const val = fields.find((field) => field.key === item.key)
              acc[item.key] = val ? val.props : {}
              return acc
            }, {})
          },
          (error) => {
            this.processing = false
          }
        )
    },
    save() {
      if (this.$v.$invalid == true) {
        this.$v.$touch()
      } else {
        this.processing = true
        const action = this.resourceId === null ? actions.ADD : actions.UPDATE
        this.$http
          .post(
            this.crudURL,
            Object.assign({}, { action: action, fields: this.form, params: this.$route.params })
          )
          .then(
            (response) => {
              this.processing = false
              // unregister dynamic vuex store to force refetch attached files
              this.unregisterCloudStore()
              this.$router.replace({ name: actions.LIST })
              this.$bridge.emit("addToast", response.data.message, "success")
            },
            (error) => {
              this.processing = false
              this.$bridge.emit("addToast", error.response.data.message, "error")
            }
          )
      }
    },
    unregisterCloudStore() {
      if (
        this.resourceId === null ||
        this.$store.state.cloud === undefined ||
        this.$store.state.cloud[this.resourceId] === void 0
      ) {
        return
      }

      this.$store.unregisterModule(["cloud", this.resourceId])
    },
  },
  computed: {
    header() {
      if (this.resourceId !== null) {
        if (this.canUpdateResource) {
          return this.$t.get("form.edit-resource", {
            resource: this.$t.get("form.section." + this.resource),
          })
        }
        return this.resourceDisplayName
      }
      return this.$t.get("form.add-new-resource", {
        resource: this.$t.get("form.section." + this.resource),
      })
    },
    resourceDisplayName() {
      return this.form.name ? this.form.name : this.resource
    },
    validation() {
      return this.fields.reduce((acc, field) => {
        let validation = Object.keys(field.validation || {}).reduce((acc, key) => {
          const item = field.validation[key]
          let [_, validator, args] = item.match(/^([^:\n]+)[:]?(.*)$/)
          args = args.match(/([^,\n]+)/g)

          if (typeof validators[validator] == "function") {
            if (args !== null) {
              acc[key] = validators[validator].apply(this, args)
            } else {
              acc[key] = validators[validator]
            }
          }

          return acc
        }, {})

        acc[field.key] = validation
        return acc
      }, {})
    },
  },
}
