//
//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"

export default {
  name: "created-at",
  mixins: [ColumnMixin],
  props: {
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-1/12 columns",
          row: "w-1/12 columns",
        }
      },
    },
    defaultTranslation: {
      type: String,
      default: "common.created-at",
    },
  },
}
