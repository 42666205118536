export default {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          $pageVisible: true,
        }
      },
      created() {
        this.checkPageVisibility()
        document.addEventListener("visibilitychange", this.checkPageVisibility, true)
      },
      methods: {
        checkPageVisibility() {
          this.$pageVisible = !document.hidden
        },
      },
    })
  },
}
