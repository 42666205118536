import * as validatorsDefault from "vuelidate/lib/validators"
import * as validatorsAdditional from "../../plugins/vuelidateAdditional"

const validators = { ...validatorsDefault, ...validatorsAdditional }

export default {
  data: function () {
    return {
      fieldsConditions: {},
      allFieldsFlatted: [],
    }
  },
  computed: {
    validation() {
      return this.fields.reduce((acc, field) => {
        if ("conditions" in field) {
          if (this.fieldConditionResolved(field) === true) {
            if (field["type"] === "condition_container") {
              field.children.forEach((childField) => {
                acc[childField.key] = this.getValidationRulesOfField(childField)
              })
            } else {
              acc[field.key] = this.getValidationRulesOfField(field)
            }
          }
        } else {
          acc[field.key] = this.getValidationRulesOfField(field)
        }

        return acc
      }, {})
    },
  },
  mounted() {
    this.fields.map((field) => {
      if (typeof field.conditions !== typeof undefined) {
        this.$set(this.fieldsConditions, field.key, field.conditions)
      }
    })

    this.allFieldsFlatted = this.fields.reduce((acc, field) => {
      if ("conditions" in field) {
        if (field["type"] === "condition_container") {
          field.children.forEach((childField) => {
            acc.push(childField)
          })
        } else {
          acc.push(field)
        }
      } else {
        acc.push(field)
      }

      return acc
    }, [])
  },
  methods: {
    fieldConditionResolved(field) {
      if (Object.keys(this.fieldsConditions).includes(field.key) === false) {
        return true
      }

      let conditionsTrue = true

      field.conditions.forEach((condition) => {
        let conditionedField = this.fields.find((field) => field.key === condition.field_key)

        if (conditionedField.type === "checkbox-group") {
          if (this.enquiry[condition.field_key].includes(condition.value) === false) {
            conditionsTrue = false
          }
        } else {
          if (this.enquiry[condition.field_key] !== condition.value) {
            conditionsTrue = false
          }
        }
      })

      return conditionsTrue === true
    },
    getValidationRulesOfField(field) {
      return Object.keys(field.validation || {}).reduce((acc, key) => {
        const item = field.validation[key]
        let [_, validator, args] = item.match(/^([^:\n]+)[:]?(.*)$/)
        args = args.match(/([^,\n]+)/g)

        if (typeof validators[validator] == "function") {
          if (args !== null) {
            acc[key] = validators[validator].apply(this, args)
          } else {
            acc[key] = validators[validator]
          }
        }

        return acc
      }, {})
    },
  },
}
