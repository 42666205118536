var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "margin-top" }, [
    _vm.ready
      ? _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-6/12 columns" },
            [
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("customer.details.benchmark.duration")) +
                    ":\n        "
                ),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(_vm._s(_vm.getDuration(_vm.benchmarkData.dates))),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t.get("customer.details.benchmark.resolution")
                    ) +
                    ":\n        "
                ),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(
                    _vm._s(Math.floor(_vm.benchmarkData.screen.width)) +
                      " x\n          " +
                      _vm._s(Math.floor(_vm.benchmarkData.screen.height))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t.get("customer.details.benchmark.video-server")
                    ) +
                    ":\n        "
                ),
                _c("span", { staticClass: "bold" }, [
                  _vm._v(
                    _vm._s(_vm.getTwilioResult(_vm.benchmarkData.twilio.result))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t.get("customer.details.benchmark.microphone-activity")
                  ) + ":"
                ),
              ]),
              _vm._v(" "),
              _vm.displayChart
                ? _c("chart-line", {
                    staticStyle: { height: "200px" },
                    attrs: {
                      "chart-data": _vm.micData,
                      options: _vm.chartOptions,
                      "gradient-color": "#00ccb1",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-6/12 columns" }, [
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("customer.details.benchmark.camera")) +
                  ":\n        "
              ),
              _c("span", { staticClass: "bold" }, [
                _vm._v(
                  _vm._s(_vm.getTwilioResult(_vm.benchmarkData.camera.result))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t.get("customer.details.benchmark.messaging-websocket")
                  ) +
                  ":\n        "
              ),
              _c("span", { staticClass: "bold" }, [
                _vm._v(
                  _vm._s(_vm.getTwilioResult(_vm.benchmarkData.socket.result))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("customer.details.common.browser-os")) +
                  ":\n        "
              ),
              _c("span", { staticClass: "bold" }, [
                _vm._v(
                  _vm._s(_vm.getBrowser(_vm.benchmarkData.userAgent)) +
                    "\n          " +
                    _vm._s(_vm.getBrowserOS(_vm.benchmarkData.userAgent))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("customer.details.benchmark.latency")) +
                  ":\n        "
              ),
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.benchmarkData.trustedoctor.logs) + " ms"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t.get("customer.details.benchmark.selected-device")
                  ) +
                  ":"
              ),
              _c("br"),
              _vm._v(" "),
              _c("ul", { staticClass: "devices" }, [
                _c("li", [
                  _c("i", { staticClass: "ict-mic" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.benchmarkData.selectedDevice.audio)),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("i", { staticClass: "ict-video" }),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.benchmarkData.selectedDevice.video)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t.get("customer.details.benchmark.permission-status")
                  ) +
                  ":"
              ),
              _c("br"),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("customer.details.benchmark.camera")) +
                  ":\n        "
              ),
              _c(
                "span",
                {
                  class:
                    (_vm.benchmarkData.permissionStatus.camera !== "granted"
                      ? "red"
                      : "") + " bold",
                },
                [_vm._v(_vm._s(_vm.benchmarkData.permissionStatus.camera))]
              ),
              _c("br"),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t.get("customer.details.benchmark.microphone")) +
                  ":\n        "
              ),
              _c(
                "span",
                {
                  class:
                    (_vm.benchmarkData.permissionStatus.microphone !== "granted"
                      ? "red"
                      : "") + " bold",
                },
                [_vm._v(_vm._s(_vm.benchmarkData.permissionStatus.microphone))]
              ),
              _c("br"),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }