import ActionHr from "./ActionHr.vue"
import ActionDetails from "./ActionDetails.vue"
import ActionSetupAppointment from "./ActionSetupAppointment.vue"
import ActionRemove from "./ActionRemove.vue"
import ActionOnboard from "./ActionOnboard.vue"
import ActionToggleStatus from "./ActionToggleStatus.vue"
import ActionOnboardLocal from "./ActionOnboardLocal.vue"
import ActionMedicalCloud from "./ActionMedicalCloud.vue"

export default {
  methods: {
    getActionComponent(type) {
      switch (type) {
        case "hr":
          return ActionHr
        case "onboard":
          return ActionOnboard
        case "onboardLocal":
          return ActionOnboardLocal
        case "details":
          return ActionDetails
        case "medicalCloud":
          return ActionMedicalCloud
        case "setupAppointment":
          return ActionSetupAppointment
        case "toggleStatus":
          return ActionToggleStatus
        case "remove":
          return ActionRemove
      }
    },
    actionsForScope(actions, scope) {
      return actions.filter(
        (action) => action.visibilityScope === null || action.visibilityScope.indexOf(scope) !== -1
      )
    },
  },
}
