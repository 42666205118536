var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content table-scroll" },
    [
      _c(
        "div",
        { staticClass: "table-header", staticStyle: { "min-width": "500px" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-1/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("customer.details.benchmark.score"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-3/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("customer.details.common.created-at"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-4/12 columns" }, [
              _vm._v(_vm._s(_vm.$t.get("customer.details.common.browser-os"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-2/12 columns" }, [
              _vm._v(
                _vm._s(_vm.$t.get("customer.details.benchmark.resolution"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/12 columns" }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.benchmark_id,
            staticClass: "table-row row-hoverable",
            staticStyle: { "min-width": "500px" },
          },
          [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-1/12 columns" }, [
                _c("strong", { staticClass: "accent" }, [
                  _vm._v(_vm._s(_vm.getScore(item.response[0]))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$dateTimeFormat.formatTime(item.created_at)) +
                    ",\n        " +
                    _vm._s(_vm.$dateTimeFormat.formatDate(item.created_at)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-4/12 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getBrowser(item.response[0].userAgent)) +
                    "\n        " +
                    _vm._s(_vm.getBrowserOS(item.response[0].userAgent)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-2/12 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(Math.floor(item.response[0].screen.width)) +
                    " x\n        " +
                    _vm._s(Math.floor(item.response[0].screen.height)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-1/12 columns" },
                [
                  _c("vue-context-menu", { staticClass: "right-over accent" }, [
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openBenchmarkDetails(item.response[0])
                          },
                        },
                      },
                      [
                        _c("a", [
                          _c("i", { staticClass: "ict-video" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t.get(
                                  "customer.details.benchmark.benchmark-details"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    item.object_id !== null && item.scope === "appointment"
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              attrs: { href: "/appointment/" + item.object_id },
                            },
                            [
                              _c("i", { staticClass: "ict-enquiries" }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t.get(
                                      "customer.details.benchmark.consultation-details"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.processing && _vm.items.total === 0
        ? _c("div", { staticClass: "table-empty" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "lg:w-full w-full columns text-center" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("customer.details.common.no-data")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }