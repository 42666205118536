var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category list-resources" }, [
    _c("div", { staticClass: "title medical-records" }, [
      _c("div", { staticClass: "name" }, [
        _c("span", [_vm._v(_vm._s(_vm.resourceName))]),
      ]),
      _vm._v(" "),
      _vm.canAddResource
        ? _c(
            "button",
            {
              staticClass: "button small",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addResource()
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t.get("profile.add-a-record")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content medical-records",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c("div", { staticClass: "scroll" }, [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-8/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.name"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-4/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("form.created-at"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list" },
            [
              _vm._l(_vm.items, function (item) {
                return _c(
                  "div",
                  { key: item.resource_id, staticClass: "single" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.editResource(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "w-8/12 columns" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-4/12 columns" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(item.timestamps.created_at)
                                  .format("YYYY-MM-DD, HH:mm")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canUpdateResource
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "tooltip", rawName: "v-tooltip" },
                            ],
                            staticClass: "edit",
                            attrs: { alt: _vm.$t.get("profile.edit-record") },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.editResource(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "ict-pencil-thin" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canDeleteResource
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "tooltip", rawName: "v-tooltip" },
                            ],
                            staticClass: "trash",
                            attrs: { alt: _vm.$t.get("profile.delete-record") },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteResource(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "ict-trash" })]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.items.length === 0 && _vm.processing === false
                ? _c("div", { staticClass: "single" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t.get("common.list-empty"))),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }