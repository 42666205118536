//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    route: {
      type: String,
    },
    component: {
      default: null,
    },
    detachTab: {
      default: true,
    },
    badge: {
      default: null,
    },
    props: {
      default: () => {},
    },
    tabKey: {
      default: null,
    },
    visible: {
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.$parent.addTab(this)
  },
}
