var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    !_vm.isChatOnline
      ? _c("div", { staticClass: "messages disabled" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isChatOnline,
                  expression: "!isChatOnline",
                },
              ],
              staticClass: "overlay",
            },
            [
              _c("div", [
                _c("i", { staticClass: "ict-messages-cloud" }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t.get("appointment.chat-is-offline"))),
                ]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isChatOnline
      ? _c(
          "div",
          { staticClass: "messages" },
          [
            _c(
              "v-scrollbar",
              { ref: "chat" },
              _vm._l(_vm.messages, function (message, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "message",
                    class: {
                      system: message.is_system,
                      "my-message": message.author === _vm.user.user_id,
                    },
                  },
                  [
                    !message.is_system
                      ? [
                          _c("div", { staticClass: "user" }, [
                            message.author === _vm.user.user_id
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "color",
                                      },
                                    ],
                                    staticClass: "name",
                                  },
                                  [
                                    _vm._t(
                                      "participant",
                                      function () {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getParticipantName(
                                                message.author
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                      { userId: message.author }
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "span",
                                  { staticClass: "name" },
                                  [
                                    _vm._t(
                                      "participant",
                                      function () {
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getParticipantName(
                                                message.author
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                      { userId: message.author }
                                    ),
                                  ],
                                  2
                                ),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$moment(message.timestamp).format("h:mma")
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.bindHyperlinks(message.body)
                              ),
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    message.is_system
                      ? [
                          _c("p", [
                            _c("span", [_vm._v(_vm._s(message.name))]),
                            _vm._v(
                              "\n            " +
                                _vm._s(message.body) +
                                "\n          "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.READY
      ? _c(
          "div",
          {
            staticClass: "new-message",
            class: { disabled: !_vm.isChatOnline || _vm.sending },
          },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message",
                },
                {
                  name: "vertical-align-content",
                  rawName: "v-vertical-align-content",
                },
              ],
              attrs: {
                placeholder: _vm.$t.get("appointment.type-message-here"),
              },
              domProps: { value: _vm.message },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.sendChatMessage.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.message = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("i", {
              directives: [
                {
                  name: "styled",
                  rawName: "v-styled:color",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "color",
                },
              ],
              staticClass: "ict-level-down",
              on: { click: _vm.sendChatMessage },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }