var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.state === _vm.states.INITIALIZING
      ? _c("div", [
          _vm._v(_vm._s(_vm.$t.get("appointment.initializing-gateway"))),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.READY
      ? _c(
          "div",
          [
            _vm.tokenSupply !== null
              ? [
                  _c("form", { attrs: { id: "cardForm" } }, [
                    _c("div", { staticClass: "half exp" }, [
                      _c("label", { attrs: { for: "token-amount" } }, [
                        _vm._v(_vm._s(_vm.$t.get("appointment.token-amount"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          attrs: { id: "token-amount" },
                        },
                        [_vm._v(_vm._s(_vm.amountToken))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "half" }, [
                      _c("label", { attrs: { for: "account" } }, [
                        _vm._v(_vm._s(_vm.$t.get("appointment.your-account"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          attrs: { id: "account" },
                        },
                        [_vm._v(_vm._s(_vm.account))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "half" }, [
                      _c("label", { attrs: { for: "balance" } }, [
                        _vm._v(
                          _vm._s(_vm.$t.get("appointment.tokens-you-own"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          attrs: { id: "balance" },
                        },
                        [_vm._v(_vm._s(Number(_vm.tokenSupply.balance)))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons-list text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button border",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.cancelPay.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t.get("appointment.cancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button accent",
                          attrs: {
                            disabled:
                              _vm.tokenSupply === null ||
                              _vm.tokenSupply.balance < _vm.amountToken,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.transfer()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t.get(
                                  "appointment.transfer-amount-token-tdh-tokens",
                                  { amount: _vm.amountToken }
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t.get(
                            "appointment.please-click-transfer-button-and-accept-transaction-in-meta-mask"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.TX_WAITING_ACCEPT
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t.get(
                  "appointment.waiting-for-your-acceptation-please-accept-transaction-in-meta-mask"
                )
              ) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.TX_WAITING_CONFIRM
      ? _c("div", [
          _vm.txReceipt === null
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(
                      "appointment.waiting-for-transaction-to-be-mined-in-eth-network"
                    )
                  )
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.TX_MINED
      ? _c(
          "div",
          [
            _vm.tokenSupply !== null
              ? [
                  _c("form", { attrs: { id: "cardForm" } }, [
                    _c("div", { staticClass: "half exp" }, [
                      _c("label", { attrs: { for: "txReceipt" } }, [
                        _vm._v(
                          _vm._s(_vm.$t.get("appointment.transaction-receipt"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          staticStyle: { width: "110%" },
                          attrs: { id: "txReceipt" },
                        },
                        [_vm._v(_vm._s(_vm.txReceipt))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "buttons-list text-center" }, [
                      _c(
                        "a",
                        {
                          ref: "noopener nofollow",
                          staticClass: "button accent",
                          attrs: { href: _vm.txEtherscanURL, target: "_blank" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t.get("appointment.show-on-etherscan"))
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t.get(
                          "appointment.when-the-payment-is-processed-your-appointment"
                        )
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state === _vm.states.ERROR
      ? _c(
          "div",
          [
            _vm.error === _vm.errors.FAILED_UNSUPPORTED_BROWSER
              ? [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get("appointment.meta-mask-browser-extension")
                      ),
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.error === _vm.errors.FAILED_UNSUPPORTED_NETWORK
              ? [
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t.get(
                            "appointment.please-open-meta-mask-and-switch-to-the-main-ethereum-network"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.error === _vm.errors.FAILED_USER_NOT_LOGGED
              ? [
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t.get(
                            "appointment.you-are-not-logged-in-to-meta-mask-please-open-meta-mask-and-provide-password-to-unlock-your-account"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.error === _vm.errors.FAILED_TRANSACTION_REJECTED_BY_USER
              ? [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t.get("appointment.you-rejected-the-transaction")
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.error === _vm.errors.FAILED_TRANSACTION_FAILED
              ? [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t.get(
                          "appointment.transaction-has-not-been-processed-by-ethereum-netwoek"
                        )
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.error === _vm.errors.FAILED_TRANSACTION_INSUFFICIENT_FUNDS
              ? [
                  _c("form", { attrs: { id: "cardForm" } }, [
                    _c("div", { staticClass: "half exp" }, [
                      _c("label", { attrs: { for: "txRequired" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("appointment.required-amount-of-tokens")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          attrs: { id: "txRequired" },
                        },
                        [_vm._v(_vm._s(_vm.amountToken))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "half exp" }, [
                      _c("label", { attrs: { for: "txBalance" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t.get("appointment.amount-of-tokens-you-own")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "hosted-field",
                          attrs: { id: "txBalance" },
                        },
                        [_vm._v(_vm._s(Number(_vm.tokenSupply.balance)))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t.get(
                          "appointment.there-are-not-enough-tokens-in-your-account"
                        )
                      )
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons-list text-center" }, [
              _c(
                "button",
                {
                  staticClass: "button border",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cancelPay.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("appointment.cancel")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button accent",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.reset()
                      _vm.state = _vm.states.INITIALIZING
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t.get("appointment.retry")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }