//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
import ResetPassword from "../../components/common/ResetPassword.vue"

export default {
  data() {
    return {
      state: "init", // [init, sending, done]
    }
  },
  methods: {
    forgotPassword() {
      this.$emit("cancel")
      Modal.create(this)
        .title(this.$t.get("auth.reset-password"))
        .component(ResetPassword)
        .props({
          token: window.__RESET_TOKEN,
        })
        .show()
    },
  },
}
