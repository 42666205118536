//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomCheckbox from "../../common/Checkbox.vue"

export default {
  components: { CustomCheckbox },
  data() {
    return {
      patientProfiles: [],
      checkedPatientProfile: null,
    }
  },
  mounted() {
    this.$http.get("/account/settings/data-portability/patient-profiles").then((response) => {
      this.patientProfiles = response.data
    })
  },
  methods: {
    download() {
      window.location.href = `/account/settings/data-portability/patient-profiles/${this.checkedPatientProfile.id}`
    },
  },
}
