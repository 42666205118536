var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box personal-information" }, [
    _c(
      "div",
      {
        staticClass: "content",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "w-full lg:w-3/12 columns avatar" }, [
            _c(
              "div",
              {
                staticClass: "image",
                class: { "preloader preloader-cover": _vm.processing },
              },
              [
                _vm.profileAvatar.url.length == 0 && _vm.profile
                  ? _c("img", {
                      attrs: {
                        id: "image_src",
                        src: _vm.profile.avatar_url,
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        id: "image_src",
                        src: _vm.profileAvatar.url,
                        alt: "",
                      },
                    }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.profile.name)),
              _c("br"),
              _vm._v(_vm._s(_vm.profile.surname)),
            ]),
          ]),
          _vm._v(" "),
          _vm.profile
            ? _c("div", { staticClass: "w-full lg:w-9/12 columns details" }, [
                _c("div", { staticClass: "informations" }, [
                  _c("div", { staticClass: "section" }, [
                    _vm._v(_vm._s(_vm.$t.get("profile.patient-details"))),
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color.before",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color",
                            modifiers: { before: true },
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t.get("profile.date-of-birth")) +
                            ":\n              "
                        ),
                        _vm.profile.birthday
                          ? _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$moment(_vm.profile.birthday)
                                    .format("DD.MM.YYYY")
                                ) +
                                  " (" +
                                  _vm._s(
                                    _vm.$t.get("profile.age-in-years", {
                                      years: _vm
                                        .$moment()
                                        .diff(
                                          _vm.$moment(
                                            _vm.profile.birthday,
                                            "YYYY.MM.DD"
                                          ),
                                          "years"
                                        ),
                                    })
                                  ) +
                                  ")"
                              ),
                            ])
                          : _c("strong", [_vm._v("-")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color.before",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color",
                            modifiers: { before: true },
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t.get("profile.gender")) +
                            ":\n              "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.profile.gender === "male"
                                ? _vm.$t.get("common.male")
                                : _vm.$t.get("common.female")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color.before",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color",
                            modifiers: { before: true },
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t.get("profile.city")) +
                            ":\n              "
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.profile.city || "-"))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "styled",
                            rawName: "v-styled:color.before",
                            value: _vm.palette.colors.primary.main,
                            expression: "palette.colors.primary.main",
                            arg: "color",
                            modifiers: { before: true },
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t.get("profile.country")) +
                            ":\n              "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.profile.country
                                ? _vm.$t.get("countries." + _vm.profile.country)
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "actions" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "w-full md:w-8/12 columns" },
                      [
                        _vm.readOnly === false
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.primary.alt,
                                    expression: "palette.colors.primary.alt",
                                    arg: "color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "background",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:border-color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "border-color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background.hover",
                                    value: _vm.palette.colors.hover.alt,
                                    expression: "palette.colors.hover.alt",
                                    arg: "background",
                                    modifiers: { hover: true },
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color.hover",
                                    value: _vm.palette.colors.hover.main,
                                    expression: "palette.colors.hover.main",
                                    arg: "color",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "button accent",
                                on: { click: _vm.shareProfile },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t.get("profile.share-profile")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.readOnly === false
                          ? _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.secondary.main,
                                    expression: "palette.colors.secondary.main",
                                    arg: "color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background",
                                    value: _vm.palette.colors.primary.alt,
                                    expression: "palette.colors.primary.alt",
                                    arg: "background",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:border-color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "border-color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background.hover",
                                    value: _vm.palette.colors.hover.main,
                                    expression: "palette.colors.hover.main",
                                    arg: "background",
                                    modifiers: { hover: true },
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color.hover",
                                    value: _vm.palette.colors.hover.alt,
                                    expression: "palette.colors.hover.alt",
                                    arg: "color",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "button",
                                attrs: { tag: "button", to: "/shared" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t.get("profile.shared-profile"))
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.readOnly === false
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.secondary.main,
                                    expression: "palette.colors.secondary.main",
                                    arg: "color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background",
                                    value: _vm.palette.colors.primary.alt,
                                    expression: "palette.colors.primary.alt",
                                    arg: "background",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:border-color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "border-color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background.hover",
                                    value: _vm.palette.colors.hover.main,
                                    expression: "palette.colors.hover.main",
                                    arg: "background",
                                    modifiers: { hover: true },
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color.hover",
                                    value: _vm.palette.colors.hover.alt,
                                    expression: "palette.colors.hover.alt",
                                    arg: "color",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "button md:hidden",
                                attrs: { disabled: _vm.processing },
                                on: { click: _vm.updateProfile },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t.get("profile.edit-information")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "hidden md:block md:w-4/12 columns md:text-right",
                      },
                      [
                        _vm.readOnly === false
                          ? _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color",
                                    value: _vm.palette.colors.secondary.main,
                                    expression: "palette.colors.secondary.main",
                                    arg: "color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background",
                                    value: _vm.palette.colors.primary.alt,
                                    expression: "palette.colors.primary.alt",
                                    arg: "background",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:border-color",
                                    value: _vm.palette.colors.primary.main,
                                    expression: "palette.colors.primary.main",
                                    arg: "border-color",
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:background.hover",
                                    value: _vm.palette.colors.hover.main,
                                    expression: "palette.colors.hover.main",
                                    arg: "background",
                                    modifiers: { hover: true },
                                  },
                                  {
                                    name: "styled",
                                    rawName: "v-styled:color.hover",
                                    value: _vm.palette.colors.hover.alt,
                                    expression: "palette.colors.hover.alt",
                                    arg: "color",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "button",
                                attrs: {
                                  "data-cy": "edit-information",
                                  disabled: _vm.processing,
                                },
                                on: { click: _vm.updateProfile },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t.get("profile.edit-information")
                                    ) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }