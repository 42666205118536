//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios"
import { ResizeObserver } from "vue-resize"

import { Droppable } from "../../../../helpers/dragdrop.js"

import StackCache from "../StackCache.js"

import DataType from "./DataType.js"
// import ImageView from "./views/ImageView.vue"
// import DicomView from "./views/DicomView.vue"
// import PdfView from "./views/PdfView.vue"

import Tools from "../tools/Tools.js"
import ToolsMixin from "../tools/ToolsMixin.js"
import Download from "../tools/list/Download.js"
import Copy from "../tools/list/Copy.js"

import { v4 as uuid } from "uuid"

export default {
  mixins: [ToolsMixin],
  inject: {
    stateManager: {
      default: null,
    },
  },
  props: {
    id: {
      default: uuid(),
      required: true,
    },
  },
  // store,
  provide() {
    return {
      id: this.id,
    }
  },
  components: {
    "resize-observer": ResizeObserver,
    "image-view": () => import(/* webpackChunkName: "viewer-data-views" */ "./views/ImageView.vue"),
    "dicom-view": () => import(/* webpackChunkName: "viewer-data-views" */ "./views/DicomView.vue"),
    "pdf-view": () => import(/* webpackChunkName: "viewer-data-views" */ "./views/PdfView.vue"),
  },
  data() {
    return {
      DataType: DataType,
      droppableArea: false,
      dataType: null,
      data: null,
      dataChangedAt: new Date().getTime(),
      contextMenu: {
        active: false,
        position: {
          left: 0,
          top: 0,
        },
      },
      hash: null,
    }
  },
  computed: {
    contextToolsList() {
      return {
        ZOOM: Tools.list.ZOOM,
        Metadata: Tools.list.Metadata,
        Download: new Download(),
        Copy: new Copy(),
      }
    },
    viewToolsList() {
      if (this.dataType === DataType.DICOM || this.dataType === DataType.IMAGE) {
        return {
          Download: new Download(),
          Copy: new Copy(),
        }
      } else if (this.dataType === DataType.PDF) {
        return {
          Download: new Download(),
        }
      }
    },
    inViewer() {
      return typeof this.$parent.viewsInstances !== void 0
    },
    viewType() {
      if (
        this.stateManager !== null &&
        this.stateManager.isEnabled() &&
        this.$store.state.viewer.views[this.id] !== void 0
      ) {
        return this.$store.state.viewer.views[this.id].type
      } else {
        return this.dataType
      }
    },
    viewData() {
      if (
        this.stateManager !== null &&
        this.stateManager.isEnabled() &&
        this.$store.state.viewer.views[this.id] !== void 0
      ) {
        return this.$store.state.viewer.views[this.id].data
      } else {
        return this.data
      }
    },
  },
  mounted() {
    this.$emit("view-mounted", this)

    const droppable = new Droppable(this.$el)
    droppable.setOnDragEnter((ev) => {
      let dropArea = this.$el.querySelector(".droppable-area")
      if (dropArea !== null) {
        dropArea.className = dropArea.className + " hover"
      }
    })
    droppable.setOnDragLeave((ev) => {
      let dropArea = this.$el.querySelector(".droppable-area")
      if (dropArea !== null) {
        dropArea.className = dropArea.className.replace(/\s*hover\s*/g, "")
      }
    })
    droppable.setOnDrop((ev) => {
      let dropArea = this.$el.querySelector(".droppable-area")
      if (dropArea !== null) {
        dropArea.className = dropArea.className.replace(/\s*hover\s*/g, "")
      }
    })
    droppable.setOnDataTransfer((dataTransfer) => {
      setTimeout(() => {
        this.loadStack(dataTransfer)
      }, 100)
    })

    document.addEventListener("mousedown", this.contextMenuHide.bind(this))
  },
  destroyed() {
    document.removeEventListener("mousedown", this.contextMenuHide.bind(this))
    this.$emit("view-destroyed", this)
  },
  methods: {
    handleResize() {
      if (this.$refs.dataView !== void 0) {
        this.$refs.dataView.handleResize()
      }
    },
    setDroppableAreaState(state) {
      this.droppableArea = state
    },
    async loadStack(stack) {
      this.resetView()

      this.dataChangedAt = new Date().getTime()

      const { type, hash, download_url, name, object_id } = stack

      this.dataType = type
      this.hash = hash

      if (StackCache.instance().has(hash)) {
        setTimeout(() => {
          // To give Vue time for resetView
          this.bindStack(StackCache.instance().get(hash))
        }, 100)
      } else {
        try {
          const response = await axios.get(
            window.__CLOUD_URL + "/objects/" + object_id + "/stacks/" + hash,
            {
              withCredentials: true,
              headers: {
                Authorization: "Bearer " + window.USER_JWT,
              },
            }
          )

          StackCache.instance().set(hash, response.data)
          this.bindStack(response.data)
        } catch (error) {
          console.log("error", error)
        }
      }
    },
    resetView() {
      this.dataType = null
      this.data = null
      this.dataChangedAt = new Date().getTime()
    },
    bindStack(items) {
      this.data = items

      if (this.stateManager !== null && this.stateManager.isEnabled()) {
        this.stateManager.dataChanged(this.id, this.dataType, this.data)
      }
    },
    viewDataLoaded() {
      this.$emit("view-data-changed", this)
    },
    contextMenuShow(event) {
      if (this.viewType === DataType.DICOM || this.viewType === DataType.IMAGE) {
        event.preventDefault()
        event.stopPropagation()

        this.contextMenu.active = true
        this.contextMenu.position = {
          left: event.clientX + "px",
          top: event.clientY + "px",
        }

        return false
      }
    },
    contextMenuHide(event) {
      const path = event.path || event.composedPath()
      if (
        (event.button !== 2 || event.which !== 3) &&
        path.indexOf(this.$el.querySelector(".context-menu-dropdown")) === -1 &&
        this.contextMenu.active
      ) {
        this.contextMenu.active = false
      }
    },
  },
}
