// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header.section[data-v-3f46d984] {
    font-weight: normal;
    font-size: 27px;
    color: #000;
    margin: 0 0 25px;
}
.header.group[data-v-3f46d984] {
    font-weight: 500;
    font-size: 15px;
    color: #07203b;
    margin: 0 0 28px;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/plugins/form-decorators/FormHeader.vue"],"names":[],"mappings":";AA2BA;IACA,mBAAA;IACA,eAAA;IACA,WAAA;IACA,gBAAA;AACA;AAEA;IACA,gBAAA;IACA,eAAA;IACA,cAAA;IACA,gBAAA;AACA","sourcesContent":["<template>\n  <div class=\"header\" v-bind:class=\"[level]\" v-html=\"html\"></div>\n</template>\n<script>\nexport default {\n  props: {\n    text: {\n      type: String,\n    },\n    level: {\n      type: String,\n      default: \"section\",\n      validator(value) {\n        return [\"section\", \"group\"].includes(value)\n      },\n    },\n  },\n  computed: {\n    html() {\n      // `this.$attrs.value` is a fallback for old component\n      return this.text || this.$attrs.value\n    },\n  },\n}\n</script>\n<style lang=\"postcss\" scoped>\n.header {\n  &.section {\n    font-weight: normal;\n    font-size: 27px;\n    color: #000;\n    margin: 0 0 25px;\n  }\n\n  &.group {\n    font-weight: 500;\n    font-size: 15px;\n    color: #07203b;\n    margin: 0 0 28px;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
