//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"
export default {
  mixins: [Customize({})],
  props: {
    id: { default: null },
    buttonClass: { default: "" },
    containerClass: { default: "" },
    dropdownClass: { default: "" },
    hideOnItemClick: { default: true },
    hideOtherDropdowns: { default: true },
    currentContextMenuId: { default: null },
  },
  data() {
    return {
      display: false,
    }
  },
  computed: {
    isVisible() {
      if (this.hideOtherDropdowns === false) return this.display
      if (this.currentContextMenuId !== null) return this.currentContextMenuId === this.id

      return false
    },
  },
  mounted() {},
  methods: {
    toggle() {
      this.display = !this.display

      if (this.hideOtherDropdowns === false) this.display = !this.display

      if (this.hideOtherDropdowns === true && this.id !== null) {
        if (this.isVisible) this.$emit("dropdown-button-toggle", null)
        else this.$emit("dropdown-button-toggle", this.id)
      }
    },
    itemClicked() {
      if (this.hideOnItemClick) this.toggle()
    },
  },
}
