var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "form position-relative",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _vm.enableSelection === true
          ? _c("div", { staticClass: "flex material-form" }, [
              _c("div", { staticClass: "w-full columns" }, [
                _c("div", { staticClass: "checkboxes inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: { type: "radio", id: "single-slot" },
                    domProps: {
                      value: "member",
                      checked: _vm._q(_vm.mode, "member"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.mode = "member"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "single-slot" } }, [
                    _vm._v(_vm._s(_vm.$t.get("share.by-selecting-member"))),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: { type: "radio", id: "range-slot" },
                    domProps: {
                      value: "email",
                      checked: _vm._q(_vm.mode, "email"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.mode = "email"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "range-slot" } }, [
                    _vm._v(_vm._s(_vm.$t.get("share.by-email"))),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.mode === "member"
          ? _c("div", { staticClass: "flex material-form" }, [
              _c("div", { staticClass: "w-full columns" }, [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _vm.members.length > 0
                      ? _c("v-select", {
                          staticClass: "user-select",
                          class: { error: _vm.$v.form.member.$error },
                          attrs: {
                            options: _vm.members,
                            "return-option": true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.form.member.$touch()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (props) {
                                  return _c("div", {}, [
                                    _c("div", {
                                      staticClass: "avatar",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          props.item.user.avatar_url +
                                          ")",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(props.item.user.name) +
                                          " " +
                                          _vm._s(props.item.user.surname)
                                      ),
                                    ]),
                                  ])
                                },
                              },
                              {
                                key: "placeholder",
                                fn: function (props) {
                                  return _c("div", {}, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(props.selected.user.name) +
                                          " " +
                                          _vm._s(props.selected.user.surname) +
                                          "\n              "
                                      ),
                                    ]),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            4128719928
                          ),
                          model: {
                            value: _vm.form.member,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "member", $$v)
                            },
                            expression: "form.member",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.processing === false && _vm.members.length === 0
                      ? _c("div", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t.get("common.list-empty")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.mode === "email"
          ? _c(
              "div",
              { staticClass: "flex material-form flex-wrap flex-spacing" },
              [
                _c("div", { staticClass: "w-full columns" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.email,
                          expression: "form.email",
                        },
                      ],
                      class: { error: _vm.$v.form.email.$error },
                      attrs: { type: "text", id: "share-email" },
                      domProps: { value: _vm.form.email },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                          function ($event) {
                            return _vm.$v.form.email.$touch()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "share-email" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t.get("share.email")) +
                          "\n            "
                      ),
                      _c("span", { staticClass: "text-alert" }, [_vm._v("*")]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-full columns" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.message,
                          expression: "form.message",
                        },
                      ],
                      class: { error: _vm.$v.form.message.$error },
                      attrs: { type: "text", id: "guest-message" },
                      domProps: { value: _vm.form.message },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.form, "message", $event.target.value)
                          },
                          function ($event) {
                            return _vm.$v.form.message.$touch()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "guest-message" } }, [
                      _vm._v(_vm._s(_vm.$t.get("share.short-message"))),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-wrap flex-spacing material-form" },
          [
            _c("div", { staticClass: "w-full" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("language-select", {
                    model: {
                      value: _vm.form.locale,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "locale", $$v)
                      },
                      expression: "form.locale",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t.get("common.notification-language"))),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full columns" }, [
              _c("p", {
                staticClass: "info",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t.get("share.invite-permissions-information")
                  ),
                },
              }),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-list margin" }, [
      _c(
        "button",
        {
          staticClass: "button accent",
          attrs: { disabled: _vm.$v.$invalid || _vm.processing },
          on: { click: _vm.sendInvitation },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("share.send")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button border",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.close },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("common.cancel")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }