var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                ) +
                "\n  "
            ),
          ]
        : [
            _vm.customer.onboard_sent_at
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm
                          .$moment(_vm.customer.onboard_sent_at)
                          .format("DD.MM.YYYY, HH:mm")
                      ) +
                      "\n    "
                  ),
                ])
              : _vm.customer.tdOnboardSentAt
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm
                          .$moment(_vm.customer.tdOnboardSentAt)
                          .format("DD.MM.YYYY, HH:mm")
                      ) +
                      "\n    "
                  ),
                ])
              : _c("span", [_vm._v("-")]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }