//
//
//

import Customize from "../../../plugins/Customize"

export default {
  mixins: [Customize({})],
  props: {
    textArea: {
      type: String,
    },
  },
}
