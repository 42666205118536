import Vue from "vue"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

if (process.env.type === "production") {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
} else {
  Vue.config.devtools = true
  Vue.config.debug = true
  Vue.config.silent = false
}

if (process.env.production) {
  window.Sentry = Sentry

  Sentry.init({
    dsn: "https://1bb91429e80c4d8584df6ede298536f3@sentry.io/125194",
    debug: process.env.env === "development",
    release: process.env.release,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  })

  Sentry.setExtra("env", process.env.type)

  if (window.__USER != null) {
    Sentry.setUser({
      id: window.__USER.user_id,
    })
  } else {
    Sentry.setUser()
  }
}

// Vue.config.errorHandler = (err, vm, info) => {
// err = String | Error object (err.message, err.stack, err.toString())
// vm = VueComponent instance (vm.data, ...)
// info = some additional info (for example: 'beforeMount hook')
// }

import moment from "moment-timezone"

import VueHttp from "./plugins/VueHttp"
import Moment from "./plugins/VueMoment.js"
import Bridge from "./plugins/VueBridge.js"
import PageVisibility from "./plugins/PageVisibility"
import VueCroppie from "vue-croppie"
import Croppa from "vue-croppa"
import VueCookie from "vue-cookie"
import Vuelidate from "vuelidate"
import VueQuillEditor from "vue-quill-editor"
import Analytics from "./plugins/Analytics"

import Socket from "./plugins/socket"

import VueScrollbar from "./plugins/VueScroll.vue"
import VueReadMore from "./plugins/VueReadMore.vue"
import VuePaginator from "./plugins/VuePaginator.vue"
import VueDraggable from "./plugins/VueDraggable"
import store from "./stores/store"
import VueVirtualScroller from "vue-virtual-scroller"
import Downloads from "./components/cloud-manager/Downloads.vue"
import VueModal from "./plugins/modal/VueModal.vue"
import VueTabs from "./plugins/tabs/VueTabs.vue"
import VueTab from "./plugins/tabs/VueTab.vue"
import VueRouter from "vue-router"
import VueAccordion from "./plugins/accordion/VueAccordion.vue"
import VueAccordionItem from "./plugins/accordion/VueAccordionItem.vue"
import VueSelect from "./plugins/select/VueSelect.vue"
import VueOption from "./plugins/select/VueOption.vue"
import Datepicker from "vuejs-datepicker"
import TranslatePlugin from "./plugins/Translate"
import Translate from "../helpers/translate"

import FindBar from "./components/common/FindBar.vue"
import BoxSlide from "./components/common/BoxSlide.vue"
import Note from "./components/common/Note.vue"
import VueContextMenu from "./plugins/VueContextMenu.vue"
import VueInputError from "./plugins/VueInputError.vue"

import { Vue as DateTimeFormat } from "../helpers/datetimeformat"

//Directives
import VueTooltip from "./plugins/VueTooltip"
import VueCleave from "./plugins/VueCleave"
import DirectiveClickOutside from "./plugins/DirectiveClickOutside"

Vue.use(VueHttp)

if (window.__SOCKET_URL !== undefined) {
  Vue.use(
    new Socket({
      debug: process.env.production === false,
      location: window.__SOCKET_URL,
      vuex: {
        store,
        actionPrefix: "socket_",
        mutationPrefix: "socket_",
      },
    })
  )
}

Vue.use(Moment)
Vue.use(DateTimeFormat)
Vue.use(Bridge)
Vue.use(PageVisibility)
Vue.use(VueCookie)
Vue.use(Vuelidate)
Vue.use(VueDraggable)
Vue.use(VueVirtualScroller)
Vue.use(VueCroppie)
Vue.use(Croppa)
Vue.use(VueRouter)
Vue.use(VueQuillEditor, {
  placeholder: Translate.get("common.quill-placeholder"),
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ direction: "rtl" }],
    ],
  },
})
Vue.use(TranslatePlugin)
Vue.use(Analytics)

Vue.directive("tooltip", VueTooltip)
Vue.directive("cleave", VueCleave)
Vue.directive("click-outside", DirectiveClickOutside)

Vue.directive("vertical-align-content", {
  inserted: function (el) {
    const calculate = (e) => {
      let el = e.target

      let height = el.offsetHeight
      el.style.paddingTop = "0px"
      el.style.height = "0px"

      let paddingTop = Math.max(0, height / 2 - el.scrollHeight / 2)
      el.style.height = ""
      el.style.paddingTop = paddingTop + "px"
    }

    el.addEventListener("keyup", calculate)
    el.dispatchEvent(new Event("keyup"))
  },
  componentUpdated: function (el) {
    el.dispatchEvent(new Event("keyup"))
  },
})

// Filters
Vue.filter("truncate", function (value, length) {
  if (value && value.length > length + 3) {
    value = value.substring(0, length) + "..."
  }
  return value
})

//Global components
Vue.component("datepicker", Datepicker)
Vue.component("vue-find-bar", FindBar)
Vue.component("box-slide", BoxSlide)
Vue.component("note", Note)
Vue.component("vue-context-menu", VueContextMenu)
Vue.component("read-more", VueReadMore)
Vue.component("vue-paginator", VuePaginator)
Vue.component("downloads", Downloads)
Vue.component("v-modal", VueModal)
Vue.component("v-scrollbar", VueScrollbar)
Vue.component("v-tabs", VueTabs)
Vue.component("v-tab", VueTab)
Vue.component("v-accordion", VueAccordion)
Vue.component("v-accordion-item", VueAccordionItem)
Vue.component("v-select", VueSelect)
Vue.component("v-option", VueOption)
Vue.component("v-input-error", VueInputError)

import { get, set } from "lodash"
Vue.prototype.$path = { get, set }

export default Vue
