//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueRouter from "vue-router"

export default {
  router: new VueRouter({ mode: "history" }),
  props: {
    useRoutes: {
      default: false,
    },
    routerBase: {
      default: null,
    },
  },
  data() {
    return {
      tabs: [],
      currentTab: 0,
      routes: [],
    }
  },
  mounted() {
    if (this.useRoutes) {
      this.switchIndex(this.$router.currentRoute.name)
      this.$watch("$route.name", this.switchIndex)
    }
  },
  computed: {
    visibleTabs() {
      return this.tabs.filter((tab) => tab.visible)
    },
  },
  watch: {
    visibleTabs() {
      const tab = this.tabs[this.currentTab]
      if (tab.visible === false) {
        const firstVisibleIndex = this.tabs.findIndex((tab) => tab.visible)
        this.switchIndex(firstVisibleIndex)
      }
    },
  },
  methods: {
    addTab(tab) {
      this.tabs.push(tab)

      if (tab.visible && this.visibleTabs.length == 1) {
        const index = this.tabs.findIndex((item) => item._uid == tab._uid)
        this.currentTab = index
        this.tabs[index].isActive = true
      }

      if (this.useRoutes) {
        let route = {
          path: this.routerBase + tab.route,
          name: this.tabs.length - 1,
        }

        this.routes.push(route)
        this.$router.addRoutes([route])
      }
    },
    changeTab(to) {
      let index = null
      if (typeof to === "number") {
        index = to
      } else {
        index = this.tabs.findIndex((tab) => tab.tabKey === to)
      }

      if (this.useRoutes) {
        if (this.routes[index].path != this.$router.currentRoute.path) {
          this.$router.push({ path: this.routes[index].path })
        }
      } else {
        this.switchIndex(index)
      }

      const tab = this.tabs[index]
      this.$emit("changed", tab.tabKey || index)
    },
    switchIndex(index) {
      this.tabs[this.currentTab].isActive = false
      this.tabs[index].isActive = true
      this.currentTab = index

      this.$emit("tab-changed", this.currentTab)
    },
  },
}
