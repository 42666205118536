import Vue from "../../vue"

import ViewerComponent from "./Viewer.vue"
import AppointmentLoader from "./loaders/AppointmentLoader"

const Loaders = Object.freeze({
  APPOINTMENT_LOADER: AppointmentLoader,
})

export default class Viewer {
  static Loaders = Loaders

  constructor(context) {
    this.options = {
      el: document.createElement("div"),
      propsData: { programmatic: true, options: { tools: true, dataList: true, closeable: true } },
      data: { opened: false },
    }
    this.options.parent = context
  }

  /**
   * Create Viewer builder instance
   *
   * @static
   * @param Vue context
   * @returns Viewer
   */
  static create(context) {
    return new Viewer(context)
  }

  /**
   * Change attachment element of Viewer from document to other
   *
   * @param {*} element
   * @returns
   * @memberof Viewer
   */
  attachTo(element) {
    this.options.propsData.attachTo = element

    return this
  }

  /**
   * Set data loader for Viewer
   *
   * @param Viewer.Loaders loader
   * @param {*} data
   * @returns Viewer
   */
  data(loader, data) {
    if (loader === null) throw new Error("Unknown data loader")

    this.loader = { class: loader, data: data }
    return this
  }

  /**
   * Set patient info in header in Viewer instance
   *
   * @param String patientInfo
   * @returns Viewer
   */
  setPatientInfo(patientInfo) {
    this.options.propsData.patientInfo = patientInfo

    return this
  }

  /**
   * Set options for Viewer, list of options: [tools, dataList, closeable]
   *
   * @param {key: boolean} options
   * @returns Viewer
   */
  setOptions(options) {
    this.options.propsData.options = Object.assign(this.options.propsData.options, options)

    return this
  }

  /**
   * Set lists of slots in Viewer from source components VueNodeProvider
   *
   * @param {string: VueNodeProvider} slots
   * @returns Viewer
   */
  setSlots(slots) {
    this.options.data.slots = slots

    return this
  }

  /**
   * Add custom tools to Viewer.
   *
   * @param {key: {Class extend AbstractTool}} tools
   * @returns Viewer
   */
  addTools(tools) {
    this.options.propsData.customTools = tools

    return this
  }

  /**
   * Show Viewer
   *
   * @returns Promise<Viewer>
   */
  async show() {
    // const { default: ViewerComponent } = await import(/* webpackChunkName: "viewer" */ "./Viewer.vue")
    const ViewerClass = Vue.extend(ViewerComponent)
    const instance = new ViewerClass(this.options)

    instance.data = await this._bindData()

    return instance
  }

  async _bindData() {
    const loader = this.loader.class
    const data = this.loader.data

    if (loader.isAsync()) {
      console.log("loader start loading")
    }

    try {
      return await loader.load(data)
    } catch (error) {
      console.log("ViewerError", error)
      // TODO: Some error message display
      this.options.parent.$bridge.emit("addToast", error.message, "error")
      return null
    } finally {
      if (loader.isAsync()) {
        console.log("loader stop loading")
      }
    }
  }
}
