//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "./Customize"
export default {
  mixins: [Customize()],
  props: {
    onFinish: {
      default: () => {},
      required: true,
    },
    onCancel: {
      default: null,
    },
    finishButtonText: {
      default: function () {
        return this.$t.get("common.finish")
      },
    },
  },
  data() {
    return {
      currentStepIndex: 0,
    }
  },
  computed: {
    currentSlotKey() {
      return Object.keys(this.$slots)[this.currentStepIndex]
    },
    currentSlot: {
      cache: false,
      get() {
        return this.$slots[this.currentSlotKey][0]
      },
    },
    steps() {
      return Object.keys(this.$slots).reduce((acc, key) => {
        if (["buttons-left", "buttons-right"].indexOf(key) === -1) {
          const step = {
            title: this.$slots[key][0].data.attrs.title,
            mounted: this.$slots[key][0].data.attrs.mounted,
          }

          acc.push(step)
        }
        return acc
      }, [])
    },
  },
  watch: {
    currentStepIndex: {
      immediate: true,
      handler(step) {
        this.steps[step].mounted && this.steps[step].mounted()

        this.$emit("step-changed", step)
      },
    },
  },
  methods: {
    nextStep() {
      this.currentStepIndex = this.currentStepIndex + 1
    },
  },
}
