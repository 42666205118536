import Tools from "./Tools.js"
import ToolType from "./ToolType"

export default {
  inject: {
    viewsInstances: { default: [] },
  },
  data() {
    return {
      ToolType: ToolType,
      tools: Tools,
    }
  },
  computed: {
    current() {
      return this.tools.currentTool
    },
  },
  methods: {
    toolSwitch(toolKey, tool) {
      const isNotCurrentTool = this.current !== toolKey

      if (this.current !== null) {
        const deactiveToolKey = this.current
        const deactiveTool = this.tools.tools[deactiveToolKey]
        if (deactiveTool !== void 0) {
          Tools.deactivate(deactiveToolKey, deactiveTool, this.viewsInstances)
        }
      }

      if (isNotCurrentTool) {
        Tools.activate(toolKey, tool, this.viewsInstances)
      }
    },
    toolToggle(toolKey, tool) {
      Tools.toggle(toolKey, tool, this.viewsInstances)
    },
    toolClick(toolKey, tool) {
      Tools.click(toolKey, tool, this.viewsInstances)
    },
  },
}
