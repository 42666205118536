//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueModal from "./VueModal.vue"
import Translate from "../../../helpers/translate.js"
import { ResizeObserver } from "vue-resize"
import Customize from "../Customize"

export default {
  extends: VueModal,
  mixins: [Customize()],
  data() {
    return {
      processing: false,
      content: Translate.get("common.are-you-sure-to-do-it"),
      positive: null,
      negative: null,
    }
  },
  methods: {
    async actionPositive() {
      this.processing = true
      let result = await this.positive.action.call(this.$parent, this)
      this.processing = false
      if (typeof result === typeof undefined || result === true) {
        this.close()
      }
    },
    async actionNegative() {
      if (this.negative.action !== null) {
        this.processing = true
        let result = await this.negative.action.call(this.$parent, this)
        this.processing = false
        if (typeof result === typeof undefined || result === true) {
          this.close()
        }
      } else {
        this.close()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.confirm?.focus()
    })
  },
  components: {
    "resize-observer": ResizeObserver,
  },
}
