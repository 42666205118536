//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    description: {
      default: function () {
        return this.$t.get("common.timezone-change-detected")
      },
    },
    timeZone: {
      default: function () {
        const val = this.$moment.tz.guess()
        return {
          label: val,
          value: val,
        }
      },
    },
  },
  data() {
    return {
      processing: false,
      timezones: [],
      selectedTimezone: this.timeZone,
    }
  },
  mounted() {
    this.getTimezones()
  },
  methods: {
    getTimezones() {
      this.processing = true
      this.$http.get("/ajax/account/timezone").then(
        (response) => {
          this.timezones = response.data
          this.processing = false
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    updateTimezone() {
      this.$http.post("/ajax/account/timezone", { timezone: this.selectedTimezone.value }).then(
        (response) => {
          window.location.reload()
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
  },
}
