import { css } from "glamor"
import { add, camelCase, merge } from "lodash"

const applyStyles = (context, el, { modifiers, arg: style, value }) => {
  if (style === void 0) {
    console.error("You need to pass style after `:` to `styled` directive")
    return
  }

  let path = null

  if (style.split(":").length > 1) {
    ;[style, ...path] = style.split(":")
    path = " ." + path.join(" .")
  }

  style = camelCase(style)

  const selectors = Object.keys(modifiers)

  let options = {}

  const rules = {}
  if (selectors.length > 0) {
    rules[":" + selectors.join(":")] = {}
    if (path) {
      rules[":" + selectors.join(":")][path] = {}
      rules[":" + selectors.join(":")][path][style] = value + " !important"
    } else {
      rules[":" + selectors.join(":")][style] = value + " !important"
    }
  } else {
    if (path) {
      rules[path] = {}
      rules[path][style] = value + " !important"
    } else {
      rules[style] = value + " !important"
    }
  }

  const styles = css(rules)
  el.classList.add(styles)
}

const Directive = {
  bind(el, binding, vnode) {
    applyStyles(vnode.context, el, binding)
  },
  inserted(el, binding, vnode) {
    applyStyles(vnode.context, el, binding)
  },
  update(el, binding, vnode) {
    applyStyles(vnode.context, el, binding)
  },
}

const systemPalette = {
  colors: {
    border: "#dcdcdc",
    background: {
      main: "#ffffff",
      alt: "#0a2949",
    },
    primary: {
      main: "#00ccb1",
      alt: "#fefefe",
    },
    secondary: {
      main: "#003770",
      alt: "#fefefe",
    },
    hover: {
      main: "#00ccb1",
      alt: "#ffffff",
    },
    hyperlink: {
      main: "#00d4b7",
      alt: "#ffffff",
    },
  },
}

const Customize = function (additional = {}) {
  return {
    props: {
      styled: {
        default() {
          return {
            ...additional,
          }
        },
        required: false,
        validator(value) {
          let colors = true

          if (value.colors !== void 0) {
            colors = Object.values(value.colors).every(
              (item) => typeof item == "string" || (item["main"] && item["alt"])
            )
          }

          return colors
        },
      },
    },
    data() {
      return {
        palette: {},
      }
    },
    beforeMount() {
      const currentProduct = JSON.parse(window.__CURRENT_PRODUCT)
      const contextColors = currentProduct ? Object.assign({}, currentProduct.colors || {}) : {}
      const contextPalette = { colors: contextColors }
      const defaultPalette = JSON.parse(JSON.stringify(systemPalette))
      const overridePalette = JSON.parse(JSON.stringify(this.styled))

      this.palette = merge(defaultPalette, contextPalette, overridePalette)
    },
    directives: {
      styled: Directive,
    },
  }
}

export default Customize
