var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-form" }, [
    _c("h3", [_vm._v(_vm._s(_vm.$t.get("appointment.case-description")))]),
    _vm._v(" "),
    _c("div", { staticClass: "card-box content" }, [
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "w-full lg:w-full quill-container" },
          [
            _c("quill-editor", {
              on: {
                input: function ($event) {
                  return _vm.handleInput(_vm.$v.description)
                },
              },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _vm._v(" "),
            _c("v-input-error", { attrs: { validation: _vm.$v.description } }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }