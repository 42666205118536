//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {},
  methods: {},
}
