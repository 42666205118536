//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isTrue } from "../../plugins/vuelidateAdditional"
import { set } from "lodash"
import Modal from "../../plugins/modal/modal.js"

export default {
  props: {
    consents: {
      required: true,
      type: Array,
    },
    model: {},
    valid: {},
    showTextDesc: { type: Boolean, default: true },
  },
  computed: {
    consentRequired() {
      return this.consents.filter((item) => item.required === true)
    },
    consentOptional() {
      return this.consents.filter((item) => item.required === false)
    },
    validation() {
      return this.consentRequired.reduce((acc, consent) => {
        acc[consent.legal_translation_id] = { isTrue }
        return acc
      }, {})
    },
  },
  methods: {
    bind(instance, model, validation) {
      const consents = this.consents.reduce((acc, item) => {
        acc[item.legal_translation_id] = false
        return acc
      }, {})

      set(instance, model, consents)
      set(instance, validation, this.validation)
    },
    openConsentModal(content) {
      this.modal = Modal.create(this)
        .component(() => import("./../settings/ConsentModal.vue"))
        .closeable(true)
        .props({
          content: content,
        })
        .show()
    },
  },
}
