import Vue from "vue"

const componentEventBus = new Vue()

/*
  Communication between components
  basic usage in schema:
  props: {
    check: "clear:other_component"
  }
 */
const componentEvents = {
  clear: function (targetComponent) {
    targetComponent.clearValue()
  },
  uncheck: function (targetComponent) {
    targetComponent.setCheckedState(false)
  },
  check: function (targetComponent) {
    targetComponent.setCheckedState(true)
  },
  disable: function (targetComponent) {
    this.$emit("modify-field-prop", {
      fieldKey: targetComponent.$props.fieldKey,
      prop: "disabled",
      value: true,
    })
  },
  enable: function (targetComponent) {
    this.$emit("modify-field-prop", {
      fieldKey: targetComponent.$props.fieldKey,
      prop: "disabled",
      value: false,
    })
  },
}

export { componentEventBus, componentEvents }
