export default {
  computed: {
    removalRequestAt() {
      return this.$store.state.user.removal_request_at
    },
    removalDaysLeft() {
      const remove = this.$moment(this.removalRequestAt)
      const now = this.$moment()
        .subtract(30, "days")
        .set({
          hour: remove.get("hour"),
          second: remove.get("second"),
          minute: remove.get("minute"),
          millisecond: remove.get("millisecond"),
        })
      return this.$moment(remove).diff(now, "days")
    },
  },
}
