var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "mb-8" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-description"))),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-8" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-info"))),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mb-8 font-semibold" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-list"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "space-y-2 mb-8" },
      [
        _c("div", { staticClass: "table-header" }, [
          _c("div"),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-5 pl-8" }, [
            _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-name"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-4" }, [
            _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-birthday"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-2" }, [
            _vm._v(_vm._s(_vm.$t.get("gdpr.patient-profile-gender"))),
          ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.patientProfiles, function (profile) {
          return _c(
            "div",
            { key: profile.id, staticClass: "form-select-group" },
            [
              _c(
                "custom-checkbox",
                {
                  attrs: {
                    label: profile.id,
                    id: profile.id,
                    inputValue: profile,
                    multiple: false,
                  },
                  model: {
                    value: _vm.checkedPatientProfile,
                    callback: function ($$v) {
                      _vm.checkedPatientProfile = $$v
                    },
                    expression: "checkedPatientProfile",
                  },
                },
                [
                  _c("div", { staticClass: "profile-name col-span-5 pl-8" }, [
                    profile.avatar_url
                      ? _c("div", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage: "url(" + profile.avatar_url + ")",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(profile.name) + " " + _vm._s(profile.surname)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-4 py-6 font-semibold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$dateTimeFormat.formatDate(profile.birthday)
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-span-2 py-6 font-semibold" }, [
                    _vm._v(_vm._s(profile.gender)),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center justify-between" }, [
      _c(
        "button",
        {
          staticClass: "button accent big symmetric py-4 shrink-0",
          class: _vm.checkedPatientProfile !== null ? "" : "disabled",
          on: { click: _vm.download },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.$t.get("gdpr.download-selected")) + "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }