//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LanguageSelect from "../common/LanguageSelect.vue"

export default {
  components: {
    "language-select": LanguageSelect,
  },
  props: {
    appointment_id: {
      required: true,
    },
    type: {
      default: "takeTheCase",
    },
  },
  data() {
    return {
      processing: false,
      selectedGroup: null,
      locale: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    userGroups() {
      return this.user.groups
    },
    userAvailableGroups() {
      return this.userGroups.filter((group) => {
        return this.canTakeTheCaseToGroup(group.group_id) === true
      })
    },
    hasOneGroup() {
      return this.userGroups.length === 1
    },
    appointment() {
      return this.$store.state.appointment
    },
  },
  methods: {
    takeTheCase() {
      this.processing = true

      this.$http
        .post(`/ajax/appointment/${this.appointment_id}/forward`, {
          group_id: this.hasOneGroup ? this.userGroups[0].group_id : this.selectedGroup,
          locale: this.locale,
        })
        .then((response) => {
          this.$bridge.emit("addToast", response.data.message, "success")
          window.location.href = response.data.data
        })
        .catch((error) => {
          this.$bridge.emit("addToast", error.data.message, "error")
          this.processing = false
        })
    },
    cancel() {
      this.$emit("cancel")
    },
    canTakeTheCaseToGroup(group_id) {
      return (
        this.appointment.forwards
          .map((appointmentForward) => {
            return (
              appointmentForward.destination_group_id + "_" + appointmentForward.destination_user_id
            )
          })
          .indexOf(group_id + "_" + this.user.user_id) === -1
      )
    },
  },
}
