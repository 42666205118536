var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image viewer-stack", on: { click: _vm.displayItem } },
    [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.itemName))]),
      _vm._v(" "),
      _c("div", { staticClass: "attrs" }, [
        _c("div", { staticClass: "attr" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.item.context_date
                  ? _vm
                      .$moment("" + _vm.item.context_date)
                      .format("DD MMMM YYYY")
                  : "-"
              ) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("img", {
        attrs: { src: _vm.thumbnail_url, title: "Drag and drop or click" },
        on: {
          load: function ($event) {
            _vm.loaded = true
          },
        },
      }),
      _vm._v(" "),
      !_vm.loaded ? _c("div", { staticClass: "spinner" }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }