//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Events style for passing data
 * this.$emit("success")
 * this.$emit("error")
 */

import { ResizeObserver } from "vue-resize"

export default {
  props: {
    title: {
      default: null,
      type: String,
    },
    closeable: {
      default: true,
    },
    programmatic: {
      default: false,
    },
    destroy: {
      default: false,
    },
    component: {
      default: null,
    },
    classes: {
      default: () => [],
    },
    events: {
      default: () => {},
    },
    props: {
      default: () => {},
    },
  },
  data() {
    return {
      opened: false,
    }
  },
  mounted() {
    if (this.programmatic === false) {
      this.$el.parentElement.removeChild(this.$el)
    }

    if (this.opened === true) {
      document.body.style.overflow = "hidden"

      this.$nextTick(() => {
        if (this.$refs.resizeObserver !== void 0) {
          this.$refs.resizeObserver.$emit("notify")
        }
      })
    }

    document.body.appendChild(this.$el)
  },
  beforeDestroy() {
    this.$el.remove()
  },
  methods: {
    handleResize() {
      this.$refs.backdrop.style.height = this.$el.scrollHeight + "px"
    },
    open() {
      this.opened = true

      document.body.style.overflow = "hidden"

      this.$nextTick(() => {
        this.$refs.resizeObserver.$emit("notify")
      })
    },
    close() {
      if (this.$refs.component !== undefined) {
        this.$refs.component.$emit("close")
      }
      this.opened = false
      document.body.style.overflow = ""

      if (this.destroy) {
        this.$destroy()
      }
      this.$emit("close")
    },
    cancel() {
      if (this.closable) {
        this.close()
      }
    },
  },
  components: {
    "resize-observer": ResizeObserver,
  },
}
