//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      lateDelay: 5,
      processing: false,
    }
  },
  props: {
    appointment: {
      required: true,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel")
    },
    willBeLate() {
      this.processing = true
      this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/delay", {
          delay: this.lateDelay,
          message: this.$t.get("appointment.i-will-be-late"),
        })
        .then(
          (response) => {
            this.$emit("success")
            this.$bridge.emit("addToast", this.$t.get("appointment.information-sent"), "success")
            this.processing = false
          },
          (response) => {
            this.$bridge.emit(
              "addToast",
              this.$t.get("common.something-went-wrong-please-try-again"),
              "error"
            )
            this.processing = false
          }
        )
    },
  },
}
