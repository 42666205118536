//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isVisible: bowser.mobile ? false : true,
    }
  },
  methods: {
    clearFilters() {
      this.$emit("clear-filters")
    },
    hideFilters() {
      this.isVisible = false
    },
    showFilters() {
      this.isVisible = true
    },
  },
}
