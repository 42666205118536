//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BirthdayInput from "../../../plugins/BirthdayInput.vue"

import { required } from "vuelidate/lib/validators"
import { isAfter, isPastDate } from "../../../plugins/vuelidateAdditional"

export default {
  props: {
    customerId: { type: String },
    organizationId: { type: String, required: true },
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
  },
  data() {
    return {
      processing: false,
      countries: window.countries,
      requester: {
        myself: true,
        name: "",
        surname: "",
        gender: "",
        birthday: "",
        country: "",
      },
    }
  },
  watch: {
    "requester.myself": {
      immediate: true,
      handler(isMyself) {
        if (isMyself) {
          this.fetchUserData()
        } else {
          this.requester = {
            name: "",
            surname: "",
            birthday: "",
            gender: "",
            country: "",
            myself: isMyself,
          }
          this.$nextTick(() => this.$v.$reset())
          this.emitRequesterData()
        }
      },
    },
    customerId() {
      this.fetchUserData()
    },
  },
  validations: {
    requester: {
      name: {
        required,
      },
      surname: {
        required,
      },
      gender: {
        required,
      },
      birthday: {
        required,
        userBirthday: isAfter("01.01.1900", "DD.MM.YYYY"),
        error: isPastDate(),
      },
      country: {
        required,
      },
    },
  },
  components: {
    "birthday-input": BirthdayInput,
  },
  mounted() {},
  methods: {
    fetchUserData() {
      if (this.customerId === null) {
        return
      }
      this.processing = true
      let params = {
        action: "fetchCustomerUserData",
        componentId: this.componentId,
        customerId: this.customerId,
      }
      this.$http.post(this.componentUrl, params).then((response) => {
        this.requester = Object.assign(this.requester, response.data.data)
        this.processing = false

        this.emitRequesterData()
        this.$nextTick(() => {
          this.$el
            .querySelectorAll("input[type=text]")
            .forEach((element) => element.dispatchEvent(new Event("change", { bubbles: true })))
        })
      })
    },
    handleInput(validator) {
      validator.$touch()
      this.emitRequesterData()
    },
    emitRequesterData() {
      this.$v.$touch()

      if (this.$v.$invalid === true) {
        this.$emit("data", "requester", null)
        return
      }

      this.$emit("data", "requester", this.requester)
    },
  },
}
