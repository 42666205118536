var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: _vm.buildSetupAppointmentPageUrl(),
        disabled: _vm.customerActive === false,
        title: _vm.$t.get("product.create-consultation"),
      },
    },
    [_vm._v(_vm._s(_vm.$t.get("product.create-consultation")))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }