//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"

export default {
  mixins: [Customize()],
  props: {
    appointment: {
      required: true,
    },
  },
  computed: {
    groups() {
      return this.appointment.enquiry_info_grouped.groups.map((item) => {
        return {
          label: item.label,
          items: this.translate(item.items),
        }
      })
    },
    free() {
      return this.translate(this.appointment.enquiry_info_grouped.other)
    },
  },
  methods: {
    translate(enquiryInfo) {
      return Object.keys(enquiryInfo).reduce((acc, item) => {
        const enquiryField = enquiryInfo[item]

        let processValue = (enquiryValue) => {
          if (enquiryValue === null) {
            return null
          }

          if (typeof enquiryValue === "object" && !Array.isArray(enquiryValue)) {
            return this.$t.get(enquiryValue.label)
          } else if (Array.isArray(enquiryValue)) {
            return enquiryValue.map((value) => this.$t.get(value.label ?? value)).join(", ")
          } else if (this.$moment(enquiryValue).isValid()) {
            return this.$dateTimeFormat.formatDate(enquiryValue)
          } else if (typeof enquiryValue === "string" || enquiryValue instanceof String) {
            return enquiryValue
              .split(" ")
              .map((i) => this.$t.get(i))
              .join(" ")
          } else {
            return enquiryValue
          }
        }

        if (
          typeof enquiryField === "object" &&
          !Array.isArray(enquiryField) &&
          enquiryField !== null
        ) {
          let enquiryValue = enquiryField.value

          const label = this.$t.get(enquiryField.label)
          const text = processValue(enquiryValue) ?? "-"

          acc[label] = {
            text,
            break: label.length + text.length > 70,
          }
          return acc
        }

        const label = this.$t.get(item)
        const text = processValue(enquiryField)

        acc[label] = {
          text,
          break: label.length + text.length > 70,
        }

        return acc
      }, {})
    },
  },
}
