var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "button",
            {
              staticClass: "close-button",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.setVisibility(false)
                },
              },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _c(
            "v-scrollbar",
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t.get(
                        "appointment.background-effects.apply-background-effects"
                      )
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "section" }, [
                _vm._v(
                  _vm._s(_vm.$t.get("appointment.background-effects.preview"))
                ),
              ]),
              _vm._v(" "),
              _c("local-media", { attrs: { tracks: _vm.localTracks } }),
              _vm._v(" "),
              _c("div", { staticClass: "section" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t.get(
                        "appointment.background-effects.no-effect-and-blurry-option"
                      )
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "options" },
                _vm._l(_vm.blurVariants, function (blur) {
                  return _c(
                    "div",
                    {
                      key: blur.value,
                      class: [
                        "level-" + blur.label.toLowerCase(),
                        { active: _vm.active === blur.value },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.setBlur(blur)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "icon",
                        class: ["icon-" + blur.icon],
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(blur.label))]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t.get(
                      "appointment.background-effects.background-images"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "images" },
                _vm._l(_vm.imageVariants, function (image) {
                  return _c(
                    "div",
                    {
                      key: image.preview,
                      on: {
                        click: function ($event) {
                          return _vm.setImage(image)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: image.preview, crossorigin: "Anonymous" },
                      }),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }