//
//
//
//
//

import { findPath } from "../../utils"
import { get } from "lodash"

export default {
  props: {
    validation: {
      required: true,
    },
  },
  computed: {
    dirty() {
      return this.validation.$dirty
    },
    rules() {
      return Object.keys(this.validation.$params).map((param) => {
        return {
          rule: param,
          valid: this.validation[param],
          params: this.params(this.validation.$params[param]),
        }
      })
    },
    invalid() {
      return this.rules.find((item) => item.valid === false)
    },
  },
  methods: {
    params(validator) {
      if (validator === null) {
        return {}
      } else if (validator.type === "isBeforeDate" || validator.type === "isAfterDate") {
        let parent = this.$parent.$v !== void 0 ? this.$parent : this.$parent.$parent
        let path = findPath(parent.$v, validator.date)
        let item = get(parent, [...path, validator.date])
        let date = this.$moment(item).format(validator.format)

        return {
          ...validator,
          date,
        }
      } else {
        return validator
      }
    },
  },
}
