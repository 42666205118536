//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {},
  },

  computed: {
    availableDurationMinutes() {
      let minutes = [
        {
          label: "-",
          value: null,
        },
      ]
      for (let m = 15; m <= 90; m += 15) {
        minutes.push({
          label: m.toString(),
          value: m,
        })
      }
      return minutes
    },
  },
}
