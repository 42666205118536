//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Translate from "../../../../helpers/translate.js"
import Customize from "../../../plugins/Customize"

export default {
  mixins: [Customize({})],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {},
    multi: {
      default: false,
    },
    disabled: {
      default: false,
    },
    options: {
      default: () => [],
      required: true,
    },
    labelKey: {
      default: "label",
    },
    valueKey: {
      default: "value",
    },
    selectedLabel: {
      default: Translate.get("common.selected-options"),
    },
    emptyLabel: {
      default: Translate.get("common.choose-option"),
    },
  },
  data() {
    return {
      isOpened: false,
      filter: "",
      dropdownPosition: "top",
    }
  },
  mounted() {
    document.addEventListener("click", this.blur.bind(this))

    if (this.multi && this.value === null) {
      this.$emit("change", [])
    }
  },
  destroyed() {
    document.removeEventListener("click", this.blur.bind(this))
  },
  computed: {
    label() {
      if (this.value === null || this.value.length === 0) {
        return this.emptyLabel
      } else {
        if (this.multi) {
          return this.selectedLabel
        } else {
          const option = this.options.find((item) => item[this.valueKey] === this.value)
          if (typeof option !== typeof undefined) {
            return this.$t.get(option[this.labelKey])
          } else {
            return ""
          }
        }
      }
    },
    translatedItems() {
      return this.options.map((item) => {
        return {
          label: this.$t.get(item.label),
          value: item.value,
        }
      })
    },
    filteredItems() {
      return this.translatedItems.filter(
        (item) => item[this.labelKey].toLowerCase().indexOf(this.filter.toLowerCase()) > -1
      )
    },
  },
  methods: {
    isSelected(option) {
      if (this.multi) {
        return this.value.findIndex((item) => item === option[this.valueKey]) > -1
      } else {
        return option[this.valueKey] === this.value
      }
    },
    blur(event) {
      const path = event.path || event.composedPath()
      if (path.indexOf(this.$el) === -1 && this.isOpened) {
        this.hide()
      }
    },
    show() {
      if (this.disabled === false) {
        if (
          this.$el.offsetTop + this.$el.clientHeight + 300 >
          window.pageYOffset + window.innerHeight
        ) {
          this.dropdownPosition = "bottom"
        } else {
          this.dropdownPosition = "top"
        }

        this.isOpened = true
      }
    },
    hide() {
      this.$el.blur()
      this.isOpened = false
      this.filter = ""
    },
    optionClicked(item) {
      if (!item.$disabled) {
        this.selectOption(item)

        if (this.multi === false) {
          this.hide()
        }
      }
    },
    selectOption(option) {
      if (this.multi) {
        const selected = JSON.parse(JSON.stringify(this.value))
        const index = selected.findIndex((item) => item === option[this.valueKey])
        if (index > -1) {
          selected.splice(index, 1)
        } else {
          selected.push(option[this.valueKey])
        }
        this.$emit("change", selected)
      } else {
        this.$emit("change", option[this.valueKey])
      }
    },
    clear() {
      if (this.multi) {
        this.$emit("change", [])
      } else {
        this.$emit("change", null)
      }
      this.hide()
    },
  },
}
