import Vue from "../vue.js"

import { calculateJitterInterval } from "../../utils"

const job_status = {
  JOB_DONE: "done",
  JOB_ERROR: "error",
}

const isJobFinished = (job) => {
  return job.status === job_status.JOB_DONE || job.status === job_status.JOB_ERROR
}

export default {
  namespaced: true,
  state: {
    visible: false,
    interval_base: 3000,
    interval_jitter: 0.1,
    jobs: {},
  },
  mutations: {
    toggleVisibility(state) {
      state.visible = !state.visible
    },
    setVisible(state) {
      state.visible = true
    },
    enqueue(state, job) {
      Vue.set(state.jobs, job.job_id, Object.assign({}, state.jobs[job.job_id] || {}, job))
    },
    clearInterval(state, job) {
      let enqueued = state.jobs[job.job_id]
      if (enqueued === undefined || enqueued.interval === null) return
      window.clearInterval(enqueued.interval)
      enqueued.interval = null
      Vue.set(state.jobs, job.job_id, Object.assign({}, state.jobs[job.job_id], enqueued))
    },
    removeFromList(state, job) {
      let enqueued = state.jobs[job.job_id]
      if (enqueued === undefined) return
      window.clearInterval(enqueued.interval)
      Vue.delete(state.jobs, job.job_id)
      if (Object.keys(state.jobs).length === 0) state.visible = false
    },
  },
  actions: {
    enqueue({ commit, dispatch, state }, job) {
      let name = job.name || job.job_id

      // job exists and is finished - fetch from state
      if (job.job_id in state.jobs && isJobFinished(state.jobs[job.job_id])) {
        commit("clearInterval", job)
        dispatch("download", state.jobs[job.job_id])
        return job
      }

      // job does not exists but is in progress = enqueue the same action
      if (!(job.job_id in state.jobs)) {
        job.interval = window.setInterval(() => {
          dispatch("enqueue", job)
        }, calculateJitterInterval(state.interval_base, state.interval_jitter))
        commit(
          "enqueue",
          Object.assign({}, job, { status: "enqueuing", stage: "Adding to queue...", name: name })
        )
      }

      // job does not exists in state - fetch from cloud
      return Vue.http
        .post(
          window.__CLOUD_URL + "/downloads/enqueue/" + job.job_id,
          {},
          {
            headers: {
              Authorization: "Bearer " + window.USER_JWT,
            },
          }
        )
        .then((response) => {
          let job = response.data
          job.name = name

          // job is finished - clear interval, update job state
          if (isJobFinished(job)) {
            dispatch("download", job)
            commit("clearInterval", job)
          }

          commit("enqueue", job)
          return job
        })
        .catch((response) => {
          commit(
            "enqueue",
            Object.assign({}, job, { status: "error", stage: "An error occurred. Try again later" })
          )
        })
    },
    download(_, job) {
      let anchor = document.createElement("a")
      document.body.appendChild(anchor)
      anchor.style = "display: none"
      anchor.href = job.download_url
      anchor.click()
      anchor.remove()
    },
  },
  getters: {
    isPanelVisible: (state) => {
      return state.visible
    },
    getJobs: (state) => {
      return state.jobs
    },
  },
}
