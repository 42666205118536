var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "mb-8" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.intercom-description"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex items-center space-x-4" }, [
      _c(
        "button",
        {
          staticClass: "button accent big symmetric py-4",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.download },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.processing
                  ? _vm.$t.get("gdpr.please-wait")
                  : _vm.$t.get("gdpr.download")
              ) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "font-medium" }, [
        _vm._v(_vm._s(_vm.$t.get("gdpr.intercom-download"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }