var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs-wrapper" }, [
    _c(
      "ul",
      { staticClass: "tabs" },
      [
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: tab.visible,
                  expression: "tab.visible",
                },
              ],
              key: index,
              staticClass: "tabs-title",
              class: {
                "is-active": tab.isActive,
                "has-badge": tab.badge !== null && tab.badge > 0,
              },
              attrs: { role: "presentation" },
            },
            [
              tab.icon == null
                ? _c(
                    "a",
                    {
                      attrs: {
                        "aria-selected": "true",
                        role: "tab",
                        badge: tab.badge,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeTab(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(tab.name))]
                  )
                : _c(
                    "a",
                    {
                      attrs: { "aria-selected": "true", role: "tab" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeTab(index)
                        },
                      },
                    },
                    [_c("i", { class: [tab.icon] })]
                  ),
            ]
          )
        }),
        _vm._v(" "),
        _vm.$slots.right !== void 0
          ? _c("li", { staticClass: "right" }, [_vm._t("right")], 2)
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }