import Quill from "quill"

let BlockEmbed = Quill.import("blots/block/embed")

class CloudImageBlot extends BlockEmbed {
  static create(info) {
    let node = super.create()

    node.setAttribute("data-cloud-proxy", info.cloudProxy)
    node.setAttribute("data-id", info.id)
    node.setAttribute("src", info.link)

    return node
  }

  static value(node) {
    return {
      cloudProxy: node.getAttribute("data-cloud-proxy"),
      id: node.getAttribute("data-id"),
      link: node.getAttribute("src"),
    }
  }
}
CloudImageBlot.blotName = "cloud-image"
CloudImageBlot.tagName = "img"

export default CloudImageBlot
