var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "mb-6" }, [
      _vm._v(_vm._s(_vm.$t.get("gdpr.data-cloud-description"))),
    ]),
    _vm._v(" "),
    _vm.folders.length > 0
      ? _c("p", { staticClass: "font-medium mb-2" }, [
          _vm._v(_vm._s(_vm.$t.get("gdpr.data-cloud-info"))),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "cloud-attached-files mb-8" }, [
      _c("div", { staticClass: "flex flex-col" }, [
        _c("div", { staticClass: "w-full columns overflow-hidden" }, [
          _vm.folders.length === 0
            ? _c("div", { staticClass: "empty" }, [
                _c("strong", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t.get(
                          "cloud-manager.there-are-no-medical-records-attached"
                        )
                      ) +
                      "\n          "
                  ),
                ]),
              ])
            : _c(
                "div",
                { staticClass: "files" },
                [
                  _c(
                    "v-scrollbar",
                    [
                      _c(
                        "v-accordion",
                        _vm._l(_vm.folders, function (folder, key) {
                          return _c(
                            "v-accordion-item",
                            {
                              key: key,
                              staticClass: "folder",
                              on: {
                                expanded: function ($event) {
                                  return _vm.refreshFiles(folder)
                                },
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "styled",
                                        rawName: "v-styled:border-color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "border-color",
                                      },
                                      {
                                        name: "styled",
                                        rawName: "v-styled:color",
                                        value: _vm.palette.colors.primary.main,
                                        expression:
                                          "palette.colors.primary.main",
                                        arg: "color",
                                      },
                                    ],
                                    staticClass: "folder-icon",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "ict-folder mt-0.5",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(folder.name))]),
                              ]),
                              _vm._v(" "),
                              folder.files.length > 0
                                ? _c("div", { staticClass: "flex header" }, [
                                    _c("div", { staticClass: "w-7/12" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get("cloud-manager.file-name")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "w-3/12" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get("cloud-manager.format")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "w-2/12" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t.get("cloud-manager.size"))
                                      ),
                                    ]),
                                  ])
                                : _c("div", { staticClass: "header" }, [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get(
                                            "cloud-manager.no-files-uploaded"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                              _vm._v(" "),
                              _vm._l(folder.files, function (file, key) {
                                return _c(
                                  "div",
                                  {
                                    key: key,
                                    staticClass: "single-file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectFile(file)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex space-x-2 items-center w-7/12",
                                      },
                                      [
                                        _c("div", [
                                          _vm.selectedFile &&
                                          _vm.selectedFile.item_id ===
                                            file.item_id
                                            ? _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "styled",
                                                      rawName:
                                                        "v-styled:background",
                                                      value:
                                                        _vm.palette.colors
                                                          .primary.main,
                                                      expression:
                                                        "palette.colors.primary.main",
                                                      arg: "background",
                                                    },
                                                    {
                                                      name: "styled",
                                                      rawName:
                                                        "v-styled:border-color",
                                                      value:
                                                        _vm.palette.colors
                                                          .primary.main,
                                                      expression:
                                                        "palette.colors.primary.main",
                                                      arg: "border-color",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "checkmark is-attached text-white",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ict-checkmark",
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "checkmark text-gray-700",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ict-checkmark",
                                                  }),
                                                ]
                                              ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [_vm._v(_vm._s(file.name))]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-3/12 text-xl pl-3 flex items-center",
                                      },
                                      [
                                        _c("i", {
                                          class:
                                            "ict-" + _vm.formatIcon(file.type),
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "w-2/12 flex items-center",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.formatSize(file.size, 1)
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.folders.length > 0
      ? _c("div", { staticClass: "flex items-center justify-between" }, [
          _c(
            "button",
            {
              staticClass: "button accent big symmetric py-4",
              class: _vm.selectedFile !== null ? "" : "disabled",
              on: { click: _vm.download },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t.get("gdpr.download-selected")) +
                  "\n    "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }