//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"
import { kebabCase } from "lodash"

export default {
  mixins: [InputMixin],
  props: {
    onChanged: {
      default: null,
    },
    options: {
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    generateInputId(value) {
      return this.inputId + "-" + kebabCase(value)
    },
    change(e, option) {
      if (this.onChanged !== null) {
        this.handleComponentEvent(this.onChanged)
      }

      this.input(this.selected)
    },
    setCheckedState(value) {
      if (this.value === value) {
        return
      }
      this.$emit("input", value)
      this.$forceUpdate()
    },
  },
}
