import { chain, upperFirst, isArray } from "lodash"
import { formatSize } from "../../../utils"
import axios from "axios"
import AttachedFilesState from "./AttachFilesState"

import cloud from "./cloud"

export default {
  props: {
    objectId: { default: () => new Date().getTime() },
    jwt: {
      default: window.USER_JWT,
    },
    allowFormats: {
      type: Array,
      default: function () {
        return ["jpg", "jpeg", "png", "pdf", "dcm", "mri"]
      },
    },
  },
  watch: {
    objectId: function (newVal, oldVal) {
      this.registerAndFetch()
    },
  },
  methods: {
    fetchFiles(folder_id, force = false) {
      if (this.objectId === null) {
        return
      }
      let folderKey = this.folders.findIndex((item) => item.item_id === folder_id)
      if (this.folders[folderKey].dataFetched && !force) return
      this.$http
        .get(
          window.__CLOUD_URL +
            "/items/" +
            folder_id +
            (typeof this.objectId !== "number"
              ? "?layers=is_attached&object_id=" + this.objectId
              : ""),
          {
            headers: {
              Authorization: "Bearer " + this.jwt,
            },
          }
        )
        .then(
          (response) => {
            let data = response.data
            this.folders[folderKey].data = {
              dicoms: data.dicoms,
              images: data.images,
              pdfs: data.pdfs,
            }
            this.folders[folderKey].dataFetched = true
            // check if folder has any files attached, if not - detach folder
            this.folders[folderKey].attached = chain(this.folders[folderKey].data)
              .map((stacks, type) => Object.values(stacks))
              .flatten()
              .filter((stack) => stack[0].hasOwnProperty("attached") && stack[0].attached === true)
              .size()
              .gt(0)
              .value()
            this.$forceUpdate()
          },
          (response) => {}
        )
    },
    formatSize(bytes, decimals) {
      return formatSize(bytes, decimals)
    },

    reloadData() {
      this.$store
        .dispatch("cloud/" + this.objectId + "/fetch", {
          objectId: this.objectId,
          jwt: this.jwt,
        })
        .then(
          () => {
            this.state = AttachedFilesState.READY
          },
          () => {
            this.state = AttachedFilesState.ERROR
          }
        )
    },
    importFolderName(provider) {
      const currentTime = new Date()

      const date =
        currentTime.getFullYear() +
        "-" +
        ("0" + (currentTime.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentTime.getDate()).slice(-2) +
        " " +
        ("0" + currentTime.getHours()).slice(-2) +
        ":" +
        ("0" + currentTime.getMinutes()).slice(-2)

      return `${provider} import (${date})`
    },
    enqueueImportFromExternalCloud(parentName, payload, object_id) {
      if (isArray(payload) === false || payload.length === 0) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("cloud-manager.import-empty-selection-error"),
          "error"
        )
        return
      }

      axios
        .post(
          window.__CLOUD_URL + "/uploads/enqueue",
          {
            items: payload,
            parent_name: upperFirst(parentName),
            object_id: object_id,
            url: `${window.location.protocol}//${window.location.hostname}`,
          },
          {
            withCredentials: true,
            headers: { Authorization: "Bearer " + this.jwt },
          }
        )
        .then((response) => {
          this.$bridge.emit("addToast", this.$t.get("cloud-manager.import-enqueued"), "info")
        })
        .catch((response) => {
          this.$bridge.emit("addToast", this.$t.get("cloud-manager.import-enqueue-error"), "error")
        })
    },
    registerAndFetch() {
      this.state = AttachedFilesState.FETCHING
      if (this.$store.state.cloud === void 0) {
        this.$store.registerModule("cloud", { namespaced: true })
      }

      if (this.$store.state.cloud[this.objectId] === void 0 && this.objectId !== null) {
        this.$store.registerModule(["cloud", this.objectId], cloud)

        this.$store
          .dispatch("cloud/" + this.objectId + "/fetch", {
            objectId: this.objectId,
            jwt: this.jwt,
          })
          .then(
            () => {
              this.state = AttachedFilesState.READY
            },
            (response) => {
              let message = this.$t.get("common.something-went-wrong-please-try-again")
              if (response.status == 0 || response.status == 500) {
                message = this.$t.get(
                  "cloud-manager.medical-records-temporary-unavailable-upload-files-is-disabled"
                )
              } else if (response.status == 401) {
                message = this.$t.get("cloud-manager.you-dont-have-permission-to-get")
              }

              this.$bridge.emit("addToast", message, "error")

              this.state = AttachedFilesState.ERROR
            }
          )
      } else {
        this.state = AttachedFilesState.READY
      }
    },
  },
  computed: {
    folders: {
      set(value) {
        this.$store.commit("cloud/" + this.objectId + "/update", { data: value })
      },
      get() {
        const cloud = this.$store.state.cloud[this.objectId]
        if (cloud !== undefined) {
          return cloud.folders || []
        }
        return []
      },
    },
  },
  beforeMount() {
    this.registerAndFetch()
  },
}
