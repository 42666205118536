//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {
    options: {
      required: true,
    },
    translateOptions: {
      default: true,
    },
  },
  computed: {
    translatedOptions() {
      if (this.translateOptions === false) {
        return this.options
      }
      return this.options.map((option) => ({
        label: this.$t.get(option.label),
        value: typeof option.label === "object" ? option : option.value,
      }))
    },
  },
  methods: {},
}
