//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../../plugins/Customize"
import seeThru from "seethru"

export default {
  mixins: [Customize({})],
  props: {
    videoLink: {
      type: String,
    },
  },
  data() {
    return {
      transparentVideo: null,
      playing: false,
    }
  },
  mounted() {
    this.transparentVideo = seeThru
      .create(this.$refs.video, {
        width: 264,
        height: 349,
      })
      .ready((instance, video, _) => {
        video.addEventListener("ended", () => {
          this.playing = false
        })
      })
  },
  methods: {
    play() {
      this.playing = true
      this.transparentVideo.play()
    },
    stop() {
      this.playing = false
      this.transparentVideo.pause()
    },
  },
}
