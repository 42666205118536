export default {
  install(Vue, options) {
    const _this = this
    _this.Bridge = new Vue()

    Vue.mixin({
      beforeCreate() {
        const $this = this
        const bridges = this.$options["bridges"]
        if (bridges) {
          Object.keys(bridges).forEach(function (key) {
            _this.Bridge.$on(key, (...args) => {
              bridges[key].call($this, ...args)
            })
          })
        }
      },
    })

    Object.defineProperties(Vue.prototype, {
      $bridge: {
        get() {
          return {
            emit: function (event, ...args) {
              _this.Bridge.$emit(event, ...args)
            },
          }
        },
      },
    })
  },
}
