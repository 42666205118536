//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Video from "twilio-video"
import VueStepperHorizontal from "../plugins/VueStepperHorizontal.vue"

import Modal from "../plugins/modal/modal"

import { v4 as uuid } from "uuid"
import Customize from "./Customize"

const TestState = {
  MOUNTED: 0,
  PREPARING: 1,
  TESTING: 2,
  SENDING: 3,
  DONE: 4,
  BAD: -1,
}

const TestResult = {
  CHECKING: 0,
  BAD: 1,
  SUFFICIENT: 2,
  GOOD: 3,
  INTERNAL_ERROR: -1,
}

const TestType = {
  TRUE_FALSE: 0,
  STATES: 1,
}

export default {
  mixins: [Customize()],
  props: {
    buttons: {
      default: true,
    },
    callback: {
      default: null,
    },
    callbackAppend: {
      default: () => {
        return {}
      },
    },
    descriptionText: {
      default: function () {
        return this.$t.get("test.we-are-preparing-test-for-you-please-wait")
      },
    },
    finishButtonText: {
      default: function () {
        return this.$t.get("common.finish")
      },
    },
  },
  data() {
    return {
      selectedSource: {
        audio: "default",
        video: "default",
      },
      audioContext: null,
      audioScript: null,
      sampleNotPlayed: true,
      TestState: TestState,
      TestResult: TestResult,
      TestType: TestType,
      state: TestState.MOUNTED,
      userLocalTracks: [],
      receiverToken: null,
      senderToken: null,
      rooms: {
        receiver: null,
        sender: null,
      },
      audio: {
        lastUpdate: +new Date(),
        status: 0,
      },
      results: {
        benchmark_id: uuid(),
        dates: {
          start: null,
          end: null,
        },
        camera: {
          type: TestType.TRUE_FALSE,
          result: TestResult.CHECKING,
          logs: [],
        },
        microphone: {
          type: TestType.TRUE_FALSE,
          result: TestResult.CHECKING,
          logs: [],
        },
        speakers: {
          type: TestType.TRUE_FALSE,
          result: TestResult.CHECKING,
          logs: [],
        },
        twilioPreflight: {
          type: TestType.STATES,
          result: TestResult.CHECKING,
          logs: [],
        },
        twilio: {
          name: this.$t.get("test.video-server"),
          type: TestType.TRUE_FALSE,
          result: TestResult.CHECKING,
          label: this.$t.get(
            "test.we-are-checking-if-there-is-any-firewall-or-blockage-to-the-video-connection"
          ),
          logs: [],
        },
        socket: {
          name: this.$t.get("test.messaging-web-socket"),
          type: TestType.TRUE_FALSE,
          result: TestResult.CHECKING,
          label: this.$t.get("test.we-are-checking-if-there-is-any-blockage-to-live-messaging"),
          logs: [],
        },
        trustedoctor: {
          name: this.$t.get("test.latency"),
          type: TestType.STATES,
          result: TestResult.CHECKING,
          label: this.$t.get(
            "test.we-are-checking-the-quality-of-connection-and-data-transmission"
          ),
          logs: [],
        },
        networkQuality: {
          name: this.$t.get("test.network-quality"),
          type: TestType.STATES,
          result: TestResult.CHECKING,
          label: this.$t.get(
            "test.we-are-checking-the-quality-of-connection-and-data-transmission"
          ),
          logs: [],
        },
      },
      metadata: {
        userAgent: navigator.userAgent,
        screen: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        permissionStatus: {
          camera: null,
          microphone: null,
        },
        inputDevices: [],
      },
      interval: null,
      timeout: null,
    }
  },
  computed: {
    continueButtonLabel() {
      if (this.state === TestState.MOUNTED || this.state === TestState.CHECKING)
        return this.$t.get("test.skip")
      if (this.state === TestState.SENDING) return this.$t.get("test.saving-result")
      return this.$t.get("test.continue")
    },
    networkLevel() {
      const levels = this.results.networkQuality.logs
      const current = levels[levels.length - 1] || 0

      return current
    },
    result() {
      let testResult = JSON.parse(JSON.stringify(this.results))
      Object.keys(testResult).forEach((key) => {
        if (testResult[key].hasOwnProperty("label")) testResult[key].label = undefined
      })

      testResult.selectedDevice = this.selectedSource
      return Object.assign({}, testResult, this.metadata, this.callbackAppend)
    },
    devices() {
      return (this.metadata.inputDevices.reduce ? this.metadata.inputDevices : []).reduce(
        (acc, item) => {
          if (item.kind === "videoinput") {
            if (acc["video"] === void 0) {
              acc["video"] = []
            }

            acc["video"].push(item)
          }

          if (item.kind === "audioinput") {
            if (acc["audio"] === void 0) {
              acc["audio"] = []
            }

            acc["audio"].push(item)
          }

          return acc
        },
        {}
      )
    },
  },
  async mounted() {
    this.results.dates.start = this.$moment().toString()
    this.state = TestState.MOUNTED

    this.start()

    this.interval = setInterval(this.sendCallback.bind(this), 5000)
    this.timeout = setTimeout(() => {
      Modal.confirm(this)
        .title(this.$t.get("test.equipment-test-closed"))
        .content(
          this.$t.get(
            "test.equipment-test-has-been-closed-after-10-minutes-which-is-a-maximum-time-of-taking-test"
          )
        )
        .positive(this.$t.get("common.ok"), () => {})
        .show()

      this.$emit("cancel")
    }, 600000)
  },
  beforeDestroy() {
    if (this.rooms.receiver !== null) {
      this.rooms.receiver.disconnect()
      this.rooms.receiver = null
    }
    if (this.rooms.sender !== null) {
      this.rooms.sender.disconnect()
      this.rooms.sender = null
    }

    this.userLocalTracks.forEach((track) => {
      if (track.stop) track.stop()
      if (track.detach) track.detach()
    })
    this.userLocalTracks = []

    clearInterval(this.interval)
    clearTimeout(this.timeout)
  },
  methods: {
    initSupportCenter() {
      this.sendCallback()

      SupportCenter.enable()
      SupportCenter.showNewMessage(this.$t.get("test.i-have-a-problem"))
    },
    audioSourceChanged(deviceId) {
      let $this = this
      const localParticipant = this.rooms.sender.localParticipant
      Video.createLocalAudioTrack({
        deviceId: { exact: deviceId },
      }).then(function (localVideoTrack) {
        const tracks = Array.from(localParticipant.audioTracks.values())
        localParticipant.unpublishTracks(tracks)

        localParticipant.publishTrack(localVideoTrack)
        $this.userLocalTracks.push(localVideoTrack)
      })
    },
    videoSourceChanged(deviceId) {
      let $this = this
      const localParticipant = this.rooms.sender.localParticipant
      Video.createLocalVideoTrack({
        deviceId: { exact: deviceId },
      }).then(function (localVideoTrack) {
        const tracks = Array.from(localParticipant.videoTracks.values())
        localParticipant.unpublishTracks(tracks)

        localParticipant.publishTrack(localVideoTrack)
        $this.userLocalTracks.push(localVideoTrack)
      })
    },

    testVideo() {
      if (this.results.twilio.result === TestResult.GOOD) {
        const participants = Array.from(this.rooms.receiver.participants)

        if (
          participants !== void 0 &&
          participants[0] !== void 0 &&
          participants[0][1] !== void 0
        ) {
          const participant = participants[0][1]

          const bind = (track) => {
            this.$nextTick(() => {
              let element = track.attach()
              if (typeof element.muted !== typeof undefined) {
                element.muted = true
              }
              this.$refs.previewVideo.appendChild(element)
            })
          }

          participant.videoTracks.forEach((publication) => {
            if (publication.isSubscribed) {
              bind(publication.track)
            }
          })

          participant.on("trackSubscribed", (track) => bind(track))
        } else {
          this.results.twilio.result = TestResult.INTERNAL_ERROR
        }
      }
    },
    testAudio() {
      if (this.results.twilio.result === TestResult.GOOD) {
        const participants = Array.from(this.rooms.receiver.participants)
        const participant = participants[0][1]

        const bind = (track) => {
          if (track.kind === "video") {
            const elements = track.detach()
            elements.forEach((el) => el.remove())
          }

          if (track.kind === "audio") {
            this.$nextTick(() => {
              let element = this.$refs.previewAudio.appendChild(track.attach())
              element.muted = true
              this.bindAudioTrack(track, "microphone")
            })
          }
        }

        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            bind(publication.track)
          }
        })

        participant.on("trackSubscribed", (track) => bind(track))
      }
    },
    testSpeakers() {
      this.$nextTick(() => {
        this.bindAudioTrack(this.$refs.previewSpeakers, "speakers")
      })
    },
    playSampleSound() {
      this.$refs.previewSpeakers.play()
      this.sampleNotPlayed = false
    },
    async testNetwork() {
      if (this.$refs.previewSpeakers && this.$refs.previewSpeakers.stop) {
        this.$refs.previewSpeakers.stop()
      }

      this.state = TestState.SENDING

      this.results.dates.end = this.$moment().toString()

      await this.sendCallback(this.result)

      this.state = TestState.DONE
    },

    async start() {
      this.state = TestState.PREPARING

      let tracksPermissions = false

      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoInput = devices.find((device) => device.kind === "videoinput")

        const config = { audio: true }
        if (videoInput !== void 0) {
          config["video"] = true
        }

        this.userLocalTracks = await Video.createLocalTracks(config)
      } catch (error) {
        if (error.message.indexOf("Permission denied") > -1) {
          tracksPermissions = true
        }
      }

      if (bowser.chrome) {
        let permissionStatusCamera = await navigator.permissions.query({ name: "camera" })
        let permissionStatusMicrophone = await navigator.permissions.query({ name: "microphone" })

        this.metadata.permissionStatus.camera = permissionStatusCamera.state
        this.metadata.permissionStatus.microphone = permissionStatusMicrophone.state
      } else {
        this.metadata.permissionStatus.camera = tracksPermissions
        this.metadata.permissionStatus.microphone = tracksPermissions
      }

      this.results.camera.result =
        this.userLocalTracks.filter((track) => track.kind === "video").length > 0
          ? TestResult.GOOD
          : TestResult.BAD
      this.results.microphone.result =
        this.userLocalTracks.filter((track) => track.kind === "audio").length > 0
          ? TestResult.GOOD
          : TestResult.BAD

      try {
        this.metadata.inputDevices = await navigator.mediaDevices.enumerateDevices()
      } catch (error) {
        this.metadata.inputDevices = "Cannot access to `navigator.mediaDevices.enumerateDevices()`"
      }

      const tokenResponse = await this.$http.get("/ajax/benchmark/start")
      this.receiverToken = tokenResponse.data.data.receiver.token
      this.senderToken = tokenResponse.data.data.sender.token // fake one

      this.results.twilio.result = await this.twilioConnect()

      this.state = TestState.TESTING

      const tests = this.tests()

      for (let item in tests) {
        this.results[item].result = await tests[item].apply(this)
      }
    },
    async twilioConnect() {
      try {
        this.selectedSource = {
          audio:
            typeof this.devices.audio === "undefined"
              ? "-"
              : this.devices.audio.find((device) => device.deviceId === "default")
              ? "default"
              : this.devices.audio[0].deviceId,
        }

        if (this.devices.video === void 0) {
          this.selectedSource["video"] = "default"
        } else {
          this.selectedSource["video"] = this.devices.video.find(
            (device) => device.deviceId === "default"
          )
            ? "default"
            : this.devices.video[0].deviceId
        }

        let receiver = await Video.connect(this.receiverToken, {
          audio: false,
          video: false,
          networkQuality: {
            local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
        })
        this.rooms.receiver = receiver

        this.rooms.receiver.on("participantConnected", (participant) => {
          participant.on("trackUnsubscribed", (track) => {
            let mediaElements = []
            if (track.stop) mediaElements = track.stop()
            if (track.detach) mediaElements = track.detach()
            mediaElements.forEach((mediaElement) => mediaElement.remove())
          })

          participant.on(
            "networkQualityLevelChanged",
            (networkQualityLevel, networkQualityStats) => {
              this.results.networkQuality.logs.push(networkQualityLevel)
            }
          )
        })

        let sender = await Video.connect(this.senderToken, { tracks: this.userLocalTracks })
        this.rooms.sender = sender

        return TestResult.GOOD
      } catch (error) {
        console.log(error)
        if (error.toString !== void 0) {
          this.results.twilio.logs = error.toString()
        }

        return TestResult.BAD
      }
    },
    async twilioPreflight() {
      const qualityScore = (stat, good, optimal, poor) => {
        if (stat >= poor) return TestResult.BAD
        if (stat >= optimal) return TestResult.SUFFICIENT
        if (stat >= good) return TestResult.GOOD

        return TestResult.GOOD
      }

      return await new Promise((resolve, reject) => {
        const preflightTest = Video.runPreflight(this.senderToken)

        preflightTest.on("failed", (error, report) => reject(error))

        preflightTest.on("completed", (report) => {
          this.results.twilioPreflight.logs = report

          const latency = qualityScore(report.stats.rtt.average, 100, 250, 400)
          const jitter = qualityScore(report.stats.jitter.average, 5, 10, 30)
          const packetLoss = qualityScore(report.stats.packetLoss.average, 1, 3, 8)

          const results = [latency, jitter, packetLoss]
          const average = Math.floor(results.reduce((a, b) => a + b, 0) / results.length)

          resolve(average)
        })
      })
    },

    stepChanged(step) {
      if (step > 0) {
        this.sendCallback()
      }
    },

    async sendCallback() {
      const result = this.result

      try {
        if (this.callback) {
          await callback(result)
        } else {
          await this.$http.post("/ajax/benchmark/result", result)
        }
      } catch (_) {}
    },
    bindAudioTrack(track, logs) {
      if (this.audioScript !== null) {
        this.audioScript.disconnect(this.audioContext.destination)
      }

      let stream = null
      if (track.mediaStreamTrack !== void 0) {
        stream = new MediaStream()
        stream.addTrack(track.mediaStreamTrack)
      } else {
        stream = track
      }

      window.AudioContext =
        window.AudioContext ||
        window.webkitAudioContext ||
        window.mozAudioContext ||
        window.msAudioContext

      this.audioContext = new AudioContext()
      let gainNode = this.audioContext.createGain()
      let source = null
      if (stream instanceof MediaStream) {
        source = this.audioContext.createMediaStreamSource(stream)
      } else {
        source = this.audioContext.createMediaElementSource(stream)
      }
      let analyser = this.audioContext.createAnalyser()
      this.audioScript = this.audioContext.createScriptProcessor(512)
      source.connect(analyser)
      source.connect(gainNode)
      source.connect(this.audioScript)
      this.audioScript.connect(this.audioContext.destination)
      gainNode.connect(this.audioContext.destination)

      if (stream instanceof MediaStream) {
        gainNode.gain.value = 0
      } else {
        gainNode.gain.value = 1
      }

      let $this = this
      this.audioScript.addEventListener(
        "audioprocess",
        function (e) {
          let data = e.inputBuffer.getChannelData(0)[0] * 3
          if (+new Date() - $this.audio.lastUpdate > 250) {
            $this.audio.status = Math.abs(data).toFixed(2) * 100
            $this.audio.lastUpdate = +new Date()

            $this.results[logs].logs.push($this.audio.status)
          }
        },
        false
      )
    },
    tests() {
      return {
        trustedoctor: this.trustedoctorLatency,
        socket: () => {
          return this.$socket.connected ? TestResult.GOOD : TestResult.BAD
        },
        networkQuality: this.networkQuality,
        twilioPreflight: this.twilioPreflight,
      }
    },
    async realmLatency() {
      let latency = await this.latency(
        "https://ecs." + this.rooms.receiver._options.realm + ".twilio.com/v2/Configuration"
      )

      if (latency < 200) {
        return TestResult.GOOD
      } else if (latency < 300) {
        return TestResult.SUFFICIENT
      } else {
        return TestResult.BAD
      }
    },
    async trustedoctorLatency() {
      let latency = await this.latency(window.__APP_URL)

      this.results.trustedoctor.logs = latency

      if (latency < 200) {
        return TestResult.GOOD
      } else if (latency < 300) {
        return TestResult.SUFFICIENT
      } else {
        return TestResult.BAD
      }
    },
    latency(endpoint) {
      return new Promise((resolve, reject) => {
        let started = new Date().getTime()
        let http = new XMLHttpRequest()
        http.open("GET", endpoint, true)
        http.onreadystatechange = function () {
          if (http.readyState == 4) {
            let ended = new Date().getTime()

            let milliseconds = ended - started
            resolve(milliseconds)
          }
        }
        try {
          http.send(null)
        } catch (exception) {
          // reject(exception)
        }
      })
    },
    async networkQuality() {
      if (this.results.twilio.result === TestResult.GOOD) {
        let result = await new Promise((resolve, reject) => {
          if (this.results.networkQuality.logs.length > 0) {
            setTimeout(() => {
              let sum,
                avg = 0
              if (this.results.networkQuality.logs.length) {
                sum = this.results.networkQuality.logs.reduce(function (a, b) {
                  return a + b
                })
                avg = sum / this.results.networkQuality.logs.length
              }

              if (avg >= 4) {
                resolve(TestResult.GOOD)
              } else if (avg < 4 && avg > 2) {
                resolve(TestResult.SUFFICIENT)
              } else {
                resolve(TestResult.BAD)
              }
            }, 10000)
          } else {
            resolve(TestResult.BAD)
          }
        })

        return result
      } else {
        return TestResult.BAD
      }
    },
    cancelTest() {
      this.sendCallback()
      this.$emit("cancel")
    },
    finishTest() {
      this.$emit("success", this.results)
    },
  },
  components: {
    "vue-stepper-horizontal": VueStepperHorizontal,
  },
}
