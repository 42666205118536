var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion-item", class: { "is-active": _vm.isActive } },
    [
      typeof _vm.$slots.title !== typeof undefined
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "styled",
                  rawName: "v-styled:color.before",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "color",
                  modifiers: { before: true },
                },
              ],
              staticClass: "accordion-title",
              on: {
                click: function ($event) {
                  _vm.isActive = !_vm.isActive
                },
              },
            },
            [_vm._t("title")],
            2
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "styled",
                  rawName: "v-styled:color.before",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "color",
                  modifiers: { before: true },
                },
              ],
              staticClass: "accordion-title",
              on: {
                click: function ($event) {
                  _vm.isActive = !_vm.isActive
                },
              },
            },
            [
              _c("span", { staticClass: "accordion-name" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]
          ),
      _vm._v(" "),
      _c(
        "transition",
        {
          on: {
            "before-enter": _vm.transitionBeforeEnter,
            enter: _vm.transitionEnter,
            leave: _vm.transitionLeave,
          },
        },
        [
          _vm.isActive
            ? _c(
                "div",
                { staticClass: "accordion-content" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }