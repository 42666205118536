//
//
//
//

export default {
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      default: false,
    },
    description: {
      required: false,
      default: null,
    },
  },
  mounted() {
    this.$parent._addOption(this.label, this.value, this.description, this.disabled)
    this.$el.remove()
  },
  destroyed() {
    this.$parent._removeOption(this.label, this.value)
  },
}
