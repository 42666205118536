//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "../../vue.js"

import Tools from "./tools/Tools.js"
import ToolsComponent from "./tools/Tools.vue"

import DataListComponent from "./data/DataList.vue"
import DataView from "./data/DataView.vue"

import store from "../../stores/store"
import StateManager, { MutationSource } from "./StateManager.js"

import cloneVNode from "../../plugins/VNodeClone.js"

export default {
  store,
  provide() {
    return {
      stateManager: this.stateManager,
      viewsInstances: this.viewsInstances,
      tools: Tools.list,
      options: this.options,
    }
  },
  components: {
    tools: ToolsComponent,
    "data-list": DataListComponent,
  },
  props: {
    full: {
      default: true,
    },
    programmatic: {
      default: false,
    },
    attachTo: {
      default: null,
    },
    patientInfo: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      required: true,
    },
    customTools: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MutationSource: MutationSource,
      stateManager: new StateManager(),
      views: [],
      viewsInstances: [],
      switchLayoutVisible: false,
      orientation: "flex",
      data: null,
    }
  },
  watch: {
    slotsNodes: {
      handler(slots) {
        Object.keys(slots).forEach((key) => {
          this.$slots[key] = slots[key]
        })

        this.$forceUpdate()
      },
      immediate: true,
      deep: true,
    },
    layout: {
      handler({ views, orientation }) {
        this.switchLayout(views, orientation, MutationSource.API)
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.slots = []
    this.$forceUpdate()
  },
  computed: {
    slotsNodes() {
      let slots = {}

      Object.keys(this.slots || {}).forEach((key) => {
        slots[key] = cloneVNode(this.slots[key].vnode[0])
      })

      return slots
    },
    stateManagerState() {
      return this.$store.state.viewer || null
    },
    gridFlex() {
      let width = "100%"
      let height = "100%"
      switch (this.views.length) {
        case 1:
          width = "100%"
          height = "100%"
          break
        case 2:
          width = this.orientation == "row" ? "50%" : "100%"
          height = this.orientation == "row" ? "100%" : "50%"
          break
        case 3:
        case 4:
          width = "50%"
          height = "50%"
          break
        case 5:
        case 6:
          width = this.orientation == "row" ? "33.333%" : "50%"
          height = this.orientation == "row" ? "50%" : "33.333%"
          break
      }

      let style = {
        width: width,
        height: height,
      }

      return style
    },
    layout() {
      if (
        this.stateManager !== null &&
        this.stateManager.isEnabled() &&
        this.$store.state.viewer !== void 0
      ) {
        return this.$store.state.viewer.layout
      } else {
        return {
          views: this.views.length,
          orientation: this.orientation,
        }
      }
    },
    selectedItems() {
      return this.viewsInstances.map((instance) => instance.hash)
    },
  },
  beforeMount() {
    document.body.style.overflow = "hidden"
    if (this.programmatic) {
      if (this.attachTo !== null) {
        this.attachTo.appendChild(this.$el)
      } else {
        document.body.appendChild(this.$el)
      }
    }

    Tools.addTools(this.customTools)
  },
  mounted() {
    store.registerModule("viewer", this.stateManager.store, { preserveState: false })

    const initialDataView = Vue.extend(DataView)
    this.views.push(initialDataView)

    Tools.init(this, [])
  },
  destroyed() {
    document.body.style.overflow = ""

    this.stateManager.unbindSynchronization()
    store.unregisterModule("viewer")
  },
  methods: {
    viewMounted(instance) {
      this.viewsInstances.push(instance)
    },
    viewDestroyed(instance) {
      const index = this.viewsInstances.indexOf(instance)
      if (index > -1) this.viewsInstances.splice(index, 1)
    },
    viewDataChanged(instance) {
      setTimeout(() => {
        Tools.init(this, [instance])
        if (this.options.tools) {
          Tools.bind([instance])
        }
      }, 150)
    },
    close() {
      this.$destroy()

      if (this.programmatic) {
        if (this.attachTo !== null) {
          this.attachTo.removeChild(this.$el)
        } else {
          document.body.removeChild(this.$el)
        }
      }
    },
    itemDragged(state) {
      this.$refs.viewsInstances.forEach((view) => view.setDroppableAreaState(state))
    },
    itemClicked(payload) {
      if (this.viewsInstances.length === 1) {
        this.viewsInstances[0].loadStack(payload)
      } else {
        this.viewsInstances
          .sort(
            (a, b) => (a.data !== null) - (b.data !== null) || a.dataChangedAt > b.dataChangedAt
          )
          .find((_) => true)
          .loadStack(payload)
      }
    },
    switchLayout(views, orientation, mutationSource) {
      const diff = views - this.views.length
      if (diff > 0) {
        this.views.push(...Array.apply(null, Array(diff)).map((item) => Vue.extend(DataView)))
      } else if (diff < 0) {
        this.views.splice(diff, Math.abs(diff))
      }

      this.orientation = orientation || "flex"

      if (mutationSource === MutationSource.USER) {
        if (this.stateManager !== null && this.stateManager.isEnabled()) {
          this.stateManager.layoutChanged(this.views.length, this.orientation)
        }
      }
    },
    switchLayoutToggle() {
      this.switchLayoutVisible = !this.switchLayoutVisible
    },
    switchLayoutHide() {
      this.switchLayoutVisible = false
    },
    bindSynchronization(id, participants) {
      this.stateManager.bindSynchronization(this.$socket, id, participants)
    },
  },
}
