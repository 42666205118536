import Vue from "../vue.js"

import { unionBy } from "lodash"
import { Constants } from "../models/Constants"

export default {
  namespaced: true,
  state: {
    object_type: null,
    processing: false,
    statuses: Constants.Thread,
    threads: {},
  },
  mutations: {
    updateThread(state, { thread }) {
      let oldThread = state.threads[thread.thread_id]
      if (oldThread !== undefined) {
        // make property reactive
        Vue.set(oldThread, "unread_count", thread.unread_count)
        thread = Object.assign(oldThread, thread)
        Vue.set(state.threads, thread.thread_id, thread)
      }
    },
    updateType(state, { object_type }) {
      Vue.set(state, "object_type", object_type)
    },
    setProcessing(state, value) {
      Vue.set(state, "processing", value)
    },
    addMessage(state, { thread_id, message }) {
      let messages = unionBy(state.threads[thread_id].messages, [message], "message_id")
      Vue.set(state.threads[thread_id], "messages", messages)
    },
    addThread(state, thread) {
      thread.messages = []
      Vue.set(state.threads, thread.thread_id, thread)
    },
    setAvailable(state, { thread_id, available }) {
      Vue.set(state.threads[thread_id], "is_available", available)
    },
  },
  actions: {
    fetch({ commit, state }, { thread_id }) {
      return Vue.http.get(`/ajax/thread/${thread_id}`).then((response) => {
        commit("updateThread", { thread: response.data.data })
      })
    },
    fetchThreads({ commit, dispatch, state }, { object_id, object_type }) {
      return new Promise((resolve, reject) => {
        if (Object.keys(state.threads).length > 0) {
          resolve(true)
        }
        commit("setProcessing", true)
        Vue.http
          .get(`/ajax/threads/${object_id}/${object_type}`)
          .then((response) => {
            commit("updateType", { object_type: object_type })
            commit("setProcessing", false)
            response.data.data.forEach((thread) => commit("addThread", thread))
            resolve(response)
          })
          .catch((response) => {
            commit("setProcessing", false)
            reject(response)
          })
      })
    },
    markThreadAsRead({ commit, state }, { thread_id }) {
      commit("updateThread", { thread: { thread_id: thread_id, unread_count: 0 } })
      return Vue.http.post(`/ajax/thread/${thread_id}/markAsRead`)
    },
    removeParticipant({ commit, dispatch }, { thread_id, user_id }) {
      return new Promise((resolve, reject) => {
        commit("setProcessing", true)
        Vue.http
          .delete(`/ajax/thread/${thread_id}/participants`, { data: { user_id: user_id } })
          .then((response) => {
            dispatch("fetchThreads", {
              object_id: appointment_id,
              object_type: Constants.Thread.TYPE_APPOINTMENT,
            })
            resolve(true)
          })
          .catch((error) => {
            commit("setProcessing", false)
            reject(error)
          })
      })
    },
    addParticipants({ commit, dispatch }, { thread_id, selectedParticipants }) {
      return new Promise((resolve, reject) => {
        commit("setProcessing", true)
        Vue.http
          .post(`/ajax/thread/${thread_id}/participants`, { users: selectedParticipants })
          .then((response) => {
            commit("setProcessing", false)
            resolve(true)
          })
          .catch((error) => {
            commit("setProcessing", false)
            reject(error)
          })
      })
    },
    sendMessage(context, { thread_id, message, files }) {
      return Vue.http
        .post(`/ajax/thread/${thread_id}/message`, {
          message: message,
          object_type: context.state.object_type,
          files: files,
        })
        .then((response) => {
          context.commit("addMessage", { thread_id: thread_id, message: response.data.data })
        })
    },
    socket_thread(context, thread_id) {
      context.dispatch("fetch", { thread_id: thread_id })
    },
    socket_threads(context, payload) {
      context.dispatch("fetchThreads", payload)
    },
    join({ commit, dispatch }, { thread_id, appointment_id }) {
      commit("setProcessing", true)
      return Vue.http
        .post(`/ajax/thread/${thread_id}/join`, {
          appointment_id,
        })
        .then((response) => {
          dispatch("fetchThreads", {
            object_id: appointment_id,
            object_type: Constants.Thread.TYPE_APPOINTMENT,
          })
        })
    },
    close({ commit, dispatch }, { thread_id, appointment_id }) {
      commit("setProcessing", true)
      return Vue.http
        .post(`/ajax/thread/${thread_id}/close`, {
          appointment_id,
        })
        .then((response) => {
          dispatch("fetchThreads", {
            object_id: appointment_id,
            object_type: Constants.Thread.TYPE_APPOINTMENT,
          })
        })
    },
    open({ commit, dispatch }, { thread_id, appointment_id }) {
      commit("setProcessing", true)
      return Vue.http
        .post(`/ajax/thread/${thread_id}/open`, {
          appointment_id,
        })
        .then((response) => {
          dispatch("fetchThreads", {
            object_id: appointment_id,
            object_type: Constants.Thread.TYPE_APPOINTMENT,
          })
        })
    },
    rename({ commit, dispatch }, { thread_id, appointment_id, currentName }) {
      commit("setProcessing", true)
      return Vue.http
        .put(`/ajax/thread/${thread_id}`, {
          name: currentName,
        })
        .then((response) => {
          dispatch("fetchThreads", {
            object_id: appointment_id,
            object_type: Constants.Thread.TYPE_APPOINTMENT,
          })
        })
    },
  },
  getters: {
    unreadTotal: (state) => () => {
      let total = 0
      Object.keys(state.threads).forEach((thread_id, index) => {
        total += state.threads[thread_id].hasOwnProperty("unread_count")
          ? state.threads[thread_id].unread_count
          : 0
      })
      return total
    },
    isThreadClosed: (state) => (thread) => {
      if (thread === undefined) {
        return false
      }
      return thread.status === state.statuses.STATUS_CLOSED
    },
  },
}
