//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComponentMixin from "./ComponentMixin"

export default {
  mixins: [ComponentMixin],
  name: "CustomerList",
  data() {
    return {
      customers: {
        data: [],
      },
    }
  },
  mounted() {
    this.fetchCustomers(1)
  },
  methods: {
    fetchCustomers(page) {
      this.emitProcessing(true)
      let params = {
        page: page,
        action: "fetchCustomers",
        componentId: this.componentId,
      }
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.emitProcessing(false)
          this.customers = response.data.data
        })
        .catch((response) => {
          this.emitProcessing(false)
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
  },
}
