//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email } from "vuelidate/lib/validators"
import Customize from "../../../../plugins/Customize"

export default {
  mixins: [Customize()],
  data() {
    return {
      email: null,
      participants: this.state,
      existingParticipants: [],
    }
  },
  props: {
    state: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxInvitedParticipantsQuantity: {
      type: Number,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    addParticipant() {
      if (this.email === null || this.email.length === 0) {
        return
      }
      this.participants.push(this.email)
      this.participants = [...new Set(this.participants)]
      this.email = null
      this.$emit("updatedParticipants", this.participants)
    },
    removeParticipant(index) {
      this.participants.splice(index, 1)
      this.$emit("updatedParticipants", this.participants)
    },
    emailInputChanged() {
      this.$v.email.$touch()
      this.$emit("update:isError", this.$v.email.$error)
    },
    emailInputUnfocus() {
      if (this.$v.email.$error === false) {
        this.addParticipant()
      }
    },
  },
  computed: {
    remainingInvitedParticipantsQuantity() {
      return this.maxInvitedParticipantsQuantity - this.participants.length
    },
    maxInvitedParticipantsQuantityReached() {
      return this.remainingInvitedParticipantsQuantity === 0
    },
  },
  validations: {
    email: { email },
  },
  mounted() {
    this.existingParticipants = Array.from(this.participants)
  },
}
