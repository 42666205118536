//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Translate from "../../../helpers/translate.js"
import { Permissions } from "../../models/Permissions"
import { required, requiredIf } from "vuelidate/lib/validators"
import * as datePickerLanguages from "vuejs-datepicker/dist/locale"

export default {
  props: {
    saveButtonLabel: {
      default: Translate.get("common.add-a-time-slot"),
      type: String,
    },
    saveButtonClasses: {
      default() {
        return {}
      },
    },
    showCancelButton: {
      default: true,
      type: Boolean,
    },
    initialDate: {
      default: () => new Date(),
      type: Date,
    },
    assignedId: {
      default: null,
      required: true,
    },
    appointmentId: {
      default: null,
      required: false,
    },
  },
  data() {
    return {
      processing: false,
      group: window._GROUP,
      weekDays: [
        { label: this.$t.get("common.week-days.mon"), value: 1 },
        { label: this.$t.get("common.week-days.tue"), value: 2 },
        { label: this.$t.get("common.week-days.wed"), value: 3 },
        { label: this.$t.get("common.week-days.thu"), value: 4 },
        { label: this.$t.get("common.week-days.fri"), value: 5 },
        { label: this.$t.get("common.week-days.sat"), value: 6 },
        { label: this.$t.get("common.week-days.sun"), value: 7 },
      ],

      form: {
        slotType: "single",
        member: null,

        date: null,
        hour: null,
        minute: null,

        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        days: [1, 2, 3, 4, 5],

        duration: 30,
      },
      bookAutomatically: false,
    }
  },
  validations: {
    form: {
      member: {
        required: requiredIf(function () {
          return this.members.length > 0 && this.canManageGroupTimeSlots
        }),
      },
      date: {
        required: requiredIf(function () {
          return this.form.slotType === "single"
        }),
      },
      hour: {
        required: requiredIf(function () {
          return this.form.slotType === "single"
        }),
      },
      minute: {
        required: requiredIf(function () {
          return this.form.slotType === "single"
        }),
      },

      from_date: {
        required: requiredIf(function () {
          return this.form.slotType === "range"
        }),
      },
      to_date: {
        required: requiredIf(function () {
          return this.form.slotType === "range"
        }),
      },
      from_time: {
        required: requiredIf(function () {
          return this.form.slotType === "range"
        }),
      },
      to_time: {
        required: requiredIf(function () {
          return this.form.slotType === "range"
        }),
      },

      duration: { required },
    },
  },
  mounted() {
    if (this.initialDate !== null) {
      this.form.date = this.initialDate
      this.form.from_date = this.initialDate
      this.form.to_date = this.$moment(this.initialDate).add(2, "weeks").toDate()
    }

    if (false === this.canManageGroupTimeSlots) {
      this.form.member = this.user
      return
    }

    if (this.group.members === void 0 && this.assignedId === null) {
      this.processing = true
      this.$http.get("/ajax/organizations/" + this.group.group_id + "/members").then(
        (response) => {
          this.processing = false
          this.group.members = response.data.data
          if (this.members.length === 1) {
            this.form.member = this.members[0]
          }
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    }

    if (this.members.length === 1) {
      this.form.member = this.members[0]
    }
  },
  computed: {
    appointment() {
      let appointment = this.$store.state.appointment
      if (appointment.appointment_id === this.appointmentId) {
        return appointment
      } else {
        return null
      }
    },
    isAppointmentForFree() {
      return this.appointment?.price?.for_free || false
    },
    user() {
      return this.$store.state.user
    },
    hasGroupPermission() {
      return this.$store.getters["user/hasGroupPermission"]
    },
    canManageGroupTimeSlots() {
      return (
        this.group !== void 0 &&
        this.hasGroupPermission(this.group.group_id, Permissions.GROUP_GLOBAL_REQUESTS_MANAGE_SLOTS)
      )
    },
    canManageTimeSlots() {
      return (
        this.group !== void 0 &&
        (this.hasGroupPermission(
          this.group.group_id,
          Permissions.GROUP_GLOBAL_REQUESTS_MANAGE_SLOTS
        ) ||
          this.hasGroupPermission(this.group.group_id, Permissions.GROUP_GLOBAL_REQUESTS_RECEIVE))
      )
    },
    members() {
      if (this.group !== void 0 && this.group.members !== void 0 && this.assignedId === null) {
        return this.group.members.filter((member) => {
          return member.permissions
            .map((item) => {
              return typeof item == "string" ? item : item.name
            })
            .includes(Permissions.GROUP_GLOBAL_REQUESTS_RECEIVE)
        })
      }

      return []
    },
    availableHours() {
      let times = []
      let id = 1
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          times.push({
            id: id,
            value: "0" + i,
            label: this.$moment().hours(i).format(this.$dateTimeFormat.time.replace(":mm", "")),
          })
        } else {
          times.push({
            id: id,
            value: i,
            label: this.$moment().hours(i).format(this.$dateTimeFormat.time.replace(":mm", "")),
          })
        }

        id++
      }

      return times
    },
    availableMinutes() {
      let times = []
      let id = 1
      for (let i = 0; i < 12; i++) {
        if (i * 5 < 10) {
          times.push({
            id: id,
            value: "0" + i * 5,
          })
        } else {
          times.push({
            id: id,
            value: i * 5,
          })
        }
      }

      return times
    },
    availableTimes() {
      let times = []
      let id = 1

      this.availableHours.forEach((hour) => {
        for (let i = 0; i < 60 / 30; i++) {
          if (i * 30 < 10) {
            times.push({
              value: hour.value + ":" + ("0" + i * 30),
              id: id,
            })
          } else {
            times.push({
              value: hour.value + ":" + i * 30,
              id: id,
            })
          }
          id = id + 1
        }
        id = id + 1
      })

      return times
    },
    selectedDate() {
      return this.$moment(this.form.date).format("DD.MM.YYYY")
    },
    selectedFromDate() {
      return this.$moment(this.form.from_date).format("DD.MM.YYYY")
    },
    selectedToDate() {
      return this.$moment(this.form.to_date).format("DD.MM.YYYY")
    },
    dateStart() {
      if (this.form.slotType === "single") {
        return this.$moment(
          this.selectedDate + " " + this.form.hour + ":" + this.form.minute,
          "DD.MM.YYYY HH:mm"
        )
          .locale("en")
          .format("YYYY-MM-DD[T]HH:mmZ")
      } else {
        return this.$moment(this.selectedFromDate + " " + this.form.from_time, "DD.MM.YYYY HH:mm")
          .locale("en")
          .format("YYYY-MM-DD[T]HH:mmZ")
      }
    },
    dateEnd() {
      if (this.form.slotType === "single") {
        return this.$moment(
          this.selectedDate + " " + this.form.hour + ":" + this.form.minute,
          "DD.MM.YYYY HH:mm"
        )
          .add(this.form.duration, "minutes")
          .locale("en")
          .format("YYYY-MM-DD[T]HH:mmZ")
      } else {
        return this.$moment(this.selectedToDate + " " + this.form.to_time, "DD.MM.YYYY HH:mm")
          .locale("en")
          .format("YYYY-MM-DD[T]HH:mmZ")
      }
    },
    daysRangeCount() {
      return this.$moment(this.form.to_date).diff(this.form.from_date, "days") + 1
    },
    disabledDates() {
      return {
        to: this.$moment().subtract(1, "days").toDate(),
        from: this.$moment(this.form.from_date).add(3, "months").toDate(),
      }
    },
    datepickerLanguage() {
      const languageAdaptable = {
        af: "af",
        ar: "ar",
        bg: "bg",
        bs: "bs",
        ca: "ca",
        cs: "cs",
        da: "da",
        de: "de",
        ee: "ee",
        el: "el",
        en: "en",
        es: "es",
        fa: "fa",
        fi: "fi",
        fo: "fo",
        fr: "fr",
        ge: "ge",
        gl: "gl",
        he: "he",
        hu: "hu",
        hr: "hr",
        id: "id",
        is: "is",
        it: "it",
        ja: "ja",
        kk: "kk",
        ko: "ko",
        lb: "lb",
        lt: "lt",
        lv: "lv",
        mk: "mk",
        mn: "mn",
        "nb-No": "nbNO",
        nl: "nl",
        pl: "pl",
        "pt-br": "ptBR",
        ro: "ro",
        ru: "ru",
        sk: "sk",
        "sl-si": "slSI",
        sv: "sv",
        sr: "sr",
        "sr-cyrl": "srCyrl",
        th: "th",
        tr: "tr",
        uk: "uk",
        ur: "ur",
        vi: "vi",
        zh: "zh",
        "zh-hk": "zhHK",
      }
      return datePickerLanguages[languageAdaptable[this.$locale]]
    },
  },
  bridges: {
    openTermPicker() {
      this.open()
    },
  },
  watch: {
    "form.from_date": {
      handler() {
        if (this.daysRangeCount < 7) {
          this.form.days = this.form.days.filter((item) => {
            return this.isDisabledDay(item) === false
          })
        }
      },
    },
    "form.to_date": {
      handler() {
        if (this.daysRangeCount < 7) {
          this.form.days = this.form.days.filter((item) => {
            return this.isDisabledDay(item) === false
          })
        }
      },
    },
  },
  methods: {
    isDisabledDay(indexOfWeek) {
      let days = new Array(this.daysRangeCount).fill(0).map((_, index) => {
        return this.$moment(this.form.from_date).add(index, "day").get("isoWeekday")
      })

      return days.indexOf(indexOfWeek) === -1
    },
    customFormatter(date) {
      return this.$moment(date, "DD.MM.YYYY").format(this.$dateTimeFormat.date)
    },
    cancel() {
      this.$emit("cancel")
    },
    addTimeSlot() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$bridge.emit("addToast", Translate.get("validation.error"), "error")
        return
      }
      this.processing = true
      this.$emit("is-processing", true)
      let request = null

      let url = ""
      if (this.form.slotType === "single") {
        url = `/ajax/organizations/${this.group.group_id}/appointments/terms`
      } else {
        url = `/ajax/organizations/${this.group.group_id}/appointments/terms/many`
      }

      request = this.$http.post(url, {
        appointment_id: this.appointmentId || undefined,
        start_date: this.dateStart,
        end_date: this.dateEnd,
        duration: this.form.duration,
        user_id: this.form.member === null ? this.assignedId : this.form.member.user_id,
        days: this.form.slotType === "single" ? null : this.form.days,
        notify_requester: this.bookAutomatically === false,
      })

      request.then(
        (response) => {
          this.$bridge.emit("addToast", response.data.message, "success")
          if (this.form.slotType === "single" && this.bookAutomatically === true) {
            this.reserveTerm(response.data.data[0].term_id)
          } else {
            this.processing = false
            this.$emit("is-processing", false)
            this.$emit("success")
          }
        },
        (error) => {
          this.processing = false
          this.$emit("is-processing", false)
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    reserveTerm(term_id) {
      this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/terms/reserve", {
          term_id: term_id,
        })
        .then(
          (response) => {
            this.processing = false
            this.$emit("is-processing", false)
            this.$bridge.emit("addToast", response.data.message, "success")
            this.$emit("success")
          },
          (error) => {
            this.processing = false
            this.$emit("is-processing", false)
            this.$emit("success")
            this.$bridge.emit("addToast", error.response.data.message, "error")
            //this.throwRavenException("Reserve: ", error)
          }
        )
    },
  },
  created() {
    this.bookAutomatically = this.isAppointmentForFree
  },
}
