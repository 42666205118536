//
//
//
//
//

import Modal from "../../plugins/modal/modal"

export default {
  props: {
    appointmentId: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: function () {
        return ["button", "accent"]
      },
    },
    buttonText: {
      type: String,
    },
    onTheCall: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    joinAppointment() {
      Modal.confirm(this)
        .title(this.$t.get("appointment.join-appointment"))
        .content(this.$t.get("appointment.want-to-join"))
        .positive(this.$t.get("common.yes"), () => {
          return this.$http
            .post("/ajax/appointment/" + this.appointmentId + "/join", {
              on_the_call: this.onTheCall,
            })
            .then(
              (response) => {
                window.location.reload(true)
                this.$bridge.emit("addToast", response.data.message, "success")
                return true
              },
              (response) => {
                this.$bridge.emit(
                  "addToast",
                  this.$t.get("common.something-went-wrong-please-try-again"),
                  "error"
                )
                return false
              }
            )
        })
        .negative(this.$t.get("appointment.cancel"))
        .show()
    },
  },
}
