var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.actualContextParticipants.length !== 0
    ? _c("div", [
        _vm.actualContextParticipants.length > 0 ? _c("hr") : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "section",
          domProps: {
            textContent: _vm._s(
              _vm.type === _vm.propsTypes.participants
                ? _vm.$t.get("appointment.participants-to-consultation")
                : _vm.$t.get("appointment.shared-with")
            ),
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "participiants",
                class: { "preloader preloader-cover": _vm.processing },
              },
              _vm._l(_vm.actualContextParticipants, function (participant) {
                return _c(
                  "div",
                  { key: participant.id, staticClass: "flex single" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full lg:w-4/12 columns user" },
                      [
                        _c("div", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage:
                              "url(" + participant.user.avatar_url || "" + ")",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  participant.user.name +
                                    " " +
                                    participant.user.surname
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          participant.is_guest &&
                          participant.roles.includes("secretary") === false
                            ? _c("div", { staticClass: "type" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t.get(
                                        "appointment.participant-guest"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : participant.user_id ===
                              _vm.appointment.requester_id
                            ? _c("div", { staticClass: "type" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t.get("appointment.requester"))
                                  ),
                                ]),
                              ])
                            : participant.type ===
                              _vm.constants.AppointmentParticipant.TYPE_SHARED
                            ? _c("div", { staticClass: "type" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t.get(
                                        "appointment.healtcare-provider"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _c("div", { staticClass: "type" }, [
                                participant.roles.includes("secretary")
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get(
                                            "appointment.participant-secretary"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t.get(
                                            "appointment.participant-doctor"
                                          )
                                        )
                                      ),
                                    ]),
                              ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-full lg:w-6/12 columns permissions" },
                      [
                        _vm.isReporter(participant.user_id)
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t.get("appointment.report-lead"))
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.appointment.status !==
                          _vm.constants.Appointment.STATUS_REUQESTED &&
                        participant.on_the_call &&
                        _vm.isVideoCall
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t.get("appointment.on-the-call"))
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.appointment.status !==
                          _vm.constants.Appointment.STATUS_REUQESTED &&
                        _vm.isVideoCall &&
                        _vm.participantHasPermission(
                          participant.participant_id,
                          _vm.permissions.APPOINTMENT_MANAGE_CALL
                        )
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t.get("appointment.can-manage-the-call")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isAssigned(participant.user_id)
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t.get("appointment.assigned"))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.haveDotsMenu(participant)
                      ? _c(
                          "div",
                          {
                            staticClass: "w-full lg:w-2/12 columns text-right",
                          },
                          [
                            _c(
                              "vue-context-menu",
                              { staticClass: "right-top" },
                              [
                                _vm.canManageSection(participant)
                                  ? [
                                      _vm.isVideoCall &&
                                      (_vm.hasAppointmentPermission(
                                        _vm.permissions
                                          .APPOINTMENT_SET_PARTICIPANT_CALL
                                      ) ||
                                        _vm.hasAppointmentPermission(
                                          _vm.permissions
                                            .GROUP_GLOBAL_REQUESTS_SET_PARTICIPANT_CALL
                                        ))
                                        ? _c("li", [
                                            participant.on_the_call
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "styled",
                                                        rawName:
                                                          "v-styled:color.hover",
                                                        value:
                                                          _vm.palette.colors
                                                            .primary.main,
                                                        expression:
                                                          "palette.colors.primary.main",
                                                        arg: "color",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "text-hover",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.manageParticipant(
                                                          participant.participant_id,
                                                          _vm.permissions
                                                            .APPOINTMENT_SET_PARTICIPANT_CALL
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t.get(
                                                            "appointment.remove-participant-from-call"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "styled",
                                                        rawName:
                                                          "v-styled:color.hover",
                                                        value:
                                                          _vm.palette.colors
                                                            .primary.main,
                                                        expression:
                                                          "palette.colors.primary.main",
                                                        arg: "color",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "text-hover",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.manageParticipant(
                                                          participant.participant_id,
                                                          _vm.permissions
                                                            .APPOINTMENT_SET_PARTICIPANT_CALL
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t.get(
                                                            "appointment.add-participant-to-call"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAppointmentPermission(
                                        _vm.permissions
                                          .GROUP_GLOBAL_REQUESTS_SET_REPORT_AUTHOR
                                      ) &&
                                      _vm.isReporter(participant.user_id) ===
                                        false &&
                                      _vm.participantHasPermission(
                                        participant.participant_id,
                                        _vm.permissions
                                          .GROUP_GLOBAL_REQUESTS_RECEIVE
                                      )
                                        ? _c("li", [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName:
                                                      "v-styled:color.hover",
                                                    value:
                                                      _vm.palette.colors.primary
                                                        .main,
                                                    expression:
                                                      "palette.colors.primary.main",
                                                    arg: "color",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass: "text-hover",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.manageParticipant(
                                                      participant.participant_id,
                                                      _vm.permissions
                                                        .GROUP_GLOBAL_REQUESTS_SET_REPORT_AUTHOR
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "appointment.set-report-author"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.appointment.assigned_id ===
                                        _vm.user.user_id &&
                                      participant.user_id !== _vm.user.user_id
                                        ? _c("li", [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName:
                                                      "v-styled:color.hover",
                                                    value:
                                                      _vm.palette.colors.primary
                                                        .main,
                                                    expression:
                                                      "palette.colors.primary.main",
                                                    arg: "color",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass: "text-hover",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.manageParticipant(
                                                      participant.participant_id,
                                                      _vm.permissions
                                                        .APPOINTMENT_MANAGE_CALL
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.participantHasPermission(
                                                  participant.participant_id,
                                                  _vm.permissions
                                                    .APPOINTMENT_MANAGE_CALL
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t.get(
                                                              "appointment.disallow-manage-the-call"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t.get(
                                                              "appointment.allow-manage-the-call"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canMuteSection(participant)
                                  ? [
                                      participant.muted === false
                                        ? _c("li", [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "styled",
                                                    rawName:
                                                      "v-styled:color.hover",
                                                    value:
                                                      _vm.palette.colors.primary
                                                        .main,
                                                    expression:
                                                      "palette.colors.primary.main",
                                                    arg: "color",
                                                    modifiers: { hover: true },
                                                  },
                                                ],
                                                staticClass: "text-hover",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.manageParticipant(
                                                      participant.participant_id,
                                                      "mute-participant",
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "appointment.participant-mute-notifications"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("li", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "text-hover",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.manageParticipant(
                                                      participant.participant_id,
                                                      "unmute-participant",
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t.get(
                                                        "appointment.participant-unmute-notifications"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canRemoveSection(participant)
                                  ? [
                                      _c("li", [_c("hr")]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "text-hover red",
                                            on: {
                                              click: function ($event) {
                                                return _vm.manageParticipant(
                                                  participant.participant_id,
                                                  "remove",
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t.get(
                                                    "appointment.remove-participant"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.type === _vm.propsTypes.participants &&
            _vm.canInvite &&
            _vm.isClosed === false
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "styled",
                        rawName: "v-styled:color",
                        value: _vm.palette.colors.secondary.main,
                        expression: "palette.colors.secondary.main",
                        arg: "color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background",
                        value: _vm.palette.colors.primary.alt,
                        expression: "palette.colors.primary.alt",
                        arg: "background",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:border-color",
                        value: _vm.palette.colors.primary.main,
                        expression: "palette.colors.primary.main",
                        arg: "border-color",
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:background.hover",
                        value: _vm.palette.colors.hover.main,
                        expression: "palette.colors.hover.main",
                        arg: "background",
                        modifiers: { hover: true },
                      },
                      {
                        name: "styled",
                        rawName: "v-styled:color.hover",
                        value: _vm.palette.colors.hover.alt,
                        expression: "palette.colors.hover.alt",
                        arg: "color",
                        modifiers: { hover: true },
                      },
                    ],
                    staticClass: "button",
                    attrs: { disabled: _vm.processing },
                    on: { click: _vm.inviteParticipant },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t.get("appointment.invite-a-participant")) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === _vm.propsTypes.participants &&
            _vm.isClosed === false &&
            _vm.hasAppointmentPermission(
              _vm.permissions.GROUP_GLOBAL_REQUESTS_JOIN_TO_PARTICIPANTS
            ) &&
            _vm.isParticipant(_vm.user.user_id) === false
              ? _c("join", {
                  attrs: {
                    "appointment-id": _vm.appointment.appointment_id,
                    classes: ["button"],
                    disabled: _vm.processing,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }