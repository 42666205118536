//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal"
export default {
  props: {
    autoEnableTwoFactor: {
      type: Boolean,
      default: false,
    },
    enableCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      processing: false,
      currentStep: 1,
      countryCode: null,
      token: "",
      phoneNumber: "",
    }
  },
  computed: {
    phoneCode() {
      const country = this.countryCodes.find((code) => code.value === this.countryCode)
      return country ? country.phone : null
    },
    countryCodes() {
      return [{ value: null, label: this.$t.get("common.choose-option") }].concat(
        window.countries
          .filter((country) => {
            return country.phone !== null
          })
          .sort((country) => country.name)
          .map((country) => {
            return {
              value: country.code,
              label: `${this.$t.get(country.translation_key)} (+${country.phone})`,
              phone: country.phone,
            }
          })
      )
    },
    phoneMask() {
      return {
        model: "phoneNumber",
        phone: true,
        phoneRegionCode: this.countryCode,
      }
    },
    number() {
      return `+${this.phoneCode} ${this.phoneNumber}`
    },
  },
  methods: {
    sendCode() {
      Modal.confirm(this)
        .title(this.$t.get("auth.phone-verification"))
        .content(this.$t.get("auth.phone-verification-question", { phone: this.number }))
        .positive(this.$t.get("common.yes"), () => {
          this.requestVerificationToken()
        })
        .negative(this.$t.get("common.no"))
        .show()
    },
    requestVerificationToken() {
      this.processing = true
      this.$http.post("/ajax/account/phone/token", { number: this.number }).then(
        (response) => {
          this.currentStep = 2
          this.$bridge.emit("addToast", this.$t.get("auth.phone-verification-code-sent"), "success")
          this.processing = false
        },
        (error) => {
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    verifyNumber() {
      this.processing = true
      this.$http
        .post("/ajax/account/phone/verify", {
          token: this.token,
          auto_enable: this.autoEnableTwoFactor,
        })
        .then(
          (response) => {
            this.$bridge.emit(
              "addToast",
              this.$t.get("auth.phone-verification-number-has-been-verified"),
              "success"
            )
            this.processing = false
            this.$emit("number-verified")
            this.$emit("success")
          },
          (error) => {
            this.processing = false
            this.token = ""
            this.$bridge.emit("addToast", error.response.data.message, "error")
          }
        )
    },
  },
}
