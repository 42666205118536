var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box margin-top basic-details" }, [
    _vm.providerName !== null && _vm.providerName !== ""
      ? _c("div", { staticClass: "provider" }, [
          _vm._v("\n    " + _vm._s(_vm.$t.get("common.source")) + ": "),
          _c("strong", [_vm._v(_vm._s(_vm.providerName))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "user" }, [
        _c(
          "div",
          {
            staticClass: "avatar",
            style: { backgroundImage: "url(" + _vm.customer.avatarUrl + ")" },
          },
          [
            _vm.statusDesc !== null
              ? _c("div", { staticClass: "status" }, [
                  _vm._v(_vm._s(_vm.statusDesc)),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "details" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.customer.name)),
            _c("br"),
            _vm._v(_vm._s(_vm.customer.surname)),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t.get("common.birthday")) + ": "),
              ]),
              _c("strong", [_vm._v(_vm._s(_vm.customer.birthday || "-"))]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [_vm._v(_vm._s(_vm.$t.get("auth.country")) + ": ")]),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.customer.country
                      ? _vm.$t.get("countries." + _vm.customer.country)
                      : "-"
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.showContact
            ? _c("div", { staticClass: "contact" }, [
                _vm.customer.email
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.mailUrlScheme + _vm.customer.email,
                          title: _vm.customer.email,
                        },
                      },
                      [_c("i", { staticClass: "ict-mail" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.customer.phone
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.phoneUrlScheme + _vm.customer.phone,
                          title: _vm.customer.phone,
                        },
                      },
                      [_c("i", { staticClass: "ict-phone" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  [
                    _vm.actions.length > 0
                      ? _c(
                          "vue-context-menu",
                          _vm._l(_vm.filteredActions, function (action) {
                            return _c(
                              "li",
                              [
                                _c(
                                  _vm.getActionComponent(action.component),
                                  _vm._g(
                                    _vm._b(
                                      {
                                        key: action.component,
                                        tag: "component",
                                        attrs: {
                                          mode: "flex",
                                          customer: _vm.customer,
                                        },
                                        on: { "reload-list": _vm.refreshPage },
                                      },
                                      "component",
                                      Object.assign(
                                        _vm.$props,
                                        action.props || {}
                                      ),
                                      false
                                    ),
                                    _vm.$listeners
                                  )
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "context-sidebar-menu" }, [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              class: {
                active: _vm.$route.name === "customerProductDetailsSection",
              },
              on: {
                click: function ($event) {
                  return _vm.changeRoute("customerProductDetailsSection")
                },
              },
            },
            [
              _c("i", { staticClass: "ict-folder" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t.get("customer.details.menu.product-details"))
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              class: {
                active: _vm.$route.name === "customerTechnicalDetailsSection",
              },
              on: {
                click: function ($event) {
                  return _vm.changeRoute("customerTechnicalDetailsSection")
                },
              },
            },
            [
              _c("i", { staticClass: "ict-video-audio" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t.get("customer.details.menu.technical-details"))
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              class: {
                active: _vm.$route.name === "customerEnquiresDetailsSection",
              },
              on: {
                click: function ($event) {
                  return _vm.changeRoute("customerEnquiresDetailsSection")
                },
              },
            },
            [
              _c("i", { staticClass: "ict-enquiries" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t.get("customer.details.menu.enquires"))),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.sfEnabled /* TODO unlock when frontend case list is done */
          ? _c("li", [
              _c(
                "a",
                {
                  class: {
                    active: _vm.$route.name === "customerCasesDetailsSection",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changeRoute("customerCasesDetailsSection")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "ict-document" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t.get("customer.details.menu.cases"))),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }