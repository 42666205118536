export class Draggable {
  constructor(el) {
    this.el = el
    this.el.setAttribute("draggable", "true")

    this.bindEvents()
  }

  setDataTransfer(dataTransfer) {
    this.dataTransfer = dataTransfer
  }

  bindEvents() {
    this.el.addEventListener("dragstart", (event) => this.dragStart(event))
    this.el.addEventListener("dragend", (event) => this.dragEnd(event))
  }

  dragStart(event) {
    if (typeof this.dataTransfer !== typeof undefined) {
      event.dataTransfer.setData("dataTransfer", JSON.stringify(this.dataTransfer))
    }
    if (typeof this.onDragStart === "function") {
      this.onDragStart.call(this, event)
    }
  }
  dragEnd(event) {
    if (typeof this.onDragEnd === "function") {
      this.onDragEnd.call(this, event)
    }
  }

  setOnDragStart(callback) {
    this.onDragStart = callback
  }
  setOnDragEnd(callback) {
    this.onDragEnd = callback
  }
}

export class Droppable {
  constructor(el) {
    this.el = el

    this.bindEvents()
  }

  bindEvents() {
    this.el.addEventListener("dragover", (event) => this.dragOver(event))
    this.el.addEventListener("dragenter", (event) => this.dragEnter(event))
    this.el.addEventListener("dragleave", (event) => this.dragLeave(event))
    this.el.addEventListener("drop", (event) => this.drop(event))
  }

  dragOver(event) {
    event.preventDefault()
    if (typeof this.onDragOver === "function") {
      this.onDragOver.call(this, event)
    }
  }
  dragEnter(event) {
    event.preventDefault()
    if (typeof this.onDragEnter === "function") {
      this.onDragEnter.call(this, event)
    }
  }
  dragLeave(event) {
    if (typeof this.onDragLeave === "function") {
      this.onDragLeave.call(this, event)
    }
  }
  drop(event) {
    event.preventDefault()
    if (typeof this.onDrop === "function") {
      this.onDrop.call(this, event)
    }

    const dataTransfer = event.dataTransfer.getData("dataTransfer")
    if (typeof this.onDataTransfer === "function") {
      try {
        this.onDataTransfer.call(this, JSON.parse(dataTransfer))
      } catch (_) {
        // Silent catch, invalid JSON
      }
    }
  }

  setOnDragOver(callback) {
    this.onDragOver = callback
  }
  setOnDragEnter(callback) {
    this.onDragEnter = callback
  }
  setOnDragLeave(callback) {
    this.onDragLeave = callback
  }
  setOnDrop(callback) {
    this.onDrop = callback
  }
  setOnDataTransfer(callback) {
    this.onDataTransfer = callback
  }
}
