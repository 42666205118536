//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TokenGateway from "../../../components/payment/TokenGateway.vue"

export default {
  components: {
    "token-gateway": TokenGateway,
  },
  props: {
    payment: {
      required: true,
    },
    method: {
      required: true,
    },
  },
  data() {
    return {
      tdhClient: null,
    }
  },
  computed: {
    amountToPay() {
      if (this.payment === null) return ""
      if (typeof this.payment !== typeof undefined)
        return this.payment.amount_brutto + " " + this.payment.currency
    },
    user() {
      return this.$store.state.user
    },
  },
  mounted() {
    this.initTdhMethod()

    this.$nextTick(() => {
      this.$refs.tokenGateway.initialize()
    })
  },
  methods: {
    initTdhMethod() {
      if (this.method.hasOwnProperty("settings")) {
        this.tdhClient = Object.assign({ method_id: this.method.method_id }, this.method.settings)
      }
    },
    cancelPay() {
      this.$emit("cancel-pay")
    },
  },
}
