var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-scroll" },
      [
        _c("div", { staticClass: "table-header" }, [
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.preparedComponents, function (column) {
              return _c(
                _vm.getColumnComponent(column.type),
                _vm._b(
                  {
                    key: column.type,
                    tag: "component",
                    attrs: { mode: "header", type: column.type },
                  },
                  "component",
                  Object.assign({}, _vm.$props, column.props, {
                    columnsCount: _vm.preparedComponents.length,
                  }),
                  false
                )
              )
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.customers.data, function (customer) {
          return _c(
            "div",
            { key: customer.group_customer_id, staticClass: "table-row" },
            [
              _c(
                "div",
                { staticClass: "flex" },
                _vm._l(_vm.preparedComponents, function (column) {
                  return _c(
                    _vm.getColumnComponent(column.type),
                    _vm._g(
                      _vm._b(
                        {
                          key: column.type,
                          tag: "component",
                          attrs: {
                            mode: "row",
                            type: column.type,
                            customer: customer,
                            actions: _vm.customers.actions || [],
                          },
                          on: {
                            "reload-list": function ($event) {
                              return _vm.fetchCustomers(1)
                            },
                          },
                        },
                        "component",
                        Object.assign({}, _vm.$props, column.props, {
                          columnsCount: _vm.preparedComponents.length,
                        }),
                        false
                      ),
                      _vm.$listeners
                    )
                  )
                }),
                1
              ),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.processing && _vm.customers.data.length === 0
          ? _c("div", { staticClass: "table-empty" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-full text-center columns" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t.get("product.customer-list-empty")) +
                      "\n        "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-pagination" },
      [
        _c("vue-paginator", {
          attrs: { "pagination-init": _vm.customers },
          on: { "set-page": _vm.fetchCustomers },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }