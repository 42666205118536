var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-slide",
      class: [_vm.display ? "display" : "", _vm.slideClass],
    },
    [
      _c("div", { staticClass: "header flex" }, [
        _c("div", { staticClass: "w-9/12 columns title" }, [
          _vm._v(_vm._s(_vm.slideTitle)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-3/12 columns text-right" }, [
          _c("div", { staticClass: "close", on: { click: _vm.hide } }, [
            _c("i", { staticClass: "ict-close" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-content" },
        [_vm._t("default"), _vm._v(" "), _vm._t("desc")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }