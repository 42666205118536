var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-container",
      class: { disabled: _vm.disabled },
      attrs: { tabindex: "0" },
      on: {
        focus: _vm.show,
        keydown: [
          function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 38) {
              return null
            }
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.arrowUp.apply(null, arguments)
          },
          function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 40) {
              return null
            }
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.arrowDown.apply(null, arguments)
          },
          function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 27) {
              return null
            }
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.hide.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.hide.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.hide.apply(null, arguments)
          },
        ],
      },
    },
    [
      _c("input", {
        staticClass: "hidden-input",
        attrs: { type: "hidden", name: _vm.name, id: _vm.id },
        domProps: { value: _vm.value },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "select-label" },
        [
          _vm.$scopedSlots.placeholder !== void 0 &&
          _vm.selectedItem !== null &&
          (_vm.enableSearch ? !_vm.isOpened : true)
            ? _vm._t("placeholder", null, { selected: _vm.selectedItem })
            : _vm.$scopedSlots.placeholder === void 0 &&
              _vm.selectedItem !== null &&
              (_vm.enableSearch ? !_vm.isOpened : true)
            ? _c(
                "span",
                [
                  _vm._t(
                    "selected",
                    function () {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.get(_vm.selectedItem, _vm.labelKey)) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.get(_vm.selectedItem, _vm.descriptionKey) !== null
                          ? _c(
                              "div",
                              { staticClass: "description-container" },
                              [
                                _vm.descriptionPreviewText
                                  ? _c(
                                      "div",
                                      { staticClass: "description-preview" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.descriptionPreviewText) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.get(
                                        _vm.selectedItem,
                                        _vm.descriptionKey
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    { selectedItem: _vm.selectedItem }
                  ),
                ],
                2
              )
            : _vm.enableSearch && _vm.isOpened
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchBy,
                    expression: "searchBy",
                  },
                ],
                ref: "searchInput",
                staticClass: "select-search",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t.get("common.type-here-to-find"),
                },
                domProps: { value: _vm.searchBy },
                on: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      ) {
                        return null
                      }
                      return _vm.hide.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 38
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.arrowUp.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 40
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.arrowDown.apply(null, arguments)
                    },
                  ],
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchBy = $event.target.value
                  },
                },
              })
            : _c("span", { staticClass: "placeholder" }, [
                _vm._v(_vm._s(_vm.placeholder)),
              ]),
          _vm._v(" "),
          _vm.enableSearch && !_vm.internalSearch && _vm.loading
            ? _c("div", { staticClass: "preloader" })
            : _c("div", {
                directives: [
                  {
                    name: "styled",
                    rawName: "v-styled:color",
                    value: _vm.palette.colors.primary.main,
                    expression: "palette.colors.primary.main",
                    arg: "color",
                  },
                ],
                staticClass: "select-button",
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpened,
              expression: "isOpened",
            },
          ],
          staticClass: "select-items active",
        },
        [
          _c(
            "v-scrollbar",
            { ref: "scrollBar", staticClass: "select-scroll" },
            [
              _vm._t("static-item"),
              _vm._v(" "),
              _vm._l(_vm.filteredItems, function (item, index) {
                return [
                  item === _vm.selectedItem
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:background-color",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "background-color",
                            },
                          ],
                          key: index,
                          staticClass: "option selected",
                          class: { disabled: item.$disabled },
                          on: {
                            click: function ($event) {
                              return _vm.itemClicked(item)
                            },
                          },
                        },
                        [
                          _vm._t(
                            "item",
                            function () {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.get(item, _vm.labelKey))
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _vm.get(item, _vm.descriptionKey) !== null
                                  ? _c(
                                      "div",
                                      { staticClass: "description-container" },
                                      [
                                        _vm.descriptionPreviewText
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "description-preview",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.descriptionPreviewText
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "description-text" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    item,
                                                    _vm.descriptionKey
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            { item: item }
                          ),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:background-color.hover",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "background-color",
                              modifiers: { hover: true },
                            },
                          ],
                          key: index,
                          staticClass: "option",
                          class: { disabled: item.$disabled },
                          on: {
                            click: function ($event) {
                              return _vm.itemClicked(item)
                            },
                          },
                        },
                        [
                          _vm._t(
                            "item",
                            function () {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.get(item, _vm.labelKey)) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.get(item, _vm.descriptionKey) !== null
                                  ? _c(
                                      "div",
                                      { staticClass: "description-container" },
                                      [
                                        _vm.descriptionPreviewText
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "description-preview",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.descriptionPreviewText
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "description-text" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.get(
                                                    item,
                                                    _vm.descriptionKey
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            { item: item }
                          ),
                        ],
                        2
                      ),
                ]
              }),
              _vm._v(" "),
              _vm.enableSearch && _vm.filteredItems.length === 0
                ? _c("div", { staticClass: "option" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t.get(
                            "common.no-elements-found-consider-changing-the-search-query"
                          )
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }