//
//
//
//
//
//
//
//
//
//
//
//
//

import { Draggable } from "../../../../helpers/dragdrop.js"
import { isEmpty } from "lodash"
export default {
  props: ["item", "type"],
  data() {
    return {
      loaded: false,
      thumbnail_url: "/assets/app/img/default_dicom.png",
    }
  },
  computed: {
    itemName() {
      return this.item.display_name || this.item.name
    },
  },
  mounted() {
    if (this.item.hasOwnProperty("thumbnail_url") && !isEmpty(this.item.thumbnail_url))
      this.thumbnail_url = this.item.thumbnail_url

    const draggable = new Draggable(this.$el)
    draggable.setDataTransfer(this.createPayload())
    draggable.setOnDragStart((ev) => this.$emit("item-dragged", true))
    draggable.setOnDragEnd((ev) => {
      this.$emit("item-dragged", false)
    })
  },
  methods: {
    displayItem() {
      this.$emit("item-clicked", this.createPayload())
    },
    createPayload() {
      return {
        type: this.type,
        hash: this.item.hash,
        download_url: this.item.download_url,
        name: this.item.name,
        object_id: this.item.object_id,
      }
    },
  },
}
