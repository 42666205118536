var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "twillio-test" },
    [
      _vm.state === _vm.TestState.PREPARING
        ? _c("div", { staticClass: "status" }, [
            _vm.state === _vm.TestState.PREPARING
              ? _c("p", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.descriptionText)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.state === _vm.TestState.BAD
              ? _c("p", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.$t.get("test.we-cant-detect"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.state >= _vm.TestState.TESTING
        ? _c(
            "vue-stepper-horizontal",
            {
              attrs: {
                "finish-button-text": _vm.finishButtonText,
                "on-finish": _vm.finishTest,
                "on-cancel": _vm.cancelTest,
              },
              on: { "step-changed": _vm.stepChanged },
            },
            [
              _c(
                "div",
                {
                  staticClass: "camera-audio-test",
                  attrs: {
                    slot: "video-test",
                    title: _vm.$t.get("test.camera-test"),
                    mounted: _vm.testVideo,
                  },
                  slot: "video-test",
                },
                [
                  _vm.results.camera.result === _vm.TestResult.CHECKING
                    ? _c("p", { staticClass: "step-details" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t.get("test.connecting-your-camera")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.results.camera.result !== _vm.TestResult.CHECKING
                    ? _c("p", {
                        staticClass: "step-details",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t.get(
                              "test.you-should-see-yourself-here-from-your-camera"
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { ref: "previewVideo", staticClass: "camera-wrapper" },
                    [
                      _vm.results.camera.result === _vm.TestResult.BAD ||
                      _vm.results.twilio.result === _vm.TestResult.BAD ||
                      _vm.results.twilio.result ===
                        _vm.TestResult.INTERNAL_ERROR
                        ? _c("div", { staticClass: "permission" }, [
                            _c("i", { staticClass: "ict-alert" }),
                            _vm._v(" "),
                            _vm.results.camera.result === _vm.TestResult.BAD
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t.get(
                                        "test.we-could-not-detect-the-camera-br-or-we-did-not-have-permissions"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.results.twilio.result === _vm.TestResult.BAD
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t.get(
                                        "test.we-cant-connect-to-video-servers-br-please-go-to-troubleshooting-or-contact-with-us"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.results.twilio.result ===
                            _vm.TestResult.INTERNAL_ERROR
                              ? _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t.get("test.internal-error-message")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.devices.video && _vm.devices.video.length > 1
                    ? _c(
                        "div",
                        { staticClass: "select-source material-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "input" },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.devices.video,
                                  "label-key": "label",
                                  "value-key": "deviceId",
                                  id: "video-source",
                                },
                                on: { change: _vm.videoSourceChanged },
                                model: {
                                  value: _vm.selectedSource.video,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectedSource, "video", $$v)
                                  },
                                  expression: "selectedSource.video",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "video-source" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t.get("test.select-your-camera"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "camera-audio-test",
                  attrs: {
                    slot: "audio-test",
                    title: _vm.$t.get("test.microphone-test"),
                    mounted: _vm.testAudio,
                  },
                  slot: "audio-test",
                },
                [
                  _c("p", {
                    staticClass: "step-details",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t.get(
                          "test.say-something-in-the-microphone-and-check-that-bar-below"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { ref: "previewAudio", staticClass: "audio" }, [
                    _c("div", {
                      staticClass: "progress",
                      style: { width: _vm.audio.status + "%" },
                    }),
                    _vm._v(" "),
                    _vm.results.microphone.result === _vm.TestResult.BAD
                      ? _c("div", { staticClass: "permission" }, [
                          _c("i", { staticClass: "ict-alert" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "test.we-could-not-detect-the-microphone-or-we-did-not-have-permissions"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm.results.twilio.result === _vm.TestResult.BAD
                      ? _c("div", { staticClass: "permission" }, [
                          _c("i", { staticClass: "ict-alert" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "test.we-cant-connect-to-audio-servers-please-go-to-troubleshooting-or-contact-with-us"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm.results.twilio.result ===
                        _vm.TestResult.INTERNAL_ERROR
                      ? _c("div", { staticClass: "permission" }, [
                          _c("i", { staticClass: "ict-alert" }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t.get("test.internal-error-message")
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.devices.audio && _vm.devices.audio.length > 1
                    ? _c(
                        "div",
                        { staticClass: "select-source material-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "input" },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.devices.audio,
                                  "label-key": "label",
                                  "value-key": "deviceId",
                                  id: "audio-source",
                                },
                                on: { change: _vm.audioSourceChanged },
                                model: {
                                  value: _vm.selectedSource.audio,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.selectedSource, "audio", $$v)
                                  },
                                  expression: "selectedSource.audio",
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "audio-source" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t.get("test.select-your-microphone")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "camera-audio-test",
                  attrs: {
                    slot: "speakers-test",
                    title: _vm.$t.get("test.speakers-test"),
                    mounted: _vm.testSpeakers,
                  },
                  slot: "speakers-test",
                },
                [
                  _c("p", { staticClass: "step-details" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t.get(
                            "test.you-should-hear-music-from-your-speakers-when-the-bar-below-moves"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "audio" }, [
                    _c("audio", {
                      ref: "previewSpeakers",
                      attrs: {
                        src: "/assets/app/sounds/speakers.mp3",
                        loop: "loop",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "progress",
                      style: { width: _vm.audio.status + "%" },
                    }),
                    _vm._v(" "),
                    _vm.results.speakers.result === _vm.TestResult.BAD
                      ? _c("div", { staticClass: "permission" }, [
                          _c("i", { staticClass: "ict-alert" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t.get(
                                    "test.we-could-not-detect-the-microphone-or-we-did-not-have-permissions"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sampleNotPlayed
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:color",
                                value: _vm.palette.colors.secondary.main,
                                expression: "palette.colors.secondary.main",
                                arg: "color",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:background",
                                value: _vm.palette.colors.primary.alt,
                                expression: "palette.colors.primary.alt",
                                arg: "background",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:background.hover",
                                value: _vm.palette.colors.hover.main,
                                expression: "palette.colors.hover.main",
                                arg: "background",
                                modifiers: { hover: true },
                              },
                              {
                                name: "styled",
                                rawName: "v-styled:color.hover",
                                value: _vm.palette.colors.hover.alt,
                                expression: "palette.colors.hover.alt",
                                arg: "color",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "button play",
                            on: {
                              click: function ($event) {
                                return _vm.playSampleSound()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t.get("test.play-sound")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "results-test",
                  attrs: {
                    slot: "network-quality",
                    title: _vm.$t.get("test.network-quality"),
                    mounted: _vm.testNetwork,
                    disable: _vm.state !== _vm.TestState.DONE,
                  },
                  slot: "network-quality",
                },
                [
                  _c("div", { staticClass: "twilio-preflight" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t.get(
                            "test.the-result-of-video-call-preflight-test"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.results.twilioPreflight.result ==
                    _vm.TestResult.CHECKING
                      ? _c("strong", [
                          _vm._v(_vm._s(_vm.$t.get("test.waiting"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.results.twilioPreflight.result == _vm.TestResult.GOOD
                      ? _c("strong", { staticClass: "good" }, [
                          _vm._v(_vm._s(_vm.$t.get("test.good"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.results.twilioPreflight.result ==
                    _vm.TestResult.SUFFICIENT
                      ? _c("strong", { staticClass: "sufficient" }, [
                          _vm._v(_vm._s(_vm.$t.get("test.sufficient"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.results.twilioPreflight.result == _vm.TestResult.BAD
                      ? _c("strong", { staticClass: "bad" }, [
                          _vm._v(_vm._s(_vm.$t.get("test.bad"))),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "boxes" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box",
                        class: {
                          bad: _vm.results.twilio.result === _vm.TestResult.BAD,
                        },
                      },
                      [
                        _c("div", { staticClass: "icon" }, [
                          _vm.results.twilio.result === _vm.TestResult.GOOD
                            ? _c("i", { staticClass: "ict-checkmark" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.results.twilio.result === _vm.TestResult.BAD
                            ? _c("i", { staticClass: "ict-close" })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.results.twilio.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "info",
                          domProps: {
                            innerHTML: _vm._s(_vm.results.twilio.label),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "box",
                        class: {
                          bad: _vm.results.socket.result === _vm.TestResult.BAD,
                        },
                      },
                      [
                        _c("div", { staticClass: "icon" }, [
                          _vm.results.socket.result === _vm.TestResult.GOOD
                            ? _c("i", { staticClass: "ict-checkmark" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.results.socket.result === _vm.TestResult.BAD
                            ? _c("i", { staticClass: "ict-close" })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.results.socket.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "info",
                          domProps: {
                            innerHTML: _vm._s(_vm.results.socket.label),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "box" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "styled",
                              rawName: "v-styled:background-color:fill",
                              value: _vm.palette.colors.primary.main,
                              expression: "palette.colors.primary.main",
                              arg: "background-color:fill",
                            },
                          ],
                          staticClass: "signal",
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                            ],
                            staticClass: "level l1",
                            class: { fill: _vm.networkLevel >= 1 },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                            ],
                            staticClass: "level l2",
                            class: { fill: _vm.networkLevel >= 2 },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                            ],
                            staticClass: "level l3",
                            class: { fill: _vm.networkLevel >= 3 },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                            ],
                            staticClass: "level l4",
                            class: { fill: _vm.networkLevel >= 4 },
                          }),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "styled",
                                rawName: "v-styled:border-color",
                                value: _vm.palette.colors.primary.main,
                                expression: "palette.colors.primary.main",
                                arg: "border-color",
                              },
                            ],
                            staticClass: "level l5",
                            class: { fill: _vm.networkLevel >= 5 },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.results.networkQuality.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "info",
                        domProps: {
                          innerHTML: _vm._s(_vm.results.networkQuality.label),
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-left",
                  attrs: { slot: "buttons-left" },
                  slot: "buttons-left",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button red",
                      on: { click: _vm.initSupportCenter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t.get("test.i-have-a-problem")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }