var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-form grid" }, [
    _c("h3", [_vm._v(_vm._s(_vm.$t.get(_vm.title)))]),
    _vm._v(" "),
    _c("div", { staticClass: "card-box content" }, [
      _c(
        "div",
        { staticClass: "flex flex-wrap flex-spacing" },
        _vm._l(_vm.fields, function (field) {
          return _vm.fieldConditionResolved(field)
            ? _c(
                "div",
                { key: field.key, class: _vm.getCssClasses(field) },
                [
                  field.type === "condition_container" &&
                  _vm.fieldConditionResolved(field)
                    ? _vm._l(field.children, function (innerField) {
                        return _c(
                          "div",
                          {
                            key: innerField.key,
                            class: _vm.getCssClasses(innerField),
                          },
                          [
                            _c(
                              _vm.getComponent(innerField.type),
                              _vm._b(
                                {
                                  tag: "component",
                                  class: _vm.getCssClasses(innerField),
                                  attrs: {
                                    "field-key": innerField.key,
                                    "read-only": innerField.readOnly || false,
                                    validation: _vm.$v.enquiry[innerField.key],
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.handleInput(
                                        _vm.$v.enquiry[innerField.key]
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.enquiry[innerField.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.enquiry, innerField.key, $$v)
                                    },
                                    expression: "enquiry[innerField.key]",
                                  },
                                },
                                "component",
                                _vm.mergeProps(innerField.props),
                                false
                              ),
                              [
                                _vm._v(
                                  _vm._s(_vm.$t.get(innerField.label)) +
                                    "\n              "
                                ),
                                typeof innerField.validation !==
                                  typeof undefined &&
                                innerField.validation.hasOwnProperty("required")
                                  ? _c("span", { staticClass: "accent" }, [
                                      _vm._v("*"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      })
                    : _c(
                        _vm.getComponent(field.type),
                        _vm._b(
                          {
                            key: field.key,
                            tag: "component",
                            attrs: {
                              "field-key": field.key,
                              "read-only": field.readOnly || false,
                              validation: _vm.$v.enquiry[field.key],
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleInput(
                                  _vm.$v.enquiry[field.key]
                                )
                              },
                            },
                            model: {
                              value: _vm.enquiry[field.key],
                              callback: function ($$v) {
                                _vm.$set(_vm.enquiry, field.key, $$v)
                              },
                              expression: "enquiry[field.key]",
                            },
                          },
                          "component",
                          _vm.mergeProps(field.props),
                          false
                        ),
                        [
                          _vm._v(
                            _vm._s(_vm.$t.get(field.label)) + "\n          "
                          ),
                          typeof field.validation !== typeof undefined &&
                          field.validation.hasOwnProperty("required")
                            ? _c("span", { staticClass: "accent" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ],
                2
              )
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }