var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "checkbox relative", attrs: { for: _vm.label } },
    [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            attrs: { id: _vm.id, type: "checkbox" },
            domProps: {
              value: _vm.inputValue,
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, _vm.inputValue) > -1
                : _vm.model,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.inputValue,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              },
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            attrs: { id: _vm.id, type: "radio" },
            domProps: {
              value: _vm.inputValue,
              checked: _vm._q(_vm.model, _vm.inputValue),
            },
            on: {
              change: function ($event) {
                _vm.model = _vm.inputValue
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            attrs: { id: _vm.id, type: _vm.type },
            domProps: { value: _vm.inputValue, value: _vm.model },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.model = $event.target.value
              },
            },
          }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c("div", { staticClass: "checkmark-container" }, [
            _c("div", { staticClass: "checkmark mx-auto" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16.817",
                    height: "13.049",
                    viewBox: "0 0 16.817 13.049",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "Path_93",
                      "data-name": "Path 93",
                      d: "M-3520.495-243.949l-5.784-5.784a1.8,1.8,0,0,1,0-2.543h0a1.8,1.8,0,0,1,2.543,0l3.241,3.241,7.437-7.437a1.8,1.8,0,0,1,2.542,0h0a1.8,1.8,0,0,1,0,2.543Z",
                      transform: "translate(3526.806 256.998)",
                      fill: "#dedede",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }