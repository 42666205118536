var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box margin-top enquiries" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t.get("common.enquiries")) + "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content table-scroll" },
      [
        _c(
          "div",
          {
            staticClass: "table-header",
            staticStyle: { "min-width": "500px" },
          },
          [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("common.date"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-3/12 columns" }, [
                _vm._v(_vm._s(_vm.$t.get("common.assigned-to"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-2/12 columns mr-8" }, [
                _vm._v(_vm._s(_vm.$t.get("appointment.consultation-type"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-4/12 columns pr-4" }, [
                _vm._v(_vm._s(_vm.$t.get("common.status"))),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.enquires.data, function (item) {
          return _c(
            "div",
            {
              key: item.appointment_id,
              staticClass: "table-row row-hoverable",
              staticStyle: { "min-width": "500px" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "flex",
                  attrs: { href: "/appointment/" + item.appointment_id },
                },
                [
                  _c("div", { staticClass: "w-3/12 columns" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$dateTimeFormat.formatTime(item.created_at)
                        ) +
                        ",\n          " +
                        _vm._s(
                          _vm.$dateTimeFormat.formatDate(item.created_at)
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  item.assigned === null
                    ? _c(
                        "div",
                        { staticClass: "w-3/12 columns user unassigned" },
                        [
                          _c("div", { staticClass: "avatar" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.$t.get("common.unassigned"))),
                          ]),
                        ]
                      )
                    : _c("div", { staticClass: "w-3/12 columns user" }, [
                        _c("div", {
                          staticClass: "avatar",
                          style: {
                            backgroundImage:
                              "url(" + item.assigned.avatar_url + ")",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.assigned.display_name)),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-2/12 columns badges mr-8" }, [
                    item.status ===
                      _vm.Constants.Appointment.STATUS_REUQESTED &&
                    item.consultation_type ===
                      _vm.Constants.Appointment.CONSULTATION_CALL_OPINION
                      ? _c("span", [_vm._v("---")])
                      : _c("span", { staticClass: "badge info enquiry-type" }, [
                          _vm._v(_vm._s(item.display_consultation_type)),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-4/12 columns pr-4" }, [
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _c("span", [_vm._v(_vm._s(item.status_desc))]),
                      _vm._v(" "),
                      (item.consultation_type ===
                        _vm.Constants.Appointment
                          .CONSULTATION_DIAGNOSTIC_TEST ||
                        item.consultation_type ===
                          _vm.Constants.Appointment
                            .CONSULTATION_OVERSEAS_TREATMENT) &&
                      item.status !== _vm.Constants.Appointment.STATUS_REUQESTED
                        ? _c(
                            "span",
                            {
                              directives: [
                                { name: "tooltip", rawName: "v-tooltip" },
                              ],
                              staticClass: "location",
                              attrs: {
                                alt: _vm.$t.get("common.location-location", {
                                  location: item.location || "-",
                                }),
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t.get("common.location-location", {
                                      location: item.location || "-",
                                    })
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.processing && _vm.enquires.total === 0
          ? _c("div", { staticClass: "table-empty" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "lg:w-full w-full columns text-center" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t.get("common.no-appointments")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.enquires.last_page > 1
      ? _c(
          "div",
          { staticClass: "table-pagination" },
          [
            _c("vue-paginator", {
              attrs: { "pagination-init": _vm.enquires },
              on: { "set-page": _vm.getComponentData },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }