import Vue from "../vue.js"
import { find, merge } from "lodash"
import Video, { LocalVideoTrack } from "twilio-video"
import moment from "moment-timezone"
import { Constants } from "../models/Constants.js"

export const RoomStage = {
  LOBBY: 0,
  ROOM: 1,
  FINISHED: 2,
}

export const RoomStatus = {
  ERROR: -1,
  MOUNTED: 0,
  WAITING: 1,
  JOINING: 2,
  JOINED: 3,
  CONNECTING: 4,
  CONNECTED: 5,
  FINISHED: 6,
}

export default {
  namespaced: true,
  state: {
    stage: RoomStage.LOBBY,
    status: RoomStatus.MOUNTED,
    mediaPermissionsError: false,
    audio_enabled: true,
    video_enabled: true,
    user_tracks: null,
    call: null,
    provider_participants: [],
    logged_user: null,
    logged_participant: null,
    local_participant: {
      participant_authorization_token: null,
      participant_id: null,
      token: null,
      networkQualityLevel: 0,
    },
    main_participant: null,
    fullscreen: false,
    room: null,
    backgrounds: [],
    socket_reconnected: false,
    intentionToNotRecordVideo: false,
  },
  mutations: {
    update(state, body) {
      state = merge(state, body)
    },
    updateStage(state, stage) {
      state.stage = stage
    },
    updateStatus(state, status) {
      state.status = status
    },
    mediaPermissionsError(state, error) {
      state.status = RoomStatus.ERROR
      state.mediaPermissionsError = error
    },
    addProviderParticipant(state, participant) {
      state.provider_participants.push(participant)
    },
    removeProviderParticipant(state, identity) {
      const index = state.provider_participants.findIndex(
        (participant) => participant.identity === identity
      )

      state.provider_participants.splice(index, 1)
    },
    setMainParticipant(state, participant) {
      state.main_participant = participant
    },
  },
  getters: {
    getStage(state) {
      return state.stage
    },
    getStatus(state) {
      return state.status
    },
    getUserTracks(state) {
      return state.user_tracks
    },
    getTrack(state) {
      return (kind) =>
        state.user_tracks === null ? null : state.user_tracks.find((track) => track.kind === kind)
    },
    hasTrack(state, getters) {
      return (kind) => getters.getTrack(kind) !== null
    },
    hasVideoTracks(state, getters) {
      return getters.hasTrack("video")
    },
    hasAudioTracks(state, getters) {
      return getters.hasTrack("audio")
    },
    isAudioEnabled(state) {
      return state.audio_enabled
    },
    isVideoEnabled(state) {
      return state.video_enabled
    },
    isFullscreen(state) {
      return state.fullscreen
    },
    isInProgress(state) {
      return Constants.Call.STATUS_STARTED
    },
    getCallStartTime(state) {
      return state.call.last_start_event_date
    },
    getCall(state) {
      return state.call
    },
    getRoom(state) {
      return state.room
    },
    getLoggedUser(state) {
      return state.logged_user
    },
    getLocalParticipant(state) {
      return state.local_participant
    },
    getProviderParticipants(state) {
      return state.provider_participants
    },
    getConnectedParticipants(state, getters) {
      return getters.getCall.participants.filter((participant) =>
        getters.getProviderParticipants.find((p) => p.identity === participant.participant_id)
      )
    },
    getMainParticipant(state, getters) {
      if (state.main_participant !== null) {
        return state.main_participant
      }

      let currentParticipant = getters.getConnectedParticipants.find(
        (participant) => participant.participant_id === state.local_participant.participant_id
      )

      if (currentParticipant?.type === "owner") {
        return (
          getters.getConnectedParticipants.find(
            (participant) => participant.participant_id !== state.local_participant.participant_id
          ) ?? currentParticipant
        )
      }

      let participant = getters.getConnectedParticipants.find(
        (participant) => participant?.type === "owner"
      )

      if (participant !== undefined) {
        return participant
      } else {
        return getters.getConnectedParticipants.find(
          (participant) => participant.participant_id !== state.local_participant.participant_id
        )
      }
    },

    getBackgrounds(state) {
      return state.backgrounds
    },
    canFinishConsultation(state, getters) {
      if (state.logged_participant === null) {
        return false
      }
      return state.logged_participant.permissions.includes(
        Constants.Call.PARTICIPANT_PERMISSION_MANAGE_CALL
      )
    },
    isCallFinished(state) {
      return state.call?.status === Constants.Call.STATUS_FINISHED
    },
    isCallWaitingToStart(state) {
      return state.call?.status === Constants.Call.STATUS_CREATED
    },
    canManageCall(state, getters) {
      if (state.logged_participant === null) {
        return false
      }

      return state.logged_participant.permissions.includes(
        Constants.Call.PARTICIPANT_PERMISSION_MANAGE_CALL
      )
    },
    isSocketReconnected(state) {
      return state.socket_reconnected
    },
    getMediaPermissionsError(state) {
      return state.mediaPermissionsError
    },
    isRecordingEnabled(state) {
      return state.call?.is_recording_enabled ?? false
    },
    isIntentionToNotRecordVideo(state) {
      return state.intentionToNotRecordVideo
    },
    isAllowedToJoinAnytime(state) {
      return state.call?.is_allowed_to_join_anytime ?? false
    },
  },
  actions: {
    intentionToRecordWithVideo({ commit, state }) {
      commit("update", {
        intentionToNotRecordVideo: false,
      })
    },
    intentionToNotRecordVideo({ commit, state }) {
      commit("update", {
        intentionToNotRecordVideo: true,
      })
    },
    join({ commit, state }, participantData) {
      if (state.mediaPermissionsError === false) {
        commit("updateStatus", RoomStatus.JOINING)

        return this._vm.$http
          .post(`/ajax/calls/${state.call.call_id}/join`, {
            name: participantData.name,
            email: participantData.email,
            camera_enabled: state.intentionToNotRecordVideo !== true,
          })
          .catch((error) => {
            commit("updateStatus", RoomStatus.ERROR)
            return Promise.reject(error)
          })
          .then((response) => {
            if (state.logged_user === null) {
              // If user is not logged we need to save in local storage his data
              localStorage.setItem(
                "guest_data",
                JSON.stringify({
                  name: participantData.name,
                  email: participantData.email,
                })
              )
            }

            commit("update", {
              call: response.data.call,
              local_participant: {
                participant_authorization_token:
                  response.data.participant.participantAuthorizationToken,
                participant_id: response.data.participant.participantId,
                token: response.data.participant.token,
              },
            })
            commit("updateStatus", RoomStatus.JOINED)
            commit("updateStage", RoomStage.ROOM)
          })
      } else {
        commit("mediaPermissionsError", true)
      }
    },
    finish({ commit, state }) {
      return this._vm.$http.post(`/ajax/calls/${state.call.call_id}/finish`).then((response) => {
        commit("updateStatus", RoomStatus.FINISHED)
        commit("updateStage", RoomStage.FINISHED)
      })
    },
    leave({ commit, state }) {
      state.room.disconnect()
      commit("updateStatus", RoomStatus.FINISHED)
      commit("updateStage", RoomStage.LOBBY)
    },
    async createLocalTracks({ commit, state, getters }) {
      const config = {
        audio: true,
        video: { height: 720, frameRate: 24, width: 1280 },
      }

      return await Video.createLocalTracks(config)
        .then((localTracks) => {
          commit("update", {
            user_tracks: localTracks,
          })
          localTracks.forEach((track) => track.enable())
          localTracks
            .find((track) => track.kind === "audio" && state.audio_enabled === false)
            ?.disable()
          localTracks
            .find((track) => track.kind === "video" && state.video_enabled === false)
            ?.disable()

          commit("updateStatus", RoomStatus.WAITING)
        })
        .catch((e) => {
          console.error(e)
          if (process.env.production) Sentry.captureException(e)
        })
    },
    async connectToSocket({ state, commit }) {
      await this._vm.$socket
        .reconnectWithAuthorization(state.local_participant.participant_authorization_token)
        .then(() => {
          commit("update", {
            socket_reconnected: true,
          })
        })
    },
    connectToProvider({ state, getters, commit }) {
      commit("updateStatus", RoomStatus.CONNECTING)

      return Video.connect(state.local_participant.token, {
        tracks: state.user_tracks,
        networkQuality: {
          local: 1,
          remote: 1,
        },
        bandwidthProfile: {
          video: {
            mode: "collaboration",
            dominantSpeakerPriority: "high",
          },
        },
        dominantSpeaker: true,
      }).then((room) => {
        commit("updateStatus", RoomStatus.CONNECTED)
        commit("update", {
          room: room,
        })
      })
    },
    toggleVideo({ commit, state, getters }, newState = null) {
      newState ??= state.video_enabled === false
      commit("update", {
        video_enabled: newState,
      })

      const track = getters.getTrack("video")

      if (track !== null) {
        if (newState === true) {
          track.enable()
        } else {
          track.disable()
        }
      } else {
        commit("mediaPermissionsError", true)
      }
    },
    toggleAudio({ commit, state, getters }, newState = null) {
      newState ??= state.audio_enabled === false
      commit("update", {
        audio_enabled: newState,
      })

      const track = getters.getTrack("audio")

      if (track !== null) {
        if (newState === true) {
          track.enable()
        } else {
          track.disable()
        }
      } else {
        commit("mediaPermissionsError", true)
      }
    },
    fetchCall({ commit, state }) {
      return this._vm.$http.get(`/ajax/calls/${state.call.call_id}`).then((response) => {
        commit("update", {
          call: response.data,
        })
      })
    },
    fetchBackgrounds({ commit, state }, token) {
      return this._vm.$http
        .get(`/ajax/product/${state.call.group_id}/call/backgrounds`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          commit("update", {
            backgrounds: response.data,
          })
        })
        .catch((e) => {
          console.error(e)
          if (process.env.production) Sentry.captureException(e)
        })
    },
  },
}
