import ToolType from "../ToolType"
import Translate from "../../../../../helpers/translate"

export default class Metadata {
  get name() {
    return Translate.get("viewer.metadata")
  }

  get icon() {
    return "ict-statistics"
  }

  get type() {
    return ToolType.TOGGLE
  }

  get mobile() {
    return true
  }

  constructor() {
    this.state = true
  }

  toggle(views) {
    this.state = !this.state
  }
}
