var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-scroll" },
    [
      _vm.results.header
        ? _c("h6", { staticClass: "provider-subheader" }, [
            _vm._v(_vm._s(_vm.results.header)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "table-header" }, [
        _c(
          "div",
          { staticClass: "flex" },
          _vm._l(_vm.preparedComponents, function (column) {
            return _c(
              _vm.getColumnComponent(column.type),
              _vm._b(
                {
                  key: column.type,
                  tag: "component",
                  attrs: { mode: "header" },
                },
                "component",
                Object.assign({}, _vm.$props, column.props, {
                  columnsCount: _vm.preparedComponents.length,
                }),
                false
              )
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.results.records, function (customer) {
        return _c(
          "div",
          { key: customer.group_customer_id, staticClass: "table-row" },
          [
            _c(
              "div",
              { staticClass: "flex" },
              _vm._l(_vm.preparedComponents, function (column) {
                return _c(
                  _vm.getColumnComponent(column.type),
                  _vm._g(
                    _vm._b(
                      {
                        key: column.type,
                        tag: "component",
                        attrs: {
                          mode: "row",
                          type: column.type,
                          customer: customer,
                          actions: _vm.results.actions || [],
                        },
                      },
                      "component",
                      Object.assign({}, _vm.$props, column.props, {
                        columnsCount: _vm.preparedComponents.length,
                      }),
                      false
                    ),
                    _vm.$listeners
                  )
                )
              }),
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.processing &&
      _vm.results.records.length === 0 &&
      _vm.results.status === 200
        ? _c("div", { staticClass: "table-empty" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "w-full text-center columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t.get("product.customer-list-empty")) +
                    "\n      "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.processing && _vm.results.status !== 200
        ? _c("div", { staticClass: "table-empty provider-error" }, [
            _vm._v("\n    " + _vm._s(_vm.results.error_message) + "\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }