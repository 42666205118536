//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"
import ActionsMixin from "./actions/ActionsMixin"

export default {
  name: "actions",
  mixins: [ColumnMixin, ActionsMixin],
  props: {
    actions: {
      type: Array,
      default: function () {
        return []
      },
    },
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-2/12 columns",
          row: "w-2/12 columns",
        }
      },
    },
    defaultTranslation: {
      type: String,
      default: "common.actions",
    },
  },
  computed: {
    actionsForSearchScope() {
      return this.actionsForScope(this.actions, "search")
    },
  },
}
