// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.provider-results[data-v-5f2102a6] {
  margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/components/organization/customer-management/OrganizationCustomerSearchResult.vue"],"names":[],"mappings":";AA0CA;EACA,mBAAA;AACA","sourcesContent":["<template>\n  <div>\n    <component\n      class=\"provider-results\"\n      v-bind:is=\"getSearchResultComponent(provider.component)\"\n      v-bind:results=\"getResultsForProvider(provider.provider || 'local')\"\n      v-bind=\"$props\"\n      v-on=\"$listeners\"\n      v-for=\"provider in results\"\n      v-bind:key=\"provider.provider\"\n    ></component>\n  </div>\n</template>\n<script>\nimport ComponentMixin from \"./ComponentMixin\"\nimport LocalProviderResults from \"./search-results/LocalProviderResults.vue\"\n\nexport default {\n  mixins: [ComponentMixin],\n  name: \"CustomerSearchResults\",\n  props: {\n    results: {\n      type: Array,\n      required: true,\n    },\n  },\n  methods: {\n    getResultsForProvider(provider) {\n      return this.results.filter((result) => result.provider === provider)[0]\n    },\n    getSearchResultComponent(type) {\n      switch (type) {\n        case \"local\":\n          return LocalProviderResults\n        default:\n          return LocalProviderResults\n      }\n    },\n  },\n}\n</script>\n<style lang=\"postcss\" scoped>\n.provider-results {\n  margin-bottom: 30px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
