//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "./Customize"
export default {
  mixins: [Customize()],
  props: {
    fixed: {
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      position: {
        top: 0,
        right: 0,
      },
    }
  },
  computed: {
    style() {
      if (this.fixed === true) {
        return {
          position: "fixed",
          right: this.position.right + "px",
          top: this.position.top + "px",
        }
      } else {
        return {}
      }
    },
  },
  methods: {
    toggle() {
      const viewport = this.$el.getBoundingClientRect()
      this.position.top = Math.round(viewport.top)
      this.position.right = Math.round(document.body.clientWidth - viewport.left - viewport.width)
      this.opened = !this.opened
    },
    hide() {
      this.opened = false
    },
  },
}
