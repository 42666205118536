// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
a[disabled][data-v-9841a428] {

  background: #f0f0f0;
  color: #8c8c8c;
  cursor: not-allowed;
}
a[disabled][data-v-9841a428]:hover {
    background: #f0f0f0 !important;
    color: #8c8c8c !important;
}
`, "",{"version":3,"sources":["webpack://./resources/app/js/vuejs/components/organization/customer-management/columns/actions/ActionSetupAppointment.vue"],"names":[],"mappings":";AAgDA;;EAIA,mBAAA;EACA,cAAA;EACA,mBAAA;AAKA;AAJA;IACA,8BAAA;IACA,yBAAA;AACA","sourcesContent":["<template>\n  <a\n    v-bind:href=\"buildSetupAppointmentPageUrl()\"\n    v-bind:disabled=\"customerActive === false\"\n    v-bind:title=\"$t.get('product.create-consultation')\"\n    >{{ $t.get(\"product.create-consultation\") }}</a\n  >\n</template>\n\n<script>\nimport ActionMixin from \"./ActionMixin\"\nimport { Constants } from \"../../../../../models/Constants\"\n\nexport default {\n  mixins: [ActionMixin],\n  props: {\n    setupAppointmentPageUrl: { type: String },\n  },\n  data() {\n    return {\n      statuses: Constants.GroupCustomer,\n    }\n  },\n  computed: {\n    customerActive() {\n      if (this.customer === null || this.customer.hasOwnProperty(\"status\") === false) {\n        return false\n      }\n      return (\n        this.customer.status == this.statuses.STATUS_ACTIVE || this.customer.status === \"Active\"\n      )\n    },\n  },\n  methods: {\n    buildSetupAppointmentPageUrl() {\n      if (this.customerActive === false) {\n        return null\n      }\n      return (\n        this.setupAppointmentPageUrl +\n        \"?customerId=\" +\n        (this.customer.group_customer_id || this.customer.tdCustomerId)\n      )\n    },\n  },\n}\n</script>\n<style lang=\"postcss\" scoped>\na[disabled] {\n  $bg: #f0f0f0;\n  $color: #8c8c8c;\n\n  background: $bg;\n  color: $color;\n  cursor: not-allowed;\n  &:hover {\n    background: $bg !important;\n    color: $color !important;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
