//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from "vuex"
const { mapGetters } = createNamespacedHelpers("appointment")

import Translate from "../../../helpers/translate.js"

import { merge, debounce } from "lodash"
import { Constants } from "../../models/Constants"

export default {
  props: {
    showSaveButton: {
      default: true,
      type: Boolean,
    },
    saveButtonLabel: {
      default: Translate.get("appointment.save-price"),
      type: String,
    },
    saveButtonClasses: {
      default() {
        return {}
      },
    },
    enablePreloader: {
      default: true,
      type: Boolean,
    },
    currencies: { type: Object },
  },
  data() {
    return {
      constants: Constants.Appointment,
      isCalculating: false,
      processing: false,
      price: {
        amount: "-",
        amount_netto: "-",
        amount_brutto: "-",
        commission: "-",
        commission_vat: "-",
        commission_pay: "-",
        charity: "-",
        charity_vat: "-",
        currency: "-",
        period: 60,
        duration: 60,
        for_free: false,
      },
    }
  },
  computed: {
    ...mapGetters(["isSecondOpinionOnly", "isVideoCall"]),
    appointment() {
      return this.$store.state.appointment
    },
  },
  mounted() {
    this.price = merge(this.price, this.appointment.price)
    this.isCalculating = true
    this.$emit("is-calculating", true)
    this.$http.get("/ajax/appointment/" + this.appointment.appointment_id + "/pricing").then(
      (response) => {
        this.price = response.data.data
        this.$emit("calculated-price", response.data.data)
        this.isCalculating = false
        this.$emit("is-calculating", false)
      },
      (error) => {
        this.isCalculating = false
        this.$bridge.emit("addToast", error.response.data.message, "error")
        this.$emit("is-calculating", false)
      }
    )
  },
  methods: {
    toggleForFree() {
      if (!this.isCalculating) {
        this.price.for_free = !this.price.for_free
        this.price.amount = this.price.for_free ? "0" : "100"
        this.calculate()
      }
    },
    mapCurrencies() {
      return Object.keys(this.currencies).map((currencyCode) => ({
        label: this.currencies[currencyCode],
        value: currencyCode,
      }))
    },
    calculate: debounce(function () {
      if (this.price.amount === "") return
      this.isCalculating = true
      this.$emit("is-calculating", true)
      this.$http
        .post(
          "/ajax/appointment/" + this.appointment.appointment_id + "/pricing/calculate",
          this.price
        )
        .then(
          (response) => {
            this.price = response.data.data
            this.$emit("calculated-price", response.data.data)
            this.isCalculating = false
            this.$emit("is-calculating", false)
          },
          (error) => {
            this.isCalculating = false
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.$emit("is-calculating", false)
          }
        )
    }, 500),
    savePrice() {
      this.processing = true
      this.$emit("is-processing", true)
      this.$http
        .post("/ajax/appointment/" + this.appointment.appointment_id + "/pricing", this.price)
        .then(
          (response) => {
            this.$bridge.emit("addToast", response.data.message, "success")
            this.processing = false
            this.$emit("is-processing", false)
            this.$emit("success")
          },
          (error) => {
            this.processing = false
            this.$emit("is-processing", false)
            this.$bridge.emit("addToast", error.response.data.message, "error")
          }
        )
    },
  },
}
