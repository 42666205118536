import Lang from "lang.js"
import { isPlainObject } from "lodash"

class Translate {
  constructor() {
    this.lang = new Lang({
      messages: window.__TRANSLATIONS,
      locale: window.__LOCALE,
      fallback: "en",
    })
  }

  get(key, replacements = null) {
    if (typeof key === "string") {
      return this.lang.get(key, replacements)
    }
    return this.kv(key)
  }

  choice(key, count, replacements) {
    return this.lang.choice(key, count, replacements)
  }

  kv(kv, fallback = "-") {
    if (isPlainObject(kv) === false) {
      return fallback
    }
    const targetLocale = this.lang.getLocale()
    const key = Object.keys(kv).find((key) => key.toLowerCase() === targetLocale.toLowerCase())
    const lang = key === undefined ? "en" : key

    return kv[lang] ? kv[lang] : fallback
  }
}

export default new Translate()
