var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.haveInvitations
    ? _c("div", { staticClass: "box members invitations" }, [
        _c("div", { staticClass: "section" }, [
          _vm._v(_vm._s(_vm.$t.get("common.invitations"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-scroll" },
          [
            _c("div", { staticClass: "table-header" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-6/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("common.email"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("invitation.created"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("common.status"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 columns text-right" }, [
                  _vm._v(_vm._s(_vm.$t.get("common.action"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            [
              _vm._l(_vm.getPendingInvitations, function (invitation) {
                return _c(
                  "div",
                  { key: invitation.id, staticClass: "table-row" },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "w-6/12 columns" }, [
                        _vm._v(_vm._s(invitation.email)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm
                                .$moment(invitation.created_at)
                                .format("DD MMM, hh:mm A")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t.get("invitation." + invitation.status_desc)
                            ) +
                            " \n            "
                        ),
                        invitation.read_at
                          ? _c("span", { staticClass: "seen" }, [
                              _vm._v(_vm._s(_vm.$t.get("invitation.seen"))),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-2/12 columns text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button text accent",
                            on: {
                              click: function ($event) {
                                return _vm.resendInvitation(invitation)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t.get("invitation.resend")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button text accent",
                            on: {
                              click: function ($event) {
                                return _vm.revokeInvitation(invitation)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t.get("invitation.revoke")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.haveInvitations
                ? _c("div", { staticClass: "table-row text-center" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "w-full columns" }, [
                        _c("span", { staticClass: "no-invitations" }, [
                          _vm._v(
                            _vm._s(_vm.$t.get("invitation.no-invitations"))
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }