export const mapping = {
  "form.group.vitals": "user",
  "form.group.health-records": "hospital-1",
  "form.group.diagnoses-treatments-procedures": "trials",
  "form.section.blood-pressure-heart-rate": "blood",
  "form.section.genomic-tests": "dna",
  "form.section.lab-results": "flask",
  "form.section.diagnoses-treatments-procedures": "heart",
  "form.section.height": "height",
  "form.section.weight": "kg",
  "form.section.diagnostic-reports": "medical-report",
  "form.section.other-tests": "paper",
  "form.section.allergies-intolerances": "peanut",
  "form.section.other-risk-factors": "risk",
  "form.section.vaccinations": "vaccine",
  "form.section.radiology-scans": "x-ray",
  "form.section.body-temperature": "temperature",
}

export default function icon(name) {
  return mapping[name] || "close"
}
