//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { castQueryStringToObject } from "../../../../../utils"
import CustomerBenchmarkList from "./technicalSectionTabs/CustomerBenchmarkList.vue"
import CustomerAuditLog from "./technicalSectionTabs/CustomerAuditLog.vue"
import CustomerLoginHistory from "./technicalSectionTabs/CustomerLoginHistory.vue"

export default {
  components: { CustomerBenchmarkList, CustomerAuditLog, CustomerLoginHistory },
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    customerId: {}, // uuid or null
  },
  data() {
    return {
      currentTab: "benchmarkList",
      currentPage: 1,
      items: {
        data: [],
        total: 0,
      },
      processing: false,
    }
  },
  methods: {
    tabChanged(key) {
      this.currentTab = key
      this.items = { data: [] }
      this.getTabData(1)
    },
    getTabData(page) {
      if (this.customerId === null) {
        return
      }

      let params = {
        action: this.currentTab,
        componentId: this.componentId,
      }

      this.currentPage = page

      params = {
        ...params,
        customerId: this.customerId,
        page: this.currentPage,
      }

      this.processing = true
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.processing = false
          this.items = response.data
        })
        .catch((response) => {
          this.processing = false
          this.error = response.response.data.message
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
  },
  created() {
    this.getTabData(this.currentTab, castQueryStringToObject())
  },
}
