//
//
//
//

import ActionMixin from "./ActionMixin"

export default {
  mixins: [ActionMixin],
}
