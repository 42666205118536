//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"

export default {
  mixins: [InputMixin],
  props: {
    step: {
      default: 1,
    },
  },
  methods: {},
}
