//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputText from "../../../plugins/inputs/InputText.vue"
import InputSelect from "../../../plugins/inputs/InputSelect.vue"
import InputDate from "../../../plugins/inputs/InputDate.vue"
import InputQuill from "../../../plugins/inputs/InputQuill.vue"
import EnquiryFormBuilderInputCheckbox from "../../../plugins/inputs/EnquiryFormBuilderInputCheckbox.vue"
import InputCheckboxGroup from "../../../plugins/inputs/InputCheckboxGroup.vue"
import InputFiles from "../../../plugins/inputs/InputFiles.vue"
import ButtonViewer from "../../../plugins/inputs/ButtonViewer.vue"
import InputTime from "../../../plugins/inputs/InputTime.vue"
import InputNumber from "../../../plugins/inputs/InputNumber.vue"
import InputSwitch from "../../../plugins/inputs/InputSwitch.vue"

import FormHeader from "../../../plugins/form-decorators/FormHeader.vue"
import FormSeparator from "../../../plugins/form-decorators/FormSeparator.vue"

import FormBuilderMixin from "../../form-builder/FormBuilderMixin.js"

export default {
  mixins: [FormBuilderMixin],
  props: {
    title: {
      default() {
        return "appointment.enquiry-details"
      },
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      enquiry: {},
      defaultFieldCssClasses: { "w-full": true, "lg:w-6/12": true },
    }
  },
  mounted() {
    this.enquiry = this.fields.reduce((acc, item) => {
      acc[item.key] = item.value ?? ""
      return acc
    }, {})
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case "text":
          return InputText
        case "select":
          return InputSelect
        case "date":
          return InputDate
        case "quill":
          return InputQuill
        case "checkbox":
          return EnquiryFormBuilderInputCheckbox
        case "checkbox-group":
          return InputCheckboxGroup
        case "files":
          return InputFiles
        case "buttonViewer":
          return ButtonViewer
        case "time":
          return InputTime
        case "number":
          return InputNumber
        case "switch":
          return InputSwitch

        case "header":
          return FormHeader
        case "separator":
          return FormSeparator
      }
    },
    mergeProps(props) {
      return Object.assign({}, this.$props, props)
    },
    preparePayload(payload) {
      const clonedPayload = Object.assign({}, payload)
      Object.keys(payload).map((fieldKey) => {
        const currentField = this.allFieldsFlatted.find((field) => field.key === fieldKey)

        if (
          typeof currentField === typeof undefined ||
          (typeof currentField["excludeFromInput"] !== typeof undefined &&
            currentField["excludeFromInput"] === true)
        ) {
          delete clonedPayload[fieldKey]
          return
        }

        clonedPayload[fieldKey] = {
          label: currentField.label,
          value: clonedPayload[fieldKey],
        }
      })

      return clonedPayload
    },
    handleInput(validator) {
      validator.$touch()
      this.emitData()
    },
    emitData() {
      this.$v.$touch()

      if (this.$v.$invalid === true) {
        this.$emit("data", "enquiry", null)
        return
      }

      this.$emit("data", "enquiry", this.preparePayload(this.enquiry))
    },
    getCssClasses(input) {
      return typeof input.cssClasses === typeof undefined
        ? this.defaultFieldCssClasses
        : input.cssClasses
    },
  },
  validations() {
    return this.validation
      ? {
          enquiry: this.validation,
        }
      : {}
  },
}
