var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", class: [{ visible: _vm.opened }, _vm.classes] },
    [
      _c("div", {
        ref: "backdrop",
        staticClass: "modal-backdrop",
        on: { click: _vm.cancel },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _vm.closeable
          ? _c(
              "button",
              {
                staticClass: "modal-close-button",
                attrs: { type: "button" },
                on: { click: _vm.close },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.title !== null
          ? _c("div", { staticClass: "modal-header" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.opened
          ? _c(
              "div",
              {
                staticClass: "modal-main",
                class: {
                  "preloader preloader-cover":
                    typeof _vm.component === "function" &&
                    typeof _vm.component.resolved === typeof undefined,
                },
              },
              [
                _c("resize-observer", {
                  ref: "resizeObserver",
                  on: { notify: _vm.handleResize },
                }),
                _vm._v(" "),
                _vm.component !== null
                  ? _c(
                      _vm.component,
                      _vm._g(
                        _vm._b(
                          {
                            ref: "component",
                            tag: "component",
                            on: { success: _vm.close, cancel: _vm.close },
                          },
                          "component",
                          _vm.props,
                          false
                        ),
                        _vm.events
                      )
                    )
                  : _vm._t("default"),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        typeof _vm.$slots.footer !== typeof undefined
          ? _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2)
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }