//
//
//
//

import ActionMixin from "./ActionMixin"
import { appendObjectAsQueryString } from "../../../../../../utils"
import { Constants } from "../../../../../models/Constants"

export default {
  mixins: [ActionMixin],
  props: {
    url: { type: String, required: true },
    translation: { type: String, required: true },
    disableWhenCustomerPlanIsNotDefined: { type: Boolean, default: false },
    disableWhenCustomerIsNotActive: { type: Boolean, default: true },
  },
  computed: {
    disabled() {
      const noCustomerPlan =
        this.disableWhenCustomerPlanIsNotDefined === true && this.customer.tdCustomerPlanId === null
      const invalidStatus =
        this.disableWhenCustomerIsNotActive === true && this.customerActive === false
      if (noCustomerPlan || invalidStatus) {
        return true
      }
    },
    customerActive() {
      const status = Constants.GroupCustomer
      return this.customer.status == status.STATUS_ACTIVE || this.customer.status === "Active"
    },
    fullUrl() {
      if (this.disabled === true) {
        return null
      }
      return appendObjectAsQueryString(this.url, this.customer.detailsLinkParams)
    },
  },
}
