//
//
//

export default {
  props: {
    participant: {
      required: true,
    },
    twilioParticipant: {
      required: true,
    },
  },
  mounted() {},
  watch: {
    twilioParticipant: {
      immediate: true,
      handler(value) {
        console.log("changed")
        if (value !== void 0 && value !== null) {
          this.bindTracks()
        }
      },
    },
  },
  methods: {
    async bindTracks() {
      this.twilioParticipant.on("trackSubscriptionFailed", (error, trackPublication) => {
        console.warn(
          'Failed to subscribe to RemoteTrack %s with name "%s": %s',
          trackPublication.trackSid,
          trackPublication.trackName,
          error.message
        )
      })

      await this.$nextTick()

      this.twilioParticipant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          this.attachTrackPublication(publication)
        }
      })

      this.twilioParticipant.on("trackSubscribed", this.attachTrack.bind(this))
      this.twilioParticipant.on("trackUnsubscribed", this.detachTrack.bind(this))
      this.twilioParticipant.on("trackEnabled", this.trackChanged.bind(this))
      this.twilioParticipant.on("trackDisabled", this.trackChanged.bind(this))
    },
    attachTrack(track) {
      let ref = this.$refs.media
      ref.appendChild(track.attach())
    },
    attachTrackPublication(publication) {
      if (publication.track) {
        this.attachTrack(publication.track)
      }

      publication.on("subscribed", this.attachTrack.bind(this))
    },
    detachTrack(track) {
      let mediaElements = []
      if (track.stop) mediaElements = track.stop()
      if (track.detach) mediaElements = track.detach()
      mediaElements.forEach((mediaElement) => mediaElement.remove())
    },
    trackChanged(track) {
      let container = this.$refs.media
      if (track.kind == "video") container.style.display = track.isTrackEnabled ? "" : "none"
    },
  },
}
