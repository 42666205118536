import Vue from "../../vue"

export default {
  namespaced: true,
  state() {
    return {
      folders: [],
    }
  },
  mutations: {
    update(state, { data }) {
      Vue.set(state, "folders", data)
    },
  },
  actions: {
    fetch(context, { objectId, jwt }) {
      return Vue.http
        .get(
          window.__CLOUD_URL +
            "/items" +
            (typeof objectId !== "number" ? "?layers=is_attached&object_id=" + objectId : ""),
          {
            headers: {
              Authorization: "Bearer " + jwt,
            },
          }
        )
        .then((response) => {
          let folders = response.data.map((folder) => {
            folder.data = { dicoms: {}, images: {}, pdfs: {} }
            folder.dataFetched = false
            folder.fetched = true

            return folder
          })

          context.commit("update", {
            data: folders,
          })
        })
    },
  },
}
