//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Permissions } from "../../models/Permissions"
import { createNamespacedHelpers } from "vuex"
import { email, maxLength, required, requiredIf } from "vuelidate/lib/validators"
const { mapGetters } = createNamespacedHelpers("invitations")
const { mapGetters: mapGetterAppointment } = createNamespacedHelpers("appointment")
import { Constants } from "../../models/Constants"
import LanguageSelect from "../common/LanguageSelect.vue"

export default {
  components: {
    "language-select": LanguageSelect,
  },
  props: {
    selectionMode: {
      type: String,
      default: "member",
    },
    enableSelection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      processing: false,
      permissions: Permissions,
      mode: this.selectionMode,
      members: [],
      form: {
        email: "",
        member: null,
        message: "",
        locale: null,
      },
    }
  },
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return this.mode === "email"
        }),
        email,
      },
      member: {
        required: requiredIf(function () {
          return this.mode === "member"
        }),
      },
      message: {
        maxLength: maxLength(255),
      },
      locale: {
        required,
      },
    },
  },
  computed: {
    me() {
      return this.$store.getters["appointment/getMe"]()
    },
    ...mapGetterAppointment(["isParticipant"]),
    appointment() {
      return this.$store.state.appointment
    },
  },
  mounted() {
    if (this.mode === "member") {
      this.processing = true
      const permissionFilter = [this.permissions.GROUP_GLOBAL_REQUESTS_JOIN_TO_PARTICIPANTS].join(
        ","
      )
      this.$store
        .dispatch("user/getGroupMembers", {
          group_id: this.appointment.group_id,
          permissions: permissionFilter,
        })
        .then((response) => {
          this.members = response.data.data.filter(
            (member) =>
              member.user_id !== this.me.user_id && this.isParticipant(member.user_id) === false
          )
          this.processing = false
        })
    }
  },
  methods: {
    sendInvitation() {
      this.processing = true
      let params = {
        appointment_id: this.appointment.appointment_id,
        email: this.form.email,
        member_id: this.form.member ? this.form.member.member_id : null,
        mode: this.mode,
        message: this.form.message,
        scope: Constants.Invitation.SCOPE_INVITE_PARTICIPANT_APPOINTMENT,
        locale: this.form.locale,
      }
      this.$store.dispatch("invitations/inviteAppointmentParticipant", params).then(
        () => {
          if (this.mode === "email") {
            this.$bridge.emit("addToast", this.$t.get("appointment.participant-invited"), "success")
          } else {
            this.$bridge.emit("addToast", this.$t.get("appointment.participant-added"), "success")
          }
          this.processing = false
          this.close()
        },
        (error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        }
      )
    },
    close() {
      this.$emit("cancel")
    },
  },
}
