import { http } from "./VueHttp"

export default class Analytics {
  static emit(name, properties = {}, type, id) {
    const product = JSON.parse(window.__CURRENT_PRODUCT)
    if (type === void 0 && id === void 0 && product !== null) {
      type = "group"
      id = product.product_id
    }

    const payload = {
      event_name: name,
      model_id: id,
      model_type: type,
      properties: properties,
    }

    Analytics._save({
      try: 0,
      payload,
    })
  }

  static _save(item) {
    const cache = JSON.parse(window.localStorage.getItem("cache-analytics") || "[]")
    if (item.try < 3) {
      item.try = item.try + 1
      cache.push(item)
      window.localStorage.setItem("cache-analytics", JSON.stringify(cache))
    }
  }

  static _push() {
    const cache = JSON.parse(window.localStorage.getItem("cache-analytics") || "[]")
    window.localStorage.removeItem("cache-analytics")

    cache.forEach((item) => {
      http.post("/ajax/analytic-events", item.payload).catch((_) => this._save(item))
    })
  }

  static install(Vue, _) {
    window.addEventListener("load", () => Analytics._push(), false)

    Object.defineProperties(Vue.prototype, {
      $analytics: {
        get() {
          return {
            click: (target, action, componentId, type, id) =>
              Analytics.emit("ui_click", { target, action, componentId }, type, id),
          }
        },
      },
    })
  }
}
