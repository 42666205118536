var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shared-profile" }, [
    _c("div", { staticClass: "title shared-profile" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t.get("profile.shared-profile")))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content shared-profile",
        class: { "preloader preloader-cover": _vm.processing },
      },
      [
        _c(
          "div",
          { staticClass: "table-scroll table-scroll-small" },
          [
            _c("div", { staticClass: "table-header" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-2/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("profile.date"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-2/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("profile.sent-to"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-4/12 columns" }, [
                  _vm._v(_vm._s(_vm.$t.get("profile.email-address"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-1/12 columns text-center" }, [
                  _vm._v(_vm._s(_vm.$t.get("profile.views"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-1/12 columns text-right" }, [
                  _vm._v(_vm._s(_vm.$t.get("profile.action"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.getShareList.total && _vm.getShareList.total > 0
              ? _vm._l(_vm.getShareList.data, function (item) {
                  return _c(
                    "div",
                    { key: item.shared_id, staticClass: "table-row" },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "w-2/12 columns" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(item.created_at)
                                  .format("DD MMM, hh:mm A")
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-2/12 columns" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-4/12 columns" }, [
                          _vm._v(_vm._s(item.email)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-1/12 columns text-center" },
                          [_vm._v(_vm._s(item.views))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "w-1/12 columns text-right" },
                          [
                            item.status === 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button text red",
                                    on: {
                                      click: function ($event) {
                                        return _vm.revokeShare(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t.get("profile.revoke")) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                })
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-pagination" },
          [
            _c("vue-paginator", {
              attrs: { "pagination-init": _vm.shared },
              on: { "set-page": _vm.fetchShared },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }