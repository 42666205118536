//
//
//

export default {
  props: {
    text: {
      type: String,
    },
    level: {
      type: String,
      default: "section",
      validator(value) {
        return ["section", "group"].includes(value)
      },
    },
  },
  computed: {
    html() {
      // `this.$attrs.value` is a fallback for old component
      return this.text || this.$attrs.value
    },
  },
}
