//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { find, debounce, get } from "lodash"
import Translate from "../../../helpers/translate.js"
import Customize from "../Customize.js"

export default {
  mixins: [Customize()],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: {
      default: "",
    },
    name: {
      default: "",
    },
    disabled: {
      default: false,
    },
    labelKey: {
      default: "label",
    },
    descriptionKey: {
      default: "desc",
    },
    valueKey: {
      default: "value",
    },
    returnOption: {
      default: false,
    },
    placeholder: {
      default: Translate.get("common.choose-option"),
    },
    descriptionPreviewText: {
      default: null,
    },
    enableSearch: {
      default: false,
    },
    internalSearch: {
      default: true,
    },
    loading: {
      default: false,
    },
    value: {},
    options: {
      default: () => [],
    },
  },
  data() {
    return {
      isOpened: false,
      items: [],
      searchBy: "",
      get: get,
    }
  },
  mounted() {
    document.addEventListener("click", this.blur.bind(this))
  },
  destroyed() {
    document.removeEventListener("click", this.blur.bind(this))
  },
  methods: {
    blur(event) {
      const path = event.path || event.composedPath()
      if (path.indexOf(this.$el) === -1 && this.isOpened) {
        this.hide()
      }
    },
    _addOption(label, value, description, disabled) {
      let item = {}
      item[this.labelKey] = label
      item[this.valueKey] = value
      item[this.descriptionKey] = description
      item.$disabled = disabled
      return this.addItem(item)
    },
    _removeOption(label, value) {
      const index = this.items.findIndex(
        (item) => get(item, this.labelKey) === label && item[this.valueKey] === value
      )
      if (index > -1) {
        this.items.splice(index, 1)
      }
    },
    addItem(option) {
      if (Object.keys(option).indexOf(this.valueKey) === -1) {
        console.error(
          "[vue-select] Object which you trying to add don't have value key `" + this.valueKey + "`"
        )
        return
      }
      if (Object.keys(option).indexOf(this.labelKey) === -1) {
        console.error(
          "[vue-select] Object which you trying to add don't have label key `" + this.labelKey + "`"
        )
        return
      }

      this.items.push(option)
      return this.items.length - 1
    },
    itemClicked(item) {
      if (!item.$disabled) {
        this.selectItem(item)
        this.hide()
      }
    },
    arrowDown() {
      const currentIndex = this.selectedItem
        ? this.filteredItems.findIndex((item) => item === this.selectedItem)
        : -1
      const item = this.filteredItems
        .slice(currentIndex + 1, this.filteredItems.length)
        .find((item) => !item.$disabled)

      if (item !== void 0) {
        this.selectItem(item)
      }
    },
    arrowUp() {
      const currentIndex = this.selectedItem
        ? this.filteredItems.findIndex((item) => item === this.selectedItem)
        : this.filteredItems.length
      const item = this.filteredItems
        .slice(0, -Math.abs(currentIndex))
        .reverse()
        .find((item) => !item.$disabled)

      if (item !== void 0) {
        this.selectItem(item)
      }
    },
    selectItem(item) {
      if (this.returnOption === false) {
        this.$emit("change", item[this.valueKey])
      } else {
        this.$emit("change", item)
      }
    },
    show() {
      if (this.disabled === false) {
        this.isOpened = true
        if (this.enableSearch) {
          this.$nextTick(() => {
            this.$refs.searchInput.focus()
          })
        }

        if (this.selectedItem !== null) {
          let selectedElement = this.$refs.scrollBar.$el.querySelector(".selected")
          if (selectedElement !== null) {
            setTimeout(() => {
              this.$refs.scrollBar.$el.scrollTop = selectedElement.offsetTop
            }, 100)
          }
        }
      }
    },
    hide() {
      this.$el.blur()
      this.isOpened = false
      this.searchBy = ""
    },
  },
  computed: {
    selectedItem() {
      if (this.returnOption === false) {
        return find(this.items, [this.valueKey, this.value]) || null
      } else {
        return this.value || null
      }
    },
    filteredItems() {
      if (this.enableSearch && this.internalSearch) {
        return this.items.filter(
          (item) =>
            get(item, this.labelKey).toLowerCase().indexOf(this.searchBy.toLowerCase()) > -1 ||
            get(item, this.descriptionKey)?.toLowerCase()?.indexOf(this.searchBy.toLowerCase()) > -1
        )
      } else {
        return this.items
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {},
    },
    options: {
      immediate: true,
      deep: true,
      handler(value) {
        this.items = value
      },
    },
    searchBy: {
      handler: debounce(function (value) {
        this.$emit("search-changed", value)
      }, 500),
      // handler(value) {
      //   this.$emit('search-changed', value)
      // }
    },
  },
}
