// @ts-ignore
import { User } from "./models/User"
import { ProviderInterface } from "./providers/ProviderInterface"
import { Call } from "./models/Call"
import { Participant } from "./models/Participant"
import { AxiosResponse } from "axios"
import { CallStatus } from "./models/ValueObjects/CallStatus"
import { ConnectedParticipant } from "./providers/ConnectedParticipant"
import { action, createModule, mutation } from "vuex-class-component"
import { http } from "../../vuejs/plugins/VueHttp"
import { CallParticipantJoined } from "./events/CallParticipantJoined"
import { CallParticipantLeft } from "./events/CallParticipantLeft"
import LocalMediaPermissionError from "./errors/LocalMediaPermissionError"

export enum ViewStage {
  LOADING,
  LOBBY,
  ROOM,
}

interface UserLocalStorageData {
  name: string
  email: string
}

export interface CallState {
  stage: ViewStage
  call: Call | null
  loggedInUser: User | null
  participant: Participant | null
  provider: ProviderInterface
  userLocalStorageData: UserLocalStorageData | null
  activeWindowParticipant: ConnectedParticipant | null
  isIntentionToNotRecordVideo: boolean
  isFullScreen: boolean
  isBackgroundModalVisible: boolean
}

const VuexModule = createModule({
  namespaced: "callService",
  strict: false,
})

export default class CallServiceStore extends VuexModule {
  private stage = ViewStage.LOADING
  public provider: ProviderInterface | null = null
  public call: Call | null = null
  public loggedInUser: User | null = null
  private activeWindowParticipant: ConnectedParticipant | null = null
  public participant: Participant | null = null
  public userLocalStorageData: UserLocalStorageData | null = null
  private intentionToRecordVideo: boolean = true
  private isFullscreen: boolean = false
  private isBackgroundModalVisible: boolean = false

  @mutation update(state: CallState) {
    this.stage = state.stage
    this.call = state.call
    this.loggedInUser = state.loggedInUser
    this.activeWindowParticipant = state.activeWindowParticipant
  }

  @mutation makeCallStarted(): void {
    this.call!.status = CallStatus.STARTED
  }

  @mutation makeCallFinished(): void {
    this.call!.status = CallStatus.FINISHED
    this.stage = ViewStage.LOBBY
  }

  @mutation addParticipantToTheCall(participantJoinedEvent: CallParticipantJoined): void {
    if (
      this.call!.participants.find(
        ({ participantId }) => participantId === participantJoinedEvent.payload.participant_id
      ) !== undefined
    ) {
      return
    }

    const participantData = participantJoinedEvent.payload

    this.call!.participants.push(
      new Participant(
        participantData.participant_id,
        participantData.user_id,
        participantData.name,
        "", // we don't need email and permissions at this stage
        [],
        participantData.type,
        participantData.avatar_url
      )
    )
  }

  @mutation removeParticipantFromTheCall(participantLeftEvent: CallParticipantLeft): void {
    this.call?.participants.splice(
      this.call!.participants.findIndex(
        ({ participantId }) => participantId === participantLeftEvent.payload.participant_id
      ),
      1
    )
  }

  @mutation setActiveWindowParticipant(participant: ConnectedParticipant): void {
    this.activeWindowParticipant = participant
  }

  @mutation setIntentionToRecordVideo(intentionToRecordVideo: boolean): void {
    this.intentionToRecordVideo = intentionToRecordVideo
  }

  @mutation setFullscreen(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen
  }

  @mutation setBackgroundModalVisibility(isVisible: boolean): void {
    this.isBackgroundModalVisible = isVisible
  }

  @action
  async join(participantData: any): Promise<any> {
    if (!this.provider!.localMedia.hasGrantedPermissions()) {
      return Promise.reject(new LocalMediaPermissionError("No permission for local media granted"))
    }

    if (!this.intentionToRecordVideo) {
      // @note We need to disable video when user intention is to not record the video
      if (this.provider!.localMedia.videoEnabled) {
        this.provider!.localMedia.toggleVideo()
      }
    }

    // @ts-ignore
    return http
      .post(`/ajax/calls/${this.call!.callId}/join`, {
        name: participantData.name,
        email: participantData.email,
        camera_enabled: this.intentionToRecordVideo,
      })
      .then((response: AxiosResponse) => {
        if (this.loggedInUser === null) {
          localStorage.setItem(
            "guest_data",
            JSON.stringify({
              name: participantData.name,
              email: participantData.email,
            })
          )

          this.userLocalStorageData = JSON.parse(localStorage.getItem("guest_data")!)
        }
        this.call = Call.fromObject(response.data.call)
        this.participant =
          this.call.participants.find(
            (p) => p.participantId === response.data.participant.participantId
          ) ?? null

        return this.provider!.connect(response.data.participant.token).then(() => {
          this.stage = ViewStage.ROOM
        })
      })
  }

  @action
  async finish(): Promise<any> {
    // @ts-ignore
    return http.post(`/ajax/calls/${this.call!.callId}/finish`).then((response: AxiosResponse) => {
      this.leave()
    })
  }

  @action
  async leave(): Promise<any> {
    // @ts-ignore
    return http
      .post(`/ajax/calls/${this.call!.callId}/leave`, {
        participant_id: this.participant?.participantId,
      })
      .then((response: AxiosResponse) => {
        this.provider!.disconnect()
        this.stage = ViewStage.LOBBY
      })
  }

  @action
  async fetchBackgrounds() {
    return http
      .get(`/ajax/product/${this.call?.groupId}/call/backgrounds`, {
        headers: { Authorization: "Bearer " + window.SOCKET_JWT },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  get viewStage(): ViewStage {
    return this.stage
  }

  get getActiveWindowParticipant(): ConnectedParticipant | null {
    // If participant is selected return that one
    if (this.activeWindowParticipant !== null) {
      return this.activeWindowParticipant
    }

    // If we are not owners we're trying to find owner as connected participant
    if (this.participant!.type !== "owner") {
      let ownerParticipant: Participant | undefined = this.call!.participants.find(
        (participant: Participant) => participant.type === "owner"
      )

      let targetParticipant: ConnectedParticipant | undefined =
        this.provider!.connectedParticipants.find(
          (participant: ConnectedParticipant) =>
            participant.participantId === ownerParticipant?.participantId
        )

      if (targetParticipant) {
        return targetParticipant
      }
    }

    return (
      this.provider!.connectedParticipants.find(
        (participant: ConnectedParticipant) =>
          participant.participantId !== this.participant!.participantId
      ) ?? null
    )
  }

  get isIntentionToRecordVideo(): boolean {
    return this.intentionToRecordVideo
  }

  get isInFullscreen(): boolean {
    return this.isFullscreen
  }

  get getBackgroundModalVisibility(): boolean {
    return this.isBackgroundModalVisible
  }
}
