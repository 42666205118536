//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    image: {
      default: null,
      required: true,
    },
    type: {
      default: "circle",
    },
    resizable: {
      default: false,
    },
    viewportHeight: {
      default: 200,
    },
    viewportWidth: {
      default: 200,
    },
    boundaryHeight: {
      default: 350,
    },
    boundaryWidth: {
      default: "100%",
    },
    resultSize: {
      default: "viewport",
    },
    buttons: {
      default: true,
    },
  },
  computed: {
    viewport() {
      return {
        width: this.viewportWidth,
        height: this.viewportHeight,
        type: this.type,
      }
    },
    boundary() {
      return {
        width: this.boundaryWidth,
        height: this.boundaryHeight,
      }
    },
  },
  mounted() {
    this.$refs.croppie.bind({
      url: this.image,
    })
  },
  watch: {
    image(image) {
      this.$refs.croppie.bind({
        url: image,
      })
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel")
    },
    result(output) {
      this.$emit("success", output)
    },
    crop() {
      this.$refs.croppie.result({ size: this.resultSize, circle: false })
    },
  },
}
