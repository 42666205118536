export default class StackCache {
  constructor() {
    this.stacks = {}
  }

  static instance() {
    if (window._stackCache === void 0) {
      window._stackCache = new StackCache()
    }

    return window._stackCache
  }

  set(hash, stack) {
    if (window.sessionStorage !== void 0) {
      window.sessionStorage.setItem(hash, JSON.stringify(stack))
    } else {
      this.stacks[hash] = stack
    }
  }

  has(hash) {
    if (window.sessionStorage !== void 0) {
      return window.sessionStorage.getItem(hash) !== null
    } else {
      return Object.keys(this.stacks).indexOf(hash) > -1
    }
  }

  get(hash) {
    if (window.sessionStorage !== void 0) {
      return JSON.parse(window.sessionStorage.getItem(hash))
    } else {
      return this.stacks[hash] || null
    }
  }
}
