var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stepper-small",
      class: { "preloader preloader-cover": _vm.processing },
    },
    [
      _c("div", { staticClass: "steps" }, [
        _c(
          "div",
          {
            staticClass: "step",
            class: {
              done: _vm.currentStep > 1,
              current: _vm.currentStep === 1,
            },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.$t.get("auth.phone-verification-phone-number"))
                ),
              ]),
              _vm._v(" "),
              _vm.currentStep === 1
                ? _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t.get(
                            "auth.phone-verification-enter-phone-number"
                          )
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 1
                ? _c("div", { staticClass: "content single-line" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap flex-spacing material-form",
                      },
                      [
                        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                          _c(
                            "div",
                            { staticClass: "input" },
                            [
                              _c("v-select", {
                                attrs: {
                                  id: "two-factor-auth-modal-phone-country",
                                  "enable-search": true,
                                  options: _vm.countryCodes,
                                  "label-key": "label",
                                  "value-key": "value",
                                },
                                model: {
                                  value: _vm.countryCode,
                                  callback: function ($$v) {
                                    _vm.countryCode = $$v
                                  },
                                  expression: "countryCode",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "two-factor-auth-modal-phone-country",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t.get(
                                          "auth.phone-verification-country-code"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                          _c("div", { staticClass: "input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "cleave",
                                  rawName: "v-cleave.config",
                                  value: _vm.phoneMask,
                                  expression: "phoneMask",
                                  modifiers: { config: true },
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "two-factor-auth-modal-phone-number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: "two-factor-auth-modal-phone-number",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t.get(
                                        "auth.phone-verification-phone-number"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full columns" }, [
                          _c("div", { staticClass: "buttons-list" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button accent",
                                attrs: {
                                  disabled:
                                    _vm.countryCode === null ||
                                    _vm.phoneNumber === "",
                                },
                                on: { click: _vm.sendCode },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t.get(
                                        "auth.two-factor-send-code-by-sms"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.enableCancel
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("cancel")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t.get("common.cancel")) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "step",
            class: {
              done: _vm.currentStep > 2,
              current: _vm.currentStep === 2,
            },
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("h5", [_vm._v(_vm._s(_vm.$t.get("auth.phone-verification")))]),
              _vm._v(" "),
              _vm.currentStep === 2
                ? _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t.get(
                            "auth.phone-verification-code-sent-please-input",
                            { phone: this.phoneNumber }
                          )
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentStep === 2
                ? _c("div", { staticClass: "content single-line" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap flex-spacing material-form",
                      },
                      [
                        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
                          _c("div", { staticClass: "input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.token,
                                  expression: "token",
                                },
                              ],
                              attrs: {
                                type: "text",
                                id: "two-factor-auth-modal-code",
                              },
                              domProps: { value: _vm.token },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.token = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "two-factor-auth-modal-code" } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t.get("auth.phone-verification-code")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full lg:w-full columns" }, [
                          _c("p", { staticClass: "description resend" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t.get(
                                    "auth.phone-verification-didnt-receive"
                                  )
                                ) +
                                "\n                "
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "button text accent",
                                on: { click: _vm.sendCode },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t.get("auth.two-factor-resend-code")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons-list" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button accent",
                                attrs: { disabled: _vm.token === "" },
                                on: { click: _vm.verifyNumber },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$t.get("common.enable")) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.enableCancel
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("success")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t.get("common.cancel")) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "number" }, [_c("div", [_vm._v("1")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "number" }, [_c("div", [_vm._v("2")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }