var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-box margin-top details-list" }, [
    _c("div", { staticClass: "title" }, [
      _c("i", { staticClass: "ict-patient" }),
      _vm._v(" " + _vm._s(_vm.$t.get(_vm.header))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "ul",
        { staticClass: "padded-left" },
        _vm._l(_vm.items, function (value, key) {
          return _c(
            "li",
            [
              _vm._l(value, function (pairValue, pairKey) {
                return [
                  _c("span", [_vm._v(_vm._s(_vm.processKey(pairKey)))]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.processPair(pairKey, pairValue))),
                  ]),
                ]
              }),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }