//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../../../models/Constants"
import Modal from "../../../../plugins/modal/modal"
import Customize from "../../../../plugins/Customize"

export default {
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    customerId: { type: String, required: true },
    organizationId: { type: String, required: true },
  },
  mixins: [Customize()],
  data() {
    return {
      Constants: Constants,
      cases: [],
      processing: false,
    }
  },
  methods: {
    getComponentData() {
      if (this.customerId === null) {
        return
      }

      let params = {
        action: "customerCases",
        componentId: this.componentId,
      }

      params = {
        ...params,
        customerId: this.customerId,
      }

      this.processing = true

      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.cases = response.data.data
          this.processing = false
        })
        .catch((response) => {
          this.processing = false
          this.error = response.response.data.message
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
    getPriorityClass(priority) {
      if (priority === "High") {
        return "bg-orange"
      }
      if (priority === "Medium") {
        return "bg-blue-900"
      }
      if (priority === "Low") {
        return "bg-primary"
      }
    },
    createAssesment(sfcase) {
      Modal.create(this)
        .classes(["modal-create-assessment"])
        .component(() => import("../../../appointment/salesforce/CreateAssessmentModal.vue"))
        .props({
          groupId: this.organizationId,
          customerId: this.customerId,
          parentCase: sfcase,
        })
        .show()
    },
  },
  created() {
    this.getComponentData()
  },
}
