var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.getColumnSize(_vm.mode) + " text-right justify-end" },
    [
      _vm.mode === "header"
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t.get(_vm.headerTranslation || _vm.defaultTranslation)
                ) +
                "\n  "
            ),
          ]
        : [
            _vm.actions.length === 0
              ? _c("div", { staticClass: "right-over" }, [_vm._v("-")])
              : _c(
                  "vue-context-menu",
                  { staticClass: "right-over", attrs: { fixed: true } },
                  _vm._l(_vm.actionsForSearchScope, function (action) {
                    return _c(
                      "li",
                      [
                        _c(
                          _vm.getActionComponent(action.component),
                          _vm._g(
                            _vm._b(
                              {
                                key: action.component,
                                tag: "component",
                                attrs: { mode: "flex", customer: _vm.customer },
                              },
                              "component",
                              Object.assign(_vm.$props, action.props || {}),
                              false
                            ),
                            _vm.$listeners
                          )
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }