//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"
import { actions } from "./builder"
import icon from "./icons"

export default {
  mixins: [Customize()],
  props: {
    canUpdateResource: {
      type: Boolean,
      default: true,
    },
    crudURL: {
      type: String,
      required: true,
    },
    extraRequestParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      processing: false,
      items: [],
    }
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    icon: icon,
    fetchData() {
      this.processing = true
      this.$http
        .post(this.crudURL, {
          action: this.$route.name,
          params: Object.assign(this.$route.params, this.extraRequestParams),
        })
        .then(
          (response) => {
            this.items = response.data
            this.processing = false
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.message, "error")
            this.processing = false
          }
        )
    },
    editResource(resource) {
      this.$router.push({
        name: actions.LIST,
        params: {
          resource: resource.resource,
        },
      })
    },
  },
}
