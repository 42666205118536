var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input files" },
    [
      _c("attach-files", {
        ref: "attachedFiles",
        attrs: { "object-id": _vm.objectId },
        on: {
          attached: _vm.onItemsAttached,
          "attached-updated": _vm.onItemsDetached,
          detached: _vm.onItemsDetached,
          "external-attached": _vm.onExternalItemsAttached,
        },
      }),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }