//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment-timezone"
import { get, isArray } from "lodash"
import { oauth2 as SMART } from "fhirclient"

function getSessionStorageKey() {
  let key = sessionStorage.getItem("SMART_KEY") || ""
  key = key.replace(/"/g, "")
  key = key.replace(/'/g, "")
  return key
}

function clearTokenResponse() {
  sessionStorage.removeItem(getSessionStorageKey())
  sessionStorage.removeItem("SMART_KEY")
}

function getTokenResponse() {
  return JSON.parse(sessionStorage.getItem(getSessionStorageKey()))
}

function hasCodeParameter() {
  let parsedUrl = new URL(window.location.href)
  return parsedUrl.searchParams.get("code") !== null
}

function isServerError() {
  let parsedUrl = new URL(window.location.href)
  return parsedUrl.searchParams.get("error")
}

function isPatientDeceased(patient) {
  return (
    get(patient, "deceasedDateTime", null) !== null ||
    get(patient, "deceasedBoolean", false) === true
  )
}

function isPatientActive(patient) {
  return get(patient, "active", true)
}

function transformPatientResource(patient) {
  const address = get(patient, "address[0]", {
    country: "",
    city: "",
  })
  const telecom = get(patient, "telecom", [])
  return {
    surname: (() => {
      let surname = get(patient, "name[0].family", "")
      return isArray(surname) ? surname.join(" ") : surname
    })(),
    name: (() => {
      let name = get(patient, "name[0].given", "")
      return isArray(name) ? name.join(" ") : name
    })(),
    gender: (() => {
      const gender = get(patient, "gender", "")
      if (["male", "female"].includes(gender) === true) {
        return gender
      }
      return ""
    })(),
    birthday: (() => {
      let birthday = get(patient, "birthDate", "")
      if (birthday !== "") {
        return moment(birthday, "YYYY-MM-DD").format("DD.MM.YYYY")
      }
    })(),
    city: address.city,
    country: address.country,
    email: get(telecom.find((tel) => tel.system === "email") || { value: "" }, "value", ""),
    phone: get(telecom.find((tel) => tel.system === "phone") || { value: "" }, "value", ""),
  }
}

export { clearTokenResponse, hasCodeParameter, isServerError }

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawFhirResource: {
      type: Boolean,
      default: false,
    },
    step: {
      default: 1,
    },
    fhirEndpoints: {
      type: Array,
      required: true,
    },
    fhirEndpointsScope: {
      type: String,
      default: "registration",
    },
  },
  data() {
    return {
      error: null,
      processing: false,
      currentStep: this.step,
      selectedEndpoint: null,
      patientResource: null,
    }
  },
  computed: {
    endpoint() {
      if (this.selectedEndpoint !== null) {
        return this.fhirEndpoints.find((provider) => provider.iss === this.selectedEndpoint)
      }
    },
    isError() {
      return this.currentStep < 0
    },
    profileName() {
      if (this.patientResource === null) {
        return ""
      }
      const patient = transformPatientResource(this.patientResource)
      return `${patient.name} ${patient.surname}`
    },
    currentUrl() {
      return `${location.protocol}//${location.host}${location.pathname}`
    },
  },
  mounted() {
    if (this.currentStep === 3) {
      this.callFhirEndpoint()
    }
  },
  watch: {
    selectedEndpoint() {
      if (this.currentStep === 1 && this.selectedEndpoint !== null) {
        sessionStorage.setItem("selectedEndpoint", this.selectedEndpoint)
        this.currentStep = 2
      }
    },
  },
  methods: {
    restart() {
      clearTokenResponse()
      window.location.href = this.currentUrl
    },
    setError(error = null) {
      this.error = error || "Something went wrong. Please try again later"
      this.currentStep = -1
      this.processing = false
    },
    callFhirEndpoint() {
      this.selectedEndpoint = sessionStorage.getItem("selectedEndpoint")
      let endpoint = this.endpoint
      endpoint.redirectUri = endpoint.redirectUri || this.currentUrl

      this.processing = true
      SMART.init(endpoint)
        .then((client) => {
          return Promise.all([client.patient.read()])
        })
        .then(
          ([patient, meds]) => {
            if (isPatientDeceased(patient)) {
              this.setError("Patient has been marked as deceased thus data cannot be imported ")
              return
            }
            if (isPatientActive(patient) === false) {
              this.setError(
                "Patient account has been marked as inactive thus data cannot be imported "
              )
              return
            }
            this.processing = false
            this.currentStep = 3
            this.patientResource = patient
          },
          (error) => {
            this.setError()
          }
        )
    },
    completeImport() {
      if (this.patientResource === null) {
        return
      }

      let event = this.patientResource
      if (this.rawFhirResource === false) {
        event = transformPatientResource(event)
      }
      event = Object.assign(
        event,
        { fhirEndpoint: this.endpoint },
        { fhirResponse: getTokenResponse() }
      )

      this.$emit("fhir-patient-imported", event)
      this.$emit("success")
    },
    cancelImport() {
      this.$emit("cancel")
    },
  },
}
