//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../../plugins/modal/modal"
import { requiredIf } from "vuelidate/lib/validators"
import { Constants } from "../../../models/Constants"

export default {
  props: {
    termRequired: {
      default: true,
    },
    availableConsultationTypes: {
      type: Array,
      default: function () {
        return [
          Constants.Appointment.CONSULTATION_CALL_OPINION,
          Constants.Appointment.CONSULTATION_OPINION,
        ]
      },
    },
  },
  data() {
    return {
      constants: Constants.Appointment,
      consultationType: null,
      term: null,
    }
  },
  computed: {
    termLabel() {
      if (this.term === null) {
        return null
      }
      return this.$moment(this.term.start_date, "YYYY-MM-DD[T]HH:mmZ").format("YYYY.MM.DD HH:mm")
    },
    termVisible() {
      return (
        this.consultationType === Constants.Appointment.CONSULTATION_CALL_OPINION ||
        this.consultationType === Constants.Appointment.CONSULTATION_DIAGNOSTIC_TEST ||
        this.consultationType === Constants.Appointment.CONSULTATION_OVERSEAS_TREATMENT
      )
    },
  },
  validations: {
    term: {
      required: requiredIf(function () {
        return this.termRequired && this.termVisible
      }),
    },
  },
  watch: {
    consultationType() {
      this.emitData()
    },
    term() {
      this.emitData()
    },
  },
  methods: {
    chooseTerm() {
      const locationRequired =
        this.consultationType === Constants.Appointment.CONSULTATION_DIAGNOSTIC_TEST ||
        this.consultationType === Constants.Appointment.CONSULTATION_OVERSEAS_TREATMENT
      Modal.create(this)
        .title(this.$t.get("appointment.add-a-time-slot"))
        .component(() => import("./OrganizationSetupAppointmentTerm.vue"))
        .props({
          showLocation: locationRequired,
        })
        .events({
          success: (timeSlot) => (this.term = timeSlot),
        })
        .show()
    },
    handleInput(validator) {
      validator.$touch()
      this.emitDescription()
    },
    emitData() {
      this.$v.$touch()

      this.$emit("data", "consultationType", this.consultationType)

      if (this.$v.$invalid === true) {
        this.$emit("data", "term", null)
        return
      }

      this.$emit("data", "term", this.term)
    },
  },
}
