//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputMixin from "./InputMixin"
import * as datePickerLanguages from "vuejs-datepicker/dist/locale"

export default {
  mixins: [InputMixin],
  props: {
    format: {
      default: "yyyy-MM-dd",
    },
    onSelected: {
      default: null,
    },
    onSelectedEmpty: {
      default: null,
    },
  },
  computed: {
    getRef() {
      return "inputdate-" + this.inputId
    },
    datepickerLanguage() {
      const languageAdaptable = {
        af: "af",
        ar: "ar",
        bg: "bg",
        bs: "bs",
        ca: "ca",
        cs: "cs",
        da: "da",
        de: "de",
        ee: "ee",
        el: "el",
        en: "en",
        es: "es",
        fa: "fa",
        fi: "fi",
        fo: "fo",
        fr: "fr",
        ge: "ge",
        gl: "gl",
        he: "he",
        hu: "hu",
        hr: "hr",
        id: "id",
        is: "is",
        it: "it",
        ja: "ja",
        kk: "kk",
        ko: "ko",
        lb: "lb",
        lt: "lt",
        lv: "lv",
        mk: "mk",
        mn: "mn",
        "nb-No": "nbNO",
        nl: "nl",
        pl: "pl",
        "pt-br": "ptBR",
        ro: "ro",
        ru: "ru",
        sk: "sk",
        "sl-si": "slSI",
        sv: "sv",
        sr: "sr",
        "sr-cyrl": "srCyrl",
        th: "th",
        tr: "tr",
        uk: "uk",
        ur: "ur",
        vi: "vi",
        zh: "zh",
        "zh-hk": "zhHK",
      }
      return datePickerLanguages[languageAdaptable[this.$locale]]
    },
  },
  methods: {
    clearValue() {
      const dp = this.$refs[this.getRef]
      if (dp !== undefined) {
        dp.clearDate()
      }
    },
    change(e) {
      if (this.onSelected !== null && e !== null) {
        this.handleComponentEvent(this.onSelected)
      }
      if (this.onSelectedEmpty !== null && e == null) {
        this.handleComponentEvent(this.onSelectedEmpty)
      }
    },
  },
}
