//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, requiredIf } from "vuelidate/lib/validators"
export default {
  data() {
    return {
      enquiry: {
        reason: [],
        type: [],
        insurance: {
          type: null,
          text: "",
        },
      },
    }
  },
  validations: {
    enquiry: {
      reason: { required },
      type: { required },
      insurance: {
        type: { required },
        text: {
          required: requiredIf(function () {
            return (
              this.enquiry.insurance.type === "appointment.insurance-private-insurance" ||
              this.enquiry.insurance.type === "appointment.insurance-other" ||
              this.enquiry.insurance.type === "appointment.insurance-embassy-sponsored"
            )
          }),
        },
      },
    },
  },
  methods: {
    handleInput(validator) {
      validator.$touch()
      this.emitEnquiryData()
    },
    emitEnquiryData() {
      this.$v.$touch()

      if (this.$v.$invalid === true) {
        this.$emit("data", "enquiry", null)
        return
      }

      this.$emit("data", "enquiry", this.enquiry)
    },
  },
}
