//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from "../../plugins/modal/modal.js"
import Invite from "./Invite.vue"
import Join from "./Join.vue"

import { Constants } from "../../models/Constants"
import { Permissions } from "../../models/Permissions"

import { createNamespacedHelpers } from "vuex"
import Customize from "../../plugins/Customize.js"

const { mapGetters } = createNamespacedHelpers("appointment")
const PROPS_TYPE_PARTICIPANTS = "participants"
const PROPS_TYPE_SHARED = "shared"

export default {
  mixins: [Customize()],
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      processing: false,
      constants: Constants,
      permissions: Permissions,
      propsTypes: {
        participants: PROPS_TYPE_PARTICIPANTS,
        shared: PROPS_TYPE_SHARED,
      },
    }
  },
  computed: {
    actualContextParticipants() {
      if (this.type === PROPS_TYPE_SHARED) {
        return this.actualParticipants.filter((participant) => {
          return participant.type === Constants.AppointmentParticipant.TYPE_SHARED
        })
      } else if (this.type === PROPS_TYPE_PARTICIPANTS) {
        return this.actualParticipants.filter((participant) => {
          return participant.type !== Constants.AppointmentParticipant.TYPE_SHARED
        })
      }
    },
    appointment() {
      return this.$store.state.appointment
    },
    user() {
      return this.$store.state.user
    },
    isGroupMember() {
      return this.$store.getters["user/getGroupMember"](this.appointment.group_id) !== null
    },
    ...mapGetters([
      "actualParticipants",
      "hasAppointmentPermission",
      "participantHasPermission",
      "canManageParticipants",
      "canInvite",
      "canShare",
      "canMuteParticipant",
      "canRemoveParticipant",
      "isParticipant",
      "isRequester",
      "isReporter",
      "isAssigned",
      "isClosed",
      "isVideoCall",
    ]),
  },
  components: {
    join: Join,
  },
  methods: {
    haveDotsMenu(participant) {
      return (
        this.canManageSection(participant) ||
        this.canMuteSection(participant) ||
        this.canRemoveSection(participant)
      )
    },
    canManageSection(participant) {
      return (
        this.canManageParticipants &&
        this.isRequester(participant.user_id) === false &&
        ((this.isVideoCall &&
          (this.hasAppointmentPermission(Permissions.APPOINTMENT_SET_PARTICIPANT_CALL) ||
            this.hasAppointmentPermission(
              Permissions.GROUP_GLOBAL_REQUESTS_SET_PARTICIPANT_CALL
            ))) ||
          (this.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_SET_REPORT_AUTHOR) &&
            this.isReporter(participant.user_id) === false &&
            this.participantHasPermission(
              participant.participant_id,
              Permissions.GROUP_GLOBAL_REQUESTS_RECEIVE
            )) ||
          (this.appointment.assigned_id === this.user.user_id &&
            participant.user_id !== this.user.user_id))
      )
    },
    canMuteSection(participant) {
      return this.canMuteParticipant || participant.user_id === this.user.user_id
    },
    canRemoveSection(participant) {
      return (
        this.isReporter(participant.user_id) === false &&
        this.isAssigned(participant.user_id) === false &&
        this.isRequester(participant.user_id) === false &&
        (participant.user_id === this.user.user_id ||
          this.canRemoveParticipant ||
          (participant.hasOwnProperty("invited_by") &&
            participant.invited_by === this.user.user_id))
      )
    },
    share() {
      Modal.create(this)
        .title(this.$t.get("share.share-the-case"))
        .classes(["modal-share", "modal-small"])
        .component(Share)
        .props({ scope: Constants.Invitation.SCOPE_SHARE_PARTICIPANT_APPOINTMENT })
        .show()
    },
    inviteParticipant() {
      Modal.create(this)
        .title(this.$t.get("appointment.invite-participant"))
        .classes(["modal-share", "modal-small"])
        .component(Invite)
        .props({
          enableSelection: this.isGroupMember,
          selectionMode: this.isGroupMember ? "member" : "email",
        })
        .show()
    },
    manageParticipant(participant_id, action, confirm = false) {
      const fn = () => {
        this.processing = true
        this.$store
          .dispatch("appointment/manageParticipant", {
            appointment_id: this.appointment.appointment_id,
            participant_id: participant_id,
            action: action,
          })
          .then(
            (response) => {
              this.processing = false
              this.$bridge.emit("addToast", response.data.message, "success")
              if (
                response.data.data.hasOwnProperty("reload") &&
                response.data.data.reload === true
              ) {
                window.location.reload()
              }
            },
            (error) => {
              this.processing = false
              this.$bridge.emit("addToast", error.response.data.message, "error")
            }
          )
      }
      if (confirm === false) {
        return fn()
      }
      Modal.confirm(this)
        .title(this.$t.get("common.perform-action-prompt"))
        .content(this.$t.get("common.perform-action"))
        .positive(this.$t.get("common.yes"), fn)
        .negative(this.$t.get("appointment.cancel"))
        .show()
    },
  },
}
