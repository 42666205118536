const AttachedFilesState = {
  FETCHING: -1,
  READY: 0,
  PREPARING: 1,
  WAITING_FOR_FOLDER: 2,
  UPLOADING: 3,
  UPLOADING_FINISHED: 4,
  ERROR: 5,
  RENAMING: 6,
  DELETING: 7,
}

export default AttachedFilesState
