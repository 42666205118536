import { componentEventBus } from "../ComponentMixin"

export default {
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    columnsCount: {},
    mode: {
      type: String,
      default: "header",
    },
    headerTranslation: {
      type: String,
    },
    defaultTranslation: {
      type: String,
    },
    placeholderTranslation: {
      type: String,
      default: "appointment.type-anything-to-start-searching",
    },
    requiredAtLeastOne: {
      type: String,
    },
    type: {
      type: String,
    },
    customer: {
      type: Object,
    },
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-5/12 columns",
          input: "w-1/12 columns",
          row: "w-5/12 columns user",
        }
      },
    },
  },
  created() {
    componentEventBus.$on("clear-input", () => {
      this.value = null
    })
  },
  data() {
    return {
      value: null,
    }
  },
  methods: {
    input(event) {
      if (event !== null && (event instanceof InputEvent || event.type === "input")) {
        this.$emit("input", event.target.value)
      }
    },
    getColumnSize(mode) {
      return this.columnsCount < 5 ? "w-" + 12 / this.columnsCount + "/12" : this.css[mode]
    },
  },
}
