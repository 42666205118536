var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "enquiry-details" }, [
    _c("div", { staticClass: "section" }, [
      _vm._v(_vm._s(_vm.$t.get("appointment.enquiry-details"))),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      [
        _c("li", [
          _c("strong", [
            _vm._v(_vm._s(_vm.$t.get("appointment.service-name")) + ": "),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.appointment.service_name))]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [
            _vm._v(
              _vm._s(_vm.$t.get("appointment.enquiry-request-on-behalf-of")) +
                ": "
            ),
          ]),
          _vm._v(" "),
          _vm.appointment.enquiry_requester.myself
            ? _c("span", [_vm._v(_vm._s(_vm.$t.get("appointment.for-myself")))])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.appointment.enquiry_requester.name) +
                    "\n        " +
                    _vm._s(_vm.appointment.enquiry_requester.surname)
                ),
              ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.free, function (item, key) {
          return _c("li", { key: key }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t.get(key)) + ": ")]),
            _vm._v(" "),
            item.break ? _c("br") : _vm._e(),
            _vm._v(" "),
            item.text !== undefined
              ? _c("span", [
                  item.text.length !== 0
                    ? _c("span", { domProps: { innerHTML: _vm._s(item.text) } })
                    : _c("span", [_vm._v("-")]),
                ])
              : _c("span", [_vm._v("-")]),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "groups" },
      [
        _c(
          "v-accordion",
          _vm._l(_vm.groups, function (ref) {
            var label = ref.label
            var items = ref.items
            return _c("v-accordion-item", { key: label }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "styled",
                      rawName: "v-styled:color.before",
                      value: _vm.palette.colors.primary.main,
                      expression: "palette.colors.primary.main",
                      arg: "color",
                      modifiers: { before: true },
                    },
                  ],
                  staticClass: "accordion-name",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(_vm._s(label))]
              ),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(items, function (item, key) {
                  return _c("li", { key: key }, [
                    _c("strong", [_vm._v(_vm._s(_vm.$t.get(key)) + ": ")]),
                    _vm._v(" "),
                    item.break ? _c("br") : _vm._e(),
                    _vm._v(" "),
                    item.text !== undefined
                      ? _c("span", [
                          item.text.length !== 0
                            ? _c("span", {
                                domProps: { innerHTML: _vm._s(item.text) },
                              })
                            : _c("span", [_vm._v("-")]),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ])
                }),
                0
              ),
            ])
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }