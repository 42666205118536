//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { find } from "lodash"

export default {
  data() {
    return {
      customSelectActive: {
        disease: false,
        treatment: false,
        area: false,
      },
      diseases: window.__DISEASES,
      form: {
        disease: -1,
        treatment: -1,
        area: "",
      },
    }
  },
  watch: {
    "form.disease": function () {
      this.form.treatment = -1
    },
  },
  mounted() {},
  methods: {
    validate(e) {
      if (this.form.disease === -1 || this.form.treatment === -1 || this.form.area.length == 0) {
        e.preventDefault()
        this.$bridge.emit("addToast", "Please fill in all select boxes in search bar", "info")
      }
    },
    getSelectedTreatmentName() {
      var $this = this
      if (
        typeof this.diseases[this.form.disease] !== typeof undefined &&
        this.form.treatment > -1
      ) {
        return find(this.diseases[this.form.disease].treatments, function (o) {
          return o.id == $this.form.treatment
        }).treatment
      } else {
        return "Choose treatment"
      }
    },
    customSelectToggle(type) {
      this.customSelectActive[type] = !this.customSelectActive[type]
    },
    customSelectHide(type) {
      this.customSelectActive[type] = false
    },
    diseaseCustomSelectHide() {
      this.customSelectHide("disease")
    },
    treatmentCustomSelectHide() {
      this.customSelectHide("treatment")
    },
    areaCustomSelectHide() {
      this.customSelectHide("area")
    },
    otherDiseaseShow() {
      this.form.disease = -2
    },
  },
}
