var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.attached && _vm.attached.length > 0
    ? _c(
        "v-accordion-item",
        {
          key:
            _vm.attached !== null && _vm.attached.length !== 0
              ? _vm.attached[0].id
              : null,
        },
        [
          _c("template", { attrs: { draggable: "false" }, slot: "title" }, [
            _c("span", { staticClass: "accordion-name" }, [
              _c("label", { attrs: { for: _vm.attached[0].id } }, [
                _vm._v(_vm._s(_vm.folderName)),
              ]),
            ]),
            _vm._v(" "),
            _vm.canDetachAll
              ? _c(
                  "button",
                  {
                    staticClass: "delete",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteAttached()
                      },
                    },
                  },
                  [_c("i", { staticClass: "ict-close" })]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stack" },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "w-6/12 column name" }, [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.name"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-2/12 column type" }, [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.type"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-2/12 column size" }, [
                    _vm._v(_vm._s(_vm.$t.get("cloud-manager.size"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-2/12 column status text-right" }),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.attached, function (item, key) {
                return _c("div", { key: item.id, staticClass: "stack-items" }, [
                  _c("div", { staticClass: "flex item single" }, [
                    _c("div", { staticClass: "w-6/12 column name" }, [
                      _c("label", { attrs: { for: "stack-" + key } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.name) +
                            "\n            "
                        ),
                        item.url
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: item.url,
                                  rel: "noopener nofollow",
                                  target: "_blank",
                                  title: "Open",
                                },
                              },
                              [_c("i", { staticClass: "ict-newspaper" })]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-2/12 column type" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            item.isDir
                              ? _vm.$t.get("cloud-manager.folder")
                              : _vm.$t.get("cloud-manager.file")
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-2/12 column size" }, [
                      _vm._v(
                        _vm._s(item.isDir ? "-" : _vm.formatSize(item.size, 1))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-2/12 column status text-right" },
                      [
                        _vm.canDetachSubitem
                          ? _c(
                              "button",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.detachItem(item.id)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "ict-close" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }