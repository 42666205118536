var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _vm.playing === false
      ? _c("div", {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:border-left-color.after",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-left-color",
              modifiers: { after: true },
            },
          ],
          staticClass: "control play",
          on: { click: _vm.play },
        })
      : _c("div", {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:border-left-color.after",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-left-color",
              modifiers: { after: true },
            },
            {
              name: "styled",
              rawName: "v-styled:border-right-color.before",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-right-color",
              modifiers: { before: true },
            },
          ],
          staticClass: "control stop",
          on: { click: _vm.stop },
        }),
    _vm._v(" "),
    _c(
      "video",
      { ref: "video", attrs: { crossorigin: "anonymous", preload: "auto" } },
      [_c("source", { attrs: { src: _vm.videoLink, type: "video/mp4" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }