export function normalizeRole(role) {
  switch (role) {
    case "trial_admin":
      return "Admin"
    case "trial_requester":
      return "Send-only"
    case "trial_reviewer":
      return "View & Download"
  }
}
