//
//
//
//
//
//
//
//
//
//

import ColumnMixin from "./ColumnMixin"
import { endsWith } from "lodash"

export default {
  name: "policyKey",
  mixins: [ColumnMixin],
  props: {
    css: {
      type: Object,
      default: function () {
        return {
          header: "w-2/12 columns",
          input: "w-2/12 columns",
          row: "w-2/12 columns",
        }
      },
    },
    fallbackValue: {
      type: String,
      default: "-",
    },
  },
  computed: {
    getPolicyKey() {
      return this.type.replace("policy.", "")
    },
    getPolicyValue() {
      if (this.customer.hasOwnProperty("policy") === false) {
        return this.fallbackValue
      }
      if (this.customer.policy.hasOwnProperty(this.getPolicyKey) === false) {
        return this.fallbackValue
      }
      const policyElement = this.customer.policy[this.getPolicyKey]
      if (policyElement === null || policyElement === "") {
        return this.fallbackValue
      }
      if (
        this.getPolicyKey.includes("date") === true ||
        endsWith(this.getPolicyKey, "_at") === true
      ) {
        return this.$moment(policyElement).format("DD.MM.YYYY")
      }
      return policyElement
    },
  },
}
