import { cornerstone, cornerstoneTools } from "../../cornerstone"
const BaseBrushTool = cornerstoneTools.import("base/BaseBrushTool")

const drawTextBox = cornerstoneTools.import("drawing/drawTextBox")
const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox")

const triggerEvent = cornerstoneTools.import("util/triggerEvent")

const getToolState = cornerstoneTools.getToolState
const addToolState = cornerstoneTools.addToolState

const store = cornerstoneTools.store
// const { state } = store.modules.brush

export default class PointerTool extends BaseBrushTool {
  constructor(configuration) {
    super({
      name: "PointerTool",
      supportedInteractionTypes: ["Mouse"],
      mixins: ["activeOrDisabledBinaryTool"],
      configuration: {},
    })

    this._configuration = configuration
  }

  activeCallback(element) {
    element.addEventListener("cornerstonetoolsmousemove", this.mouseMoveCallback)
  }

  disabledCallback(element) {
    element.removeEventListener("cornerstonetoolsmousemove", this.mouseMoveCallback)
  }

  mouseMoveCallback(event) {
    const eventData = event.detail
    const element = eventData.element
    const mousePosition = eventData.currentPoints.image

    const enabledElement = cornerstone.getEnabledElement(element)
    const imageId = enabledElement.image.imageId

    triggerEvent(element, "cornerstonetoolscursormoved", { position: mousePosition, imageId })
  }

  renderBrush(event) {
    const eventData = event.detail

    if (this.element === void 0) return

    const enabledElement = cornerstone.getEnabledElement(this.element)

    if (this.context === void 0) {
      this.context = enabledElement.canvas.getContext("2d")
    }

    const context = eventData.canvasContext
    const element = eventData.element

    context.setTransform(1, 0, 0, 1, 0, 0)

    const pointers = Object.values(this._configuration.cursors)
    const imageId = enabledElement.image.imageId

    pointers.forEach((pointer) => {
      if (
        this._configuration.participants[pointer.userId] !== void 0 &&
        pointer.viewId === imageId &&
        pointer.userId !== this._configuration.userId &&
        pointer.position !== void 0 &&
        pointer.position.x !== void 0 &&
        pointer.position.y !== void 0
      ) {
        const mouseCoordsCanvas = cornerstone.pixelToCanvas(element, pointer.position)

        const userName = this._configuration.participants[pointer.userId].name
        const userColor = this._configuration.participants[pointer.userId].color

        context.beginPath()
        context.fillStyle = userColor
        context.ellipse(mouseCoordsCanvas.x, mouseCoordsCanvas.y, 3, 3, 0, 0, 2 * Math.PI)
        context.fill()

        drawTextBox(context, [userName], mouseCoordsCanvas.x, mouseCoordsCanvas.y, userColor, {})
      }
    })
  }

  _paint(event) {}

  static _overlappingStrategy(eventData, configuration) {
    const element = eventData.element
    const { rows, columns } = eventData.image
    const { x, y } = eventData.currentPoints.image

    console.log("_overlappingStrategy", x, y)
  }
}
