//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Velocity from "velocity-animate"
import Customize from "../Customize"

export default {
  mixins: [Customize()],
  props: {
    title: {
      type: String,
      // required: true
    },
    expanded: {
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.isActive = this.expanded
    if (typeof this.$parent.addItem === "function") {
      this.$parent.addItem(this)
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.$emit("expanded", this)
      } else {
        this.$emit("collapsed", this)
      }
    },
  },
  methods: {
    collapse() {
      this.isActive = false
    },
    transitionLeave(el, done) {
      Velocity(
        el,
        {
          opacity: 0,
        },
        { duration: 300 }
      )
      Velocity(el, "slideUp", { duration: 300, complete: done })
    },
    transitionBeforeEnter(el) {
      el.style.opacity = 0
    },
    transitionEnter(el, done) {
      Velocity(el, "slideDown", { duration: 300 })
      Velocity(el, "fadeIn", { duration: 300 })
    },
  },
}
