//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from "vuex"
import VueProgress from "../../plugins/VueProgress.vue"

const { mapGetters } = createNamespacedHelpers("downloads")
export default {
  props: {
    visible: {
      default: false,
    },
    position: {
      default: "top-left",
    },
  },
  computed: {
    ...mapGetters(["isPanelVisible", "getJobs"]),
  },
  methods: {
    close() {
      this.$emit("downloads-close")
      this.$store.commit("downloads/toggleVisibility")
    },
    removeFromList(job) {
      this.$store.commit("downloads/removeFromList", job)
    },
  },
  components: {
    "progress-circle": VueProgress,
  },
}
