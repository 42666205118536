var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-spacing" }, [
    _c("div", { staticClass: "w-full lg:w-4/12 column" }, [
      _c(
        "div",
        { staticClass: "card-box menu" },
        [_c(_vm.components[_vm.sidebar], { tag: "component" })],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "w-full lg:w-8/12 column" }, [
      _c("div", { staticClass: "card-box" }, [_c("router-view")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }