var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: { "preloader preloader-cover": _vm.processing } }, [
      _c("p", { staticClass: "description" }, [
        _vm._v(
          _vm._s(
            _vm.$t.get("appointment.pick-how-many-minutes-you-will-be-late")
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form radio-buttons" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lateDelay,
              expression: "lateDelay",
            },
          ],
          attrs: {
            type: "radio",
            id: "add-duration-5",
            value: "5",
            disabled: _vm.processing,
          },
          domProps: { checked: _vm._q(_vm.lateDelay, "5") },
          on: {
            change: function ($event) {
              _vm.lateDelay = "5"
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "add-duration-5" } }, [_vm._v("5")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lateDelay,
              expression: "lateDelay",
            },
          ],
          attrs: {
            type: "radio",
            id: "add-duration-10",
            value: "10",
            disabled: _vm.processing,
          },
          domProps: { checked: _vm._q(_vm.lateDelay, "10") },
          on: {
            change: function ($event) {
              _vm.lateDelay = "10"
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "add-duration-10" } }, [_vm._v("10")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lateDelay,
              expression: "lateDelay",
            },
          ],
          attrs: {
            type: "radio",
            id: "add-duration-15",
            value: "15",
            disabled: _vm.processing,
          },
          domProps: { checked: _vm._q(_vm.lateDelay, "15") },
          on: {
            change: function ($event) {
              _vm.lateDelay = "15"
            },
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "add-duration-15" } }, [_vm._v("15")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-list text-right" }, [
      _c(
        "button",
        {
          staticClass: "button accent",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.willBeLate },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("common.send")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: _vm.processing },
          on: { click: _vm.cancel },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t.get("common.cancel")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }