export default {
  data: {
    componentsMapping: window.__COMPONENTS_MAPPING || {},
    components: {},
  },
  async mounted() {
    let context = require.context("../components", true, /\.vue$/, "lazy")

    for (let mapping in this.componentsMapping) {
      let module = await context("./" + this.componentsMapping[mapping])
      this.components[mapping] = module.default || module
    }
    this.$forceUpdate()
    window.status = "mounted"
  },
}
