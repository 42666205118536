var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "styled",
              rawName: "v-styled:border-color",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-color",
            },
            {
              name: "styled",
              rawName: "v-styled:border-top-color.before",
              value: _vm.palette.colors.primary.main,
              expression: "palette.colors.primary.main",
              arg: "border-top-color",
              modifiers: { before: true },
            },
          ],
          staticClass: "term-component-tooltip",
        },
        [
          _vm.term.status === 0
            ? [
                _c("span", { staticClass: "term-time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$dateTimeFormat.formatTime(_vm.term.start)) +
                      " -\n      " +
                      _vm._s(_vm.$dateTimeFormat.formatTime(_vm.term.end)) +
                      "\n    "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "meta" }, [
                  _vm._v(_vm._s(_vm.term.title)),
                ]),
                _vm._v(" "),
                _vm.$moment(_vm.term.start).diff(_vm.now) < -900000
                  ? _c("span", { staticClass: "meta with" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t.get("common.unavailable-to-book")) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$moment(_vm.term.start).diff(_vm.now) >= -900000
                  ? _c(
                      "span",
                      { staticClass: "meta with" },
                      [
                        _vm.term.appointment_id !== null
                          ? [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t.get("common.available-to-book-by") +
                                      " " +
                                      _vm.term.patient_name
                                  ) +
                                  "\n      "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm.$t.get("common.available-to-book")
                                  ) +
                                  "\n      "
                              ),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$moment(_vm.term.start).diff(_vm.now) >= -900000
                  ? _c(
                      "span",
                      { staticClass: "meta with" },
                      [
                        _vm.term.price
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t.get("appointment.price")) +
                                  ": " +
                                  _vm._s(_vm.term.price) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm.term.status === 1
            ? [
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$dateTimeFormat.formatTime(_vm.term.start)) +
                      " -\n      " +
                      _vm._s(_vm.$dateTimeFormat.formatTime(_vm.term.end)) +
                      "\n    "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "meta" }, [
                  _vm._v(_vm._s(_vm.term.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "meta with" }, [
                  _vm._v(_vm._s(_vm.term.patient_name)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }