//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Queue from "./Queue.js"
import RxTime from "../../plugins/RxTime"
import { transliterate as transl } from "transliteration"
import Translate from "../../../helpers/translate.js"

import VueProgress from "../../plugins/VueProgress.vue"
import DropDownButton from "../../components/common/DropDownButton.vue"

import CloudMixin from "./CloudMixin"
import Customize from "../../plugins/Customize"

import {
  indexOf,
  keys,
  filter,
  remove,
  capitalize,
  isEmpty,
  findIndex,
  chunk,
  forEach,
  find,
} from "lodash"

export default {
  mixins: [RxTime.mixin(), Object.assign({}, CloudMixin), Customize({})],
  props: {
    alreadyAttached: {
      default: () => [],
    },
    selectFolder: { default: null },
    showFolders: { default: true }, // optionally hide folders list - show only newly created folders
    showActions: { default: true },
    showBottomButtonsList: { default: true },
    exploreMode: { default: false }, // show files and folders only without attaching functionality
    hideAttachButton: { default: false },
  },
  data() {
    return {
      state: 0,
      states: {
        FETCHING: -1,
        READY: 0,
        PREPARING: 1,
        WAITING_FOR_FOLDER: 2,
        UPLOADING: 3,
        UPLOADING_FINISHED: 4,
        ERROR: 5,
        RENAMING: 6,
        DELETING: 7,
      },
      disallowFileNames: [".ds_store", "dicomdir", "logo.jpg"], // do not analyze system files
      maxUpload: 4000000000, // 4GB
      dropzoneActive: false,
      uploadTimeLeft: Translate.get("cloud-manager.calculating"),
      uploadStartTime: 0,
      files: [],
      sizeFiles: 0,
      sizeUploadedFiles: 0,
      countFiles: 0,
      countFileHistory: 0, // Prevent uploading empty folders
      countEntries: 0,
      selectedFolder: null,
      newFolder: null,
      queues: {
        signing: new Queue(2, Infinity, true),
        uploading: new Queue(3, Infinity, true),
      },
      attached: [],
      current_context_menu: null,
      current_folder_rename: { name: "", old_name: "" },
      current_item_delete: { parent_id: null, item_id: null, name: "", type: "folder" },
    }
  },
  mounted() {
    this.attached = this.alreadyAttached
  },
  components: {
    "progress-circle": VueProgress,
    "dropdown-button": DropDownButton,
  },
  watch: {
    state: function (newState) {
      if (newState === this.states.FETCHING) this.onStateFetching()
      if (newState === this.states.PREPARING) this.onStatePreparing()
      if (newState === this.states.WAITING_FOR_FOLDER) this.onStateWaitingForFolder()
      if (newState === this.states.UPLOADING) this.onStateUploading()
      if (newState === this.states.UPLOADING_FINISHED) this.onStateUploadFinished()
    },
    sizeUploadedFiles: function (newSize) {
      if (newSize > 0) {
        this.detectTimeLeft()
      } else {
        this.uploadTimeLeft = this.$t.get("cloud-manager.calculating")
      }
    },
  },
  computed: {
    uploadedPercent() {
      return Math.round((this.sizeUploadedFiles / this.sizeFiles) * 100) || 0
    },
  },
  methods: {
    handleDropdownClick(id) {
      if (this.state !== this.states.READY) {
        event.stopPropagation()
        return
      }
      this.current_context_menu = id
    },
    dropzoneOver(e) {
      e.preventDefault()
      this.dropzoneActive = true
    },
    dropzoneLeave(e) {
      e.preventDefault()
      this.dropzoneActive = false
    },
    incrementCountFileHistory(count) {
      this.countFileHistory = this.countFileHistory + count
    },
    detectTimeLeft() {
      let diff =
        this.sizeFiles *
          (this.$moment(this.now).diff(this.uploadStartTime) / this.sizeUploadedFiles) -
        this.$moment(this.now).diff(this.uploadStartTime)
      if (diff >= 3600000) {
        this.uploadTimeLeft = this.$moment.utc(diff).format(this.$t.get("common.h-h-mm-m-ss-s"))
      } else {
        this.uploadTimeLeft = this.$moment.utc(diff).format(this.$t.get("common.mm-m-ss-s"))
      }
    },
    checkEntryFormat(entry) {
      let filename = entry.name.toLowerCase()
      if (indexOf(this.disallowFileNames, filename) > -1) return false

      let format = filename.split(".").pop()
      if (format === filename) return true // probably DCM without ext.
      return indexOf(this.allowFormats, format) === -1 ? false : true
    },
    convertInputToEntries(files) {
      let dirs = {
        entities: {},
      }

      const iterate = function (result, p, file) {
        let path = p.split("/")
        if (path && path.length > 0) {
          let item = path.shift()
          if (path.length > 0) {
            if (typeof result.entities[item] == typeof undefined)
              result.entities[item] = {
                name: item,
                entities: {},
                fullPath: "/" + file.webkitRelativePath.split("/").slice(0, -1).join("/"),
                isFile: false,
                isDirectory: true,
              }
            iterate(result.entities[item], path.join("/"), file)
          } else {
            result.entities[file.name] = {
              name: file.name,
              file: function (f) {
                f(file)
              },
              fullPath: "/" + file.webkitRelativePath,
              isFile: true,
              isDirectory: false,
            }
          }
        }
      }

      for (let i = 0; i < files.length; i++) {
        iterate(dirs, files[i].webkitRelativePath, files[i])
      }

      return dirs.entities
    },
    isEmptyFolder(folder) {
      return (
        keys(folder.data.dicoms).length === 0 &&
        keys(folder.data.images).length === 0 &&
        keys(folder.data.pdfs).length === 0 &&
        folder.dataFetched === true
      )
    },
    isEmptyAttached() {
      return (
        this.attached.length === 0 &&
        filter(this.folders, (folder) => {
          return folder.attached === true
        }).length === 0
      )
    },
    getIndexItemAttached(parent_id, item_id, type) {
      return findIndex(this.attached, (item) => {
        return item.parent_id == parent_id && item.item_id === item_id && item.type === type
      })
    },
    haveItemsAttached(parent_id) {
      return (
        filter(this.attached, function (item) {
          return item.parent_id == parent_id
        }).length > 0
      )
    },
    isItemAttached(parent_id, item_id, type) {
      return (
        this.getIndexItemAttached(parent_id, item_id, type) > -1 ||
        this.getIndexItemAttached(parent_id, parent_id, "folder") > -1
      )
    },
    deleteItemAttached(parent_id, item_id, type) {
      let index = this.getIndexItemAttached(parent_id, item_id, type)
      if (index > -1) this.attached.splice(index, 1)
    },
    detachItem(parent_id, item_id, type) {
      this.deleteItemAttached(parent_id, item_id, type) // delete item first
      if (type === "folder") {
        remove(this.attached, (item) => {
          return item.parent_id === item_id
        })
        this.$forceUpdate()
      } //if detach folder do nothing
      if (type === "file") {
        // if detach file - check all other conditions
        if (this.isItemAttached(parent_id, parent_id, "folder")) {
          //if only folder was selected: ,
          this.detachItem(parent_id, parent_id, "folder") // 1. deselect folder
          forEach(
            this.folders[findIndex(this.folders, { item_id: parent_id })].data,
            (items, key) => {
              forEach(items, (file, subkey) => {
                this.attachItem(parent_id, subkey, "file") //2 - select all files without thie one
              })
            }
          )
          this.deleteItemAttached(parent_id, item_id, type) // delete item first
        }
      }
    },
    detachItemFromCloud(folder, item) {
      let detached = { parent_id: folder.item_id, item_id: folder.item_id, type: "folder" }
      if (item !== null) {
        // detach only file
        item[0].attached = false
        detached.item_id = item[0].stack_name
        detached.type = "file"
      }
      if (item === null) {
        //detach folder and all children
        folder.attached = false
        forEach(folder.data, (stacks, type) => {
          forEach(stacks, (stack, stackname) => {
            stack[0].attached = false
          })
        })
      }
      this.$emit("detached", detached, this.objectId)
      this.$forceUpdate()
    },
    attachItem(parent_id, item_id, type, check_is_attach = true) {
      if (this.isItemAttached(parent_id, item_id, type) && check_is_attach === true) {
        this.detachItem(parent_id, item_id, type)
      } else {
        remove(this.attached, (item) => {
          return item.parent_id === item_id
        })

        if (this.isItemAttached(parent_id, item_id, type) === false) {
          this.attached.push({
            parent_id: parent_id,
            item_id: item_id,
            type: type,
          })
        }
      }
      this.$forceUpdate()
    },
    attachSelectedFiles() {
      this.$emit("success", this.attached, this.objectId, this.countFileHistory)
    },
    deleteItem(parent_id, item_id, name, type) {
      this.current_item_delete = { parent_id: parent_id, item_id: item_id, name: name, type: type }
      this.$refs.cloudManagerDeleteConfirm.open()
    },
    deleteItemConfirmation() {
      if (isEmpty(this.current_item_delete)) {
        this.$refs.cloudManagerDeleteConfirm.close()
        return
      }
      let {
        parent_id: parent_id,
        item_id: item_id,
        name: name,
        type: type,
      } = this.current_item_delete
      let p1 = this.$http.post(
        "/ajax/medical_records/delete",
        {
          parent_id: parent_id,
          name: name,
          type: type,
          jwt: this.jwt,
        },
        {
          headers: {
            Authorization: "Bearer " + this.jwt,
          },
        }
      )
      // rename can take some time depending on cloud load; add fake timeout
      let p2 = new Promise((resolve, reject) => {
        setTimeout(resolve, 3000, true)
      })
      this.state = this.states.DELETING
      Promise.all([p1, p2])
        .then((responses) => {
          this.$bridge.emit(
            "addToast",
            this.$t.get("cloud-manager.item-removed", { item: capitalize(type) }),
            "success"
          )
          this.detachItem(parent_id, item_id, type)

          this.current_item_delete = { parent_id: null, item_id: null, name: "", type: "folder" }
          if (type === "file") {
            this.fetchFiles(parent_id, true)
            this.state = this.states.READY
          }
          if (type === "folder") {
            this.$refs.medicalCloudAccordion?.collapseAll()
            this.state = this.states.FETCHING
          }
          this.$refs.cloudManagerDeleteConfirm.close()
        })
        .catch((response) => {
          if (response.status === 409) {
            this.$bridge.emit(
              "addToast",
              this.$t.get("cloud-manager.some-operations-are-in-progress-please-try-again-later"),
              "error"
            )
          } else {
            this.$bridge.emit(
              "addToast",
              this.$t.get("cloud-manager.an-error-occured-while-deleting-please-try-again-later"),
              "error"
            )
          }
          this.$refs.medicalCloudAccordion?.collapseAll()
          this.current_item_delete = { parent_id: null, item_id: null, name: "", type: "folder" }
          this.state = this.states.FETCHING
          this.$refs.cloudManagerDeleteConfirm.close()
        })
    },
    clearStates() {
      this.files = []
      this.sizeFiles = 0
      this.sizeUploadedFiles = 0
      this.incrementCountFileHistory(this.countFiles)
      this.countFiles = 0
      this.countEntries = 0
      this.uploadStartTime = 0
      this.selectedFolder = null
      this.queues.uploading.setOnFinishListener(() => {})
      this.queues.signing.clear()
      this.queues.uploading.clear()
      this.$forceUpdate()
    },
    cancelAttach() {
      this.current_context_menu = null
      this.$emit("cancel")
    },
    createFolder(auto_select = true) {
      if (this.newFolder === null) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("cloud-manager.please-put-folder-name-before-create"),
          "error"
        )
        return
      }
      if (this.newFolder.length < 2 || this.newFolder.length > 50) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("cloud-manager.the-folder-name-must-be-between-2-and-50-characters"),
          "error"
        )
        return
      }
      if (
        find(this.folders, (folder) => {
          return folder.name === this.newFolder.trim()
        })
      ) {
        this.$bridge.emit(
          "addToast",
          this.$t.get("cloud-manager.folder-with-this-name-exists"),
          "error"
        )
        return
      }
      this.$http
        .post(
          window.__CLOUD_URL + "/items",
          {
            name: transl(this.newFolder),
            parent_id: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.jwt,
            },
          }
        )
        .then(
          (response) => {
            let folder = response.data
            folder.data = { dicoms: {}, images: {}, pdfs: {} }
            folder.dataFetched = false
            this.folders.push(folder)
            this.newFolder = null
            if (auto_select) this.selectedFolder = folder.item_id
          },
          (error) => {
            this.$bridge.emit("addToast", error.response.data.error, "error")
          }
        )
    },
    renameFolder(folder, mode) {
      this.current_folder_rename = Object.assign({ old_name: folder.name }, folder)

      if (mode === "reset") {
        this.current_folder_rename = { name: "", old_name: "" }
        this.$refs.cloudManagerRenameFolder.close()
        return
      }

      if (mode === "modal") {
        this.$refs.cloudManagerRenameFolder.open()
        return
      }

      if (isEmpty(this.current_folder_rename) || this.current_folder_rename.name.length === 0)
        return

      let p1 = this.$http.post(
        window.__CLOUD_URL + "/items/rename",
        {
          item_id: this.current_folder_rename.item_id,
          name: this.current_folder_rename.name,
        },
        {
          headers: {
            Authorization: "Bearer " + this.jwt,
          },
        }
      )
      // rename can take some time depending on cloud load; add fake timeout
      let p2 = new Promise((resolve, reject) => {
        setTimeout(resolve, 2000, true)
      })
      this.state = this.states.RENAMING
      Promise.all([p1, p2])
        .then((responses) => {
          this.$bridge.emit(
            "addToast",
            this.$t.get("cloud-manager.folder-has-been-renamed"),
            "success"
          )
          this.current_folder_rename = { name: "", old_name: "" }
          this.$refs.medicalCloudAccordion?.collapseAll()
          this.state = this.states.FETCHING
          this.$refs.cloudManagerRenameFolder.close()
        })
        .catch((responses) => {
          this.$bridge.emit(
            "addToast",
            this.$t.get(
              "cloud-manager.an-error-occured-while-renaming-a-folder-please-try-again-later"
            ),
            "error"
          )
          this.current_folder_rename = { name: "", old_name: "" }
          this.$refs.medicalCloudAccordion?.collapseAll()
          this.state = this.states.FETCHING
          this.$refs.cloudManagerRenameFolder.close()
        })
    },
    cancelUpload() {
      this.$refs.cloudManagerChooseFolder.close()
      this.clearStates()
      this.state = this.states.READY
    },
    startUpload() {
      this.$refs.cloudManagerChooseFolder.close()
      this.state = this.states.UPLOADING
    },

    // Files handler
    onFileChange(e) {
      e.preventDefault()
      e.stopPropagation()

      let $this = this
      this.dropzoneActive = false
      this.state = this.states.PREPARING

      // From input buttons
      if (e.target.files) {
        let items = e.target.files
        forEach(items, function (item) {
          let entry = {
            name: item.name,
            file: function (f) {
              f(item)
            },
            isFile: true,
            isDirectory: false,
          }
          $this.scanEntry(entry)
        })
      }

      // From dropzone
      if (e.dataTransfer) {
        let items = e.dataTransfer.items || e.dataTransfer.files
        for (let i = 0; i < items.length; i++) {
          let entry = items[i].getAsEntry || items[i].webkitGetAsEntry()
          this.scanEntry(entry)
        }
      }
    },
    scanEntry(entry) {
      let $this = this
      if (entry == null) {
        return
      }
      if (entry.isDirectory) {
        let reader = entry.createReader()
        let doBatch = () => {
          reader.readEntries((entries) => {
            if (entries.length === 0) return
            entries.forEach((e) => {
              $this.scanEntry(e)
            })
            doBatch()
          })
        }
        doBatch()
      }
      if (entry.isFile) {
        $this.handleFile(entry)
      }
    },
    handleFile(entry) {
      if (!this.checkEntryFormat(entry)) return
      let $this = this
      $this.countEntries++
      entry.file((file) => {
        $this.files.push({
          name: file.name,
          type: file.type.length > 0 ? file.type : "application/dicom",
          file: file,
        })
        $this.countFiles++
        $this.sizeFiles += file.size
        $this.$forceUpdate()
      })
    },
    uploadFile(file, aws_params) {
      if (this.state === this.states.READY) return
      let $this = this
      return $this.queues.uploading.add(() => {
        return new Promise(function (resolve, reject) {
          if ($this.state === $this.states.READY) resolve()
          if (typeof file === typeof undefined) {
            reject(new Error("File not presented"))
          } else if (typeof aws_params === typeof undefined) {
            reject(new Error("AWS Params not presented"))
          } else {
            let xhr = new XMLHttpRequest()
            let fd = new FormData()
            forEach(aws_params, function (value, key) {
              if (key !== "attributes" && key !== "x-amz-meta-study_id") fd.append(key, value)
            })
            fd.append("file", file)
            xhr.open(aws_params.attributes.method, aws_params.attributes.action, true)

            xhr.onerror = function () {
              reject(
                new Error(
                  "File not uploaded: " +
                    JSON.stringify({
                      status: this.status,
                      statusText: xhr.statusText,
                    })
                )
              )
            }

            xhr.onreadystatechange = function () {
              if (xhr.readyState !== 4) {
                return
              }
              if (xhr.status >= 300 || xhr.status < 200) {
                reject(
                  new Error(
                    "File not uploaded: " +
                      JSON.stringify({
                        status: this.status,
                        statusText: xhr.statusText,
                      })
                  )
                )
              } else {
                if ($this.state !== $this.states.READY) $this.sizeUploadedFiles += file.size
                resolve(file)
              }
            }

            xhr.send(fd)
          }
        })
      })
    },
    // Uploader states methods
    onStateFetching() {
      this.reloadData()
    },
    onStatePreparing() {
      let $this = this
      let timer = null

      if (this.selectFolder !== null) {
        let folder = find(this.folders, (folder) => {
          return folder.name === this.selectFolder
        })
        if (folder !== undefined && folder.item_id !== undefined) {
          //if folder exist select it.
          folder.fetched = false

          this.selectedFolder = folder.item_id
        } else {
          this.newFolder = this.selectFolder
          this.createFolder()
        }
      }

      timer = setInterval(function () {
        if ($this.sizeFiles > $this.maxUpload) {
          $this.$bridge.emit(
            "addToast",
            $this.$t.get("cloud-manager.selected-files-exceeds-the-maximum-upload-size"),
            "error"
          )
          $this.clearStates()
          $this.state = $this.states.READY
          return clearInterval(timer)
        }
        if (
          $this.countFiles === $this.countEntries &&
          ($this.selectFolder === null ? true : $this.selectedFolder !== null)
        ) {
          if ($this.countFiles === 0) {
            $this.$bridge.emit(
              "addToast",
              $this.$t.get(
                "cloud-manager.no-files-to-upload-make-sure-your-files-have-valid-format-or-size"
              ),
              "error"
            )
            $this.clearStates()
            $this.state = $this.states.READY
          }
          if ($this.countFiles > 0) {
            $this.files = chunk($this.files, 100)
            $this.state = $this.states.WAITING_FOR_FOLDER
          }
          return clearInterval(timer)
        }
      }, 100)
    },
    onStateWaitingForFolder() {
      this.$forceUpdate()
      if (this.selectedFolder) {
        // if folder is selected start upload directly
        this.state = this.states.UPLOADING
      } else {
        // else open folders list
        this.$refs.cloudManagerChooseFolder.open()
      }
    },
    onStateUploading() {
      let $this = this
      $this.uploadStartTime = $this.$moment()
      $this.queues.uploading.setOnFinishListener(() => {
        this.state = this.states.UPLOADING_FINISHED
      })

      forEach($this.files, (items, key) => {
        $this.queues.signing
          .add(function () {
            if ($this.state === $this.states.READY) return
            return $this.$http.post(
              window.__CLOUD_URL + "/items/presign",
              {
                parent_id: $this.selectedFolder,
                items: items,
              },
              {
                headers: {
                  Authorization: "Bearer " + $this.jwt,
                },
              }
            )
          })
          .then(
            (response) => {
              if ($this.state === $this.states.READY) return
              forEach(items, (item, key) => {
                $this.uploadFile(item.file, response.data[key].aws_params)
              })
            },
            (error) => {}
          )
      })
    },
    onStateUploadFinished() {
      this.attachItem(this.selectedFolder, this.selectedFolder, "folder", false)
      this.fetchFiles(this.selectedFolder, true)
      this.clearStates()
      this.$bridge.emit(
        "addToast",
        this.$t.get(
          "cloud-manager.upload-successful-please-notice-files-will-appear-on-the-list-after-processing"
        ),
        "success"
      )
      this.state = this.states.READY
    },
  },
}
