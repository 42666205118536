//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Queue from "./Queue.js"
import RxTime from "../../plugins/RxTime"
import axios from "axios"
import Translate from "../../../helpers/translate.js"
import VueProgress from "../../plugins/VueProgress.vue"
import DropDownButton from "../../components/common/DropDownButton.vue"

import { indexOf, forEach } from "lodash"

export default {
  mixins: [RxTime.mixin()],
  props: {
    objectId: { default: 1 },
    maxUploadLimit: { default: 15000000 }, // 15 MB
    maxAmountLimit: { default: 5 }, // 5 files only
    disableUploader: { default: false },
    uploadedFiles: {
      type: Array,
      default: function () {
        return []
      },
    },
    allowFormats: {
      type: Array,
      default: function () {
        return ["jpg", "jpeg", "png", "pdf", "doc", "docx", "txt", "rtf", "dcm", "mri"]
      },
    },
    showDownloadIcon: {
      type: Boolean,
      default: false,
    },
    staticItems: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      state: 0,
      states: {
        READY: 0,
        PREPARING: 1,
        UPLOADING: 2,
        UPLOADING_FINISHED: 3,
        ERROR: 4,
      },
      disallowFileNames: [".ds_store", "dicomdir", "logo.jpg"], // do not analyze system files
      maxUpload: this.maxUploadLimit,
      dropzoneActive: false,
      uploadTimeLeft: Translate.get("cloud-manager.calculating"),
      uploadStartTime: 0,
      files: [],
      uploaded: this.uploadedFiles,
      sizeUploadedTotal: 0,
      sizeFiles: 0,
      sizeTransferred: 0,
      countFiles: 0,
      countEntries: 0,
      queues: {
        uploading: new Queue(3, Infinity, true),
      },
      current_context_menu: null,
    }
  },
  mounted() {
    this.state = this.states.READY
  },
  components: {
    "progress-circle": VueProgress,
    "dropdown-button": DropDownButton,
  },
  watch: {
    state: function (newState) {
      if (newState === this.states.PREPARING) this.onStatePreparing()
      if (newState === this.states.UPLOADING) this.onStateUploading()
      if (newState === this.states.UPLOADING_FINISHED) this.onStateUploadFinished()
    },
    sizeTransferred: function (newSize) {
      if (newSize > 0) {
        this.detectTimeLeft()
      } else {
        this.uploadTimeLeft = this.$t.get("cloud-manager.calculating")
      }
    },
  },
  computed: {
    uploadedPercent() {
      if (this.sizeTransferred >= this.sizeFiles) {
        return 100
      }
      return Math.round((this.sizeTransferred / this.sizeFiles) * 100) || 0
    },
    isMaxAmountLimitReached() {
      if (this.maxUploadLimit !== Infinity && this.sizeUploadedTotal >= this.maxUploadLimit) {
        return true
      }

      if (this.maxAmountLimit === Infinity) {
        return false
      }
      return this.uploaded.length === this.maxAmountLimit
    },
    uploadedWithStatic() {
      return this.staticItems.concat(this.uploaded)
    },
  },
  methods: {
    detachFile(index) {
      this.uploaded.splice(index, 1)
    },
    handleDropdownClick(id) {
      if (this.state !== this.states.READY) {
        event.stopPropagation()
        return
      }
      this.current_context_menu = id
    },
    formatSize(bytes, decimals) {
      if (bytes === null) return ""
      if (bytes === "-") return "-"
      if (bytes === 0) return "0.00 MB"
      let k = 1000,
        dm = decimals + 1 || 3,
        sizes = ["Bytes", "KB", "MB", "GB"],
        i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    },
    detectTimeLeft() {
      let diff =
        this.sizeFiles *
          (this.$moment(this.now).diff(this.uploadStartTime) / this.sizeTransferred) -
        this.$moment(this.now).diff(this.uploadStartTime)
      if (diff >= 3600000) {
        this.uploadTimeLeft = this.$moment.utc(diff).format(this.$t.get("common.h-h-mm-m-ss-s"))
      } else {
        this.uploadTimeLeft = this.$moment.utc(diff).format(this.$t.get("common.mm-m-ss-s"))
      }
    },
    checkEntryFormat(entry) {
      let filename = entry.name.toLowerCase()
      if (indexOf(this.disallowFileNames, filename) > -1) return false

      let format = filename.split(".").pop()
      if (format === filename) return true // probably DCM without ext.
      return indexOf(this.allowFormats, format) === -1 ? false : true
    },
    convertInputToEntries(files) {
      let dirs = {
        entities: {},
      }

      const iterate = function (result, p, file) {
        let path = p.split("/")
        if (path && path.length > 0) {
          let item = path.shift()
          if (path.length > 0) {
            if (typeof result.entities[item] == typeof undefined)
              result.entities[item] = {
                name: item,
                entities: {},
                fullPath: "/" + file.webkitRelativePath.split("/").slice(0, -1).join("/"),
                isFile: false,
                isDirectory: true,
              }
            iterate(result.entities[item], path.join("/"), file)
          } else {
            result.entities[file.name] = {
              name: file.name,
              file: function (f) {
                f(file)
              },
              fullPath: "/" + file.webkitRelativePath,
              isFile: true,
              isDirectory: false,
            }
          }
        }
      }

      for (let i = 0; i < files.length; i++) {
        iterate(dirs, files[i].webkitRelativePath, files[i])
      }

      return dirs.entities
    },
    clearStates() {
      this.files = []
      this.sizeFiles = 0
      this.sizeTransferred = 0
      this.countFiles = 0
      this.countEntries = 0
      this.uploadStartTime = 0
      this.queues.uploading.setOnFinishListener(() => {})
      this.queues.uploading.clear()
      this.state = this.states.READY
      this.$forceUpdate()
    },
    clearUploaded() {
      this.uploaded = []
      this.sizeUploadedTotal = 0
    },
    cancelUpload() {
      this.clearStates()
      this.state = this.states.READY
    },
    startUpload() {
      this.state = this.states.UPLOADING
    },

    onFileChange(e) {
      e.preventDefault()
      e.stopPropagation()

      let $this = this
      this.dropzoneActive = false
      this.state = this.states.PREPARING

      // From input buttons
      if (e.target.files) {
        let items = e.target.files
        forEach(items, function (item) {
          let entry = {
            name: item.name,
            file: function (f) {
              f(item)
            },
            isFile: true,
            isDirectory: false,
          }
          $this.scanEntry(entry)
        })
      }

      // From dropzone
      if (e.dataTransfer) {
        let items = e.dataTransfer.items || e.dataTransfer.files
        for (let i = 0; i < items.length; i++) {
          let entry = items[i].getAsEntry || items[i].webkitGetAsEntry()
          this.scanEntry(entry)
        }
      }
    },
    scanEntry(entry) {
      let $this = this
      if (entry.isDirectory) {
        let reader = entry.createReader()
        let doBatch = () => {
          reader.readEntries((entries) => {
            if (entries.length === 0) return
            entries.forEach((e) => {
              $this.scanEntry(e)
            })
            doBatch()
          })
        }
        doBatch()
      }
      if (entry.isFile) {
        $this.handleFile(entry)
      }
    },
    handleFile(entry) {
      if (!this.checkEntryFormat(entry)) return
      let $this = this
      $this.countEntries++
      entry.file((file) => {
        $this.files.push({
          name: file.name,
          type: file.type.length > 0 ? file.type : "application/dicom",
          file: file,
        })
        $this.countFiles++
        $this.sizeFiles += file.size
        $this.$forceUpdate()
      })
    },
    onStatePreparing() {
      let $this = this
      let timer = null

      timer = setInterval(function () {
        if (
          $this.maxUpload !== Infinity &&
          ($this.sizeFiles > $this.maxUpload ||
            $this.sizeFiles + $this.sizeUploadedTotal >= $this.maxUpload)
        ) {
          $this.$bridge.emit(
            "addToast",
            $this.$t.get("cloud-manager.selected-files-exceeds-the-maximum-upload-size"),
            "error"
          )
          $this.clearStates()
          $this.state = $this.states.READY
          return clearInterval(timer)
        }
        if ($this.countFiles === $this.countEntries) {
          if ($this.countFiles === 0) {
            $this.$bridge.emit(
              "addToast",
              $this.$t.get(
                "cloud-manager.no-files-to-upload-make-sure-your-files-have-valid-format-or-size"
              ),
              "error"
            )
            $this.clearStates()
            $this.state = $this.states.READY
          }
          if (
            $this.maxAmountLimit !== Infinity &&
            ($this.countFiles > $this.maxAmountLimit ||
              $this.countFiles + $this.uploaded.length > $this.maxAmountLimit)
          ) {
            $this.$bridge.emit(
              "addToast",
              $this.$t.get("cloud-manager.amount-files-limit-reached"),
              "error"
            )
            $this.clearStates()
            $this.state = $this.states.READY
          }
          if ($this.maxAmountLimit === Infinity || $this.countFiles < $this.maxAmountLimit) {
            $this.state = $this.states.UPLOADING
            return clearInterval(timer)
          }
          return clearInterval(timer)
        }
      }, 100)
    },
    upload(file) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            window.__CLOUD_URL + "/objects/" + this.objectId + "/storage",
            {
              content_type: file.type,
              size: file.file.size + "",
            },
            {
              withCredentials: true,
              headers: { Authorization: "Bearer " + window.USER_JWT },
            }
          )
          .then((response) => {
            let { link, item_id, aws_params } = response.data
            let fd = new FormData()
            let xhr = new XMLHttpRequest()

            forEach(aws_params, function (value, key) {
              if (key !== "attributes") fd.append(key, value)
            })
            fd.append("file", file.file)
            xhr.open(aws_params.attributes.method, aws_params.attributes.action, true)

            xhr.onerror = () => {
              reject(
                new Error(
                  "File not uploaded: " +
                    JSON.stringify({
                      status: this.status,
                      statusText: xhr.statusText,
                    })
                )
              )
            }
            xhr.upload.onprogress = (e) => {
              this.sizeTransferred += e.loaded / 100
            }
            xhr.onload = () => {
              resolve({
                link: link,
                cloudProxy: "/objects/" + this.objectId + "/storage/" + item_id,
                id: item_id,
                name: file.file.name,
                size: file.file.size,
                type: file.file.type,
              })
            }
            xhr.send(fd)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    onStateUploading() {
      this.$emit("processing", true)
      this.uploadStartTime = this.$moment()
      this.queues.uploading.setOnFinishListener(() => {
        this.state = this.states.UPLOADING_FINISHED
      })
      let $this = this
      this.files.forEach((file) => {
        this.queues.uploading.add(function () {
          return $this
            .upload(file)
            .then((item) => {
              $this.sizeUploadedTotal += item.size
              $this.uploaded.push(item)
            })
            .catch((response) => {
              $this.$bridge.emit(
                "addToast",
                $this.$t.get("common.something-went-wrong-please-try-again"),
                "error"
              )
            })
        })
      })
    },
    onStateUploadFinished() {
      this.clearStates()
      this.$emit("processing", false)
      this.$emit("finished", this.uploaded)

      this.$refs.fileInput.value = ""
    },
    getIconByItemType(type) {
      type = type.replace("s", "").replace("application/", "").replace("image/", "")
      if (["jpg", "jpeg", "jpg", "png"].indexOf(type) > -1) {
        return "image"
      }
      if (["pdf", "doc", "docx", "txt", "rtf"].indexOf(type) > -1) {
        return "pdf"
      }
      return "document"
    },
  },
}
