//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LocalMedia from "./LocalMedia.vue"
import * as VideoProcessors from "@twilio/video-processors"
import translate from "../../../helpers/translate"

export default {
  props: {
    availableBackgrounds: {
      require: true,
    },
    localTracks: {},
  },
  components: { "local-media": LocalMedia },
  data() {
    return {
      visible: false,
      blurVariants: [
        {
          label: translate.get("appointment.background-effects.none"),
          icon: "no-effect",
          value: 0,
        },
        {
          label: translate.get("appointment.background-effects.slightly"),
          icon: "blur-slightly",
          value: 5,
        },
        {
          label: translate.get("appointment.background-effects.blur"),
          icon: "blur",
          value: 10,
        },
      ],
      maskBlurRadius: 5,
      active: 0,
    }
  },
  watch: {
    imageVariants() {
      this.imageVariants.forEach(async (image) => await image.image())
    },
  },
  computed: {
    imageVariants() {
      return this.availableBackgrounds.map((background) => {
        return {
          preview: background.image_url,
          image: () =>
            new Promise((resolve) => {
              const image = new Image()
              image.onload = () => resolve(image)
              image.crossOrigin = "Anonymous"
              image.src = background.image_url
            }),
        }
      })
    },
    videoTrack() {
      return this.localTracks.find((track) => track.kind === "video")
    },
  },
  methods: {
    setVisibility(visible) {
      this.visible = visible
    },
    async setImage(image) {
      this.active = image.preview

      const background = new VideoProcessors.VirtualBackgroundProcessor({
        assetsPath: "/assets/app/tf/",
        backgroundImage: await image.image(),
        maskBlurRadius: this.maskBlurRadius,
      })

      this.setProcessor(background)
    },
    async setBlur(blur) {
      this.setVisibility(false)
      this.active = blur.value

      if (blur.value == 0) {
        return this.removeProcessor()
      }

      const background = new VideoProcessors.GaussianBlurBackgroundProcessor({
        assetsPath: "/assets/app/tf/",
        maskBlurRadius: this.maskBlurRadius,
        blurFilterRadius: blur.value,
      })

      this.setProcessor(background)
    },
    async setProcessor(processor) {
      this.setVisibility(false)

      await processor.loadModel()
      this.removeProcessor()
      this.videoTrack.addProcessor(processor)
    },
    async removeProcessor() {
      if (this.videoTrack.processor) {
        this.videoTrack.removeProcessor(this.videoTrack.processor)
      }
    },
  },
}
