import Pan from "./list/Pan"
import Zoom from "./list/Zoom"
import WWWC from "./list/WWWC"
import LengthMeasurement from "./list/LengthMeasurement"
import RectangleROI from "./list/RectangleROI"
import ArrowAnnotate from "./list/ArrowAnnotate"
import Pencil from "./list/Pencil"
import ClearDraws from "./list/ClearDraws"
import Metadata from "./list/Metadata"
import ViewActions from "./list/ViewActions"
import StackPrefetchBarTool from "./cornerstone/StackPrefetchBarTool"
import ArrowsStackScrollTool from "./cornerstone/ArrowsStackScrollTool"

import DataType from "../data/DataType"
import { cornerstoneTools } from "../cornerstone"
import { StackScrollTool, StackScrollMouseWheelTool } from "cornerstone-tools"

class Tools {
  constructor() {
    cornerstoneTools.init()

    cornerstoneTools.toolColors.setToolColor("#00ccb1")

    this.tools = {
      ZOOM: new Zoom(),
      WWWC: new WWWC(),
      // LengthMeasurement: new LengthMeasurement(),
      // RectangleROI: new RectangleROI(),
      ArrowAnnotate: new ArrowAnnotate(),
      Pencil: new Pencil(),
      ClearDraws: new ClearDraws(this),
      Metadata: new Metadata(),
    }

    this.defaultTool = new Pan()
    this.currentTool = null
  }

  addTools(tools) {
    this.tools = Object.assign(this.tools, tools)
  }

  init(viewerInstance, views) {
    this.defaultTool.bind(viewerInstance, views)

    Object.keys(this.tools).forEach((key) => {
      if (typeof this.tools[key].bind === "function") {
        this.tools[key].bind(viewerInstance, views)
      }
    })
  }

  bind(views) {
    views.forEach((view) => {
      if (view.viewType === DataType.DICOM || view.viewType === DataType.IMAGE) {
        const dataView = view.$refs.dataView
        const cornerstoneElement = dataView.$refs.view

        cornerstoneTools.addToolForElement(cornerstoneElement, StackScrollTool)
        cornerstoneTools.addToolForElement(cornerstoneElement, StackScrollMouseWheelTool)
        cornerstoneTools.addToolForElement(cornerstoneElement, StackPrefetchBarTool)
        cornerstoneTools.addToolForElement(cornerstoneElement, ArrowsStackScrollTool)

        cornerstoneTools.setToolActive("StackScroll", { mouseButtonMask: 1 })
        cornerstoneTools.setToolActive("StackScrollMouseWheel", { mouseButtonMask: 1 })
        cornerstoneTools.setToolActive("StackPrefetchBar")
        cornerstoneTools.setToolActive("ArrowsStackScroll")
      }
    })

    if (this.currentTool !== null) {
      const tool = this.tools[this.currentTool]
      this.activate(this.currentTool, tool, views)
    } else {
      this.default(views)
    }
  }

  /**
   * Bind default tool for DataViews
   *
   * @param [DataView] views
   */
  default(views) {
    if (this.currentTool !== null) {
      this.list[this.currentTool].deactivate(views)
    }
    this.currentTool = null
    this.defaultTool.activate(views)
  }

  activate(toolKey, tool, views) {
    this.defaultTool.deactivate(views)
    tool.activate(views)
    this.currentTool = toolKey
  }

  deactivate(toolKey, tool, views) {
    tool.deactivate(views)
    this.currentTool = null
    this.defaultTool.activate(views)
  }

  toggle(toolKey, tool, views) {
    tool.toggle(views)
  }

  click(toolKey, tool, views) {
    tool.click(views)
  }

  get list() {
    return this.tools
  }
}

export default new Tools()
