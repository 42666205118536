//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required } from "vuelidate/lib/validators"

export default {
  inject: ["context", "permissions"],
  data() {
    return {
      processing: false,
      members: [],
      member: null,
    }
  },
  validations: {
    member: {
      required,
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  mounted() {
    this.processing = true
    const permissionFilter = [this.permissions.GROUP_GLOBAL_REQUESTS_RECEIVE].join(",")
    this.$store
      .dispatch("user/getGroupMembers", {
        group_id: this.context.group_id,
        permissions: permissionFilter,
      })
      .then((response) => {
        this.members = response.data.data.filter(
          (member) =>
            member.user_id !== this.context.requester_id &&
            member.user_id !== this.context.assigned_id
        )
        this.processing = false
      })
  },
  methods: {
    assign() {
      const payload = {
        appointment_id: this.context.appointment_id,
        user_id: this.member.user_id,
      }
      this.processing = true
      this.$store
        .dispatch("appointment/assign", payload)
        .then((response) => {
          this.processing = false
          this.$bridge.emit("addToast", this.$t.get("appointment.member-assigned"), "success")
          this.$emit("success")
        })
        .catch((error) => {
          this.$bridge.emit("addToast", this.$t.get(error.response.data.message), "error")
          this.processing = false
        })
    },
  },
}
