//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sidebar: {
      type: String,
      default: "defaultSidebar",
    },
  },
  data() {
    return {
      components: {
        defaultSidebar: () => import("./Sidebar.vue"),
      },
    }
  },
}
