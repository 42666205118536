var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-box margin-top enquiries" },
    [
      _c(
        "v-tabs",
        { staticClass: "no-border", on: { changed: _vm.tabChanged } },
        [
          _c(
            "v-tab",
            {
              class: { "preloader preloader-cover": _vm.processing },
              attrs: {
                name: _vm.$t.get("customer.details.tabs.benchmark-list"),
                "tab-key": "benchmarkList",
              },
            },
            [
              _c("CustomerBenchmarkList", {
                attrs: { items: _vm.items.data, processing: _vm.processing },
              }),
              _vm._v(" "),
              _vm.items.last_page > 1
                ? _c(
                    "div",
                    { staticClass: "table-pagination" },
                    [
                      _c("vue-paginator", {
                        attrs: { "pagination-init": _vm.items },
                        on: { "set-page": _vm.getTabData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            {
              class: { "preloader preloader-cover": _vm.processing },
              attrs: {
                name: _vm.$t.get("customer.details.tabs.login-history"),
                "tab-key": "loginHistory",
              },
            },
            [
              _c("CustomerLoginHistory", {
                attrs: { items: _vm.items.data, processing: _vm.processing },
              }),
              _vm._v(" "),
              _vm.items.last_page > 1
                ? _c(
                    "div",
                    { staticClass: "table-pagination" },
                    [
                      _c("vue-paginator", {
                        attrs: { "pagination-init": _vm.items },
                        on: { "set-page": _vm.getTabData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            {
              class: { "preloader preloader-cover": _vm.processing },
              attrs: {
                name: _vm.$t.get("customer.details.tabs.audit-log"),
                "tab-key": "customerAuditLog",
              },
            },
            [
              _c("CustomerAuditLog", {
                attrs: { items: _vm.items.data, processing: _vm.processing },
              }),
              _vm._v(" "),
              _vm.items.last_page > 1
                ? _c(
                    "div",
                    { staticClass: "table-pagination" },
                    [
                      _c("vue-paginator", {
                        attrs: { "pagination-init": _vm.items },
                        on: { "set-page": _vm.getTabData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }