//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as datePickerLanguages from "vuejs-datepicker/dist/locale"

export default {
  data() {
    return {
      date: null,
      hour: null,
      minute: null,
    }
  },
  props: {
    state: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.state) {
      let parsedDate = this.$moment(this.state)
      this.date = this.state
      this.hour = parsedDate.format("HH")
      this.minute = parsedDate.format("mm")
    }
  },
  methods: {
    customFormatter(date) {
      return this.$moment(date).format(this.$dateTimeFormat.date)
    },
    emitUpdate() {
      this.$emit("updatedDatetime", {
        date: this.$moment(this.date).format("DD.MM.YYYY"),
        hour: this.hour,
        minute: this.minute,
      })
    },
  },
  watch: {
    date(_) {
      this.emitUpdate()
    },
    hour(_) {
      this.emitUpdate()
    },
    minute(_) {
      this.emitUpdate()
    },
    isRequired(val) {
      if (val === false) {
        this.date = null
        this.hour = null
        this.minute = null
      }
    },
  },
  computed: {
    datepickerLanguage() {
      const languageAdaptable = {
        af: "af",
        ar: "ar",
        bg: "bg",
        bs: "bs",
        ca: "ca",
        cs: "cs",
        da: "da",
        de: "de",
        ee: "ee",
        el: "el",
        en: "en",
        es: "es",
        fa: "fa",
        fi: "fi",
        fo: "fo",
        fr: "fr",
        ge: "ge",
        gl: "gl",
        he: "he",
        hu: "hu",
        hr: "hr",
        id: "id",
        is: "is",
        it: "it",
        ja: "ja",
        kk: "kk",
        ko: "ko",
        lb: "lb",
        lt: "lt",
        lv: "lv",
        mk: "mk",
        mn: "mn",
        "nb-No": "nbNO",
        nl: "nl",
        pl: "pl",
        "pt-br": "ptBR",
        ro: "ro",
        ru: "ru",
        sk: "sk",
        "sl-si": "slSI",
        sv: "sv",
        sr: "sr",
        "sr-cyrl": "srCyrl",
        th: "th",
        tr: "tr",
        uk: "uk",
        ur: "ur",
        vi: "vi",
        zh: "zh",
        "zh-hk": "zhHK",
      }
      return datePickerLanguages[languageAdaptable[this.$locale]]
    },
    availableHours() {
      let times = []
      let id = 1
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          times.push({
            id: id,
            value: "0" + i,
            label: this.$moment().hours(i).format(this.$dateTimeFormat.time.replace(":mm", "")),
          })
        } else {
          times.push({
            id: id,
            value: i.toString(),
            label: this.$moment().hours(i).format(this.$dateTimeFormat.time.replace(":mm", "")),
          })
        }
        id++
      }
      return times
    },
    availableMinutes() {
      let times = []
      let id = 1
      for (let i = 0; i < 12; i++) {
        if (i * 5 < 10) {
          times.push({
            id: id,
            value: "0" + i * 5,
          })
        } else {
          times.push({
            id: id,
            value: (i * 5).toString(),
          })
        }
      }
      return times
    },
  },
}
