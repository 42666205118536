import { find, toInteger } from "lodash"

export default {
  ROOM_MAIN_PA: -1, // main participant
  ROOM_GUEST_1: 0,
  ROOM_GUEST_2: 1,
  ROOM_GUEST_3: 2,
  ROOM_GUEST_4: 3,

  available_rooms: [
    { name: "ROOM_1_1", participants: 2 },
    { name: "ROOM_1_2", participants: 3 },
    { name: "ROOM_1_3", participants: 4 },
    { name: "ROOM_1_4", participants: 5 },
    { name: "ROOM_1_5", participants: 6 },
  ],

  available_participants: [
    { name: "ROOM_MAIN_PA", value: -1 },
    { name: "ROOM_GUEST_1", value: 0 },
    { name: "ROOM_GUEST_2", value: 1 },
    { name: "ROOM_GUEST_3", value: 2 },
    { name: "ROOM_GUEST_4", value: 3 },
  ],

  grid: {
    ROOM_1_1: {
      ROOM_MAIN_PA: { x: 0, y: 0, w: 100, h: 2, i: null },
    },
    ROOM_1_2: {
      ROOM_GUEST_1: { x: 5, y: 0, w: 30, h: 1, i: null },
      ROOM_MAIN_PA: { x: 35, y: 0, w: 60, h: 2, i: null },
    },
    ROOM_1_3: {
      ROOM_GUEST_1: { x: 5, y: 0, w: 30, h: 1, i: null },
      ROOM_GUEST_2: { x: 5, y: 30, w: 30, h: 1, i: null },
      ROOM_MAIN_PA: { x: 35, y: 0, w: 60, h: 2, i: null },
    },
    ROOM_1_4: {
      ROOM_GUEST_1: { x: 0, y: 0, w: 25, h: 1, i: null },
      ROOM_GUEST_2: { x: 0, y: 25, w: 25, h: 1, i: null },
      ROOM_MAIN_PA: { x: 25, y: 0, w: 50, h: 2, i: null },
      ROOM_GUEST_3: { x: 75, y: 0, w: 25, h: 1, i: null },
    },
    ROOM_1_5: {
      ROOM_GUEST_1: { x: 0, y: 0, w: 25, h: 1, i: null },
      ROOM_GUEST_2: { x: 0, y: 25, w: 25, h: 1, i: null },
      ROOM_MAIN_PA: { x: 25, y: 0, w: 50, h: 2, i: null },
      ROOM_GUEST_3: { x: 75, y: 0, w: 25, h: 1, i: null },
      ROOM_GUEST_4: { x: 75, y: 25, w: 25, h: 1, i: null },
    },
  },

  get(identity, position, count) {
    let room = find(this.available_rooms, { participants: toInteger(count) })
    console.log("position")
    console.log(position)
    let seat = find(this.available_participants, { value: toInteger(position) })
    console.log("room.name")
    console.log(room.name)
    console.log("seat.name")
    console.log(seat.name)
    let properties = this.grid[room.name][seat.name]
    properties.i = identity
    return properties
  },
}
