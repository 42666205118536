//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../plugins/Customize"
const id = "dropboxjs"
export default {
  mixins: [Customize()],
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: "button accent small",
    },
    parentName: {
      type: String,
      default: null,
    },
  },
  computed: {
    available() {
      return document.getElementById(id) !== null
    },
  },
  beforeMount() {
    this.appendElement()
  },
  methods: {
    appendElement() {
      if (this.available === true) {
        return
      }
      let dropBox = document.createElement("script")
      dropBox.setAttribute("src", "https://www.dropbox.com/static/api/2/dropins.js")
      dropBox.setAttribute("id", "dropboxjs")
      dropBox.setAttribute("data-app-key", this.apiKey)
      document.head.appendChild(dropBox)
    },
    startImport() {
      let options = {
        success: async (files) => {
          let selected = files.map((file) => {
            return {
              size: file.bytes,
              id: file.id,
              name: file.name,
              url: file.link,
              isDir: file.isDir,
              provider: "dropbox",
            }
          })
          this.$emit("selected", { provider: "dropbox", attached: selected })
        },
        linkType: "preview",
        multiselect: true,
        extensions: [".pdf", ".jpg", "jpeg"],
        folderselect: true,
        sizeLimit: 350000000,
      }
      Dropbox.choose(options)
    },
  },
}
