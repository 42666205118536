import Vue from "../vue.js"

import { merge } from "lodash"

export default {
  namespaced: true,
  state: {
    thread_id: null,
    message: null,
    ip_address: null,
    message_id: null,
    recipient_id: null,
    sender_id: null,
    type: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  mutations: {
    updateNote(state, { body }) {
      state = merge(state, body)
    },
  },
  actions: {
    fetch({ commit }, { thread_id }) {
      return Vue.http.get("/ajax/thread/" + thread_id + "/note").then((response) => {
        commit("updateNote", { body: response.data.data })
      })
    },
    sendNote(context, content) {
      return Vue.http
        .post("/ajax/thread/" + context.state.thread_id + "/note", {
          content: content,
        })
        .then((response) => {
          context.commit("updateNote", { body: response.data.data })
        })
    },
  },
}
