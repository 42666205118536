//
//
//
//
//
//
//
//

import { castQueryStringToObject } from "../../../../../utils"
import CustomerDetailsSection from "./CustomerDetailsSection.vue"

export default {
  components: { CustomerDetailsSection },
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
  },
  data() {
    return {
      componentData: [],
    }
  },
  methods: {
    getComponentData(queryString) {
      let params = {
        action: "getProductDetails",
        componentId: this.componentId,
      }
      params = { ...params, ...queryString }

      this.processing = true
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.processing = false
          this.componentData = response.data.data
        })
        .catch((response) => {
          this.processing = false
          this.error = response.response.data.message
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
  },
  created() {
    this.getComponentData(castQueryStringToObject())
  },
}
