var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    {
      scopedSlots: _vm._u([
        {
          key: "selected",
          fn: function (prop) {
            return [
              _c("div", { staticClass: "language" }, [
                _c("img", {
                  staticClass: "flag",
                  attrs: {
                    src:
                      "/assets/app/img/flags/" +
                      prop.selectedItem.value +
                      ".svg",
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  domProps: { textContent: _vm._s(prop.selectedItem.label) },
                }),
              ]),
            ]
          },
        },
        {
          key: "item",
          fn: function (prop) {
            return [
              _c("img", {
                staticClass: "flag",
                attrs: {
                  src: "/assets/app/img/flags/" + prop.item.value + ".svg",
                },
              }),
              _vm._v(" "),
              _c("span", {
                domProps: { textContent: _vm._s(prop.item.label) },
              }),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._l(_vm.locales, function (locale, index) {
        return _c("v-option", {
          key: index,
          attrs: { label: locale.label, value: locale.value },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }