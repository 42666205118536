//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { castQueryStringToObject } from "../../../../../utils"
import { Constants } from "../../../../models/Constants"

export default {
  props: {
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
    customerId: {}, // uuid or null
  },
  data() {
    return {
      Constants: Constants,
      currentPage: 1,
      enquires: {
        data: [],
        total: 0,
      },
      processing: false,
    }
  },
  methods: {
    getComponentData(page) {
      if (this.customerId === null) {
        return
      }

      let params = {
        action: "customerEnquires",
        componentId: this.componentId,
      }

      this.currentPage = page

      params = {
        ...params,
        customerId: this.customerId,
        page: this.currentPage,
      }

      this.processing = true
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.enquires = response.data.data
          this.processing = false
        })
        .catch((response) => {
          this.processing = false
          this.error = response.response.data.message
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
  },
  created() {
    this.getComponentData(1)
  },
}
