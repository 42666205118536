var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex flex-wrap flex-spacing" }, [
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("v-select", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: _vm.$t.get("common.select-hour"),
                  options: _vm.availableHours,
                  "label-key": "value",
                  "value-key": "value",
                },
                model: {
                  value: _vm.hour,
                  callback: function ($$v) {
                    _vm.hour = $$v
                  },
                  expression: "hour",
                },
              }),
              _vm._v(" "),
              _c("label", [_vm._t("default")], 2),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-full lg:w-6/12 columns" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("v-select", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: _vm.$t.get("common.select-minute"),
                  options: _vm.availableMinutes,
                  "label-key": "value",
                  "value-key": "value",
                },
                model: {
                  value: _vm.minute,
                  callback: function ($$v) {
                    _vm.minute = $$v
                  },
                  expression: "minute",
                },
              }),
              _vm._v(" "),
              _c("label"),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.validation
        ? _c("v-input-error", { attrs: { validation: _vm.validation } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }