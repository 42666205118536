import Vue from "../vue.js"

import Modal from "../plugins/modal/modal.js"
import TwoFactor from "../components/common/TwoFactorAuth.vue"
import Translate from "../../helpers/translate"

const defaultSendMethod = {
  available: true,
  label: Translate.get("auth.two-factor-send-code-by-email"),
  onSuccessLabel: Translate.get("auth.two-factor-email-has-been-sent"),
  icon: "fa-envelope-o",
  hint: Translate.get("auth.two-factor-check-your-email-account"),
  method_id: 1,
}

const twoFactorStore = {
  namespaced: true,
  state: {
    processing: false,
    input: null,
    callback: null,
    generated: false,
    sendMethods: [],
    lastUsedMethod: null,
  },
  mutations: {
    setProcessing(state, value) {
      state.processing = value
    },
    setGenerated(state, value) {
      state.generated = value
    },
    setLastUsedMethod(state, method) {
      state.lastUsedMethod = method
    },
    updateState(state, { callback, sendMethods }) {
      state.callback = callback
      if (sendMethods !== undefined) {
        state.sendMethods = sendMethods
      }
    },
    updateMethods(state, sendMethods) {
      Vue.set(state, "sendMethods", sendMethods)
    },
    clearState(state) {
      state.callback = null
      state.input = null
      state.generated = false
    },
  },
  actions: {
    perform({ commit, dispatch, state }, values) {
      commit("updateState", values)

      let { sendMethods, asModal } = values

      if (asModal === undefined || asModal === true) {
        const clearState = () => {
          commit("clearState")
        }

        Modal.create(new Vue({ store: this }))
          .title(Translate.get("auth.two-factor-authentication"))
          .component(TwoFactor)
          .closeable(true)
          .events({
            success: clearState,
            error: clearState,
            close: clearState,
            cancel: clearState,
          })
          .show()
      }

      if (sendMethods === undefined || sendMethods.len === 0) {
        dispatch("getSendMethods")
      }
    },
    getSendMethods({ commit }) {
      commit("setProcessing", true)
      return Vue.http
        .get("/twoFactorAuth/methods")
        .then((response) => {
          commit("updateMethods", response.data.data)
          commit("setProcessing", false)
        })
        .catch((response) => {
          commit("setProcessing", false)
        })
    },
    generateCode({ commit }, input) {
      commit("setProcessing", true)
      commit("setLastUsedMethod", input.method_id)

      return new Promise((resolve, reject) => {
        Vue.http
          .post("/twoFactorAuth/generate", input)
          .then((response) => {
            commit("setProcessing", false)
            commit("setGenerated", true)
            resolve(response)
          })
          .catch((response) => {
            commit("setProcessing", false)
            reject(response)
          })
      })
    },
    inputCode({ commit, dispatch, state }, input) {
      commit("setProcessing", true)

      return new Promise((resolve, reject) => {
        let { twoFactorAuthCode } = input
        if (twoFactorAuthCode === undefined) {
          reject({ body: { message: Translate.get("auth.two-factor-error-code-not-provided") } })
        }
        Vue.http
          .post("/twoFactorAuth/validate", input || {})
          .then((response) => {
            state.callback(response, twoFactorAuthCode)
            commit("clearState")
            resolve(response)
          })
          .catch((response) => {
            commit("setProcessing", false)
            reject(response)
          })
      })
    },
    configure({ commit, dispatch, state }, input) {
      return Vue.http.post("/twoFactorAuth/configure", input || {})
    },
    configureMethod({ commit, dispatch, state }, { method_id, input }) {
      return Vue.http.post(`/twoFactorAuth/methods/${method_id}/configure`, input || {})
    },
  },
  getters: {
    isProcessing: (state) => {
      return state.processing
    },
  },
}

export { defaultSendMethod, twoFactorStore }
