import Vue from "../vue.js"

import { intersection, merge } from "lodash"

import { Permissions } from "../models/Permissions"

export default {
  namespaced: true,
  state: window.__USER ? JSON.parse(JSON.stringify(window.__USER)) : null,
  mutations: {
    setPendingInvitationsCount(state, count) {
      Vue.set(state, "pending_invitations_count", count)
    },
    clearRemovalRequest(state) {
      Vue.set(state, "removal_request_at", null)
    },
  },
  actions: {
    getGroupMembers({ commit }, { group_id, permissions }) {
      let qs = "?"
      qs = permissions === undefined ? "" : `${qs}permissions=${permissions}`
      return Vue.http.get(`/ajax/organizations/${group_id}/manage/members${qs}`)
    },
    getRolesAndPermissions({ commit }) {
      return Vue.http.get(`/ajax/organizations/roles`)
    },
    getGroupMemberDetails({ commit }, { group_id, group_member_id }) {
      return Vue.http.get(`/ajax/organizations/${group_id}/manage/members/${group_member_id}`)
    },
    setMemberRole({ commit }, { group_id, group_member_id, role_id }) {
      return Vue.http.post(
        `/ajax/organizations/${group_id}/manage/members/${group_member_id}/role`,
        {
          role_id: role_id,
        }
      )
    },
    inviteGroupMember({ commit }, member) {
      return Vue.http.post(`/ajax/organizations/${member.group_id}/manage/members`, member)
    },
    removeGroupMember({ commit }, { group_id, group_member_id }) {
      return Vue.http.post(
        `/ajax/organizations/${group_id}/manage/members/${group_member_id}/remove`
      )
    },
    setBasicInfo({ commit }, data) {
      return Vue.http.post(`/ajax/organizations/${data.get("group_id")}/manage/basic_info`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    getGroupConsents({ commit }, group_id) {
      return Vue.http.get(`/ajax/organizations/${group_id}/manage/consents`)
    },
    getGroupTranslationConsents({ commit }, { group_id, consent_id }) {
      return Vue.http.get(`/ajax/organizations/${group_id}/manage/consents/${consent_id}`)
    },
    saveGroupConsent({ commit }, { group_id, consent }) {
      return Vue.http.post(`/ajax/organizations/${group_id}/manage/consents`, consent)
    },
    saveGroupConsentVisibiliy({ commit }, { group_id, consent_id, visible }) {
      return Vue.http.post(
        `/ajax/organizations/${group_id}/manage/consents/${consent_id}/visible`,
        { visible: visible }
      )
    },
    saveGroupBank({ commit }, data) {
      return Vue.http.post(`/ajax/organizations/${data.group_id}/manage/bank`, data)
    },
    saveGroupInvoice({ commit }, data) {
      return Vue.http.post(`/ajax/organizations/${data.group_id}/manage/invoice`, data)
    },
    socket_pending_invitations_count(context, count) {
      context.commit("setPendingInvitationsCount", count)
    },
  },
  getters: {
    belongsToAnyGroup: (state) => {
      return state.groups === undefined ? false : state.groups.length > 0
    },
    hasPatientProfiles: (state) => {
      return state.patient_profiles === undefined ? false : state.patient_profiles.length !== 0
    },
    getGroups: (state) => {
      return state.groups
    },
    getGroupById: (state) => (group_id) => {
      const group = state.groups.find((group) => group.group_id === group_id)

      if (group === undefined) {
        return null
      }
      return group
    },
    getGroupMember: (state, getters) => (group_id) => {
      const group = getters.getGroupById(group_id)

      if (group === undefined || group === null) {
        return null
      }
      return group.member
    },
    hasGroupPermission: (state, getters) => (group_id, permission_name) => {
      const member = getters.getGroupMember(group_id)
      if (member === null) {
        return false
      }
      return member.permissions.includes(permission_name)
    },
    canManageGroup:
      (state, getters) =>
      (
        group_id,
        permissions = [
          Permissions.GROUP_MANAGE_BASIC_INFO,
          Permissions.GROUP_MANAGE_DEFAULT_PRICING,
          Permissions.GROUP_MANAGE_MEMBERS,
          Permissions.GROUP_MANAGE_SET_SETTLEMENTS_INFO,
        ]
      ) => {
        const member = getters.getGroupMember(group_id)
        if (member === null) {
          return false
        }

        return intersection(permissions, member.permissions).length > 0
      },
    getPendingInvitationsCount: (state) => {
      return state.pending_invitations_count
    },
  },
}
