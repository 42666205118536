import Vue from "../vue.js"

export default {
  namespaced: true,
  state: {
    payments: false,
  },
  mutations: {
    updatePaymentsStatus(state, payments) {
      state.payments = payments
    },
  },
  actions: {
    async fetchEnabledPayments({ commit }) {
      try {
        const productData = JSON.parse(window.__CURRENT_PRODUCT)
        const productId = productData.product_id
        const response = await Vue.http.get(`/organizations/${productId}/enabled_payments`)
        commit("updatePaymentsStatus", response.data.payments)
      } catch (error) {
        console.error("Error fetching enabled payments:", error)
        throw error
      }
    },
  },
}
